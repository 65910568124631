import { BuyerEvent, Pixel } from '@propps-au/pixel-analytics-types'

const config = (amplitudeEventName: string): Pixel.EventConfig => ({
  amplitude: {
    dispatch: true,
    event: amplitudeEventName,
  },
  mailroom: {
    dispatch: true,
    topic: Pixel.PixelEventTopic.Frames,
  },
})

export const BuyerEventConfigs: Partial<
  Record<Pixel.BuyerEvent.EventType, Pixel.EventConfig>
> = {
  [BuyerEvent.VIEW_LISTING_PAGE]: config('listing page visited'),
  [BuyerEvent.OPEN_OFFER_FRAME]: config('open offer frame'),
  [BuyerEvent.VIEW_OFFER_HUB]: config('view offer hub'),
  [BuyerEvent.VIEW_LISTING_DOCUMENTS]: config('view listing documents'),
  [BuyerEvent.VIEW_LOGIN]: config('view login'),
  [BuyerEvent.VIEW_LOGIN_VERIFY]: config('view login verify'),
  [BuyerEvent.SWITCH_TO_EMAIL_LOGIN]: config('switch to email login'),
  [BuyerEvent.SWITCH_TO_SMS_LOGIN]: config('switch to sms login'),
  [BuyerEvent.RESEND_CODE]: config('resend code'),
  [BuyerEvent.VIEW_LOGIN_PHONE]: config('view primary buyer phone number'),
  [BuyerEvent.VIEW_LOGIN_SMS_VALIDATE]: config(
    'view primary buyer sms verification'
  ),
  [BuyerEvent.VIEW_LOGIN_REGISTER]: config('view primary buyer basic details'),
  [BuyerEvent.VIEW_LOGIN_QR_CODE]: config('view qr code'),
  [BuyerEvent.DOWNLOAD_QR_CODE]: config('download qr code'),
  [BuyerEvent.LOGIN]: config('login'),
  [BuyerEvent.LOGOUT]: config('logout'),
  [BuyerEvent.REGISTER]: config('register'),
  [BuyerEvent.VIEW_OFFER_OVERVIEW]: config('view overview'),
  [BuyerEvent.VIEW_OFFER_PROPERTY_AGENT_DETAILS]: config(
    'view property and agent details'
  ),
  [BuyerEvent.VIEW_OFFER_AMOUNT]: config('view amount'),
  [BuyerEvent.VIEW_OFFER_SETTLEMENT]: config('view settlement'),
  [BuyerEvent.VIEW_OFFER_CONDITIONS]: config('view conditions'),
  [BuyerEvent.VIEW_OFFER_FINANCE]: config('view finance'),
  [BuyerEvent.VIEW_OFFER_CONVEYANCER]: config('view conveyancer'),
  [BuyerEvent.VIEW_OFFER_DOCUMENTS]: config('view documents'),
  [BuyerEvent.VIEW_OFFER_BUYERS]: config('view buyers'),
  [BuyerEvent.VIEW_OFFER_ADD_BUYER]: config('view add buyer'),
  [BuyerEvent.VIEW_OFFER_ADD_BUYER_SMS_VALIDATE]: config(
    'view additional buyer sms verification'
  ),
  [BuyerEvent.VIEW_OFFER_BUYER]: config('view identity'),
  [BuyerEvent.VIEW_OFFER_AGREEMENTS]: config('view agreements'),
  [BuyerEvent.VIEW_OFFER_SIGNATURE]: config('view signing'),
  [BuyerEvent.VIEW_OFFER_SELLING]: config('view selling'),
  [BuyerEvent.COMPLETE_OFFER_AMOUNT]: config('complete amount'),
  [BuyerEvent.COMPLETE_OFFER_SETTLEMENT]: config('complete settlement'),
  [BuyerEvent.ACCEPT_OFFER_CONDITION]: config('accept condition'),
  [BuyerEvent.REMOVE_OFFER_CONDITION]: config('remove condition'),
  [BuyerEvent.ADD_OFFER_EXPIRY]: config('add expiry'),
  [BuyerEvent.REMOVE_OFFER_EXPIRY]: config('remove expiry'),
  [BuyerEvent.COMPLETE_OFFER_CONDITIONS]: config('complete conditions'),
  [BuyerEvent.COMPLETE_OFFER_FINANCE]: config('complete finance'),
  [BuyerEvent.COMPLETE_OFFER_CONVEYANCER]: config('complete conveyancer'),
  [BuyerEvent.COMPLETE_OFFER_BUYER]: config('complete identity'),
  [BuyerEvent.REMOVE_OFFER_BUYER]: config('remove buyer'),
  [BuyerEvent.COMPLETE_OFFER_SELLING]: config('complete selling'),
  [BuyerEvent.SUBMIT_OFFER]: config('submitted offer'),
  [BuyerEvent.UPDATE_OFFER]: config('submitted updated offer'),
  [BuyerEvent.VIEW_PTB_OVERVIEW]: config('view prepare overview'),
  [BuyerEvent.VIEW_PTB_AMOUNT]: config('view prepare offer amount'),
  [BuyerEvent.VIEW_PTB_SETTLEMENT]: config('view prepare settlement'),
  [BuyerEvent.VIEW_PTB_CONDITIONS]: config('view prepare conditions'),
  [BuyerEvent.VIEW_PTB_FINANCE]: config('view prepare finance'),
  [BuyerEvent.VIEW_PTB_CONVEYANCER]: config('view prepare conveyancer'),
  [BuyerEvent.VIEW_PTB_BUYERS]: config('view prepare buyers'),
  [BuyerEvent.VIEW_PTB_ADD_BUYER]: config('view prepare add buyer'),
  [BuyerEvent.VIEW_PTB_ADD_BUYER_SMS_VALIDATE]: config(
    'view prepare additional buyer sms verification'
  ),
  [BuyerEvent.VIEW_PTB_BUYER]: config('view prepare identity'),
  [BuyerEvent.VIEW_PTB_DOCUMENTS]: config('view prepare documents'),
  [BuyerEvent.UPGRADE_PTB_TO_OFFER]: config('upgraded draft offer'),
  [BuyerEvent.DOWNLOAD_DOCUMENT]: config('download document'),
  [BuyerEvent.BUYER_START_SESSION]: {
    amplitude: {
      dispatch: false,
    },
    mailroom: {
      dispatch: true,
      topic: Pixel.PixelEventTopic.Frames,
    },
  },
  [BuyerEvent.CLAIM_LITE_OFFER]: config('[form] [add offer] claim lite offer'),
}
