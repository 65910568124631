/** @jsx jsx */
import { gql } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import { Activity, color, pxToRem, screen } from '@propps-au/ui'
import { ListingActivity_ListingBuyerAnnouncementSentEventFragment } from './__generated__/listing-buyer-announcement-sent.generated'

export const styles = {
  preformatted: css`
    margin-top: ${pxToRem(4)};
    margin-bottom: ${pxToRem(8)};
    padding: ${pxToRem(8)} ${pxToRem(16)};
    background: ${color.grey10};
    border-radius: 0 ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)};
    white-space: break-spaces;

    @media ${screen.sm} {
      margin-bottom: 0;
      max-width: ${pxToRem(328)};
    }
  `,
}

export const ListingBuyerAnnouncementSentEvent = ({
  event,
}: {
  event: ListingActivity_ListingBuyerAnnouncementSentEventFragment
}) => {
  const agent = event.announcement.agents[0]
  return (
    <Activity.Event
      firstName={agent?.firstName ?? 'An agent'}
      lastName={agent?.lastName ?? ''}
      createdAt={event.createdAt}
      title=" sent you a direct message"
    >
      <p css={styles.preformatted}>
        {event.announcement.message + `\nView ${event.announcement.shortUrl}`}
      </p>
    </Activity.Event>
  )
}

gql`
  fragment ListingActivity_ListingBuyerAnnouncementSentEvent on ListingBuyerAnnouncementSentEvent {
    createdAt
    eventId
    buyer {
      id
      firstName
      lastName
    }
    announcement {
      id
      message
      shortUrl
      agents {
        id
        firstName
        lastName
      }
    }
  }
`
