export type Deferred<T> = {
  promise: Promise<T>
  resolve: (value: T) => void
  reject: (error: any) => void
}

/**
 * Creates an pending promise and captures the resolve
 * and reject callbacks to be settled later.
 */
export function createDeferred<T>() {
  const deferred: Deferred<T> = {
    promise: null as any as Promise<T>,
    resolve: () => {},
    reject: () => {},
  }

  const promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  deferred.promise = promise

  return deferred
}
