import { SplitFactory, useClient } from '@splitsoftware/splitio-react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
const ANONYMOUS_KEY = 'anonymous';
export function create(overrides) {
    function useFeature(key, feature, options = {}) {
        key = key !== null && key !== void 0 ? key : ANONYMOUS_KEY;
        const client = useClient(key);
        if (!client) {
            throw new Error('useFeature must be called within a SplitProvider');
        }
        const [attributes, setAttributes] = useState(options.attributes || {});
        const [treatment, setTreatment] = useState(() => client.getTreatmentWithConfig(feature, attributes));
        useEffect(() => {
            if (options.attributes && isDifferent(options.attributes, attributes)) {
                setAttributes(options.attributes);
            }
        }, [options.attributes]);
        const update = useCallback(() => {
            const value = client.getTreatmentWithConfig(feature, attributes);
            if (value.treatment === 'control')
                return;
            setTreatment(value);
        }, [client, feature, attributes]);
        useEffect(() => {
            update();
            let cancelled = false;
            client.ready().then(() => {
                if (cancelled)
                    return;
                update();
            });
            return () => {
                cancelled = true;
            };
        }, [update, client]);
        useEffect(() => {
            if (options.subscribe) {
                client.on(client.Event.SDK_UPDATE, update);
                return () => {
                    client.off(client.Event.SDK_UPDATE, update);
                };
            }
        }, [client, options.subscribe, update]);
        if (treatment.treatment === 'control') {
            return undefined;
        }
        return {
            enabled: treatment.treatment === 'on',
            config: treatment.config,
        };
    }
    const SplitProvider = ({ apiKey, children, }) => {
        const config = {
            core: {
                authorizationKey: apiKey,
                key: ANONYMOUS_KEY,
            },
            features: overrides,
            streamingEnabled: false,
        };
        return (React.createElement(SplitFactory, { config: config, updateOnSdkTimedout: true },
            React.createElement(Fragment, null, children)));
    };
    return { useFeature, SplitProvider };
}
/**
 * Shallow object diff
 */
function isDifferent(a, b) {
    const aMatch = Object.keys(a).every((key) => b.hasOwnProperty(key) && equals(a[key], b[key]));
    const bMatch = Object.keys(b).every((key) => a.hasOwnProperty(key) && equals(a[key], b[key]));
    return !(aMatch && bMatch);
}
function equals(a, b) {
    if (typeof a !== typeof b)
        return false;
    if (Array.isArray(a)) {
        if (!Array.isArray(b))
            return false;
        if (a.length !== b.length)
            return false;
        return a.every((val) => b.includes(val));
    }
    return a === b;
}
