/** @jsx jsx */
import { gql } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Alert,
  Article,
  ArticleTitle,
  Button,
  color,
  CommonError,
  DropdownMenu,
  DropdownMenuItem,
  fontSize,
  Icon,
  IconChevronRight,
  IconCircleInfo2,
  IconCircleMinus,
  IconCirclePlus2,
  IconCircleTick,
  IconListBullets,
  List,
  ListItem,
  SectionTitle,
  Title,
} from '@propps-au/ui'
import { format } from 'date-fns'
import { Fragment, useEffect, useState } from 'react'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { useOfferForm } from '../context'
import { OfferFormMode } from '../mode'
import { getStatus } from '../status'
import { Condition, OfferFormValues } from '../values'
import { Conditions_ListingFragment } from './__generated__/conditions.generated'

export function hasReviewedMandatoryConditions(
  lines: Conditions_ListingFragment['conditions'],
  values: OfferFormValues
) {
  const mandatory = lines.filter(
    (condition: Conditions_ListingFragment['conditions'][0]) =>
      condition.currentRevision.isRequired
  )

  return mandatory.every((line: Conditions_ListingFragment['conditions'][0]) =>
    values.conditions.some(
      (item: Condition) =>
        item.region === line.region && item.line === line.name
    )
  )
}

export function hasAcceptedAdditionalConditions(
  lines: Conditions_ListingFragment['conditions'],
  values: OfferFormValues
) {
  const optional = lines.filter(
    (condition: Conditions_ListingFragment['conditions'][0]) =>
      !condition.currentRevision.isRequired
  )

  return (
    optional.some((line: Conditions_ListingFragment['conditions'][0]) =>
      values.conditions.some(
        (item: Condition) =>
          item.region === line.region && item.line === line.name
      )
    ) ||
    !!values.customCondition ||
    !!values.expiry
  )
}

export function Conditions({
  listing,
  goToCondition,
  goToCustomCondition,
  goToExpiry,
  onContinue,
  label = 'Done',
}: {
  listing: Conditions_ListingFragment
  goToCondition: (line: string) => void
  goToCustomCondition: () => void
  goToExpiry: () => void
  onContinue: () => void
  label?: string
}) {
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbConditions>({
        type: BuyerEvent.VIEW_PTB_CONDITIONS,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferConditions>({
        type: BuyerEvent.VIEW_OFFER_CONDITIONS,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    update((s) => ({
      ...s,
      hasReviewedConditions: true,
    }))
    // run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    optional,
    mandatory,
    hasCondition,
    acceptConditionalOffers,
    hasCustomCondition,
    hasExpiry,
    hasOptionalConditions,
    hasReviewedMandatory,
    isConditionalOffer,
  } = getConditionStatus({ state, listing })

  const [showOptional, setShowOptional] = useState(isConditionalOffer)
  const isOptionalShowing = showOptional || isConditionalOffer

  const [isSubmitted, setSubmitted] = useState(false)

  const onRemoveCondition = (
    line: Conditions_ListingFragment['conditions'][0]
  ) => {
    update((s) => ({
      ...s,
      conditions: s.conditions.filter(
        (item) => item.line !== line.name || item.region !== line.region
      ),
    }))

    analytics.logPixelEvent<Pixel.BuyerEvent.RemoveOfferCondition>({
      type: BuyerEvent.REMOVE_OFFER_CONDITION,
      conditionName: line.name,
      conditionRegion: line.region,
      ...analytics.getEventMetadata(),
    })
  }

  const onRemoveCustomCondition = () => {
    update((s) => ({
      ...s,
      customConditionAdded: false,
    }))
  }

  const onRemoveExpiry = () => {
    update((s) => ({
      ...s,
      expiry: null,
    }))
    analytics.logPixelEvent<Pixel.BuyerEvent.RemoveOfferExpiry>({
      type: BuyerEvent.REMOVE_OFFER_EXPIRY,
      ...analytics.getEventMetadata(),
    })
  }

  const handleContinue = () => {
    setSubmitted(true)

    if (!hasReviewedMandatory) return
    if (!acceptConditionalOffers && isConditionalOffer) return

    analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferConditions>({
      type: BuyerEvent.COMPLETE_OFFER_CONDITIONS,
      ...analytics.getEventMetadata(),
    })

    onContinue()
  }

  return (
    <Fragment>
      <Title>Offer conditions</Title>
      {!!mandatory.length && (
        <Article>
          <ArticleTitle>Mandatory conditions</ArticleTitle>
          <List>
            {mandatory.map((line) => (
              <ListItem
                onClick={() => goToCondition(line.name)}
                key={line.name}
                start={
                  hasCondition(line) ? (
                    <Icon svg={IconCircleTick} aria-label="Added:" />
                  ) : (
                    <Icon
                      svg={IconCircleInfo2}
                      fill={color.orange}
                      aria-label="Add:"
                    />
                  )
                }
                end={<Icon svg={IconChevronRight} aria-label="View" />}
              >
                {line.currentRevision.title}
              </ListItem>
            ))}
          </List>

          {isSubmitted && !hasReviewedMandatory && (
            <CommonError>
              All conditions need to be reviewed before continuing.
            </CommonError>
          )}
        </Article>
      )}

      <Article>
        <ArticleTitle>Optional conditions</ArticleTitle>
        {acceptConditionalOffers ? (
          <Alert title="Adding optional conditions">
            An offer without optional conditions is more favourable and more
            likely to be accepted. Including conditions, however, may reduce
            some risk.
          </Alert>
        ) : (
          <Alert
            title="Unconditional offers only"
            variant={isConditionalOffer ? 'warning' : 'info'}
          >
            The vendor is accepting unconditional offers only for this property.
            Please contact the agent for more information.
          </Alert>
        )}

        {isOptionalShowing ? (
          <Fragment>
            {(hasOptionalConditions || hasCustomCondition || hasExpiry) && (
              <Fragment>
                <SectionTitle>Selected conditions</SectionTitle>
                <List>
                  {optional.map(
                    (line) =>
                      hasCondition(line) && (
                        <ConditionItem
                          onClick={() => goToCondition(line.name)}
                          title={line.currentRevision.title}
                          onRemove={() => onRemoveCondition(line)}
                          key={line.name}
                        />
                      )
                  )}
                  {state.expiry ? (
                    <ConditionItem
                      onClick={() => goToExpiry()}
                      title="Offer expiry"
                      description={`Valid until ${format(
                        state.expiry,
                        'H:mma dd/MM/yyyy'
                      )}`}
                      onRemove={onRemoveExpiry}
                    />
                  ) : null}
                  {hasCustomCondition && (
                    <ConditionItem
                      onClick={() => goToCustomCondition()}
                      title="Custom condition"
                      onRemove={onRemoveCustomCondition}
                    />
                  )}
                </List>
              </Fragment>
            )}

            {acceptConditionalOffers &&
              (optional.some((line) => !hasCondition(line)) ||
                !hasExpiry ||
                !hasCustomCondition) && (
                <Fragment>
                  <SectionTitle>Add Optional conditions</SectionTitle>
                  <List>
                    {optional.map(
                      (line) =>
                        !hasCondition(line) && (
                          <NewConditionItem
                            title={line.currentRevision.title}
                            onClick={() => goToCondition(line.name)}
                            key={line.name}
                          />
                        )
                    )}
                    {!state.expiry ? (
                      <NewConditionItem
                        title="Offer expiry"
                        onClick={goToExpiry}
                      />
                    ) : null}
                    {!hasCustomCondition && (
                      <NewConditionItem
                        title="Custom condition"
                        onClick={goToCustomCondition}
                      />
                    )}
                  </List>
                </Fragment>
              )}
          </Fragment>
        ) : (
          acceptConditionalOffers && (
            <Button onClick={() => setShowOptional(true)}>
              Review optional conditions
            </Button>
          )
        )}
        {!acceptConditionalOffers && isConditionalOffer && (
          <CommonError>
            Offers must be unconditional (excluding mandatory conditions) for
            this property. Removing conditions may make your offer more
            attractive to the vendor but may increase your risk.
          </CommonError>
        )}
      </Article>
      <PrimaryButton onClick={handleContinue} label={label} />
    </Fragment>
  )
}

function NewConditionItem({
  title,
  onClick,
}: {
  title: string
  onClick: () => void
}) {
  return (
    <ListItem
      onClick={onClick}
      start={<Icon svg={IconCirclePlus2} aria-label="Add:" />}
      end={<Icon svg={IconChevronRight} aria-label="View" />}
    >
      {title}
    </ListItem>
  )
}

function ConditionItem({
  title,
  description,
  onClick,
  onRemove,
}: {
  title: string
  description?: string
  onClick: () => void
  onRemove: () => void
}) {
  return (
    <ListItem
      onClick={onClick}
      start={<Icon svg={IconCircleTick} aria-label="Added:" />}
      end={
        <div css={styles.dropdownMenu}>
          <DropdownMenu
            size="sm"
            icon="Menu"
            dark
            aria-label="Condition settings:"
          >
            <DropdownMenuItem icon={{ svg: IconListBullets }} onClick={onClick}>
              View / edit
            </DropdownMenuItem>
            <DropdownMenuItem
              icon={{
                svg: IconCircleMinus,
                fill: `${color.red} !important`,
              }}
              onClick={onRemove}
            >
              Remove condition
            </DropdownMenuItem>
          </DropdownMenu>
        </div>
      }
    >
      {title}
      {description && (
        <Fragment>
          <br />
          <span css={styles.description}>{description}</span>
        </Fragment>
      )}
    </ListItem>
  )
}

export function getConditionStatus({
  state,
  listing,
}: {
  listing: Conditions_ListingFragment
  state: OfferFormValues
}) {
  const mandatory = listing.conditions.filter(
    (condition) => condition.currentRevision.isRequired
  )
  const optional = listing.conditions.filter(
    (condition) => !condition.currentRevision.isRequired
  )

  const hasCondition = (line: Conditions_ListingFragment['conditions'][0]) => {
    return state.conditions.some(
      (item) => item.region === line.region && item.line === line.name
    )
  }
  const hasOptionalConditions = optional.some(hasCondition)
  const hasExpiry = !!state.expiry
  const hasCustomCondition = !!state.customConditionAdded
  const hasReviewedMandatory = mandatory.every((line) => hasCondition(line))
  const isConditionalOffer =
    hasOptionalConditions || hasCustomCondition || hasExpiry
  const acceptConditionalOffers = listing.acceptConditionalOffers?.enabled

  return {
    mandatory,
    optional,
    hasCondition,
    hasOptionalConditions,
    hasExpiry,
    hasCustomCondition,
    hasReviewedMandatory,
    isConditionalOffer,
    acceptConditionalOffers,
  }
}

export function getConditionsStepStatus({
  offerState,
  listing,
}: {
  listing: Conditions_ListingFragment
  offerState: OfferFormValues
}) {
  const { hasReviewedMandatory, acceptConditionalOffers, isConditionalOffer } =
    getConditionStatus({
      state: offerState,
      listing,
    })

  const hasReviewedStep =
    offerState.hasReviewedConditions || offerState.conditions.length > 0

  return getStatus({
    completed: acceptConditionalOffers
      ? hasReviewedMandatory && hasReviewedStep
      : !isConditionalOffer && hasReviewedMandatory && hasReviewedStep,
    needsAttention: acceptConditionalOffers
      ? !hasReviewedMandatory && hasReviewedStep
      : hasReviewedStep && (isConditionalOffer || !hasReviewedMandatory),
  })
}

const styles = {
  dropdownMenu: css`
    display: flex;
  `,
  conditionItem: css`
    pointer-events: none;
  `,
  description: css`
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${fontSize.sm};
    color: ${color.grey50};
  `,
}

export const GRAPHQL = gql`
  fragment Conditions_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    acceptConditionalOffers {
      enabled
    }
    conditions {
      name
      region
      weight
      currentRevision {
        lineName
        region
        title
        isRequired
      }
    }
  }
`
