import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useSyncExternalStore,
} from 'react'
import { Resource, useResource } from '../suspense'
import { useEvent } from '../use-event'
import { AuthStateResolved, IAuth } from './auth'

type AuthMethods = Pick<IAuth, 'activateRole' | 'signIn' | 'signOut'>

export const AuthContext = createContext<IAuth | null>(null)
export const AuthStateResourceContext =
  createContext<Resource<AuthStateResolved> | null>(null)

export function AuthProvider({
  auth,
  children,
}: {
  auth: IAuth
  children?: React.ReactNode
}) {
  const subscribe = useCallback(
    (onStoreChange: () => void) => auth.onStateChanged(onStoreChange),
    [auth]
  )
  const getSnapshot = useCallback(() => auth.state, [auth])
  const state = useSyncExternalStore(subscribe, getSnapshot)

  const [resource, controller] = useResource<AuthStateResolved>(
    state.ready ? state : undefined
  )

  const updateResource = useEvent(() => {
    if (!state.ready) {
      return
    }

    // avoid unnecessary updates
    const snapshot = controller.peek()
    if (snapshot.status === 'resolved' && state === snapshot.value) {
      return
    }

    controller.write(state)
  })

  useEffect(() => {
    // Keep the resource in sync with the state
    updateResource()
  }, [state])

  return (
    <AuthContext.Provider value={auth}>
      <AuthStateResourceContext.Provider value={resource}>
        {children}
      </AuthStateResourceContext.Provider>
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const auth = useContext(AuthContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!auth) {
      throw new Error(
        'useAuth must be called as a descendant of <AuthProvider>'
      )
    }
  }

  return auth!
}

export function useAuthState(): AuthStateResolved & AuthMethods {
  const auth = useContext(AuthContext)
  const resource = useContext(AuthStateResourceContext)

  if (!resource || !auth) {
    throw new Error(
      'useAuthState must be called as a descendant of <AuthProvider>'
    )
  }

  const state = resource.read()

  const methods = useMemo(
    () => ({
      signIn: auth!.signIn.bind(auth),
      signOut: auth!.signOut.bind(auth),
      activateRole: auth!.activateRole.bind(auth),
    }),
    [auth]
  )

  return useMemo(
    () => ({
      ...state,
      ...methods,
    }),
    [state, methods]
  )
}

export function useAuthBypass({
  onSuccess,
  processToken,
}: {
  onSuccess?: () => Promise<void>
  processToken?: (token: string) => void | Promise<void>
}) {
  const auth = useAuth()

  const defaultProcessToken = useCallback(
    async (token: string) => {
      await auth.signIn(token)
    },
    [auth]
  )

  useEffect(() => {
    // Install auth bypass for CI
    ;(window as any).__signInWithToken = async (token: string) => {
      await (processToken || defaultProcessToken)(token)
      onSuccess && (await onSuccess())
    }
    return () => {
      delete (window as any).__signInWithToken
    }
  }, [defaultProcessToken, onSuccess, processToken])
}
