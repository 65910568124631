import { TypePolicies } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

export const typePolicies = define({
  Address: {
    merge: true,
  },
  Agency: {
    fields: {
      listingsPaginated: relayStylePagination(),
    },
  },
  AgencyBrandingSettings: {
    merge: true,
  },
  Agent: {
    fields: {
      profileImage: {
        merge: (existing: string[] = [], incoming: string[]) => {
          return incoming
        },
      },
    },
    keyFields: ['id'],
  },
  FileResource: {
    merge: true,
  },
  HoldingDeposit: {
    merge: true,
  },
  Listing: {
    fields: {
      documents: {
        merge: (existing, incoming) => incoming,
      },
      holdingDeposit: {
        merge: (existing, incoming, { mergeObjects }) =>
          mergeObjects(existing, incoming),
      },
      priceIndication: {
        merge: (existing, incoming, { mergeObjects }) =>
          mergeObjects(existing, incoming),
      },
    },
    merge: true,
  },
  ListingActivity: {
    fields: {
      events: relayStylePagination(),
    },
  },
  ListingActivityBuyer: {
    fields: {
      events: relayStylePagination(),
    },
  },
  ListingPriceIndication: {
    keyFields: false,
  },
  Offer: {
    merge: true,
  },
  OfferAttachment: {
    merge: true,
  },
  OfferBuyerVerification: {
    merge: true,
  },
  OfferConditionsRevision: {
    keyFields: ['id', 'lineName', 'region'],
  },
  OfferConnectedBuyer: {
    merge: true,
  },
  OfferConveyancerInformation: {
    merge: true,
  },
  OfferConveyancerInformationFindConveyancerHelp: {
    merge: true,
  },
  OfferConveyancerInformationLegalProcessHelp: {
    merge: true,
  },
  OfferConveyancerInformationNone: {
    merge: true,
  },
  OfferConveyancerInformationProvided: {
    merge: true,
  },
  OfferFinanceDeclarationApproved: {
    merge: true,
  },
  OfferFinanceDeclarationNone: {
    merge: true,
  },
  OfferFinanceDeclarationNotApplicable: {
    merge: true,
  },
  OfferFinanceDeclarationOther: {
    merge: true,
  },
  OfferSignatory: {
    merge: true,
  },
  OfferSignature: {
    merge: true,
  },
  ProfileImage: {
    keyFields: false,
  },
  Property: {
    merge: true,
  },
  Query: {
    fields: {
      listing: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Listing',
            id: args!.id,
          })
        },
      },
      offer: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Offer',
            id: args!.id,
          })
        },
      },
    },
  },
  User: {
    fields: {
      roles: {
        merge: (existing: string[] = [], incoming: string[]) => {
          return incoming
        },
      },
    },
    keyFields: ['uid'],
  },
})

function define<T extends TypePolicies>(value: T): T {
  return value
}
