import { Role, useAuthState } from '@propps-au/client'
import { AnimatePresence } from 'framer-motion'
import { LocationDescriptor } from 'history'
import React, { Fragment, useCallback, useRef } from 'react'
import {
  match as Match,
  Redirect,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { FlatSwitch } from '../flat-switch'
import { PageTransition } from '../page-transition'
import { ScrollPosition, useScrollHistory } from '../scroll-history'
import { AddBuyerRoot, AddBuyerState } from './add-buyer'
import { Amount } from './amount'
import { Buyer, SignatoryCreateData } from './buyer'
import { Buyers } from './buyers'
import { ConditionsRoot } from './conditions'
import { useOfferForm } from './context'
import { Conveyancer } from './conveyancer'
import { Documents } from './documents'
import { Finance } from './finance'
import { OfferFormMode } from './mode'
import { Overview } from './overview'
import { PropertyAgentDetails } from './property-agent-details'
import { Selling } from './selling/selling'
import { Settlement } from './settlement'
import { Signature } from './signature'
import {
  OfferForm_BuyerFragment,
  OfferForm_ListingFragment,
} from './__generated__/query.generated'

type BuildRouteParamsFn<Params> = (params: Params) => LocationDescriptor
type BuildRouteNoParamsFn = () => LocationDescriptor
type BuildRouteFn<T = any> = BuildRouteParamsFn<T> | BuildRouteNoParamsFn

type RouteType<Slug, BuildRoute extends BuildRouteFn> = {
  name: Slug
  path: string
  buildRoute: BuildRoute
}

function createRoutes<T extends Record<string, RouteType<any, any>>>(items: T) {
  return items
}

export const ROUTES = createRoutes({
  overview: {
    name: 'overview' as const,
    path: '',
    buildRoute: () => '',
  },
  'property-agent-details': {
    name: 'property-agent-details' as const,
    path: '/property-agent-details',
    buildRoute: () => '/property-agent-details',
  },
  amount: {
    name: 'amount' as const,
    path: '/amount',
    buildRoute: () => '/amount',
  },
  settlement: {
    name: 'settlement' as const,
    path: '/settlement',
    buildRoute: () => '/settlement',
  },
  conditions: {
    name: 'conditions' as const,
    path: '/conditions',
    buildRoute: () => '/conditions',
  },
  buyers: {
    name: 'buyers' as const,
    path: '/buyers',
    buildRoute: () => '/buyers',
  },
  addBuyer: {
    name: 'addBuyer' as const,
    label: 'Add buyer',
    path: '/buyers/new',
    buildRoute: (params?: { signatory?: SignatoryCreateData }) => ({
      pathname: '/buyers/new',
      state: params?.signatory
        ? ({
            status: 'signatory-details',
            signatory: params.signatory,
          } as AddBuyerState)
        : null,
    }),
  },
  buyer: {
    name: 'buyer' as const,
    path: '/buyers/:index',
    buildRoute: (params: { index: number }) => '/buyers/' + params.index,
  },
  finance: {
    name: 'finance' as const,
    label: 'Finance',
    path: '/finance',
    buildRoute: () => '/finance',
  },
  conveyancer: {
    name: 'conveyancer' as const,
    label: 'Conveyancer',
    path: '/conveyancer',
    buildRoute: () => '/conveyancer',
  },
  selling: {
    name: 'selling' as const,
    label: 'Selling',
    path: '/selling',
    buildRoute: () => '/selling',
  },
  documents: {
    name: 'documents' as const,
    label: 'Documents',
    path: '/documents',
    buildRoute: () => '/documents',
  },
  signature: {
    name: 'signature' as const,
    path: '/signature/:index',
    buildRoute: (params: { index: number }) => '/signature/' + params.index,
  },
})

export type OfferFormRoute = Extract<typeof ROUTES>
export type OfferFormNavigationFn<
  T extends OfferFormRoute['name'] = OfferFormRoute['name']
> = <Name extends T>(
  name: Name,
  ...params: Parameters<typeof ROUTES[Name]['buildRoute']>
) => void

type Extract<T> = T extends Record<any, infer X> ? X : void

export function OfferFormRoutes({
  buildSignInRoute,
  goBack,
  match,
  listing,
  buyer,
}: {
  buildSignInRoute: (params: { to: string; from: string }) => LocationDescriptor
  goBack: () => void
  match: Match
  listing: OfferForm_ListingFragment
  buyer: OfferForm_BuyerFragment | null
}) {
  const history = useHistory()
  const location = useLocation()
  const auth = useAuthState()
  const { state, status, mode } = useOfferForm()

  const isBuyer = auth.role?.name === Role.BUYER
  const flowThroughSteps = false
  const label = flowThroughSteps ? 'Continue' : 'Done'

  const navigate: OfferFormNavigationFn = (name, ...params) => {
    const location = (ROUTES[name].buildRoute as any)(
      ...params
    ) as LocationDescriptor
    history.push(
      typeof location === 'string'
        ? match.url + location
        : {
            ...location,
            pathname: match.url + location.pathname,
          }
    )
  }

  const overview = () => (
    <Overview
      listing={listing}
      buyer={buyer ?? null}
      goToStep={navigate}
      descriptiveTitles={mode === OfferFormMode.PREPARE_OFFER ? true : false}
      data-testid="overview"
    />
  )

  const scrollTo = useRef<ScrollPosition | null>(null)

  useScrollHistory({
    onRestore: useCallback((position, location, action) => {
      if (position) {
        scrollTo.current = position
      } else if (action === 'PUSH') {
        scrollTo.current = { left: 0, top: 0 }
      } else {
        scrollTo.current = null
      }
    }, []),
  })

  const onExitComplete = useCallback(() => {
    if (scrollTo.current) {
      window.scrollTo({
        top: scrollTo.current.top,
        left: scrollTo.current.left,
        behavior: 'auto',
      })
      scrollTo.current = null
    }
  }, [])

  const shouldShowDocuments = listing.showDocuments && listing.documents.length

  return (
    <AnimatePresence exitBeforeEnter onExitComplete={onExitComplete}>
      <PageTransition key={location.pathname}>
        <FlatSwitch location={location}>
          <Route path={match.path + ROUTES.overview.path} exact>
            {overview}
          </Route>
          {isBuyer && buyer ? (
            <Fragment>
              <Route path={match.path + '/overview'} exact>
                {overview}
              </Route>
              <Route
                path={`${match.path}${ROUTES['property-agent-details'].path}`}
              >
                <PropertyAgentDetails
                  listing={listing}
                  label={label}
                  onContinue={() =>
                    navigate(flowThroughSteps ? 'amount' : 'overview')
                  }
                  data-testid="property-agent-details"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.amount.path}`}>
                <Amount
                  listing={listing}
                  label={label}
                  onContinue={() =>
                    navigate(flowThroughSteps ? 'settlement' : 'overview')
                  }
                  data-testid="amount"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.settlement.path}`}>
                <Settlement
                  listing={listing}
                  label={label}
                  onContinue={() =>
                    navigate(flowThroughSteps ? 'conditions' : 'overview')
                  }
                  data-testid="settlement"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.conditions.path}`}>
                <ConditionsRoot
                  listing={listing}
                  label={label}
                  onContinue={() => navigate('overview')}
                  data-testid="conditions"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.finance.path}`}>
                <Finance
                  listing={listing}
                  label={label}
                  onContinue={() =>
                    navigate(flowThroughSteps ? 'conveyancer' : 'overview')
                  }
                  data-testid="finance"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.conveyancer.path}`}>
                <Conveyancer
                  listing={listing}
                  label={label}
                  onContinue={() => {
                    if (flowThroughSteps) {
                      if (listing.vendorLeadsEnabled) {
                        return navigate('selling')
                      }
                      if (shouldShowDocuments) {
                        return navigate('documents')
                      }
                    }
                    return navigate('overview')
                  }}
                  data-testid="conveyancer"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.selling.path}`}>
                <Selling
                  label={label}
                  onContinue={() =>
                    navigate(
                      flowThroughSteps
                        ? shouldShowDocuments
                          ? 'documents'
                          : 'overview'
                        : 'overview'
                    )
                  }
                  data-testid="selling"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.documents.path}`}>
                <Documents
                  label={label}
                  listing={listing}
                  onContinue={() => navigate('overview')}
                  data-testid="documents"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.buyers.path}`} exact>
                <Buyers
                  listing={listing}
                  label={label}
                  onContinue={() => navigate('overview')}
                  navigate={navigate}
                  buyer={buyer}
                  data-testid="buyers"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.addBuyer.path}`}>
                <AddBuyerRoot
                  listing={listing}
                  onContinue={() => navigate('buyers')}
                  data-testid="add-buyer"
                />
              </Route>
              <Route path={`${match.path}${ROUTES.buyer.path}`}>
                {({ match: match1 }) =>
                  !isNaN(parseInt(match1!.params.index!)) &&
                  state.signatories[parseInt(match1!.params.index!)] ? (
                    <Buyer
                      listing={listing}
                      index={parseInt(match1!.params.index!)}
                      onContinue={() => navigate('buyers')}
                      data-testid="buyer"
                    />
                  ) : (
                    <Redirect to={`${match.url}/buyers`} />
                  )
                }
              </Route>
              <Route path={`${match.path}${ROUTES.signature.path}`}>
                {({ match: match2 }) =>
                  !isNaN(parseInt(match2!.params.index!)) &&
                  state.signatories[parseInt(match2!.params.index!)] ? (
                    <Signature
                      listing={listing}
                      index={parseInt(match2!.params.index!)}
                      onContinue={() => navigate('overview')}
                      data-testid="signature"
                    />
                  ) : (
                    <Redirect to={`${match.url}/overview`} />
                  )
                }
              </Route>
            </Fragment>
          ) : (
            /** Send the user to sign in and come back to the page */
            <Redirect
              to={buildSignInRoute({
                to: history.location.pathname,
                from: match.url,
              })}
            />
          )}
        </FlatSwitch>
      </PageTransition>
    </AnimatePresence>
  )
}
