import { gql } from '@apollo/client'
import { Activity, Button, Flex, OfferCard } from '@propps-au/ui'
import { formatISO, parseISO, startOfDay } from 'date-fns'
import { groupBy, reverse, sortBy } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useListing } from '../../pages/listing/listing-context'
import {
  ListingBuyerAnnouncementSentEvent,
  ListingBuyerRegisteredEvent,
  ListingNoticePostedEvent,
} from './events'
import { AnonymousOfferCreatedEvent } from './events/anonymous-offer-created'
import { AnonymousOfferUpdatedEvent } from './events/anonymous-offer-updated'
import { ListingStatusUpdatedEvent } from './events/listing-status-updated'
import { ListingUpdateAcceptConditionalOffersTacticEvent } from './events/listing-update-accept-conditional-offers-tactic'
import { OfferCreatedEvent } from './events/offer-created'
import { OfferUpdatedEvent } from './events/offer-updated'
import { setViewedLatest } from './use-unseen-activity-notification'
import { ListingActivity_ActivityBuyerEventFragment } from './__generated__/activity-feed.generated'

type DefinedEvent = { createdAt: string; eventId: string }

function sortEvents<T>(events: DefinedEvent[]) {
  const groupByDate = groupBy(
    (event) => startOfDay(parseISO(event.createdAt)).toISOString(),
    events
  )

  return reverse(
    sortBy(([date]) => parseISO(date), Object.entries(groupByDate))
  ) as unknown as [string, T][]
}

export const ActivityFeed = ({
  loading,
  events,
  loadMore,
}: {
  loading?: boolean
  events: Array<ListingActivity_ActivityBuyerEventFragment>
  loadMore?: () => Promise<unknown>
}) => {
  const { id } = useListing()
  const eventFeed = useMemo(() => {
    const definedEvents = events.filter(
      (event) => 'createdAt' in event
    ) as DefinedEvent[]
    return sortEvents<ListingActivity_ActivityBuyerEventFragment[]>(
      definedEvents
    )
  }, [events])

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const onLoadMore = async () => {
    if (!loadMore) return
    setFetchMoreLoading(true)
    await loadMore()
    setFetchMoreLoading(false)
  }

  useEffect(() => {
    if (events.length) {
      setViewedLatest(id, events[0]?.eventId || 'unknown')
    }
  }, [events, id])

  if (loading) {
    return <Skeleton />
  }

  if (!events.length) {
    return (
      <Flex xs={{ justifyContent: 'center' }}>
        <small>This listing hasn't received any activity yet.</small>
      </Flex>
    )
  }

  return (
    <>
      <Feed days={eventFeed} />
      {loadMore && (
        <Flex xs={{ justifyContent: 'center' }}>
          <Button
            size="sm"
            pill
            onClick={onLoadMore}
            pending={fetchMoreLoading}
            disabled={fetchMoreLoading}
          >
            Load more
          </Button>
        </Flex>
      )}
    </>
  )
}

function Feed({
  days,
  dummy,
}: {
  days: [string, ListingActivity_ActivityBuyerEventFragment[]][]
  dummy?: boolean
}) {
  return (
    <Activity.Feed dummy={dummy}>
      {days.map(([date, events]) => (
        <Activity.Day createdAt={date} key={date}>
          {events.map((event, index) => (
            <Event event={event} key={`${event.__typename}-${index}`} />
          ))}
        </Activity.Day>
      ))}
    </Activity.Feed>
  )
}

export function Event({
  event,
}: {
  event: ListingActivity_ActivityBuyerEventFragment
}) {
  switch (event.__typename) {
    case 'ListingStatusUpdatedEvent':
      return <ListingStatusUpdatedEvent event={event} />
    case 'ListingBuyerAnnouncementSentEvent':
      return <ListingBuyerAnnouncementSentEvent event={event} />
    case 'ListingNoticePostedEvent':
      return <ListingNoticePostedEvent event={event} />
    case 'OfferCreatedEvent':
      return <OfferCreatedEvent event={event} />
    case 'OfferUpdatedEvent':
      return <OfferUpdatedEvent event={event} />
    case 'AnonymousOfferCreatedEvent':
      return <AnonymousOfferCreatedEvent event={event} />
    case 'AnonymousOfferUpdatedEvent':
      return <AnonymousOfferUpdatedEvent event={event} />
    case 'ListingBuyerRegisteredEvent':
      return <ListingBuyerRegisteredEvent event={event} />
    case 'ListingUpdateAcceptConditionalOffersTacticEvent':
      return <ListingUpdateAcceptConditionalOffersTacticEvent event={event} />
    default:
      return null
  }
}

function Skeleton() {
  return (
    <Activity.Feed>
      <Activity.Day createdAt={formatISO(new Date())} loading>
        <Activity.Event
          loading
          icon="plus"
          firstName=""
          lastName=""
          createdAt={formatISO(new Date())}
          title=""
          card
        >
          <OfferCard loading firstName="" amount="" timestamp={new Date()} />
        </Activity.Event>
        <Activity.Event
          loading
          firstName=""
          lastName=""
          createdAt={formatISO(new Date())}
          title=""
        />
      </Activity.Day>
    </Activity.Feed>
  )
}

gql`
  fragment ListingActivity_ActivityBuyerEvent on ActivityBuyerEvent {
    ... on ListingStatusUpdatedEvent {
      ...ListingStatusUpdated_ListingStatusUpdatedEvent
    }
    ... on ListingBuyerAnnouncementSentEvent {
      ...ListingActivity_ListingBuyerAnnouncementSentEvent
    }
    ... on ListingNoticePostedEvent {
      ...ListingActivity_ListingNoticePostedEvent
    }
    ... on AnonymousOfferCreatedEvent {
      ...AnonymousOfferCreated_AnonymousOfferCreatedEvent
    }
    ... on AnonymousOfferUpdatedEvent {
      ...AnonymousOfferUpdated_AnonymousOfferUpdatedEvent
    }
    ... on OfferCreatedEvent {
      ...OfferCreated_OfferCreatedEvent
    }
    ... on OfferUpdatedEvent {
      ...OfferUpdated_OfferUpdatedEvent
    }
    ... on ListingBuyerRegisteredEvent {
      ...ListingBuyerRegistered_ListingBuyerRegisteredEvent
    }
    ... on ListingUpdateAcceptConditionalOffersTacticEvent {
      ...ListingUpdateAcceptConditionalOffersTactic_ListingUpdateAcceptConditionalOffersTacticEvent
    }
  }

  fragment ListingActivity_ActivityBuyerEventConnection on ActivityBuyerEventConnection {
    edges {
      cursor
      node {
        ...ListingActivity_ActivityBuyerEvent
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }

  fragment ListingActivity_Buyer on Buyer {
    id
    firstName
    lastName
    email
    phone
  }
`
