import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BuyerDevtoolsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BuyerDevtoolsQuery = { user?: { __typename: 'User', uid: string, firstName?: string | null, lastName?: string | null, phone?: string | null, email?: string | null } | null };

export type BuyerDevtoolsResetBuyerAccountMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type BuyerDevtoolsResetBuyerAccountMutation = { unlinkBuyer: boolean };


export const BuyerDevtoolsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BuyerDevtoolsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"user"},"name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<BuyerDevtoolsQuery, BuyerDevtoolsQueryVariables>;
export const BuyerDevtoolsResetBuyerAccountMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BuyerDevtoolsResetBuyerAccountMutation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unlinkBuyer"}}]}}]} as unknown as DocumentNode<BuyerDevtoolsResetBuyerAccountMutation, BuyerDevtoolsResetBuyerAccountMutationVariables>;