/* eslint-disable jsx-a11y/anchor-is-valid */
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { Title } from '@propps-au/ui'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { useAnalytics } from '../analytics'
import { DocumentsList } from '../documents-list'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { OfferFormMode } from './mode'
import { Documents_ListingFragment } from './__generated__/documents.generated'

export function Documents({
  listing,
  label = 'Done',
  onContinue,
}: {
  listing: Documents_ListingFragment
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbDocuments>({
        type: BuyerEvent.VIEW_PTB_DOCUMENTS,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferDocuments>({
        type: BuyerEvent.VIEW_OFFER_DOCUMENTS,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = () => {
    update((s) => ({
      ...s,
      reviewedDocumentIds: listing.documents.map((document) => document.id),
    }))
    onContinue()
  }

  return (
    <>
      <Title sub={listing.property.address.line1}>Documents for</Title>
      <DocumentsList documents={listing.documents} />
      <PrimaryButton onClick={submit} label={label} />
    </>
  )
}

export const GRAPHQL = gql`
  fragment Documents_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    property {
      address {
        line1
      }
    }
    ...DocumentsList_Listing
  }
`
