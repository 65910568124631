import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OfferForm_SaveDraftOfferMutationVariables = Types.Exact<{
  input: Types.SaveDraftOfferInput;
}>;


export type OfferForm_SaveDraftOfferMutation = { result: { __typename: 'SaveDraftOfferResult', draftOffer: { __typename: 'DraftOffer', id: string, disposition: Types.DraftOfferDisposition } } };


export const OfferForm_SaveDraftOfferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OfferForm_SaveDraftOffer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveDraftOfferInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"result"},"name":{"kind":"Name","value":"saveDraftOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"draftOffer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"disposition"}}]}}]}}]}}]} as unknown as DocumentNode<OfferForm_SaveDraftOfferMutation, OfferForm_SaveDraftOfferMutationVariables>;