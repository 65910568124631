import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TermsRoute_TermsRevisionFragment = { __typename: 'TermsRevision', id: string, contentHTML: string, versionName: string, createdAt: string, line: { __typename: 'TermsLine', name: string } };

export type CurrentTermsQueryVariables = Types.Exact<{
  lineName: Types.Scalars['String'];
}>;


export type CurrentTermsQuery = { line?: { __typename: 'TermsLine', revision: { __typename: 'TermsRevision', id: string, contentHTML: string, versionName: string, createdAt: string, line: { __typename: 'TermsLine', name: string } } } | null };

export type TermsRevisionQueryVariables = Types.Exact<{
  lineName: Types.Scalars['String'];
  revisionId: Types.Scalars['ID'];
}>;


export type TermsRevisionQuery = { line?: { __typename: 'TermsLine', revision?: { __typename: 'TermsRevision', id: string, contentHTML: string, versionName: string, createdAt: string, line: { __typename: 'TermsLine', name: string } } | null } | null };

export const TermsRoute_TermsRevisionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TermsRoute_TermsRevision"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TermsRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"line"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"contentHTML"}},{"kind":"Field","name":{"kind":"Name","value":"versionName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode<TermsRoute_TermsRevisionFragment, unknown>;
export const CurrentTermsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentTermsQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"lineName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"line"},"name":{"kind":"Name","value":"termsLine"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"lineName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"revision"},"name":{"kind":"Name","value":"currentRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TermsRoute_TermsRevision"}}]}}]}}]}},...TermsRoute_TermsRevisionFragmentDoc.definitions]} as unknown as DocumentNode<CurrentTermsQuery, CurrentTermsQueryVariables>;
export const TermsRevisionQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TermsRevisionQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"lineName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"revisionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"line"},"name":{"kind":"Name","value":"termsLine"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"lineName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revision"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"revisionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TermsRoute_TermsRevision"}}]}}]}}]}},...TermsRoute_TermsRevisionFragmentDoc.definitions]} as unknown as DocumentNode<TermsRevisionQuery, TermsRevisionQueryVariables>;