import type {
  IMailroomEvent,
  MailmanDispatchPayload,
  MailroomPixelHeaders,
} from '@propps-au/mailroom-types'
import 'isomorphic-unfetch'

const emptySetHeaders = () => {}
export class MailroomClient {
  private readonly _basepath: string
  private sessionId: string | null
  private metadata: string | null
  private user: IMailroomEvent['user'] | null
  private lastSessionHash = ''
  private readonly setRequestHeaders: (headers: {
    [key: string]: string | undefined
  }) => void

  constructor({ basepath, setHeaders }: MailroomClientOptions) {
    this._basepath = basepath
    this.sessionId = null
    this.metadata = null
    this.user = null
    this.setRequestHeaders = setHeaders ?? emptySetHeaders
    this.lastSessionHash = ''
  }

  /**
   * Sets the current session ID in the Mailroom instance store to be injected
   * in to the Mailroom dispatch payload.
   */
  async setSessionId(id: string | null) {
    this.sessionId = id
    this.setRequestHeaders(this.getHeaders())
    await this.handleSessionUpdate()
  }

  /**
   * Sets the current custom metadata passed into Proppsland via the Propps
   * script. This metadata is usually set by external developers and we attach
   * them to each event so that we can return the data back to them via
   * Webhooks.
   */
  async setMetadata(metadata: string | null) {
    this.metadata = metadata
    this.setRequestHeaders(this.getHeaders())
    await this.handleSessionUpdate()
  }

  private async handleSessionUpdate() {
    const hash = [this.metadata, this.sessionId].join(':')
    if (this.metadata && this.sessionId && this.lastSessionHash !== hash) {
      await fetch(`${this._basepath}/session/init`, {
        body: JSON.stringify({
          sessionId: this.sessionId,
          data: { metadata: this.metadata, started: new Date().toISOString() },
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).catch((err) => {
        console.error('[Mailroom 📮] Error with initialising session.', err)
      })
      this.lastSessionHash = hash
    }
  }

  setUser(user: IMailroomEvent['user'] | null) {
    this.user = user
  }

  clearUser() {
    this.user = null
  }

  clearStore() {
    this.sessionId = null
    this.metadata = null
    this.user = null
    this.setRequestHeaders(this.getHeaders())
  }

  async dispatch<T = any>(
    payload: Omit<MailmanDispatchPayload<T>, 'sessionId' | 'metadata' | 'user'>
  ) {
    const fullPayload: MailmanDispatchPayload<T> = {
      ...payload,
      user: this.user,
      sessionId: this.sessionId,
      metadata: {
        custom: this.metadata,
      },
    }

    await fetch(`${this._basepath}/pixel/${payload.topic}`, {
      body: JSON.stringify(fullPayload),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.error('[Mailroom 📮] Error with dispatching event to API.', err)
      })
  }

  getHeaders(): MailroomPixelHeaders {
    const headers: MailroomPixelHeaders = {
      'x-pixel-session-id': undefined,
      'x-pixel-metadata': undefined,
    }
    if (this.sessionId) {
      headers['x-pixel-session-id'] = this.sessionId
    }
    if (this.metadata) {
      headers['x-pixel-metadata'] = this.metadata
    }
    return headers
  }
}

export type MailroomClientOptions = {
  /** API endpoint for Mailroom Services */
  basepath: string
  /** A function to be called called when metadata changes to set request
  headers in the Frontend client's API handler */
  setHeaders?: (headers: { [key: string]: string | undefined }) => void
}
