import { datadogRum } from '@datadog/browser-rum'

export namespace ErrorReporting {
  export function report(err: any) {
    if (process.env.NODE_ENV === 'production') {
      datadogRum.addError(err)
    }
    console.error(err)
  }
}
