import { z } from 'zod'
import { PixelEventBaseSchema } from '../pixel-event'

export enum _EventType {
  VIEW_LISTING_PAGE = 'buyer_view_listing_page',
  OPEN_OFFER_FRAME = 'buyer_open_offer_frame',
  VIEW_OFFER_HUB = 'buyer_view_offer_hub',
  VIEW_LISTING_DOCUMENTS = 'buyer_view_listing_documents',

  // Log in events
  SWITCH_TO_SMS_LOGIN = 'buyer_switch_to_sms_login',
  SWITCH_TO_EMAIL_LOGIN = 'buyer_switch_to_email_login',
  RESEND_CODE = 'buyer_resend-code',
  VIEW_LOGIN = 'buyer_view_login',
  VIEW_LOGIN_VERIFY = 'buyer_view_login_verify',
  VIEW_LOGIN_PHONE = 'buyer_view_login_phone',
  VIEW_LOGIN_SMS_VALIDATE = 'buyer_view_login_sms_validate',
  VIEW_LOGIN_REGISTER = 'buyer_view_login_register',
  VIEW_LOGIN_QR_CODE = 'buyer_view_login_qr_code',
  DOWNLOAD_QR_CODE = 'buyer_download_qr_code',
  LOGIN = 'buyer_login',
  LOGOUT = 'buyer_logout',
  REGISTER = 'buyer_register',

  // Make an offer view step event types
  VIEW_OFFER_OVERVIEW = 'buyer_view_offer_overview',
  VIEW_OFFER_PROPERTY_AGENT_DETAILS = 'buyer_view_offer_property_agent_details',
  VIEW_OFFER_AMOUNT = 'buyer_view_offer_amount',
  VIEW_OFFER_SETTLEMENT = 'buyer_view_offer_settlement',
  VIEW_OFFER_CONDITIONS = 'buyer_view_offer_conditions',
  VIEW_OFFER_FINANCE = 'buyer_view_offer_finance',
  VIEW_OFFER_CONVEYANCER = 'buyer_view_offer_conveyancer',
  VIEW_OFFER_DOCUMENTS = 'buyer_view_offer_documents',
  VIEW_OFFER_BUYERS = 'buyer_view_offer_buyers',
  VIEW_OFFER_ADD_BUYER = 'buyer_view_offer_add_buyer',
  VIEW_OFFER_ADD_BUYER_SMS_VALIDATE = 'buyer_view_offer_add_buyer_sms_validate',
  VIEW_OFFER_BUYER = 'buyer_view_offer_buyer',
  VIEW_OFFER_AGREEMENTS = 'buyer_view_offer_agreements',
  VIEW_OFFER_SIGNATURE = 'buyer_view_offer_signature',
  VIEW_OFFER_SELLING = 'buyer_view_offer_selling',

  // Make an offer step completed event types
  COMPLETE_OFFER_AMOUNT = 'buyer_complete_offer_amount',
  COMPLETE_OFFER_SETTLEMENT = 'buyer_complete_offer_settlement',
  ACCEPT_OFFER_CONDITION = 'buyer_accept_offer_condition',
  REMOVE_OFFER_CONDITION = 'buyer_remove_offer_condition',
  ADD_OFFER_EXPIRY = 'buyer_add_offer_expiry',
  REMOVE_OFFER_EXPIRY = 'buyer_remove_offer_expiry',
  COMPLETE_OFFER_CONDITIONS = 'buyer_complete_offer_conditions',
  COMPLETE_OFFER_FINANCE = 'buyer_complete_offer_finance',
  COMPLETE_OFFER_CONVEYANCER = 'buyer_complete_offer_conveyancer',
  COMPLETE_OFFER_BUYER = 'buyer_complete_offer_buyer',
  REMOVE_OFFER_BUYER = 'buyer_remove_offer_buyer',
  COMPLETE_OFFER_SELLING = 'buyer_complete_offer_selling',

  SUBMIT_OFFER = 'buyer_submit_offer',
  UPDATE_OFFER = 'buyer_update_offer',

  // Prepare to buy event types
  VIEW_PTB_OVERVIEW = 'buyer_view_ptb_overview',
  VIEW_PTB_AMOUNT = 'buyer_view_ptb_amount',
  VIEW_PTB_SETTLEMENT = 'buyer_view_ptb_settlement',
  VIEW_PTB_CONDITIONS = 'buyer_view_ptb_conditions',
  VIEW_PTB_FINANCE = 'buyer_view_ptb_finance',
  VIEW_PTB_CONVEYANCER = 'buyer_view_ptb_conveyancer',
  VIEW_PTB_DOCUMENTS = 'buyer_view_ptb_documents',
  VIEW_PTB_BUYERS = 'buyer_view_ptb_buyers',
  VIEW_PTB_ADD_BUYER = 'buyer_view_ptb_add_buyer',
  VIEW_PTB_ADD_BUYER_SMS_VALIDATE = 'buyer_view_ptb_add_buyer_sms_validate',
  VIEW_PTB_BUYER = 'buyer_view_ptb_buyer',
  UPGRADE_PTB_TO_OFFER = 'buyer_upgrade_ptb_to_offer',

  // Download events
  DOWNLOAD_DOCUMENT = 'buyer_download_document',

  // Session start link
  BUYER_START_SESSION = 'buyer-start-session',

  // Lite offer events
  CLAIM_LITE_OFFER = 'buyer_claim_lite_offer',
}
export namespace BuyerEvent {
  export import EventType = _EventType
  interface IBuyerEvent {
    type: string
    appId: string
    listingId: string
    foreignId: string
  }

  const BaseSchema = PixelEventBaseSchema.extend({
    userId: z.string().optional(),
    listingId: z.string(),
    foreignId: z.string(),
    appId: z.string(),
  })

  export interface ViewListingPage extends IBuyerEvent {
    type: _EventType.VIEW_LISTING_PAGE
  }
  export const ViewListingPageEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LISTING_PAGE),
  })
  export interface OpenOfferFrame extends IBuyerEvent {
    type: _EventType.OPEN_OFFER_FRAME
  }
  export const OpenOfferFrameEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.OPEN_OFFER_FRAME),
  })
  export interface ViewOfferHub extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_HUB
  }
  export const ViewOfferHubEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_HUB),
  })
  export interface ViewListingDocuments extends IBuyerEvent {
    type: _EventType.VIEW_LISTING_DOCUMENTS
  }
  export const ViewListingDocumentsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LISTING_DOCUMENTS),
  })

  /* Log in events */
  export interface ViewLoginEvents extends IBuyerEvent {
    type:
      | _EventType.VIEW_LOGIN_PHONE
      | _EventType.VIEW_LOGIN_SMS_VALIDATE
      | _EventType.VIEW_LOGIN_REGISTER
      | _EventType.VIEW_LOGIN_QR_CODE
  }
  export interface ViewLogin extends IBuyerEvent {
    type: _EventType.VIEW_LOGIN
    channel: 'sms' | 'email'
  }
  export const ViewLoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN),
    channel: z.string(),
  })
  export interface ViewLoginVerify extends IBuyerEvent {
    type: _EventType.VIEW_LOGIN_VERIFY
    channel: 'sms' | 'email'
  }
  export const ViewLoginVerifyEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN_VERIFY),
    channel: z.string(),
  })
  export interface SwitchToSmsLogin extends IBuyerEvent {
    type: _EventType.SWITCH_TO_SMS_LOGIN
  }
  export const SwitchToSmsLoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SWITCH_TO_SMS_LOGIN),
  })
  export interface SwitchToEmailLogin extends IBuyerEvent {
    type: _EventType.SWITCH_TO_EMAIL_LOGIN
  }
  export const SwitchToEmailLoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SWITCH_TO_EMAIL_LOGIN),
  })
  export interface ResendCode extends IBuyerEvent {
    type: _EventType.RESEND_CODE
    channel: 'sms' | 'email'
  }
  export const ResendCodeEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.RESEND_CODE),
    channel: z.string(),
  })
  export interface ViewLoginPhone extends IBuyerEvent {
    type: _EventType.VIEW_LOGIN_PHONE
  }
  export const ViewLoginPhoneEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN_PHONE),
  })
  export interface ViewLoginSmsValidate extends IBuyerEvent {
    type: _EventType.VIEW_LOGIN_SMS_VALIDATE
  }
  export const ViewLoginSmsValidateEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN_SMS_VALIDATE),
  })
  export interface ViewLoginRegister extends IBuyerEvent {
    type: _EventType.VIEW_LOGIN_REGISTER
  }
  export const ViewLoginRegisterEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN_REGISTER),
  })
  export interface ViewLoginQrCode extends IBuyerEvent {
    type: _EventType.VIEW_LOGIN_QR_CODE
  }
  export const ViewLoginQrCodeEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN_QR_CODE),
  })
  export interface DownloadQrCode extends IBuyerEvent {
    type: _EventType.DOWNLOAD_QR_CODE
  }
  export const DownloadQrCodeEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.DOWNLOAD_QR_CODE),
  })
  export interface Login extends IBuyerEvent {
    type: _EventType.LOGIN
  }
  export const LoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.LOGIN),
  })
  export interface Logout extends IBuyerEvent {
    type: _EventType.LOGOUT
  }
  export const LogoutEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.LOGOUT),
  })
  export interface Register extends IBuyerEvent {
    type: _EventType.REGISTER
    buyerId: string
  }
  export const RegisterEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REGISTER),
    buyerId: z.string(),
  })

  /* Make an offer - step viewed */
  export interface ViewOfferOverview extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_OVERVIEW
  }
  export const ViewOfferOverviewEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_OVERVIEW),
  })
  export interface ViewOfferPropertyAgentDetails extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_PROPERTY_AGENT_DETAILS
  }
  export const ViewOfferPropertyAgentDetailsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_PROPERTY_AGENT_DETAILS),
  })
  export interface ViewOfferAmount extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_AMOUNT
  }
  export const ViewOfferAmountEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_AMOUNT),
  })
  export interface ViewOfferSettlement extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_SETTLEMENT
  }
  export const ViewOfferSettlementEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_SETTLEMENT),
  })
  export interface ViewOfferConditions extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_CONDITIONS
  }
  export const ViewOfferConditionsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_CONDITIONS),
  })
  export interface ViewOfferFinance extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_FINANCE
  }
  export const ViewOfferFinanceEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_FINANCE),
  })
  export interface ViewOfferConveyancer extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_CONVEYANCER
  }
  export const ViewOfferConveyancerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_CONVEYANCER),
  })
  export interface ViewOfferDocuments extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_DOCUMENTS
  }
  export const ViewOfferDocumentsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_DOCUMENTS),
  })
  export interface ViewOfferBuyers extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_BUYERS
  }
  export const ViewOfferBuyersEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_BUYERS),
  })
  export interface ViewOfferAddBuyer extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_ADD_BUYER
  }
  export const ViewOfferAddBuyerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_ADD_BUYER),
  })
  export interface ViewOfferAddBuyerSmsValidate extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_ADD_BUYER_SMS_VALIDATE
  }
  export const ViewOfferAddBuyerSmsValidateEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_ADD_BUYER_SMS_VALIDATE),
  })
  export interface ViewOfferBuyer extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_BUYER
  }
  export const ViewOfferBuyerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_BUYER),
  })
  export interface ViewOfferAgreements extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_AGREEMENTS
  }
  export const ViewOfferAgreementsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_AGREEMENTS),
  })
  export interface ViewOfferSignature extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_SIGNATURE
  }
  export const ViewOfferSignatureEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_SIGNATURE),
  })
  export interface ViewOfferSelling extends IBuyerEvent {
    type: _EventType.VIEW_OFFER_SELLING
  }
  export const ViewOfferSellingEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER_SELLING),
  })

  /* Make an offer - step completed */
  export interface CompleteOfferAmount extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_AMOUNT
    amount: number
  }
  export const CompleteOfferAmountEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_AMOUNT),
    amount: z.number(),
  })
  export interface CompleteOfferSettlement extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_SETTLEMENT
    settlement: string
  }
  export const CompleteOfferSettlementEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_SETTLEMENT),
    settlement: z.string(),
  })
  export interface AcceptOfferCondition extends IBuyerEvent {
    type: _EventType.ACCEPT_OFFER_CONDITION
    conditionId: string
    conditionLine: string
    conditionRegion: string
    conditionParams: string | null
  }
  export const AcceptOfferConditionEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.ACCEPT_OFFER_CONDITION),
    conditionId: z.string(),
    conditionLine: z.string(),
    conditionRegion: z.string(),
    conditionParams: z.string().nullable(),
  })
  export interface RemoveOfferCondition extends IBuyerEvent {
    type: _EventType.REMOVE_OFFER_CONDITION
    conditionName: string
    conditionRegion: string
  }
  export const RemoveOfferConditionEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REMOVE_OFFER_CONDITION),
    conditionName: z.string(),
    conditionRegion: z.string(),
  })
  export interface AddOfferExpiry extends IBuyerEvent {
    type: _EventType.ADD_OFFER_EXPIRY
    expiry: string
  }
  export const AddOfferExpiryEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.ADD_OFFER_EXPIRY),
    expiry: z.string(),
  })
  export interface RemoveOfferExpiry extends IBuyerEvent {
    type: _EventType.REMOVE_OFFER_EXPIRY
  }
  export const RemoveOfferExpiryEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REMOVE_OFFER_EXPIRY),
  })
  export interface CompleteOfferConditions extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_CONDITIONS
  }
  export const CompleteOfferConditionsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_CONDITIONS),
  })
  export interface CompleteOfferFinance extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_FINANCE
    financeType: string
    financeInstitution: string | null
    financeDate: string | null
    financeNote: string | null
  }
  export const CompleteOfferFinanceEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_FINANCE),
    financeType: z.string(),
    financeInstitution: z.string().nullable(),
    financeDate: z.string().nullable(),
    financeNote: z.string().nullable(),
  })
  export interface CompleteOfferConveyancer extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_CONVEYANCER
    conveyancerType: string
    conveyancerName: string | null
    conveyancerPhone: string | null
    conveyancerReferralEnabled: boolean
  }
  export const CompleteOfferConveyancerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_CONVEYANCER),
    conveyancerType: z.string(),
    conveyancerName: z.string().nullable(),
    conveyancerPhone: z.string().nullable(),
    conveyancerReferralEnabled: z.boolean(),
  })
  export interface CompleteOfferBuyer extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_BUYER
    buyerId: string
  }
  export const CompleteOfferBuyerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_BUYER),
    buyerId: z.string(),
  })
  export interface RemoveOfferBuyer extends IBuyerEvent {
    type: _EventType.REMOVE_OFFER_BUYER
    buyerId: string
  }
  export const RemoveOfferBuyerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REMOVE_OFFER_BUYER),
    buyerId: z.string(),
  })
  export interface CompleteOfferSelling extends IBuyerEvent {
    type: _EventType.COMPLETE_OFFER_SELLING
    situation: string
  }
  export const CompleteOfferSellingEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COMPLETE_OFFER_SELLING),
    situation: z.string(),
  })

  export interface SubmitOffer extends IBuyerEvent {
    type: _EventType.SUBMIT_OFFER
  }
  export const SubmitOfferEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SUBMIT_OFFER),
  })
  export interface UpdateOffer extends IBuyerEvent {
    type: _EventType.UPDATE_OFFER
  }
  export const UpdateOfferEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.UPDATE_OFFER),
  })

  /* Prepare to buy events */
  export interface ViewPtbOverview extends IBuyerEvent {
    type: _EventType.VIEW_PTB_OVERVIEW
  }
  export const ViewPtbOverviewEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_OVERVIEW),
  })
  export interface ViewPtbAmount extends IBuyerEvent {
    type: _EventType.VIEW_PTB_AMOUNT
  }
  export const ViewPtbAmountEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_AMOUNT),
  })
  export interface ViewPtbSettlement extends IBuyerEvent {
    type: _EventType.VIEW_PTB_SETTLEMENT
  }
  export const ViewPtbSettlementEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_SETTLEMENT),
  })
  export interface ViewPtbConditions extends IBuyerEvent {
    type: _EventType.VIEW_PTB_CONDITIONS
  }
  export const ViewPtbConditionsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_CONDITIONS),
  })
  export interface ViewPtbFinance extends IBuyerEvent {
    type: _EventType.VIEW_PTB_FINANCE
  }
  export const ViewPtbFinanceEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_FINANCE),
  })
  export interface ViewPtbConveyancer extends IBuyerEvent {
    type: _EventType.VIEW_PTB_CONVEYANCER
  }
  export const ViewPtbConveyancerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_CONVEYANCER),
  })
  export interface ViewPtbDocuments extends IBuyerEvent {
    type: _EventType.VIEW_PTB_DOCUMENTS
  }
  export const ViewPtbDocumentsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_DOCUMENTS),
  })
  export interface ViewPtbBuyers extends IBuyerEvent {
    type: _EventType.VIEW_PTB_BUYERS
  }
  export const ViewPtbBuyersEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_BUYERS),
  })
  export interface ViewPtbAddBuyer extends IBuyerEvent {
    type: _EventType.VIEW_PTB_ADD_BUYER
  }
  export const ViewPtbAddBuyerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_ADD_BUYER),
  })
  export interface ViewPtbAddBuyerSmsValidate extends IBuyerEvent {
    type: _EventType.VIEW_PTB_ADD_BUYER_SMS_VALIDATE
  }
  export const ViewPtbAddBuyerSmsValidateEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_ADD_BUYER_SMS_VALIDATE),
  })
  export interface ViewPtbBuyer extends IBuyerEvent {
    type: _EventType.VIEW_PTB_BUYER
  }
  export const ViewPtbBuyerEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_PTB_BUYER),
  })
  export interface UpgradePtbToOffer extends IBuyerEvent {
    type: _EventType.UPGRADE_PTB_TO_OFFER
  }
  export const UpgradePtbToOfferEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.UPGRADE_PTB_TO_OFFER),
  })

  /* Download events */
  export interface DownloadDocument extends IBuyerEvent {
    type: _EventType.DOWNLOAD_DOCUMENT
    documentId: string
    documentUrl: string
    documentType: string
  }
  export const DownloadDocumentEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.DOWNLOAD_DOCUMENT),
    documentId: z.string(),
    documentUrl: z.string(),
    documentType: z.string(),
  })

  /*Script metadata link*/
  export interface BuyerStartSession extends IBuyerEvent {
    type: _EventType.BUYER_START_SESSION
    buyerId: string
  }
  export const BuyerStartSessionEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.BUYER_START_SESSION),
    buyerId: z.string(),
  })

  /** Lite offer events */
  export interface ClaimLiteOffer extends IBuyerEvent {
    type: _EventType.CLAIM_LITE_OFFER
    liteOfferId: string
  }
  export const ClaimLiteOfferEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.CLAIM_LITE_OFFER),
    liteOfferId: z.string(),
  })

  export type Event =
    | ViewListingPage
    | OpenOfferFrame
    | ViewOfferHub
    | ViewListingDocuments
    | ViewLogin
    | ViewLoginVerify
    | SwitchToEmailLogin
    | SwitchToSmsLogin
    | ResendCode
    | ViewLoginPhone
    | ViewLoginSmsValidate
    | ViewLoginRegister
    | ViewLoginQrCode
    | DownloadQrCode
    | Login
    | Logout
    | Register
    | ViewOfferOverview
    | ViewOfferPropertyAgentDetails
    | ViewOfferAmount
    | ViewOfferSettlement
    | ViewOfferConditions
    | ViewOfferFinance
    | ViewOfferConveyancer
    | ViewOfferDocuments
    | ViewOfferBuyers
    | ViewOfferAddBuyer
    | ViewOfferAddBuyerSmsValidate
    | ViewOfferBuyer
    | ViewOfferAgreements
    | ViewOfferSignature
    | ViewOfferSelling
    | CompleteOfferAmount
    | CompleteOfferSettlement
    | AcceptOfferCondition
    | RemoveOfferCondition
    | AddOfferExpiry
    | RemoveOfferExpiry
    | CompleteOfferConditions
    | CompleteOfferFinance
    | CompleteOfferConveyancer
    | CompleteOfferBuyer
    | RemoveOfferBuyer
    | CompleteOfferSelling
    | SubmitOffer
    | UpdateOffer
    | ViewPtbOverview
    | ViewPtbAmount
    | ViewPtbSettlement
    | ViewPtbConditions
    | ViewPtbFinance
    | ViewPtbConveyancer
    | ViewPtbDocuments
    | ViewPtbBuyers
    | ViewPtbAddBuyer
    | ViewPtbAddBuyerSmsValidate
    | ViewPtbBuyer
    | UpgradePtbToOffer
    | DownloadDocument
    | BuyerStartSession
    | ClaimLiteOffer

  export const Validators: { [key in _EventType]: z.ZodObject<any> } = {
    [_EventType.VIEW_LISTING_PAGE]: ViewListingPageEventSchema,
    [_EventType.OPEN_OFFER_FRAME]: OpenOfferFrameEventSchema,
    [_EventType.VIEW_OFFER_HUB]: ViewOfferHubEventSchema,
    [_EventType.VIEW_LISTING_DOCUMENTS]: ViewListingDocumentsEventSchema,

    // Log in events
    [_EventType.VIEW_LOGIN]: ViewLoginEventSchema,
    [_EventType.VIEW_LOGIN_VERIFY]: ViewLoginVerifyEventSchema,
    [_EventType.SWITCH_TO_EMAIL_LOGIN]: SwitchToEmailLoginEventSchema,
    [_EventType.SWITCH_TO_SMS_LOGIN]: SwitchToSmsLoginEventSchema,
    [_EventType.RESEND_CODE]: ResendCodeEventSchema,
    [_EventType.VIEW_LOGIN_PHONE]: ViewLoginPhoneEventSchema,
    [_EventType.VIEW_LOGIN_SMS_VALIDATE]: ViewLoginSmsValidateEventSchema,
    [_EventType.VIEW_LOGIN_REGISTER]: ViewLoginRegisterEventSchema,
    [_EventType.VIEW_LOGIN_QR_CODE]: ViewLoginQrCodeEventSchema,
    [_EventType.DOWNLOAD_QR_CODE]: DownloadQrCodeEventSchema,
    [_EventType.LOGIN]: LoginEventSchema,
    [_EventType.LOGOUT]: LogoutEventSchema,
    [_EventType.REGISTER]: RegisterEventSchema,

    // Make an offer event types
    [_EventType.VIEW_OFFER_OVERVIEW]: ViewOfferOverviewEventSchema,
    [_EventType.VIEW_OFFER_PROPERTY_AGENT_DETAILS]:
      ViewOfferPropertyAgentDetailsEventSchema,
    [_EventType.VIEW_OFFER_AMOUNT]: ViewOfferAmountEventSchema,
    [_EventType.VIEW_OFFER_SETTLEMENT]: ViewOfferSettlementEventSchema,
    [_EventType.VIEW_OFFER_CONDITIONS]: ViewOfferConditionsEventSchema,
    [_EventType.VIEW_OFFER_FINANCE]: ViewOfferFinanceEventSchema,
    [_EventType.VIEW_OFFER_CONVEYANCER]: ViewOfferConveyancerEventSchema,
    [_EventType.VIEW_OFFER_DOCUMENTS]: ViewOfferDocumentsEventSchema,
    [_EventType.VIEW_OFFER_BUYER]: ViewOfferBuyerEventSchema,
    [_EventType.VIEW_OFFER_ADD_BUYER]: ViewOfferAddBuyerEventSchema,
    [_EventType.VIEW_OFFER_ADD_BUYER_SMS_VALIDATE]:
      ViewOfferAddBuyerSmsValidateEventSchema,
    [_EventType.VIEW_OFFER_BUYERS]: ViewOfferBuyersEventSchema,
    [_EventType.VIEW_OFFER_AGREEMENTS]: ViewOfferAgreementsEventSchema,
    [_EventType.VIEW_OFFER_SIGNATURE]: ViewOfferSignatureEventSchema,
    [_EventType.VIEW_OFFER_SELLING]: ViewOfferSellingEventSchema,

    [_EventType.COMPLETE_OFFER_AMOUNT]: CompleteOfferAmountEventSchema,
    [_EventType.COMPLETE_OFFER_SETTLEMENT]: CompleteOfferSettlementEventSchema,
    [_EventType.ACCEPT_OFFER_CONDITION]: AcceptOfferConditionEventSchema,
    [_EventType.REMOVE_OFFER_CONDITION]: RemoveOfferConditionEventSchema,
    [_EventType.ADD_OFFER_EXPIRY]: AddOfferExpiryEventSchema,
    [_EventType.REMOVE_OFFER_EXPIRY]: RemoveOfferExpiryEventSchema,
    [_EventType.COMPLETE_OFFER_CONDITIONS]: CompleteOfferConditionsEventSchema,
    [_EventType.COMPLETE_OFFER_FINANCE]: CompleteOfferFinanceEventSchema,
    [_EventType.COMPLETE_OFFER_CONVEYANCER]:
      CompleteOfferConveyancerEventSchema,
    [_EventType.COMPLETE_OFFER_BUYER]: CompleteOfferBuyerEventSchema,
    [_EventType.REMOVE_OFFER_BUYER]: RemoveOfferBuyerEventSchema,
    [_EventType.COMPLETE_OFFER_SELLING]: CompleteOfferSellingEventSchema,

    [_EventType.SUBMIT_OFFER]: SubmitOfferEventSchema,
    [_EventType.UPDATE_OFFER]: UpdateOfferEventSchema,

    // Prepare to buy events
    [_EventType.VIEW_PTB_OVERVIEW]: ViewPtbOverviewEventSchema,
    [_EventType.VIEW_PTB_AMOUNT]: ViewPtbAmountEventSchema,
    [_EventType.VIEW_PTB_SETTLEMENT]: ViewPtbSettlementEventSchema,
    [_EventType.VIEW_PTB_CONDITIONS]: ViewPtbConditionsEventSchema,
    [_EventType.VIEW_PTB_FINANCE]: ViewPtbFinanceEventSchema,
    [_EventType.VIEW_PTB_CONVEYANCER]: ViewPtbConveyancerEventSchema,
    [_EventType.VIEW_PTB_DOCUMENTS]: ViewPtbDocumentsEventSchema,
    [_EventType.VIEW_PTB_BUYERS]: ViewPtbBuyersEventSchema,
    [_EventType.VIEW_PTB_ADD_BUYER]: ViewPtbAddBuyerEventSchema,
    [_EventType.VIEW_PTB_ADD_BUYER_SMS_VALIDATE]:
      ViewPtbAddBuyerSmsValidateEventSchema,
    [_EventType.VIEW_PTB_BUYER]: ViewPtbBuyerEventSchema,
    [_EventType.UPGRADE_PTB_TO_OFFER]: UpgradePtbToOfferEventSchema,
    [_EventType.DOWNLOAD_DOCUMENT]: DownloadDocumentEventSchema,
    [_EventType.BUYER_START_SESSION]: BuyerStartSessionEventSchema,

    // Lite offer events
    [_EventType.CLAIM_LITE_OFFER]: ClaimLiteOfferEventSchema,
  }
}
