import { Card, Flex, FlexItem } from '@propps-au/ui'
import React from 'react'

export const OfferEventContentCard = ({
  start,
  children,
  end,
}: {
  start?: React.ReactNode
  children?: React.ReactNode
  end?: React.ReactNode
}) => {
  return (
    <Card>
      <Flex xs={{ wrap: 'nowrap' }}>
        <FlexItem xs={{ padding: { right: 'sm' } }}>{start}</FlexItem>
        <FlexItem xs={{ grow: true }}>{children}</FlexItem>
        <FlexItem>{end}</FlexItem>
      </Flex>
    </Card>
  )
}
