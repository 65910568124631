/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorReporting } from '@propps-au/client'
import {
  ButtonCTA,
  GlobalStyle,
  Icon,
  IconHeroSurprised,
  pxToRem,
  StackApp,
  StackMain,
  Title,
} from '@propps-au/ui'
import React, { Fragment, useCallback } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { FrameContentLayout } from './frame-content-layout'
import { useFrameTransport } from './FrameTransport'

export function ErrorBoundary({ children }: { children?: React.ReactNode }) {
  const onError = (err: any) => {
    ErrorReporting.report(err)
  }
  return (
    <ReactErrorBoundary onError={onError} fallback={<CrashScreen />}>
      {children}
    </ReactErrorBoundary>
  )
}

function CrashScreen() {
  const reload = (e: React.MouseEvent) => {
    e.preventDefault()
    // @ts-ignore
    window.location.reload(true)
  }

  const transport = useFrameTransport()

  const close = useCallback(() => {
    transport.send({ type: 'close' })
  }, [transport])

  return (
    <Fragment>
      <GlobalStyle />
      <StackApp variant="frames">
        <StackMain variant="topCenter">
          <FrameContentLayout>
            <Icon svg={IconHeroSurprised} size={pxToRem(128)} />
            <Title sub="Something went wrong">Oh no</Title>
            <p className="grey">
              Make sure you're using a{' '}
              <a
                href="https://www.google.com.au/intl/en_au/chrome/"
                target="_blank"
                rel="noopener noreferrer"
              >
                modern browser
              </a>
              , then{' '}
              <a href="#" onClick={reload}>
                try again
              </a>
              . If this keeps happening, reach out to{' '}
              <a href="mailto:support@propps.com">support@propps.com</a>
            </p>

            <ButtonCTA onClick={close} fixed={false}>
              Close
            </ButtonCTA>
          </FrameContentLayout>
        </StackMain>
      </StackApp>
    </Fragment>
  )
}
