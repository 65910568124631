import {
  AuthStateResolved,
  createApolloClient,
  ErrorReporting,
  IAuth,
} from '@propps-au/client'
import PossibleTypes from '@propps-au/supergraph-schema/possible-types.json'
import gql from 'graphql-tag'
import {
  OfferForm_CreateBuyerMutationDocument,
  OfferForm_GetCurrentBuyerQueryDocument,
} from './__generated__/create-buyer.generated'

export async function createBuyer(
  auth: IAuth,
  profile: { firstName: string; email: string; phone: string }
) {
  const client = createApolloClient(
    process.env.REACT_APP_GRAPHQL_ENDPOINT!,
    auth,
    null,
    PossibleTypes.possibleTypes
  )

  let { data } = await client.query({
    query: OfferForm_GetCurrentBuyerQueryDocument,
  })

  // The buyer already exists.
  if (data.me?.buyer) {
    return data.me.buyer.id
  }

  // Create a new buyer
  const { data: data2, errors } = await client.mutate({
    mutation: OfferForm_CreateBuyerMutationDocument,
    variables: {
      input: {
        uid: (auth.state as AuthStateResolved).uid!,
        firstName: profile.firstName,
        lastName: '',
        email: profile.email.trim(),
        phone: profile.phone.replace(/\s/g, ''),
      },
    },
  })

  if (!data2) {
    ErrorReporting.report(errors)
    throw new Error('Could not create new buyer.')
  }

  return data2.result.buyer.id
}

export type BuyerCreateData = {
  firstName: string
  phone: string
  email: string
}

export const GRAPHQL = gql`
  query OfferForm_GetCurrentBuyerQuery {
    me {
      uid
      buyer {
        id
      }
    }
  }

  mutation OfferForm_CreateBuyerMutation($input: CreateBuyerInput!) {
    result: createBuyer(input: $input) {
      buyer {
        id
      }
    }
  }
`
