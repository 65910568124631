/** @jsx jsx */
import { gql, useQuery } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import { ErrorReporting, useAuthState } from '@propps-au/client'
import {
  Accordion,
  color,
  Icon,
  IconBookmark2,
  IconCircleDown,
  IconCircleEmpty,
  IconCircleInfo,
  IconCircleInfo2,
  IconCircleUp,
  List,
  ListItem,
  pxToRem,
  Section,
  SectionTitle,
  Tooltip,
} from '@propps-au/ui'
import { Fragment, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import NumberFormat from 'react-number-format'
import { useAnalytics } from './analytics'
import {
  ExposeCompetitionInitialQueryDocument,
  ExposeCompetitionQueryDocument,
} from './__generated__/expose-competition.generated'

const styles = {
  amount: css`
    color: ${color.grey50};
  `,
}

export function ExposeCompetition({
  listingId,
  fromOfferSubmit,
  offerId,
}: {
  listingId: string
  fromOfferSubmit?: boolean
  offerId?: string
}) {
  const [isUpToDate, setIsUpToDate] = useState(!fromOfferSubmit)
  const analytics = useAnalytics()
  const auth = useAuthState()
  const [failedToCatchUp, setFailedToCatchUp] = useState(false)
  const amplitude = analytics.pixel?.amplitude

  const { data, loading, startPolling, stopPolling } = useQuery(
    ExposeCompetitionQueryDocument,
    {
      variables: { listingId: listingId, buyerId: auth.role?.id! },
      skip: !auth.role?.id,
      errorPolicy: 'all',
    }
  )

  const { data: initialData } = useQuery(
    ExposeCompetitionInitialQueryDocument,
    {
      variables: { listingId },
    }
  )

  const competition = data?.listing?.buyerCompetition.buyer
  const state = initialData?.listing?.buyerCompetition.state ?? 'HIDDEN'
  const show = state !== 'HIDDEN'

  useEffect(() => {
    // if data warehouse has caught up with latest offer
    if (
      fromOfferSubmit &&
      offerId &&
      competition?.offer?.offer?.id === offerId
    ) {
      setIsUpToDate(true)
    }
  }, [fromOfferSubmit, offerId, competition])

  useEffect(() => {
    if (!show) {
      stopPolling()
    }
    if (!isUpToDate) {
      startPolling(2e3)
    } else {
      startPolling(15e3)
    }
  }, [isUpToDate, startPolling, stopPolling, show])

  useEffect(() => {
    if (state !== 'HIDDEN') {
      amplitude?.logEvent(`view competition (${state})`)
    }
  }, [state, amplitude])

  useEffect(() => {
    if (!isUpToDate && show) {
      const handler = setTimeout(() => {
        stopPolling()
        setFailedToCatchUp(true)
        console.error({ listingId, offerId })
        ErrorReporting.report(
          new Error('Expose competition failed to catch up with data warehouse')
        )
      }, 30e3) // failed to catch up after 30 seconds

      return () => clearTimeout(handler)
    }
  }, [show, isUpToDate, listingId, offerId, stopPolling])

  if (!show || failedToCatchUp) {
    return null
  }

  if ((!data && loading) || !isUpToDate) {
    return (
      <Section>
        <SectionTitle loading />
        <List>
          <ListItem start={<Icon svg={IconCircleEmpty} loading />}>
            <Skeleton width="12ch" />
          </ListItem>
          <ListItem start={<Icon svg={IconCircleEmpty} loading />}>
            <Skeleton width="10ch" />
          </ListItem>
          <ListItem start={<Icon svg={IconCircleEmpty} loading />}>
            <Skeleton width="12ch" />
          </ListItem>
          {state === 'OFFER_INFO' && (
            <ListItem start={<Icon svg={IconCircleEmpty} loading />}>
              <Skeleton width="10ch" />
            </ListItem>
          )}
        </List>
      </Section>
    )
  }

  return (
    <Section>
      <SectionTitle>
        Offer strength&nbsp;
        <Tooltip
          icon={{ svg: IconCircleInfo, size: pxToRem(18) }}
          content="Ranked by price"
        />
      </SectionTitle>
      <List>
        <Fragment>
          {competition?.offer?.position && competition?.offer?.position !== 1 && (
            <Fragment>
              {state === 'OFFER_INFO' && (
                <ListItem
                  start={<Icon svg={IconBookmark2} />}
                  end={
                    <NumberFormat
                      value={competition.highest?.amount}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      css={styles.amount}
                    />
                  }
                >
                  Highest offer
                </ListItem>
              )}
              <ListItem start={<Icon svg={IconCircleUp} fill={color.red} />}>
                {competition.offer.position - 1} offer
                {competition.offer.position - 1 > 1 && 's'} above yours
              </ListItem>
            </Fragment>
          )}
          {competition?.offer?.offer.conditions &&
            competition?.offer?.offer.conditions?.length >= 1 && (
              <li>
                <Accordion
                  start={
                    <span>
                      <Icon svg={IconCircleInfo2} fill={color.orange} />
                    </span>
                  }
                  title="Offer appeal"
                >
                  An offer without conditions may be more attractive to the
                  vendor but may increase your risk. The offer ultimately
                  accepted by a vendor may come down to multiple factors like
                  price and other conditions. Sometimes the highest amount is
                  less important to a vendor.
                </Accordion>
              </li>
            )}
          {competition?.offer?.position &&
            competition?.totalOffers &&
            competition.totalOffers - competition.offer.position >= 1 && (
              <Fragment>
                <ListItem
                  start={<Icon svg={IconCircleDown} fill={color.green} />}
                >
                  {competition.totalOffers - competition.offer.position} offer
                  {competition.totalOffers - competition.offer.position > 1 &&
                    's'}{' '}
                  below yours
                </ListItem>
              </Fragment>
            )}
        </Fragment>
      </List>
    </Section>
  )
}

gql`
  fragment BuyerCompetition_ListingBuyerCompetition on ListingBuyerCompetition {
    highest {
      amount
      id
    }
    lowest {
      amount
      id
    }
    offer {
      offer {
        id
        amount
        conditions {
          ... on CustomOfferCondition {
            content
          }
          ... on OfferCondition {
            params {
              name
            }
          }
        }
      }
      position
    }
    totalOffers
  }

  query ExposeCompetitionInitialQuery($listingId: ID!) {
    listing(id: $listingId) {
      id
      buyerCompetition {
        state
      }
    }
  }

  query ExposeCompetitionQuery($listingId: ID!, $buyerId: ID!) {
    listing(id: $listingId) {
      id
      buyerCompetition {
        state
        buyer(id: $buyerId) {
          ...BuyerCompetition_ListingBuyerCompetition
        }
      }
    }
  }
`
