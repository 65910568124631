/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedPhoneNumberInput, getErrors } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { Alert, Input, Label, pxToRem, Select, Title } from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import isMobile from 'is-mobile'
import React, { Fragment, useEffect } from 'react'
import * as Yup from 'yup'
import { ReactComponent as LogoSettleEasy } from '../../images/logo-settle-easy.svg'
import { useAnalytics } from '../analytics'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { OfferFormMode } from './mode'
import { Conveyancer as ConveyancerValue, ConveyancerType } from './values'
import { Conveyancer_ListingFragment } from './__generated__/conveyancer.generated'

type ConveyancerFormValues = {
  type: ConveyancerType | ''
  name: string
  phone: string
}

const constructConveyancerObject = (values: ConveyancerFormValues) => {
  switch (values.type) {
    case ConveyancerType.CONVEYANCER_HELP:
      return {
        type: ConveyancerType.CONVEYANCER_HELP as ConveyancerType.CONVEYANCER_HELP,
        name: null,
        phone: null,
      }
    case ConveyancerType.LEGAL_PROCESS_HELP:
      return {
        type: ConveyancerType.LEGAL_PROCESS_HELP as ConveyancerType.LEGAL_PROCESS_HELP,
        name: null,
        phone: null,
      }
    case ConveyancerType.NONE:
      return {
        type: ConveyancerType.NONE as ConveyancerType.NONE,
        name: null,
        phone: null,
      }
    case ConveyancerType.PROVIDED:
      return {
        type: values.type as ConveyancerType.PROVIDED,
        name: values.name,
        phone: values.phone.replace(/\s/g, '') || null,
      }
    default:
      return null
  }
}

const isMobileDevice = isMobile()

export function Conveyancer({
  listing,
  label = 'Done',
  onContinue,
}: {
  listing: Conveyancer_ListingFragment
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  const showReferral = listing.partnerReferrals?.conveyancing?.enabled

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbConveyancer>({
        type: BuyerEvent.VIEW_PTB_CONVEYANCER,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferConveyancer>({
        type: BuyerEvent.VIEW_OFFER_CONVEYANCER,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logEvent = (conveyancer: ConveyancerValue) => {
    analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferConveyancer>({
      type: BuyerEvent.COMPLETE_OFFER_CONVEYANCER,
      conveyancerType: conveyancer.type,
      conveyancerName: conveyancer.name,
      conveyancerPhone: conveyancer.phone,
      conveyancerReferralEnabled: showReferral || false,
      ...analytics.getEventMetadata(),
    })
  }

  const form = useFormik<ConveyancerFormValues>({
    initialValues: {
      type: state.conveyancer?.type || '',
      name: state.conveyancer?.name || '',
      phone: state.conveyancer?.phone || '',
    },
    validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      const conveyancer = constructConveyancerObject(values)
      update((s) => ({
        ...s,
        conveyancer,
      }))

      if (conveyancer) {
        logEvent(conveyancer)
      }

      onContinue()
    },
  })

  const skip = (e: any) => {
    e.preventDefault()
    const conveyancer: ConveyancerValue = {
      type: ConveyancerType.NONE,
      name: null,
      phone: null,
    }
    update((s) => ({
      ...s,
      conveyancer,
    }))
    logEvent(conveyancer)
    onContinue()
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>
        {showReferral
          ? 'Who will help review the legal documents and assist with settlement?'
          : 'Do you have a conveyancer or legal help ready for a purchase?'}
      </Title>
      <fieldset>
        <Label label="Legal help">
          <Select
            autoFocus={!isMobileDevice}
            {...form.getFieldProps('type')}
            errors={getErrors(form, 'type')}
            data-testid="type-select"
          >
            <optgroup label="Legal help status">
              <option disabled value="">
                ...
              </option>
              {showReferral ? (
                <Fragment>
                  <option value={ConveyancerType.LEGAL_PROCESS_HELP}>
                    I need help with the legal process
                  </option>
                  <option value={ConveyancerType.CONVEYANCER_HELP}>
                    I need help finding a conveyancer
                  </option>
                  <option value={ConveyancerType.PROVIDED}>
                    I have a conveyancer or solicitor ready
                  </option>
                  <option value={ConveyancerType.NONE}>
                    I don't require further help
                  </option>
                </Fragment>
              ) : (
                <Fragment>
                  <option value={ConveyancerType.PROVIDED}>
                    I have a conveyancer or solicitor ready
                  </option>
                  <option value={ConveyancerType.NONE}>
                    I haven't got one yet
                  </option>
                </Fragment>
              )}
            </optgroup>
          </Select>
        </Label>
        {form.values.type === ConveyancerType.PROVIDED && (
          <Fragment>
            <Label label="Contact name">
              <Input
                type="text"
                placeholder="..."
                {...form.getFieldProps('name')}
                errors={getErrors(form, 'name')}
                data-testid="name-input"
              />
            </Label>
            <Label label="Phone number">
              <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                component={Input}
                defaultCountry="AU"
                type="text"
                placeholder="..."
                {...form.getFieldProps('phone')}
                onChange={(value) => form.setFieldValue('phone', value)}
                errors={getErrors(form, 'phone')}
                autoComplete="tel"
                inputMode="tel"
                data-testid="phone-input"
              />
            </Label>
          </Fragment>
        )}
      </fieldset>

      {showReferral &&
        (form.values.type === ConveyancerType.LEGAL_PROCESS_HELP ||
          form.values.type === ConveyancerType.CONVEYANCER_HELP) && (
          <Alert
            icon={
              <LogoSettleEasy
                height={pxToRem(48)}
                width={pxToRem(48)}
                aria-label="Settle Easy logo"
              />
            }
            size="md"
            title="We’re here to help!"
          >
            By saying you require help, you agree to us sending your details to
            Settle Easy, who can guide you through the legal process.
          </Alert>
        )}
      <p className="grey">
        {showReferral
          ? `Everyone needs help with the legal aspects of purchasing a property. Transferring ownership of property or land typically requires a conveyancer.`
          : `You'll need legal representation to help you with the purchase of a property. If you're unsure about this, your agent can help you out.`}{' '}
        You can also{' '}
        <a href="" onClick={skip}>
          skip this step
        </a>
        .
      </p>
      <PrimaryButton onClick={form.submitForm} label={label} />
    </form>
  )
}

const validationSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(
      [
        ConveyancerType.LEGAL_PROCESS_HELP,
        ConveyancerType.CONVEYANCER_HELP,
        ConveyancerType.NONE,
        ConveyancerType.PROVIDED,
      ],
      'Please select a legal option'
    )
    .required('Please select a legal option'),
  name: Yup.string().when('type', (type: string, schema: Yup.StringSchema) =>
    type === ConveyancerType.PROVIDED
      ? schema.required("Please enter the legal contact's name.")
      : schema.notRequired()
  ),
  phone: Yup.string().when('type', (type: string, schema: Yup.StringSchema) =>
    type === ConveyancerType.PROVIDED
      ? schema.required("Please enter the legal contact's phone number.")
      : schema.notRequired()
  ),
})

export const GRAPHQL = gql`
  fragment Conveyancer_Listing on Listing {
    id
    agents {
      id
    }
    agency {
      id
    }
    property {
      address {
        state
      }
    }
    partnerReferrals {
      conveyancing {
        enabled
      }
    }
  }
`
