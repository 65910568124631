import { gql } from '@apollo/client'
import { Activity } from '@propps-au/ui'
import React from 'react'
import { ListingBuyerRegistered_ListingBuyerRegisteredEventFragment } from './__generated__/listing-buyer-registered.generated'

export const ListingBuyerRegisteredEvent = ({
  event,
}: {
  event: ListingBuyerRegistered_ListingBuyerRegisteredEventFragment
}) => {
  if (event.buyer.__typename === 'Buyer') {
    return (
      <Activity.Event
        label="You"
        firstName={event.buyer.firstName}
        lastName={event.buyer.lastName}
        createdAt={event.createdAt}
        title=" signed up to receive updates"
      />
    )
  }
  return null
}

gql`
  fragment ListingBuyerRegistered_ListingBuyerRegisteredEvent on ListingBuyerRegisteredEvent {
    createdAt
    eventId
    buyer {
      ... on Buyer {
        id
        firstName
        lastName
        phone
        email
      }
    }
  }
`
