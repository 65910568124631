/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CheckVerificationMethodRequest = {
    uid: string;
    identifier: string;
    type: CheckVerificationMethodRequest.type;
}

export namespace CheckVerificationMethodRequest {

    export enum type {
        PHONE = 'phone',
        EMAIL = 'email',
    }


}
