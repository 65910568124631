import { format, isValid, parse as parseDate } from 'date-fns'
import * as Yup from 'yup'

export const inputDateFormat = 'dd/MM/yyyy'

export const displayDateFormat = 'dd MMM yyyy'
export const displayDateTimeFormat = 'hh:mm a, dd MMM yyyy'

const supportedDateFormats = [
  'dd/MM/yyyy',
  'ddMMyyyy',
  'yyyy/MM/dd',
  'yyyyMMdd',
  'dd-MM-yyyy',
  'yyyy-MM-dd',
]

const parseDateStringWithMultipleFormats = (
  value: string,
  formats: string[]
): Date | number => {
  for (const formatString of formats) {
    const parsedDate = parseDate(value, formatString, new Date())
    if (isValid(parsedDate)) {
      return parsedDate
    }
  }
  return NaN
}

export const parseDateString = (value: string) => {
  return parseDateStringWithMultipleFormats(value, supportedDateFormats)
}

export const formatInputDate = (value: string) => {
  const parsedDate = parseDateString(value.replace(/\s+/g, ''))
  if (isValid(parsedDate)) {
    return format(parsedDate, inputDateFormat)
  }
  return value
}

export const DateSchema = Yup.string()
  .transform((value) => value.replace(/\s+/g, ''))
  .test(
    'date',
    'This appears to be an invalid date.',
    (value: string | undefined): value is string =>
      !value || isValid(parseDateString(value))
  )
