import { ErrorReporting, Role, useAuthState } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { parseISO } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IdentityDocumentType } from '../../__generated__/types'
import { useAnalytics } from '../analytics'
import { LiteOfferFragment } from '../use-lite-offer'
import { OfferFormProvider, SubmissionError } from './context'
import { OfferFormMode } from './mode'
import {
  OfferForm_BuyerFragment,
  OfferForm_CurrentOfferFragment,
  OfferForm_DraftOfferFragment,
  OfferForm_ListingFragment,
} from './query'
import { useSaveDraftOfferMutation } from './save-draft-offer-mutation'
import { useVendorLead } from './selling/vendor-lead-context'
import { DEFAULT_OFFER_FORM_STATUS, getFormStatus } from './status'
import {
  OfferFormCompleteValues,
  useSubmitOfferMutation,
} from './submit-offer-mutation'
import {
  Agent,
  Condition,
  Conveyancer,
  ConveyancerType,
  DEFAULT_OFFER_FORM_VALUES,
  Finance,
  FinanceType,
  IdentityDocument,
  OfferFormValues,
  Property,
  RepresentedEntity,
  Settlement,
  Signatory,
} from './values'

export function OfferFormController({
  listing,
  mode,
  children,
  liteOffer,
  draftOffer,
  currentOffer,
  buyer,
  onSubmit,
}: {
  listing: OfferForm_ListingFragment
  mode: OfferFormMode
  children?: React.ReactNode
  liteOffer: LiteOfferFragment | null
  draftOffer: OfferForm_DraftOfferFragment | null
  currentOffer: OfferForm_CurrentOfferFragment | null
  buyer: OfferForm_BuyerFragment | null
  onSubmit: (offerid?: string) => void
}) {
  const analytics = useAnalytics()
  const [initialValue] = useState(() => {
    if (buyer) {
      if (mode === OfferFormMode.UPDATE_OFFER) {
        if (currentOffer) {
          return fromCurrentOffer(currentOffer, buyer.id)
        }
      } else {
        if (draftOffer) {
          return fromDraftOffer(draftOffer, buyer.id)
        }
        if (liteOffer) {
          return fromLiteOffer(liteOffer)
        }
      }
    }
    return {
      ...DEFAULT_OFFER_FORM_VALUES,
      ...(buyer
        ? {
            signatories: [
              {
                email: buyer.email,
                firstName: buyer.firstName,
                lastName: buyer.lastName,
                phone: buyer.phone,
                address: '12 Test Street',
                buyerId: buyer.id,
                agreedContractRevision: null,
                agreedPrivacyPolicyRevision: null,
                agreedTermsRevision: null,
                agreeToIdentityVerification: false,
                dateOfBirth: '12/12/2000',
                identityDocument: {
                  country: 'AU',
                  documentNo: '123456789',
                  type: IdentityDocumentType.drivers_licence,
                  region: 'NSW',
                },
                isPrimaryBuyer: true,
                middleName: '',
                signatureData: null,
                onBehalfOf: { type: 'self' as const },
              },
            ],
          }
        : {}),
    }
  })

  const [state, setState] = useState(initialValue)
  const [status, setStatus] = useState(DEFAULT_OFFER_FORM_STATUS)
  const [submissionError, setSubmissionError] = useState<SubmissionError>(null)
  const { state: vendorLeadState } = useVendorLead()
  const auth = useAuthState()
  const isBuyer = auth.role?.name === Role.BUYER

  useEffect(() => {
    setStatus(getFormStatus(listing, state, vendorLeadState, mode))
  }, [listing, mode, state, vendorLeadState])

  /** Save draft offer every time it is updated */
  const [saveDraftOffer] = useSaveDraftOfferMutation()
  useEffect(() => {
    if (
      isBuyer &&
      state !== initialValue &&
      mode !== OfferFormMode.UPDATE_OFFER
    ) {
      saveDraftOffer(state, {
        buyerId: auth.role!.id,
        listingId: listing.id,
        mode,
      })
    }
  }, [
    state,
    isBuyer,
    saveDraftOffer,
    auth.role,
    mode,
    initialValue,
    listing.id,
  ])

  const [submitOffer] = useSubmitOfferMutation()

  /** Upgrade an implicit draft to an explicit draft and swap to offer form */
  const submit = useCallback(async () => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      try {
        await saveDraftOffer(state, {
          listingId: listing.id,
          buyerId: buyer!.id,
          mode: OfferFormMode.CREATE_OFFER,
        })
        onSubmit()
      } catch (err) {
        ErrorReporting.report(err)
        return
      }
      analytics.logPixelEvent<Pixel.BuyerEvent.UpgradePtbToOffer>({
        type: BuyerEvent.UPGRADE_PTB_TO_OFFER,
        ...analytics.getEventMetadata(),
      })
    } else {
      try {
        const { data } = await submitOffer(state as OfferFormCompleteValues, {
          listingId: listing.id,
          isBinding: listing.acceptedOfferType === 'binding',
          mode,
        })
        onSubmit(data?.result.offer.id)
      } catch (err) {
        ErrorReporting.report(err)
        setSubmissionError(
          "Hmm, we've run into some trouble submitting your offer. It's best you reach out to us at help@propps.com"
        )
        return
      }

      if (mode === OfferFormMode.UPDATE_OFFER) {
        analytics.logPixelEvent<Pixel.BuyerEvent.UpdateOffer>({
          type: BuyerEvent.UPDATE_OFFER,
          ...analytics.getEventMetadata(),
        })
      } else {
        analytics.logPixelEvent<Pixel.BuyerEvent.SubmitOffer>({
          type: BuyerEvent.SUBMIT_OFFER,
          ...analytics.getEventMetadata(),
        })
      }
    }
  }, [
    analytics,
    buyer,
    listing.acceptedOfferType,
    listing.id,
    mode,
    onSubmit,
    saveDraftOffer,
    state,
    submitOffer,
  ])

  const context = useMemo(
    () => ({
      state: state,
      status: status,
      update: setState,
      submit,
      submissionError,
      mode,
    }),
    [state, status, submit, submissionError, mode]
  )

  return <OfferFormProvider value={context}>{children}</OfferFormProvider>
}

export function fromDraftOffer(
  draftOffer: OfferForm_DraftOfferFragment,
  primaryBuyerId: string
): OfferFormValues {
  return {
    property: fromDraftOfferProperty(draftOffer.property ?? null),
    agents: fromDraftOfferAgents(draftOffer.agents),
    expiry: draftOffer.expiresAt ? parseISO(draftOffer.expiresAt) : null,
    attributedAgentId: draftOffer.attributedAgent
      ? draftOffer.attributedAgent.id
      : null,
    amount: draftOffer.amount ?? null,
    settlement: fromDraftOfferSettlement(draftOffer.settlement ?? null),
    hasReviewedConditions: false,
    ...fromDraftOfferConditions(draftOffer.conditions),
    finance: fromDraftOfferFinance(draftOffer.finance),
    addTitleHoldersLater: draftOffer.addTitleHoldersLater ?? false,
    signatories: draftOffer.signatories.map((item) =>
      fromDraftOfferSignatory(item, primaryBuyerId)
    ),
    conveyancer: fromDraftOfferConveyancer(draftOffer.conveyancer),
    reviewedDocumentIds: null,
    depositPercentage: null,
    depositAmount: null,
  }
}

export function fromCurrentOffer(
  currentOffer: OfferForm_CurrentOfferFragment,
  primaryBuyerId: string
): OfferFormValues {
  return {
    property: fromDraftOfferProperty(currentOffer.property ?? null),
    agents: fromDraftOfferAgents(currentOffer.agents),
    expiry: currentOffer.expiresAt ? parseISO(currentOffer.expiresAt) : null,
    attributedAgentId: currentOffer.attributedAgent
      ? currentOffer.attributedAgent.id
      : null,
    amount: currentOffer.amount,
    settlement: fromDraftOfferSettlement(currentOffer.settlement ?? null),
    hasReviewedConditions: true,
    ...fromDraftOfferConditions(currentOffer.conditions),
    finance: fromDraftOfferFinance(currentOffer.finance),
    addTitleHoldersLater: currentOffer.addTitleHoldersLater ?? false,
    signatories: currentOffer.signatories.map((item) =>
      fromDraftOfferSignatory(item, primaryBuyerId)
    ),
    conveyancer: fromDraftOfferConveyancer(currentOffer.conveyancer),
    reviewedDocumentIds: ['doc'],
    depositPercentage: currentOffer.depositPercentage
      ? currentOffer.depositPercentage
      : null,
    depositAmount: currentOffer.depositAmount
      ? currentOffer.depositAmount
      : null,
  }
}

export function fromLiteOffer(liteOffer: LiteOfferFragment): OfferFormValues {
  return {
    ...DEFAULT_OFFER_FORM_VALUES,
    amount: liteOffer.amount ? parseInt(liteOffer.amount) : null,
  }
}

function fromDraftOfferProperty(
  property: OfferForm_DraftOfferFragment['property']
): Property | null {
  if (!property) {
    return null
  }

  return {
    address: {
      line1: property?.address.line1,
      city: property?.address.city,
      state: property?.address.state,
      postcode: property?.address.postcode,
    },
  }
}

function fromDraftOfferAgents(
  agents: OfferForm_DraftOfferFragment['agents']
): Agent[] {
  if (!agents) {
    return []
  }

  return agents.map((agent) => ({
    firstName: agent.firstName,
    lastName: agent.lastName,
    phone: agent.phone,
    email: agent.email,
  }))
}

function fromDraftOfferSettlement(
  settlement: OfferForm_DraftOfferFragment['settlement']
): Settlement | null {
  switch (settlement?.__typename) {
    case 'OfferSettlementPeriodDate':
      return {
        type: 'date',
        date: settlement.date.formatted,
      }
    case 'OfferSettlementPeriodDays':
      return {
        type: 'days',
        days: settlement.days,
      }
    case 'OfferSettlementPeriodDefault':
      throw new Error('OfferSettlementPeriodDefault is not supported')
    case undefined:
      return null
  }
}

function fromDraftOfferConditions(
  conditions: OfferForm_DraftOfferFragment['conditions']
): {
  conditions: Condition[]
  customCondition: string
  customConditionAdded: boolean
} {
  if (!conditions) {
    return {
      conditions: [],
      customCondition: '',
      customConditionAdded: false,
    }
  }

  const customCondition =
    conditions?.find(
      (
        condition
      ): condition is Exclude<
        typeof condition,
        { __typename: 'OfferCondition' }
      > => condition.__typename === 'CustomOfferCondition'
    )?.content ?? ''

  return {
    conditions:
      conditions
        ?.filter(
          (
            condition
          ): condition is Exclude<
            typeof condition,
            { __typename: 'CustomOfferCondition' }
          > => condition.__typename !== 'CustomOfferCondition'
        )
        .map((condition) => ({
          region: condition.revision.region,
          line: condition.revision.line,
          id: condition.revision.id,
          params: condition.params.map((param) => ({
            name: param.name,
            value: param.value,
          })),
        })) ?? [],
    customCondition: customCondition,
    customConditionAdded: !!customCondition,
  }
}

function fromDraftOfferFinance(
  finance: OfferForm_DraftOfferFragment['finance']
): Finance | null {
  switch (finance?.__typename) {
    case 'OfferFinanceDeclarationApproved':
      return {
        type: FinanceType.APPROVED,
        institution: finance.institution,
        date: finance.date.formatted,
        note: null,
      }

    case 'OfferFinanceDeclarationNotApplicable':
      return {
        type: FinanceType.NOT_APPLICABLE,
        institution: null,
        date: null,
        note: finance.note ?? null,
      }

    case 'OfferFinanceDeclarationNone':
      return {
        type: FinanceType.NONE,
        institution: null,
        date: null,
        note: null,
      }
    case 'OfferFinanceDeclarationOther':
      return {
        type: FinanceType.OTHER,
        institution: null,
        date: null,
        note: finance.note ?? null,
      }
    case undefined:
      return null
  }
}

function fromDraftOfferConveyancer(
  conveyancer: OfferForm_DraftOfferFragment['conveyancer']
): Conveyancer | null {
  switch (conveyancer?.__typename) {
    case 'OfferConveyancerInformationNone':
      return {
        type: ConveyancerType.NONE,
        name: null,
        phone: null,
      }
    case 'OfferConveyancerInformationProvided':
      return {
        type: ConveyancerType.PROVIDED,
        name: conveyancer.name,
        phone: conveyancer.phone,
      }
    case 'OfferConveyancerInformationLegalProcessHelp':
      return {
        type: ConveyancerType.LEGAL_PROCESS_HELP,
        name: null,
        phone: null,
      }
    case 'OfferConveyancerInformationFindConveyancerHelp':
      return {
        type: ConveyancerType.CONVEYANCER_HELP,
        name: null,
        phone: null,
      }
    case undefined:
      return null
  }
}

function fromDraftOfferSignatory(
  signatory:
    | OfferForm_DraftOfferFragment['signatories'][0]
    | OfferForm_CurrentOfferFragment['signatories'][0],
  primaryBuyerId: string
): Signatory {
  return {
    firstName: signatory.firstName,
    middleName: signatory.middleName,
    lastName: signatory.lastName,
    address: signatory.address,
    agreeToIdentityVerification: true,
    dateOfBirth: signatory.dateOfBirth.formatted,
    email: signatory.email,
    identityDocument: fromDraftOfferIdentityDocument(
      signatory.identityDocument
    ),
    onBehalfOf: fromRepresentedEntity(signatory.onBehalfOf),
    phone: signatory.phone,
    buyerId: signatory.buyer!.id,
    isPrimaryBuyer: signatory.buyer!.id === primaryBuyerId,
    agreedContractRevision: null,
    agreedPrivacyPolicyRevision: null,
    agreedTermsRevision: null,
    signatureData: null,
  }
}

function fromDraftOfferIdentityDocument(
  identityDocument: OfferForm_DraftOfferFragment['signatories'][0]['identityDocument']
): IdentityDocument {
  switch (identityDocument.type) {
    case 'drivers_licence':
      return {
        country: identityDocument.country,
        documentNo: identityDocument.documentNo ?? '',
        region: identityDocument.region ?? '',
        type: 'drivers_licence',
      }
    case 'passport':
      return {
        country: identityDocument.country,
        documentNo: identityDocument.documentNo ?? '',
        type: 'passport',
      }
  }
}

function fromRepresentedEntity(
  entity: OfferForm_DraftOfferFragment['signatories'][0]['onBehalfOf']
): RepresentedEntity {
  switch (entity.__typename) {
    case 'RepresentedEntitySelf':
      return { type: 'self' }
    case 'RepresentedEntityPerson':
      return { type: 'person', name: entity.name }
    case 'RepresentedEntityCompany':
      return { type: 'company', name: entity.name }
  }
}
