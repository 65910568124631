export var FrameMessage;
(function (FrameMessage) {
    FrameMessage.isNavigate = is('navigate');
    FrameMessage.isConfigure = is('configure');
    FrameMessage.isSetVisibility = is('set-visible');
    FrameMessage.isTriggerMounted = is('trigger-mounted');
    FrameMessage.isSetSessionMetadata = is('set-session-metadata');
    function is(t) {
        return (message) => typeof message === 'object' &&
            !!message &&
            'type' in message &&
            typeof message.type === 'string' &&
            message.type === t;
    }
    FrameMessage.is = is;
})(FrameMessage || (FrameMessage = {}));
