import { OfferFormMode } from './offer-form'
import { OfferForm_ListingFragment } from './query'
import { OfferFormRoute, ROUTES } from './routes'
import { VendorLeadValues } from './selling/vendor-lead-context'
import { ConveyancerType, FinanceType, OfferFormValues } from './values'

export enum OfferStep {
  PROPERTY_AGENT_DETAILS = 'property-agent-details',
  AMOUNT = 'amount',
  SETTLEMENT = 'settlement',
  CONDITIONS = 'conditions',
  FINANCE = 'finance',
  CONVEYANCER = 'conveyancer',
  DOCUMENTS = 'documents',
  SIGNATORIES = 'buyers',
  SELLING = 'selling',
}

export function getStepOrder(
  mode: OfferFormMode,
  listingType: OfferForm_ListingFragment['type'] = 'native'
) {
  if (mode === OfferFormMode.PREPARE_OFFER) {
    return [
      OfferStep.FINANCE,
      OfferStep.CONVEYANCER,
      OfferStep.SELLING,
      OfferStep.DOCUMENTS,
      OfferStep.SIGNATORIES,
      OfferStep.CONDITIONS,
      OfferStep.SETTLEMENT,
      OfferStep.AMOUNT,
    ]
  } else {
    return [
      ...(listingType === 'referred' ? [OfferStep.PROPERTY_AGENT_DETAILS] : []),
      OfferStep.AMOUNT,
      OfferStep.SETTLEMENT,
      OfferStep.CONDITIONS,
      OfferStep.FINANCE,
      OfferStep.CONVEYANCER,
      OfferStep.SELLING,
      OfferStep.DOCUMENTS,
      OfferStep.SIGNATORIES,
    ]
  }
}

export enum StepStatus {
  Completed = 'completed',
  Incomplete = 'incomplete',
  NeedsAttention = 'needs-attention',
  Skipped = 'skipped',
  NotApplicable = 'not-applicable',
}

export type LatestStepRoute = {
  route: OfferFormRoute
  index?: number
}

export type OfferFormStatus = {
  steps: Record<OfferStep, StepStatus>
  started: boolean
  completed: boolean
  latestStep: LatestStepRoute
}

export const DEFAULT_OFFER_FORM_STATUS: OfferFormStatus = {
  steps: {
    [OfferStep.AMOUNT]: StepStatus.Incomplete,
    [OfferStep.CONDITIONS]: StepStatus.Incomplete,
    [OfferStep.CONVEYANCER]: StepStatus.Incomplete,
    [OfferStep.DOCUMENTS]: StepStatus.Incomplete,
    [OfferStep.FINANCE]: StepStatus.Incomplete,
    [OfferStep.PROPERTY_AGENT_DETAILS]: StepStatus.Incomplete,
    [OfferStep.SELLING]: StepStatus.Incomplete,
    [OfferStep.SETTLEMENT]: StepStatus.Incomplete,
    [OfferStep.SIGNATORIES]: StepStatus.Incomplete,
  },
  started: false,
  completed: false,
  latestStep: { route: null as any },
}

export function getStatus({
  completed,
  needsAttention = false,
  skipped = false,
  notApplicable = false,
}: {
  completed: boolean
  needsAttention?: boolean
  skipped?: boolean
  notApplicable?: boolean
}) {
  if (skipped) {
    return StepStatus.Skipped
  }
  if (completed) {
    return StepStatus.Completed
  }
  if (needsAttention) {
    return StepStatus.NeedsAttention
  }
  if (notApplicable) {
    return StepStatus.NotApplicable
  }
  return StepStatus.Incomplete
}

export function getFormStatus(
  listing: OfferForm_ListingFragment,
  offerState: OfferFormValues,
  vendorLeadState: VendorLeadValues,
  mode: OfferFormMode
): OfferFormStatus {
  const stepStatus = {
    [OfferStep.AMOUNT]: getStatus({
      completed: !!offerState?.amount,
    }),
    [OfferStep.SIGNATORIES]: getStatus({
      completed:
        (!!offerState.signatories.length &&
          !!(mode === OfferFormMode.CREATE_OFFER ||
          mode === OfferFormMode.UPDATE_OFFER
            ? !offerState.signatories.find(
                (signatory) => signatory.signatureData === null
              )
            : true)) ||
        (listing.acceptedOfferType === 'nonbinding' &&
          offerState.signatories.length > 0 &&
          !offerState.signatories.find(
            (signatory) => signatory.agreedTermsRevision === null
          )),
    }),
    [OfferStep.CONDITIONS]: getStatus({
      completed: true,
    }),
    [OfferStep.CONVEYANCER]: getStatus({
      completed: !!offerState?.conveyancer?.type,
      skipped:
        offerState?.conveyancer?.type === ConveyancerType.NONE ||
        offerState?.conveyancer?.type === ConveyancerType.CONVEYANCER_HELP ||
        offerState?.conveyancer?.type === ConveyancerType.LEGAL_PROCESS_HELP,
    }),
    [OfferStep.DOCUMENTS]: getStatus({
      completed: !!offerState?.reviewedDocumentIds,
      notApplicable: true,
    }),
    [OfferStep.FINANCE]: getStatus({
      completed: !!offerState?.finance?.type,
      skipped: offerState?.finance?.type === FinanceType.NONE,
    }),
    [OfferStep.PROPERTY_AGENT_DETAILS]: getStatus({
      completed: !!offerState.property && !!offerState.agents,
      notApplicable:
        mode === OfferFormMode.PREPARE_OFFER || listing.type !== 'referred',
    }),
    [OfferStep.SETTLEMENT]: getStatus({
      completed: !!offerState?.settlement?.type,
    }),
    [OfferStep.SELLING]: getStatus({
      completed: !!vendorLeadState.situation,
      skipped:
        vendorLeadState.situation === 'no' ||
        vendorLeadState.situation === 'noowner',
      notApplicable: true,
    }),
  }

  const orderedSteps = getStepOrder(mode, listing.type)

  const latestStep = (): LatestStepRoute => {
    const latestStep = Object.values(orderedSteps).find(
      (step: OfferStep) =>
        stepStatus[step] === StepStatus.NeedsAttention ||
        stepStatus[step] === StepStatus.Incomplete
    )
    if (
      latestStep === OfferStep.SIGNATORIES &&
      mode !== OfferFormMode.PREPARE_OFFER
    ) {
      const index = offerState.signatories.findIndex((signatory) =>
        listing.acceptedOfferType === 'binding'
          ? signatory.signatureData === null
          : signatory.agreedTermsRevision === null
      )
      if (index === -1) {
        return { route: ROUTES[latestStep!] }
      } else {
        return {
          route: ROUTES['signature'],
          index,
        }
      }
    } else {
      return { route: ROUTES[latestStep!] }
    }
  }

  const formStatus = {
    started: orderedSteps
      .filter((step) => stepStatus[step] !== StepStatus.NotApplicable)
      .some(
        (step) =>
          stepStatus[step] === StepStatus.Completed ||
          stepStatus[step] === StepStatus.Skipped ||
          stepStatus[step] === StepStatus.NeedsAttention
      ),
    completed: orderedSteps.every(
      (step) =>
        stepStatus[step] !== StepStatus.Incomplete &&
        stepStatus[step] !== StepStatus.NeedsAttention
    ),
    latestStep: latestStep(),
  }

  console.log('formStatus', {
    formStatus,
    incomplete: orderedSteps.filter(
      (step) =>
        !(
          stepStatus[step] !== StepStatus.Incomplete &&
          stepStatus[step] !== StepStatus.NeedsAttention
        )
    ),
  })

  return {
    steps: stepStatus,
    ...formStatus,
  }
}
