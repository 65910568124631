import { ApolloLink } from '@apollo/client'
import React, { useContext } from 'react'

type Headers = { [key: string]: string | undefined }

export class GraphQLHeaders {
  headers: Headers
  constructor(headers: Headers) {
    this.headers = headers
  }

  setHeaders(headers: Headers) {
    this.headers = headers
  }
}

const GraphQLHeaderContext = React.createContext<GraphQLHeaders>(
  new GraphQLHeaders({})
)

export const GraphQLHeaderProvider = ({
  headers,
  children,
}: {
  headers: GraphQLHeaders
  children: React.ReactNode
}) => {
  return (
    <GraphQLHeaderContext.Provider value={headers}>
      {children}
    </GraphQLHeaderContext.Provider>
  )
}

export const useGraphQLHeaders = () => {
  const context = useContext(GraphQLHeaderContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!context) {
      throw new Error(
        'useGraphQLHeaders must be called as a descendant of <GraphQLHeaderProvider>'
      )
    }
  }

  return context
}

export function HeaderLink(gqlHeaders: GraphQLHeaders) {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: {} }) => ({
      headers: {
        ...gqlHeaders.headers,
        ...headers,
      },
    }))
    return forward(operation)
  })
}
