import { gql, useQuery } from '@apollo/client'
import { Role, useAuthState } from '@propps-au/client'
import { ButtonCTA, StackHeader, StackMain } from '@propps-au/ui'
import { LocationDescriptor } from 'history'
import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { OfferForm, OfferFormMode } from '../../components/offer-form'
import { useSearchParams } from '../../components/use-search-params'
import { MakeOfferQueryDocument } from './__generated__/make-offer.generated'

export function MakeOffer({
  offerHubRoute,
  startAgain,
  ...rest
}: Omit<React.ComponentProps<typeof OfferForm>, 'mode'> & {
  offerHubRoute: LocationDescriptor
  startAgain?: boolean
}) {
  const params = useSearchParams<{ fromAuth?: string }>()
  const auth = useAuthState()

  const skip = auth.role?.name !== Role.BUYER

  const { data, loading, error } = useQuery(MakeOfferQueryDocument, {
    variables: {
      listingId: rest.listingId,
      buyerId: auth.role?.id!,
    },
    fetchPolicy: 'network-only',
    skip,
  })

  if (error) {
    throw error
  }

  if (loading || (!skip && !data && !startAgain)) {
    return <Loading />
  }

  if (data && data.currentOffer && data.currentOffer.status === 'active') {
    return <Redirect to={offerHubRoute} />
  } else {
    return (
      <OfferForm
        {...rest}
        mode={OfferFormMode.CREATE_OFFER}
        fromDraft={params.fromAuth ? true : !startAgain}
      />
    )
  }
}

function Loading() {
  return (
    <Fragment>
      <StackHeader variant="frames" />
      <StackMain variant="frames">
        <ButtonCTA pending />
      </StackMain>
    </Fragment>
  )
}

export default MakeOffer

export const GRAPHQL = gql`
  query MakeOfferQuery($listingId: ID!, $buyerId: ID!) {
    currentOffer: getCurrentOfferByListingAndBuyer(
      listingId: $listingId
      buyerId: $buyerId
    ) {
      id
      status
    }
    draftOffer(listingId: $listingId, buyerId: $buyerId) {
      id
      disposition
    }
  }
`
