import type * as Types from '../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ListingCountdownTimerQueryVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
}>;


export type ListingCountdownTimerQuery = { listing?: { __typename: 'Listing', id: string, countdownTimer?: { __typename: 'CountdownTimer', enabled: boolean, expiry?: string | null, label?: string | null, pauseListingOnExpiry?: boolean | null } | null } | null };


export const ListingCountdownTimerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListingCountdownTimer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"countdownTimer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"expiry"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"pauseListingOnExpiry"}}]}}]}}]}}]} as unknown as DocumentNode<ListingCountdownTimerQuery, ListingCountdownTimerQueryVariables>;