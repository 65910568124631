import qs from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useSearchParams<
  T extends {} = { [name: string]: string | undefined }
>() {
  const location = useLocation()

  const query = useMemo(
    () => (location.search ? qs.parse(location.search.substr(1)) : {}),
    [location.search]
  )

  return query as T
}
