import { FirebaseApp } from 'firebase/app'
import React, { createContext, useContext } from 'react'

const FirebaseAppContext = createContext<FirebaseApp | null>(null)

export function FirebaseAppProvider({
  value,
  children,
}: {
  value: FirebaseApp
  children?: React.ReactNode
}) {
  return (
    <FirebaseAppContext.Provider value={value}>
      {children}
    </FirebaseAppContext.Provider>
  )
}

export function useFirebaseApp() {
  const app = useContext(FirebaseAppContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!app) {
      throw new Error(
        'useFirebaseApp can only be used from within a <FirebaseAppProvider>'
      )
    }
  }

  return app!
}
