import { gql, useQuery } from '@apollo/client'
import { useAuthState } from '@propps-au/client'
import { UseMeQueryDocument } from './__generated__/use-me.generated'

/**
 * Access identifier information for the currently signed in user, such as their
 * UID and Buyer details
 */
export function useMe() {
  const auth = useAuthState()
  const { data } = useQuery(UseMeQueryDocument, { skip: !auth.role })

  return data?.me
}

gql`
  query UseMeQuery {
    me {
      uid
      buyer {
        id
        firstName
        lastName
        email
      }
    }
  }
`
