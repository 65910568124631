import {
  formatDollarAmountForInput,
  FormattedNumberInput,
  getErrors,
  renderConditionWithParams,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Article,
  ArticleTitle,
  Input,
  Label,
  Select,
  Title,
} from '@propps-au/ui'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import { equals, fromPairs, toPairs } from 'ramda'
import React, { useMemo, useRef } from 'react'
import * as Yup from 'yup'
import { OfferConditionRevisionParam } from '../../../__generated__/types'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { useOfferForm } from '../context'
import { ConditionStatus } from './condition-status'
import {
  Condition_OfferConditionRevisionParamFragment,
  Condition_OfferConditionsLineFragment,
} from './__generated__/condition.generated'

export function Condition({
  line,
  onContinue,
}: {
  line: Condition_OfferConditionsLineFragment
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update } = useOfferForm()
  const done = useRef(false)

  const condition = useMemo(
    () =>
      state.conditions.find(
        (item) => item.region === line.region && item.line === line.name
      ),
    [line.name, line.region, state.conditions]
  )

  const hasCondition = !!condition

  const defaultParams = useMemo(
    () =>
      condition?.params.length
        ? fromPairs(condition.params.map((param) => [param.name, param.value]))
        : fromPairs(
            line.currentRevision.params.map((param) => [
              param.name,
              param.defaultValue || '',
            ])
          ),
    [condition?.params, line.currentRevision.params]
  )

  const validationSchema = useMemo(
    () => inputValidation(line.currentRevision.params),
    [line.currentRevision.params]
  )

  const form = useFormik({
    initialValues: {
      params: defaultParams,
    },
    onSubmit: (values) => {
      const acceptedCondition = {
        region: line.region,
        line: line.name,
        id: line.currentRevision.id,
        params: toPairs(replaceParamsWithOtherValue(values.params)).map(
          ([param, value]) => ({
            name: param,
            value,
          })
        ),
      }

      update((current) => {
        return {
          ...current,
          conditions: [
            ...current.conditions.filter((item) => item !== condition),
            acceptedCondition,
          ],
        }
      })

      if (acceptedCondition) {
        analytics.logPixelEvent<Pixel.BuyerEvent.AcceptOfferCondition>({
          type: BuyerEvent.ACCEPT_OFFER_CONDITION,
          conditionId: acceptedCondition.id,
          conditionLine: acceptedCondition.line,
          conditionRegion: acceptedCondition.region,
          conditionParams: JSON.stringify(acceptedCondition.params),
          ...analytics.getEventMetadata(),
        })
      }

      if (done.current) {
        onContinue()
      }
    },
    validationSchema,
  })

  const hasParams = !!line.currentRevision.params.length

  const hasChanged =
    condition &&
    !equals(
      form.values.params,
      fromPairs(condition.params.map((param) => [param.name, param.value]))
    )

  const handleRemove = () => {
    update((s) => ({
      ...s,
      conditions: s.conditions.filter((item) => item !== condition),
    }))

    analytics.logPixelEvent<Pixel.BuyerEvent.RemoveOfferCondition>({
      type: BuyerEvent.REMOVE_OFFER_CONDITION,
      conditionName: line.name,
      conditionRegion: line.region,
      ...analytics.getEventMetadata(),
    })
  }

  const renderOption = (
    param: Condition_OfferConditionRevisionParamFragment
  ) => {
    switch (param.type) {
      case 'select':
        if (param.allowOther) {
          const hasOtherValue =
            param.values?.includes(form.values.params[param.name]) &&
            !!form.values.params[param.name + '_other']
          return (
            <div>
              <Select
                {...form.getFieldProps('params.' + param.name)}
                onChange={(e) => {
                  form.handleChange(e)
                  if (hasOtherValue) {
                    form.setFieldValue('params.' + param.name + '_other', '')
                  }
                }}
              >
                <option value={'other'} key={'other'}>
                  Other
                </option>
                {param.values?.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
              {!param.values?.includes(form.values.params[param.name]) && (
                <>
                  {param.otherParams?.type === 'dollar' && (
                    <FormattedNumberInput<React.ComponentProps<typeof Input>>
                      component={Input}
                      format={formatDollarAmountForInput}
                      centered
                      type="text"
                      placeholder="$"
                      {...form.getFieldProps('params.' + param.name + '_other')}
                      value={form.values.params[param.name + '_other']?.replace(
                        /\D/g,
                        ''
                      )}
                      onChange={(value) => {
                        form.setFieldTouched('params.' + param.name + '_other')
                        form.setFieldValue(
                          'params.' + param.name + '_other',
                          value
                            ? formatDollarAmountForInput(
                                parseInt(value)
                              ).replace(/\s/g, '')
                            : undefined
                        )
                      }}
                      errors={getErrors(
                        form,
                        'params.' + param.name + '_other'
                      )}
                      inputMode="numeric"
                    />
                  )}
                </>
              )}
            </div>
          )
        }

        if (param.values!.length > 1) {
          return (
            <Select {...form.getFieldProps('params.' + param.name)}>
              {param.values?.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Select>
          )
        }
        return <Input type="text" value={param.values![0]} disabled />
      case 'amount':
        return (
          <FormattedNumberInput<React.ComponentProps<typeof Input>>
            component={Input}
            format={formatDollarAmountForInput}
            centered
            type="text"
            placeholder="$"
            {...form.getFieldProps('params.' + param.name)}
            onChange={(value) => {
              form.setFieldTouched('params.' + param.name)
              form.setFieldValue('params.' + param.name, value)
            }}
            errors={getErrors(form, 'params.' + param.name)}
            inputMode="numeric"
          />
        )
      case 'dateText':
        const [d, m, y] = (form.values.params as any)[param.name].split('/')
        const dateValue = d && m && y ? [y, m, d].join('-') : '' // Format dd/MM/yyyy to yyyy-MM-dd for native date input.
        return (
          <>
            <Input
              type="date"
              {...form.getFieldProps('params.' + param.name)}
              errors={getErrors(form, 'params.' + param.name)}
              value={dateValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldTouched('params.' + param.name)
                form.setFieldValue(
                  'params.' + param.name,
                  format(new Date(e.target.value), 'dd/MM/yyyy')
                )
              }}
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <form
      onSubmit={(e) => {
        done.current = true
        form.handleSubmit(e)
      }}
    >
      <Title>{line.currentRevision.title}</Title>
      {!line.currentRevision.isRequired ? (
        <Article>
          <ConditionStatus
            included={hasCondition}
            onAdd={form.submitForm}
            onRemove={handleRemove}
          />
        </Article>
      ) : null}
      {hasParams && (
        <Article>
          <ArticleTitle>Condition options</ArticleTitle>
          <fieldset>
            {line.currentRevision.params.map(
              (param) =>
                param.values && (
                  <>
                    <Label label={param.label} key={param.name}>
                      {renderOption(param)}
                    </Label>
                    {param.description && (
                      <p>
                        <small>{param.description}</small>
                      </p>
                    )}
                  </>
                )
            )}
          </fieldset>
        </Article>
      )}
      <Article>
        {hasParams && <ArticleTitle>Condition text</ArticleTitle>}
        <div
          dangerouslySetInnerHTML={{
            __html: renderConditionWithParams({
              contentHTML: line.currentRevision.contentHTML,
              params: Object.entries(
                replaceParamsWithOtherValue(form.values.params)
              ).map((param) => ({
                name: param[0],
                value: param[1],
              })),
              paramDefinitions: line.currentRevision.params.map((p) => ({
                name: p.name,
                type: p.type,
              })),
            }),
          }}
        />
      </Article>
      {line.currentRevision.isRequired ? (
        <PrimaryButton
          label={hasCondition ? 'Done' : 'Accept'}
          onClick={() => {
            done.current = true
            form.submitForm()
          }}
        />
      ) : hasCondition && hasChanged ? (
        <PrimaryButton
          label="Done"
          onClick={() => {
            done.current = true
            form.submitForm()
          }}
        />
      ) : (
        <PrimaryButton label="Done" onClick={onContinue} />
      )}
    </form>
  )
}

gql`
  fragment Condition_OfferConditionsLine on OfferConditionsLine {
    name
    region
    currentRevision {
      ...Condition_OfferConditionsRevision
    }
  }

  fragment Condition_OfferConditionRevisionParam on OfferConditionRevisionParam {
    name
    label
    description
    type
    values
    defaultValue
    allowOther
    otherParams {
      name
      label
      description
      type
    }
  }

  fragment Condition_OfferConditionsRevision on OfferConditionsRevision {
    id
    title
    line: lineName
    region
    contentHTML
    isRequired
    params {
      ...Condition_OfferConditionRevisionParam
    }
  }
`

interface LooseObject {
  [key: string]: any
}

const inputValidation = (params: any) => {
  let shape: LooseObject = {}

  params.forEach((param: OfferConditionRevisionParam) => {
    if (param.type === 'amount') {
      shape[param.name] = Yup.number()
        // eslint-disable-next-line no-template-curly-in-string
        .typeError('${label} must be a number.')
        .integer()
        .required('Please enter a number.')
        .label(param.label)
    }

    if (param.type === 'dateText') {
      shape[param.name] = Yup.string().required(
        'Please select a date or enter a timeframe.'
      )
    }
  })

  return Yup.object({ params: Yup.object().shape(shape) })
}

const replaceParamsWithOtherValue = (params: { [key: string]: string }) => {
  const otherValueKey = Object.keys(params).find((key) =>
    key.includes('_other')
  )
  const originalParamKey = otherValueKey?.replace('_other', '')!
  const hasOtherValue = otherValueKey && params[otherValueKey]

  if (!hasOtherValue || params[originalParamKey] !== 'other') {
    return params
  }
  return {
    ...params,
    [originalParamKey]: params[otherValueKey],
  }
}
