import { gql, useMutation, useQuery } from '@apollo/client'
import { useAuthState } from '@propps-au/client'
import {
  ButtonCTA,
  List,
  ListItem,
  Modal,
  Radio,
  StackHeader,
  StackMain,
  Title,
  useModalState,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import React, { Fragment, useEffect } from 'react'
import { CountdownTimer } from '../../components/countdown-timer'
import { useCountdownTimer } from '../../components/countdown-timer-provider'
import { FrameContentLayout } from '../../components/frame-content-layout'
import { HoldingDepositInstructions } from '../../components/holding-deposit-instructions'
import { useSearchParams } from '../../components/use-search-params'
import { HoldingDepositStatus } from '../../__generated__/types'
import {
  HoldingDepositPageQueryDocument,
  HoldingDepositPageUpdateOfferStatusDocument,
  HoldingDepositPage_OfferFragment,
} from './__generated__/holding-deposit.generated'

export function HoldingDepositPage({
  goToOfferHubPage,
}: {
  goToOfferHubPage: () => void
}) {
  const modal = useModalState()
  const countdownTimer = useCountdownTimer()

  const { offerId } = useSearchParams<{ offerId?: string }>()
  const auth = useAuthState()

  const { data } = useQuery(HoldingDepositPageQueryDocument, {
    variables: { id: offerId! },
    skip: !auth.role || !offerId,
  })

  useEffect(() => {
    if (auth.role && offerId && data && !data.offer) {
      goToOfferHubPage()
    }
  }, [auth.role, data, goToOfferHubPage, offerId])

  const handleContinue = () => {
    if (
      data?.offer?.holdingDeposit?.status === 'unpaid' ||
      data?.offer?.holdingDeposit?.status === 'submitted'
    ) {
      modal.show()
    } else {
      goToOfferHubPage()
    }
  }

  return (
    <Fragment>
      <StackHeader variant="frames">
        {countdownTimer.countdownTimer?.enabled &&
          countdownTimer.countdownTimer?.expiry && (
            <CountdownTimer
              to="../activity"
              variant="alternate"
              status={countdownTimer.countdownTimer.label}
              timer={{
                loading: !countdownTimer.ready,
                size: 'sm',
                endDate: countdownTimer.countdownTimer.expiry,
              }}
            />
          )}
      </StackHeader>
      <StackMain variant="frames">
        <FrameContentLayout>
          {data?.offer ? (
            <Fragment>
              <Title>Holding deposit</Title>
              <HoldingDepositInstructions offer={data.offer} />
            </Fragment>
          ) : null}
          <FrameContentLayout.PrimaryAction
            pending={data === undefined}
            onClick={handleContinue}
          >
            Continue
          </FrameContentLayout.PrimaryAction>
        </FrameContentLayout>
      </StackMain>
      <Modal state={modal} title="Holding deposit">
        {data?.offer ? (
          <UpdateHoldingDepositForm
            offer={data.offer}
            onComplete={() => {
              modal.hide()
              goToOfferHubPage()
            }}
          />
        ) : null}
      </Modal>
    </Fragment>
  )
}

function UpdateHoldingDepositForm({
  offer,
  onComplete,
}: {
  offer: HoldingDepositPage_OfferFragment
  onComplete: () => void
}) {
  const [mutate] = useMutation(HoldingDepositPageUpdateOfferStatusDocument)

  const form = useFormik({
    initialValues: {
      status: offer.holdingDeposit!.status,
    },
    onSubmit: async (values) => {
      await mutate({
        variables: { input: { offerId: offer.id, status: values.status } },
      })
      onComplete()
    },
  })

  const handleChange = (status: HoldingDepositStatus) => () =>
    form.setFieldValue('status', status)

  return (
    <form onSubmit={form.handleSubmit}>
      <List>
        <ListItem as="label">
          <Radio
            name="deposit-payment"
            value="paid"
            checked={form.values.status === 'submitted'}
            onChange={handleChange('submitted')}
            label="I have paid the deposit."
          />
        </ListItem>
        <ListItem as="label">
          <Radio
            name="deposit-payment"
            value="not-paid"
            checked={form.values.status === 'unpaid'}
            onChange={handleChange('unpaid')}
            label="I have not paid the deposit yet."
          />
        </ListItem>
      </List>

      <ButtonCTA type="submit" pending={form.isSubmitting}>
        Submit
      </ButtonCTA>
    </form>
  )
}

export const GRAPHQL = gql`
  query HoldingDepositPageQuery($id: ID!) {
    offer(id: $id) {
      id
      ...HoldingDepositInstructions_Offer
      ...HoldingDepositPage_Offer
    }
  }

  mutation HoldingDepositPageUpdateOfferStatus(
    $input: UpdateOfferHoldingDepositStatusInput!
  ) {
    result: updateOfferHoldingDepositStatus(input: $input) {
      offer {
        id
        ...HoldingDepositPage_Offer
      }
    }
  }

  fragment HoldingDepositPage_Offer on Offer {
    id
    holdingDeposit {
      status
    }
  }
`
