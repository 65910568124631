/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  GlobalStyle,
  Icon,
  IconHeroCross,
  pxToRem,
  StackApp,
  StackMain,
  Title,
} from '@propps-au/ui'
import React, { Fragment } from 'react'
import { FrameContentLayout } from '../../components/frame-content-layout'

export function ErrorPage({
  title,
  errorMessage,
}: {
  title: string
  errorMessage: string
}) {
  return (
    <Fragment>
      <GlobalStyle />
      <StackApp variant="frames">
        <StackMain variant="topCenter">
          <FrameContentLayout>
            <Icon svg={IconHeroCross} size={pxToRem(128)} />
            <Title>{title}</Title>
            <p className="grey">{errorMessage}</p>
          </FrameContentLayout>
        </StackMain>
      </StackApp>
    </Fragment>
  )
}

export function PageNotFound() {
  return (
    <ErrorPage
      title="Page not found"
      errorMessage="The page cannot be found."
    />
  )
}

export function ListingNotAvailable() {
  return (
    <ErrorPage
      title="Listing not available"
      errorMessage="The listing is not available yet. If you would like to purchase the property, get in touch with the agent directly."
    />
  )
}
