import { gql } from '@apollo/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  ActionCard,
  Article,
  ArticleTitle,
  Button,
  Checkbox,
  color,
  Icon,
  IconCircleInfo2,
  IconCircleMinus,
  IconCircleTick,
  IconLargeCompany,
  IconLargePlus,
  IconLargeUser,
  IconLargeUsers,
  Title,
} from '@propps-au/ui'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { remove, uniqBy } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { OfferFormMode } from './mode'
import { OfferFormNavigationFn } from './routes'
import { Signatory } from './values'
import {
  Buyers_ListingFragment,
  OfferForm_Buyers_BuyerFragment,
} from './__generated__/buyers.generated'

function buyerInSignatories(signatories: Signatory[], buyerId: string) {
  return signatories.find((signatory) => signatory.buyerId === buyerId)
}

export function Buyers({
  label = 'Done',
  onContinue,
  navigate,
  buyer,
}: {
  listing: Buyers_ListingFragment
  label?: string
  onContinue: () => void
  navigate: OfferFormNavigationFn<'addBuyer' | 'buyer'>
  buyer: OfferForm_Buyers_BuyerFragment
}) {
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbBuyers>({
        type: BuyerEvent.VIEW_PTB_BUYERS,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferBuyers>({
        type: BuyerEvent.VIEW_OFFER_BUYERS,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik<{
    addTitleHoldersLater: boolean
    signatories: Signatory[]
  }>({
    initialValues: {
      addTitleHoldersLater: state.addTitleHoldersLater,
      signatories: state.signatories,
    },
    validationSchema,
    onSubmit: (values) => {
      const signatories = uniqBy(({ buyerId }) => buyerId, values.signatories)

      update((v) => ({
        ...v,
        signatories,
        addTitleHoldersLater: values.addTitleHoldersLater,
      }))

      onContinue()
    },
  })

  const [deleteMode, setDeleteMode] = useState(false)

  useEffect(() => {
    if (
      deleteMode &&
      form.values.signatories.filter(
        (signatory) => signatory.isPrimaryBuyer === false
      ).length === 0
    ) {
      setDeleteMode(false)
    }
  }, [deleteMode, form.values.signatories, form.values.signatories.length])

  const onEditBuyer = (index: number) => {
    update((v) => ({
      ...v,
      addTitleHoldersLater: form.values.addTitleHoldersLater,
    }))
    navigate('buyer', { index })
  }

  const onAddBuyer = () => {
    if (!buyerInSignatories(form.values.signatories, buyer.id)) {
      return
    }
    update((v) => ({
      ...v,
      addTitleHoldersLater: form.values.addTitleHoldersLater,
    }))
    navigate('addBuyer')
  }

  const onAddPrimaryBuyer = () => {
    navigate('addBuyer', {
      signatory: {
        buyerId: buyer.id,
        firstName: buyer.firstName,
        lastName: buyer.lastName,
        email: buyer.email,
        phone: buyer.phone,
        isPrimaryBuyer: true,
      },
    })
  }

  const onDeleteBuyer = (
    removeFromFormikState: (index: number) => void,
    index: number
  ) => {
    const buyerId = form.values.signatories[index].buyerId
    update((v) => ({
      ...v,
      signatories: remove(index, 1, form.values.signatories),
    }))
    removeFromFormikState(index)

    analytics.logPixelEvent<Pixel.BuyerEvent.RemoveOfferBuyer>({
      type: BuyerEvent.REMOVE_OFFER_BUYER,
      buyerId,
      ...analytics.getEventMetadata(),
    })
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const errors = await form.validateForm()
    if (errors.signatories) {
      onAddPrimaryBuyer()
    }
    return form.submitForm()
  }

  return (
    <FormikProvider value={form}>
      <form onSubmit={onSubmit}>
        <Title>Tell us about the people buying this property</Title>
        <FieldArray name="signatories">
          {({ remove }) => (
            <Article>
              <ArticleTitle
                end={
                  form.values.signatories.filter(
                    (signatory) => signatory.isPrimaryBuyer === false
                  ).length > 0 && (
                    <Button
                      size="xs"
                      pill
                      onClick={() => setDeleteMode((val) => !val)}
                    >
                      {deleteMode ? 'Done' : 'Edit'}
                    </Button>
                  )
                }
                marginBottom
              >
                Buyers
              </ArticleTitle>
              {!buyerInSignatories(form.values.signatories, buyer.id) ? (
                <SignatoryCard
                  type="person"
                  name={[buyer.firstName, buyer.lastName]
                    .filter((item) => !!item.trim())
                    .join(' ')}
                  phone={buyer.phone}
                  onClick={onAddPrimaryBuyer}
                  isIncomplete
                />
              ) : null}
              {form.values.signatories.map((signatory, index) => (
                <SignatoryCard
                  type={
                    (signatory.onBehalfOf &&
                      (signatory.onBehalfOf.type === 'person'
                        ? 'persons'
                        : signatory.onBehalfOf.type === 'self'
                        ? 'person'
                        : 'company')) ??
                    undefined
                  }
                  name={[
                    signatory.firstName,
                    signatory.middleName,
                    signatory.lastName,
                  ]
                    .filter((item) => !!item.trim())
                    .join(' ')}
                  phone={signatory.phone}
                  onClick={() => onEditBuyer(index)}
                  onDelete={
                    deleteMode && !signatory.isPrimaryBuyer
                      ? () => onDeleteBuyer(remove, index)
                      : undefined
                  }
                  key={index}
                />
              ))}
              {!deleteMode && (
                <ActionCard
                  icon={{ svg: IconLargePlus }}
                  title="Add a person or company"
                  onClick={() => onAddBuyer()}
                >
                  {buyer
                    ? 'Buying with someone else?'
                    : 'Add buyer details to your offer'}
                </ActionCard>
              )}
            </Article>
          )}
        </FieldArray>
        <Article>
          <ArticleTitle marginBottom>Add buyers later</ArticleTitle>
          <ActionCard
            icon={{ svg: IconLargeUsers }}
            title="And/or nominee"
            end={
              <Checkbox
                {...form.getFieldProps({
                  type: 'checkbox',
                  name: 'addTitleHoldersLater',
                })}
              />
            }
            onClick={() => {
              form.setFieldValue(
                'addTitleHoldersLater',
                !form.values.addTitleHoldersLater
              )
            }}
            chevron={false}
          >
            This allows you to nominate buyers later.
          </ActionCard>
        </Article>
        <PrimaryButton onClick={onSubmit} label={label} />
      </form>
    </FormikProvider>
  )
}

const validationSchema = Yup.object({
  signatories: Yup.array(Yup.mixed()).min(
    1,
    'Oops, some buyer details are yet to be completed.'
  ),
})

function SignatoryCard({
  type = 'person',
  name,
  isIncomplete = false,
  representing,
  phone,
  onDelete,
  onClick,
}: {
  type?: 'person' | 'persons' | 'company'
  name: string
  representing?: string
  phone: string
  isIncomplete?: boolean
  onDelete?: () => void
  onClick: () => void
}) {
  return (
    <ActionCard
      icon={{
        svg:
          type === 'person'
            ? IconLargeUser
            : type === 'persons'
            ? IconLargeUsers
            : type === 'company'
            ? IconLargeCompany
            : IconLargeUser,
      }}
      title={
        <Fragment>
          <b>{name}</b> {representing && `on behalf of ${representing}`}
        </Fragment>
      }
      end={
        <Fragment>
          {onDelete ? (
            <Icon
              aria-label="remove"
              svg={IconCircleMinus}
              fill={color.red}
              onClick={(e) => {
                e.stopPropagation()
                onDelete()
              }}
            />
          ) : (
            <Fragment>
              {isIncomplete ? (
                <Icon svg={IconCircleInfo2} fill={color.orange} />
              ) : (
                <Icon svg={IconCircleTick} fill={color.green} />
              )}
            </Fragment>
          )}
        </Fragment>
      }
      onClick={!onDelete ? onClick : undefined}
    >
      {!isIncomplete ? phone : 'Please complete buyer details.'}
    </ActionCard>
  )
}

export const GRAPHQL = gql`
  fragment Buyers_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
  }

  fragment OfferForm_Buyers_Buyer on Buyer {
    id
    firstName
    lastName
    phone
    email
  }
`
