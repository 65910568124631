import { gql, useQuery } from '@apollo/client'
import { Role, useAuthState } from '@propps-au/client'
import { ButtonCTA, StackHeader, StackMain } from '@propps-au/ui'
import React, { Fragment } from 'react'
import { OfferForm, OfferFormMode } from '../../components/offer-form'
import { UpdateOfferQueryDocument } from './__generated__/update-offer.generated'

export function UpdateOffer({
  ...rest
}: Omit<React.ComponentProps<typeof OfferForm>, 'mode'>) {
  const auth = useAuthState()

  const skip = auth.role?.name !== Role.BUYER
  const { data, error } = useQuery(UpdateOfferQueryDocument, {
    variables: {
      listingId: rest.listingId,
      buyerId: auth.role?.id!,
    },
    fetchPolicy: 'no-cache',
    skip,
  })

  if (error) {
    throw error
  }

  if (!skip && !data) {
    return <Loading />
  }

  return <OfferForm {...rest} mode={OfferFormMode.UPDATE_OFFER} />
}

function Loading() {
  return (
    <Fragment>
      <StackHeader variant="frames" />
      <StackMain variant="frames">
        <ButtonCTA pending />
      </StackMain>
    </Fragment>
  )
}

export default UpdateOffer

export const GRAPHQL = gql`
  query UpdateOfferQuery($listingId: ID!, $buyerId: ID!) {
    offer: getCurrentOfferByListingAndBuyer(
      listingId: $listingId
      buyerId: $buyerId
    ) {
      id
    }
  }
`
