import type * as Types from '../../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { DocumentsList_ListingFragmentDoc } from '../../../../components/documents-list/__generated__/index.generated';
export type ViewListingDocumentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ViewListingDocumentsQuery = { listing?: { __typename: 'Listing', id: string, documents: Array<{ __typename: 'ListingDocument', id: string, name: string, filename: string, url: string, size?: number | null }> } | null };

export type ViewDocuments_ListingFragment = { __typename: 'Listing', id: string, property: { __typename: 'Property', address: { __typename: 'Address', line1: string } } };

export const ViewDocuments_ListingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ViewDocuments_Listing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Listing"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"property"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"line1"}}]}}]}}]}}]} as unknown as DocumentNode<ViewDocuments_ListingFragment, unknown>;
export const ViewListingDocumentsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ViewListingDocumentsQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"DocumentsList_Listing"}}]}}]}},...DocumentsList_ListingFragmentDoc.definitions]} as unknown as DocumentNode<ViewListingDocumentsQuery, ViewListingDocumentsQueryVariables>;