import {
  BehaviorSubject,
  from,
  fromEvent,
  ReplaySubject,
  Subscription,
} from 'rxjs'
import { filter, map, mergeMap, tap } from 'rxjs/operators'

export class FrameTransport {
  private _connected = new BehaviorSubject<boolean>(false)
  private sends = new ReplaySubject<unknown>()
  private _subs: Subscription[] = []

  constructor(private target: Window, private origin: string) {
    /** when connected start sending messages */
    const sub1 = this.connected
      .pipe(
        mergeMap((value) =>
          value
            ? this.sends.pipe(tap((message) => this._send(message)))
            : from([])
        )
      )
      .subscribe()

    /** start listening to incoming messages */
    const sub2 = this._messages().subscribe((message) => {
      if (FrameTransportMessage.isConnect(message)) {
        this._send<FrameTransportMessage.Connected>({
          type: 'transport:connected',
        })
        return
      }

      if (FrameTransportMessage.isConnected(message)) {
        if (!this._connected.getValue()) {
          this._send<FrameTransportMessage.Connected>({
            type: 'transport:connected',
          })
          this._connected.next(true)
        }
        return
      }
    })

    this._subs.push(sub1, sub2)
  }

  private _send<T>(message: T) {
    this.target.postMessage(message, this.origin)
  }

  private _messages() {
    return fromEvent<MessageEvent>(window, 'message')
      .pipe(
        filter((e) => {
          return this.origin === '*' || e.origin === this.origin
        })
      )
      .pipe(map((e) => e.data as unknown))
  }

  public messages() {
    return this._connected.pipe(
      mergeMap((value) => (value ? this._messages() : from([])))
    )
  }

  public open() {
    this._send<FrameTransportMessage.Connect>({
      type: 'transport:connect',
    })
  }

  public send<T = unknown>(message: T) {
    this.sends.next(message)
  }

  public get connected() {
    return this._connected
  }

  public destroy() {
    this._subs.forEach((sub) => sub.unsubscribe())
    this.sends.complete()
    this._connected.complete()
  }
}

export namespace FrameTransportMessage {
  export type Connect = {
    type: 'transport:connect'
  }
  export const isConnect = is<Connect>('transport:connect')

  export type Connected = {
    type: 'transport:connected'
  }
  export const isConnected = is<Connected>('transport:connected')
}

function is<T extends { type: string }>(t: T['type']) {
  return (message: unknown): message is T =>
    typeof message === 'object' &&
    !!message &&
    'type' in message &&
    typeof (message as any).type === 'string' &&
    (message as any).type === t
}
