/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  BlurFormatInput,
  getError,
  getErrors,
  useAddressFinderWidget,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Article,
  ArticleTitle,
  Checkbox,
  CommonError,
  Input,
  Label,
  pxToRem,
  Select,
  Textarea,
  Title,
} from '@propps-au/ui'
import { FormikErrors, useFormik } from 'formik'
import isMobile from 'is-mobile'
import { update as replace } from 'ramda'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { IsCIContext } from '../is-ci'
import { PrimaryButton } from '../primary-button'
import { BuyerCreateData } from './add-buyer/create-buyer'
import { useOfferForm } from './context'
import { DateSchema, formatInputDate } from './date-schema'
import { OfferFormMode } from './mode'
import { Signatory } from './values'
import { Buyers_ListingFragment } from './__generated__/buyers.generated'

export type SignatoryCreateData = BuyerCreateData & {
  buyerId: string
  lastName?: string
  isPrimaryBuyer: boolean
}

const isMobileDevice = isMobile()

export function Buyer({
  listing,
  index,
  onContinue,
  signatory,
}: {
  listing: Buyers_ListingFragment
  signatory?: SignatoryCreateData
  index: number | null
  onContinue: () => void
}) {
  const isCI = useContext(IsCIContext)
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()
  const idvDisclaimerCheckbox = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbBuyer>({
        type: BuyerEvent.VIEW_PTB_BUYER,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferBuyer>({
        type: BuyerEvent.VIEW_OFFER_BUYER,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik<FormValues>({
    initialValues: signatory
      ? {
          ...DEFAULT_FORM_VALUES,
          ...signatory,
        }
      : typeof index === 'number' && state.signatories[index]
      ? mapSignatoryToBuyerFormValues(state.signatories[index])
      : null!,
    validationSchema,
    onSubmit: (values) => {
      const completedSignatory = packSignatory(
        values,
        signatory ?? state.signatories[index!]!
      )
      update((v) => ({
        ...v,
        signatories:
          index !== null
            ? replace(index, completedSignatory, v.signatories)
            : [...v.signatories, completedSignatory],
      }))

      if (completedSignatory) {
        analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferBuyer>({
          type: BuyerEvent.COMPLETE_OFFER_BUYER,
          buyerId: completedSignatory.buyerId,
          ...analytics.getEventMetadata(),
        })
      }

      onContinue()
    },
  })

  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  // Clear the region field when the id type changes
  useEffect(() => {
    if (isMounted) {
      form.setFieldValue('identityDocument.region', '')
    }
    // Run when identityDocument.type changes after first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.identityDocument.type])

  // Clear the representing name field when the representing type changes to 'self'
  useEffect(() => {
    if (isMounted) {
      form.setFieldValue(`onBehalfOf.name`, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.onBehalfOf.type])

  const addressInput = useRef<HTMLTextAreaElement>()
  useAddressFinderWidget(
    addressInput,
    {
      onAddressSelectPre: (address: string) => {
        form.setFieldValue(`address`, address)
        form.setFieldTouched(`address`)
      },
    },
    !isCI
  )

  const focusIdvDisclaimerError = (errors: FormikErrors<FormValues>) => {
    if (
      idvDisclaimerCheckbox.current &&
      errors.agreeToIdentityVerification &&
      Object.keys(errors).length === 1
    ) {
      idvDisclaimerCheckbox.current.focus()
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const error = await form.validateForm()
    focusIdvDisclaimerError(error)
    return form.submitForm()
  }

  return (
    <form onSubmit={onSubmit}>
      <Title
        sub={[form.values.firstName, form.values.lastName]
          .filter((item) => !!item)
          .join(' ')}
      >
        Buyer details
      </Title>

      <Article>
        <p>
          The information provided must be your legal name as shown on your
          Australian identification document.
        </p>
        <fieldset>
          <Label label="Identification">
            <Select
              {...form.getFieldProps(`identityDocument.type`)}
              errors={getErrors(form, `identityDocument.type`)}
            >
              <optgroup label="Identification type">
                <option value="drivers_licence">Driver's licence</option>
                <option value="passport">Passport</option>
              </optgroup>
            </Select>
          </Label>
          {form.values.identityDocument?.type === 'drivers_licence' && (
            <Label label="State">
              <Select
                {...form.getFieldProps(`identityDocument.region`)}
                errors={getErrors(form, `identityDocument.region`)}
              >
                <optgroup label="State">
                  <option disabled value="">
                    ...
                  </option>
                  <option value="NSW">New South Wales</option>
                  <option value="VIC">Victoria</option>
                  <option value="QLD">Queensland</option>
                  <option value="WA">Western Australia</option>
                  <option value="SA">South Australia</option>
                  <option value="TAS">Tasmania</option>
                  <option value="ACT">Australian Capital Territory</option>
                  <option value="NT">Northern Territory</option>
                </optgroup>
              </Select>
            </Label>
          )}
          <Label
            label={
              form.values.identityDocument?.type === 'drivers_licence'
                ? 'Licence no.'
                : 'Passport no.'
            }
          >
            <Input
              type="text"
              placeholder="..."
              {...form.getFieldProps(`identityDocument.documentNo`)}
              errors={getErrors(form, `identityDocument.documentNo`)}
            />
          </Label>
          <Label label="First name">
            <Input
              autoFocus={!isMobileDevice}
              type="text"
              placeholder="..."
              {...form.getFieldProps(`firstName`)}
              errors={getErrors(form, `firstName`)}
              autoComplete="given-name"
            />
          </Label>
          <Label label="Middle name">
            <Input
              type="text"
              placeholder="..."
              {...form.getFieldProps(`middleName`)}
              errors={getErrors(form, `middleName`)}
              autoComplete="additional-name"
            />
          </Label>
          <Label label="Last name">
            <Input
              type="text"
              placeholder="..."
              {...form.getFieldProps(`lastName`)}
              errors={getErrors(form, `lastName`)}
              autoComplete="family-name"
            />
          </Label>
          <Label label="Date of birth">
            <BlurFormatInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="text"
              placeholder="DD / MM / YYYY"
              {...form.getFieldProps(`dateOfBirth`)}
              errors={getErrors(form, `dateOfBirth`)}
              format={(value) => formatInputDate(value)}
              onBlur={(event) => {
                form.setFieldValue(
                  `dateOfBirth`,
                  formatInputDate(form.values.dateOfBirth)
                )
                form.handleBlur(event)
              }}
            />
          </Label>
          <Label label="Address">
            <Textarea
              {...form.getFieldProps(`address`)}
              errors={getErrors(form, `address`)}
              ref={addressInput as React.RefObject<HTMLTextAreaElement>}
            />
          </Label>
        </fieldset>
        <p className="grey">
          To submit your offer, we'll need to perform an identity verification
          online. Not to worry, it's simple and free.
        </p>
      </Article>
      <Article>
        <label>
          <Checkbox
            id="idv-disclaimer-checkbox"
            {...form.getFieldProps({
              name: `agreeToIdentityVerification`,
              type: 'checkbox',
            })}
            data-testid="idv-disclaimer-checkbox"
            label={
              <Fragment>
                <strong>I agree to an online identity verification</strong>
                <p
                  css={css`
                    margin-top: ${pxToRem(16)};
                  `}
                >
                  I confirm that I am authorised to provide the personal details
                  presented and I consent to my information being checked with
                  the document issuer or official record holder via third party
                  systems for the purpose of confirming my identity.
                </p>
              </Fragment>
            }
            ref={idvDisclaimerCheckbox}
          />
        </label>
        {getError(form, `agreeToIdentityVerification`) && (
          <CommonError>
            {getError(form, `agreeToIdentityVerification`)}
          </CommonError>
        )}
      </Article>
      <Article>
        <ArticleTitle>Buyer type</ArticleTitle>
        <fieldset>
          <Label label="Buying">
            <Select
              {...form.getFieldProps(`onBehalfOf.type`)}
              errors={getErrors(form, `onBehalfOf.type`)}
            >
              <option value="self">As an individual</option>
              <option value="person">On behalf of another person</option>
              <option value="company">On behalf of a company</option>
            </Select>
          </Label>
          {form.values.onBehalfOf?.type === 'person' && (
            <Label label="Name of represented">
              <Input
                type="text"
                {...form.getFieldProps(`onBehalfOf.name`)}
                errors={getErrors(form, `onBehalfOf.name`)}
              />
            </Label>
          )}
          {form.values.onBehalfOf?.type === 'company' && (
            <Label label="Company name">
              <Input
                type="text"
                {...form.getFieldProps(`onBehalfOf.name`)}
                errors={getErrors(form, `onBehalfOf.name`)}
              />
            </Label>
          )}
        </fieldset>
        {form.values.onBehalfOf?.type !== 'self' ? (
          <p className="grey">
            An agent will be in touch when more information about the
            represented party is needed.
          </p>
        ) : undefined}
      </Article>
      <PrimaryButton onClick={onSubmit} label="Done" />
    </form>
  )
}

const DEFAULT_FORM_VALUES = {
  firstName: '',
  middleName: '',
  lastName: '',
  address: '',
  agreeToIdentityVerification: false,
  dateOfBirth: '',
  email: '',
  identityDocument: {
    type: 'drivers_licence',
    region: '',
    country: 'AUS',
    documentNo: '',
  },
  onBehalfOf: {
    type: 'self',
    name: '',
  },
}

type FormValues = typeof DEFAULT_FORM_VALUES

function mapSignatoryToBuyerFormValues(signatory: Signatory): FormValues {
  return {
    firstName: signatory.firstName,
    middleName: signatory.middleName,
    lastName: signatory.lastName,
    address: signatory.address,
    agreeToIdentityVerification: signatory.agreeToIdentityVerification,
    dateOfBirth: signatory.dateOfBirth,
    email: signatory.email,
    identityDocument: {
      type: signatory.identityDocument.type,
      country: signatory.identityDocument.country,
      documentNo: signatory.identityDocument.documentNo,
      region:
        'region' in signatory.identityDocument
          ? signatory.identityDocument.region
          : '',
    },
    onBehalfOf: {
      type: signatory.onBehalfOf.type,
      name:
        signatory.onBehalfOf.type !== 'self' ? signatory.onBehalfOf.name : '',
    },
  }
}

function packSignatory(
  values: FormValues,
  {
    isPrimaryBuyer,
    buyerId,
    phone,
  }: { isPrimaryBuyer: boolean; buyerId: string; phone: string }
): Signatory {
  return {
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    address: values.address,
    agreedContractRevision: null,
    agreedPrivacyPolicyRevision: null,
    agreedTermsRevision: null,
    agreeToIdentityVerification: values.agreeToIdentityVerification,
    dateOfBirth: values.dateOfBirth,
    email: values.email,
    identityDocument:
      values.identityDocument.type === 'drivers_licence'
        ? {
            type: 'drivers_licence',
            country: values.identityDocument.country,
            documentNo: values.identityDocument.documentNo,
            region: values.identityDocument.region,
          }
        : {
            type: 'passport',
            country: values.identityDocument.country,
            documentNo: values.identityDocument.documentNo,
          },
    onBehalfOf:
      values.onBehalfOf.type === 'self'
        ? {
            type: 'self',
          }
        : {
            type: values.onBehalfOf.type as 'person' | 'company',
            name: values.onBehalfOf.name,
          },
    isPrimaryBuyer: isPrimaryBuyer,
    buyerId: buyerId,
    phone: phone,
    signatureData: null,
  }
}

const validationSchema = Yup.object({
  firstName: Yup.string().label('First name').required(),
  middleName: Yup.string().label('Middle name'),
  lastName: Yup.string().label('Last name').required(),
  dateOfBirth: DateSchema.label('Date of birth').required(),
  address: Yup.string().label('Address').required(),
  identityDocument: Yup.object({
    country: Yup.string().oneOf(['AUS']).required(),
    type: Yup.string().oneOf(['passport', 'drivers_licence']).required(),
    region: Yup.string().when(
      'type',
      (type: string, schema: Yup.StringSchema) =>
        type === 'drivers_licence'
          ? schema.required('Please select a state or territory.')
          : schema.notRequired()
    ),
    documentNo: Yup.string().required('Please enter the document number.'),
  }),
  agreeToIdentityVerification: Yup.boolean().oneOf(
    [true],
    'Please confirm for your identity to be verified'
  ),
  onBehalfOf: Yup.object({
    type: Yup.string().oneOf(['self', 'person', 'company']).required(),
    name: Yup.string()
      .when('type', (type: string, schema: Yup.StringSchema) =>
        type !== 'self'
          ? schema.required('Please enter the name of the represented party.')
          : schema.notRequired()
      )
      .label('Represented party'),
  }),
})
