import type * as Types from '../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LiteOfferFragment = { __typename: 'LiteOffer', id: string, amount?: string | null, claimed: boolean, buyer: { __typename: 'Buyer' } | { __typename: 'LiteOfferBuyerContact', firstName: string, lastName: string, phone?: string | null, email: string } };

export type LiteOfferQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type LiteOfferQuery = { liteOffer?: { __typename: 'LiteOffer', id: string, amount?: string | null, claimed: boolean, buyer: { __typename: 'Buyer' } | { __typename: 'LiteOfferBuyerContact', firstName: string, lastName: string, phone?: string | null, email: string } } | null };

export type ClaimLiteOfferMutationVariables = Types.Exact<{
  input: Types.ClaimLiteOfferInput;
}>;


export type ClaimLiteOfferMutation = { claimLiteOffer: { __typename: 'ClaimLiteOfferResult', liteOffer: { __typename: 'LiteOffer', id: string, amount?: string | null, claimed: boolean, buyer: { __typename: 'Buyer' } | { __typename: 'LiteOfferBuyerContact', firstName: string, lastName: string, phone?: string | null, email: string } } } };

export const LiteOfferFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LiteOffer"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LiteOffer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"claimed"}},{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LiteOfferBuyerContact"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<LiteOfferFragment, unknown>;
export const LiteOfferQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LiteOfferQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"liteOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LiteOffer"}}]}}]}},...LiteOfferFragmentDoc.definitions]} as unknown as DocumentNode<LiteOfferQuery, LiteOfferQueryVariables>;
export const ClaimLiteOfferMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ClaimLiteOfferMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ClaimLiteOfferInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"claimLiteOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"liteOffer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LiteOffer"}}]}}]}}]}},...LiteOfferFragmentDoc.definitions]} as unknown as DocumentNode<ClaimLiteOfferMutation, ClaimLiteOfferMutationVariables>;