import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import React, { createContext, useContext, useEffect } from 'react'
import { ListingStatus } from '../../__generated__/types'
import { ListingStatusDocument } from './__generated__/listing-context.generated'

export type ListingContextType = {
  id: string
}

const ListingContext = createContext<null | ListingContextType>(null)

export const ListingProvider = ({
  children,
  value,
}: {
  children: React.ReactNode
  value: ListingContextType
}) => {
  const { data, startPolling } = useQuery(ListingStatusDocument, {
    variables: { listingId: value.id },
  })

  useEffect(() => {
    startPolling(15e3)
  }, [startPolling])

  if (
    data?.listing?.status &&
    data?.listing?.status !== ListingStatus.ENABLED
  ) {
    window.location.reload()
  }

  return (
    <ListingContext.Provider value={value}>{children}</ListingContext.Provider>
  )
}

export const useListing = () => {
  const context = useContext(ListingContext)
  if (process.env.NODE_ENV !== 'production') {
    if (!context) {
      throw new Error(
        'useListingContext muse be called from within a <ListingProvider>'
      )
    }
  }
  return context!
}

gql`
  query ListingStatus($listingId: ID!) {
    listing(id: $listingId) {
      id
      status
    }
  }
`
