export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Datetime in ISO 8601 format. */
  ISODateString: string;
  /** Date in dd/mm/yyyy format */
  PlainDateString: any;
  join__FieldSet: any;
  link__Import: any;
};

export type ActivityBuyerEvent = AnonymousOfferCreatedEvent | AnonymousOfferUpdatedEvent | ListingBuyerAnnouncementSentEvent | ListingBuyerRegisteredEvent | ListingNoticePostedEvent | ListingStatusUpdatedEvent | ListingUpdateAcceptConditionalOffersTacticEvent | OfferCreatedEvent | OfferUpdatedEvent;

export type ActivityBuyerEventConnection = {
  __typename: 'ActivityBuyerEventConnection';
  edges: Array<ActivityBuyerEventEdge>;
  pageInfo: PageInfo;
};

export type ActivityBuyerEventEdge = {
  __typename: 'ActivityBuyerEventEdge';
  cursor: Scalars['String'];
  node: ActivityBuyerEvent;
};

export type ActivityEvent = FormBuyerStepStatusEvent | FormCompletedBuyerEvent | FormCompletedConditionsEvent | FormCompletedConveyancerEvent | FormCompletedFinanceEvent | FormCompletedOfferAmountEvent | FormCompletedSettlementEvent | FormDocumentDownloadedEvent | ListingAnnouncementSentAggregateEvent | ListingBuyerAnnouncementSentEvent | ListingBuyerDraftStartedEvent | ListingBuyerRegisteredEvent | ListingBuyerVendorLeadSubmittedEvent | ListingNoticePostedEvent | ListingStatusUpdatedEvent | ListingUpdateAcceptConditionalOffersTacticEvent | OfferAcknowledgedEvent | OfferCreatedEvent | OfferForwardedEvent | OfferUpdatedEvent | OfferWithdrawnEvent;

export type ActivityEventConnection = {
  __typename: 'ActivityEventConnection';
  edges: Array<ActivityEventEdge>;
  pageInfo: PageInfo;
};

export type ActivityEventEdge = {
  __typename: 'ActivityEventEdge';
  cursor: Scalars['String'];
  node: ActivityEvent;
};

export type AddListingDocumentInput = {
  id: Scalars['ID'];
  metadata: ListingDocumentMetadata;
  upload: FileUploadInput;
};

export type AddMemberToBillingGroupInput = {
  agencyId: Scalars['ID'];
  groupId: Scalars['ID'];
};

export type AddMemberToBillingGroupResult = {
  __typename: 'AddMemberToBillingGroupResult';
  billingGroup: BillingGroup;
  billingGroupMember: BillingGroupMember;
};

export type AddTrustAccountToAgencyInput = {
  agencyId: Scalars['ID'];
  trustAccount: BankAccountInput;
};

export type AddTrustAccountToAgencyResult = {
  __typename: 'AddTrustAccountToAgencyResult';
  agency: Agency;
  trustAccount: TrustAccount;
};

export type AddUserEmailInput = {
  email: Scalars['String'];
  uid: Scalars['ID'];
};

export type AddUserEmailResult = {
  __typename: 'AddUserEmailResult';
  user: User;
};

export type AddUserPhoneInput = {
  phone: Scalars['String'];
  uid: Scalars['ID'];
};

export type AddUserPhoneResult = {
  __typename: 'AddUserPhoneResult';
  user: User;
};

export type Address = {
  __typename: 'Address';
  city: Scalars['String'];
  line1: Scalars['String'];
  postcode: Scalars['String'];
  state: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  line1: Scalars['String'];
  postcode: Scalars['String'];
  state: Scalars['String'];
};

export type AdminRegisterNewAgentInput = {
  agency: Scalars['String'];
  country: Scalars['String'];
  office?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  teamSize?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AdminRegisterNewAgentResult = {
  __typename: 'AdminRegisterNewAgentResult';
  agencyId?: Maybe<Scalars['String']>;
  agentId: Scalars['String'];
  userId: Scalars['String'];
};

export type Agency = {
  __typename: 'Agency';
  acceptOffersWhileUnderOffer: Scalars['Boolean'];
  agents: Array<Agent>;
  billingAccount?: Maybe<AgencyBillingAccount>;
  branding: AgencyBrandingSettings;
  claimEmailTemplate?: Maybe<AgentClaimEmailTemplateType>;
  connectedDeveloperApps: Array<DeveloperApp>;
  crmIntegrations: Array<CrmIntegration>;
  hasCRMIntegration?: Maybe<Scalars['Boolean']>;
  hasProppsPlus?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  listingResolutionEnabled: Scalars['Boolean'];
  listings: Array<Listing>;
  listingsPaginated: ListingQueryConnection;
  logos: LogoAssetGroup;
  name: Scalars['String'];
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
  offerBoost?: Maybe<AgencyOfferBoost>;
  offersMailingList: Array<Scalars['String']>;
  partnerReferrals?: Maybe<PartnerReferrals>;
  permissions: Array<PermissionState>;
  /** Policy associated with the agency which grants permissions */
  policy: Policy;
  qrCodeMailingList: Array<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rtaIntegration?: Maybe<RtaIntegration>;
  shouldSendClaimEmail: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  subscription?: Maybe<ProppsSubscription>;
  trustAccounts: Array<TrustAccount>;
  vendorLeadConfig: VendorLeadConfig;
};


export type AgencyListingsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AgencyPaginatedListingQueryFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AgencyBillingAccount = {
  __typename: 'AgencyBillingAccount';
  customerId: Scalars['String'];
  representative: AgencyBillingAccountRepresentative;
  subscriptions: Array<ProppsSubscription>;
};

export type AgencyBillingAccountAddress = {
  __typename: 'AgencyBillingAccountAddress';
  city?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AgencyBillingAccountAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type AgencyBillingAccountRepresentative = {
  __typename: 'AgencyBillingAccountRepresentative';
  address?: Maybe<AgencyBillingAccountAddress>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type AgencyBillingAccountRepresentativeInput = {
  address?: InputMaybe<AgencyBillingAccountAddressInput>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type AgencyBrandingInput = {
  colors: BrandingColorsInput;
  contexts: BrandingContextsInput;
  formFooter: FooterFooterBrandingInput;
  logos: BrandingLogosInput;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type AgencyBrandingSettings = {
  __typename: 'AgencyBrandingSettings';
  colors?: Maybe<BrandingColors>;
  contexts: BrandingContexts;
  enabled: Scalars['Boolean'];
  formFooter?: Maybe<FormFooterBranding>;
  logos?: Maybe<LogoBranding>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type AgencyBrandingToggleInput = {
  agencyId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type AgencyOfferBoost = {
  __typename: 'AgencyOfferBoost';
  allListingsAgency: AgencyOfferBoostAllSettings;
  allListingsAgents: Array<AgencyOfferBoostAllAgents>;
  skipBilling: Scalars['Boolean'];
  skipUnlockNewListings: Scalars['Boolean'];
};

export type AgencyOfferBoostAllAgents = {
  __typename: 'AgencyOfferBoostAllAgents';
  agent: Agent;
  includeAuctionListings: Scalars['Boolean'];
};

export type AgencyOfferBoostAllSettings = {
  __typename: 'AgencyOfferBoostAllSettings';
  enabled: Scalars['Boolean'];
  includeAuctionListings: Scalars['Boolean'];
};

export type AgencyPaginatedListingQueryFilters = {
  /** Filter by an array of listing statuses. If omitted, all listings will be returned. */
  statuses?: InputMaybe<Array<ListingStatus>>;
};

export type AgencyUpdateBrandingSettingsInput = {
  agencyId: Scalars['String'];
  branding: AgencyBrandingInput;
};

export type Agent = {
  __typename: 'Agent';
  agency?: Maybe<Agency>;
  appAgentOnboarding?: Maybe<AppAgentOnboarding>;
  /** Which app or agency the agent was created by */
  createdBy: AgentReferrer;
  /** Whether the agent has acknowledged their details are correct */
  detailsVerified: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  honkedFeature?: Maybe<FeatureHonk>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  /** Listings which the agent is connected to */
  listings: Array<Listing>;
  onboarding?: Maybe<AgentOnboarding>;
  permissions: Array<PermissionState>;
  phone?: Maybe<Scalars['String']>;
  /** Policy associated with the agent which grants permissions */
  policy: Policy;
  position?: Maybe<Scalars['String']>;
  profile?: Maybe<AgentProfile>;
  profileImage?: Maybe<ProfileImage>;
  qrAssets?: Maybe<QrAssets>;
  role?: Maybe<AgentRole>;
  status: AgentStatusType;
  user?: Maybe<User>;
  username?: Maybe<Scalars['String']>;
};


export type AgentHonkedFeatureArgs = {
  key: Scalars['ID'];
};

export type AgentBoxAgent = {
  __typename: 'AgentBoxAgent';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type AgentBoxOffice = {
  __typename: 'AgentBoxOffice';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type AgentCard = {
  __typename: 'AgentCard';
  agent: Agent;
  id: Scalars['ID'];
  nfcUrl: Scalars['String'];
  qrSvg: Scalars['String'];
  qrUrl: Scalars['String'];
};

export type AgentCardResultCsv = {
  __typename: 'AgentCardResultCSV';
  data: Scalars['String'];
};

export const AgentClaimEmailTemplateType = {
  eagle: 'eagle',
  rh_vaultre: 'rh_vaultre',
  standard: 'standard'
} as const;

export type AgentClaimEmailTemplateType = typeof AgentClaimEmailTemplateType[keyof typeof AgentClaimEmailTemplateType];
export type AgentHonkFeatureInput = {
  agentId: Scalars['ID'];
  featureKey: Scalars['ID'];
};

export type AgentHonkFeatureResult = {
  __typename: 'AgentHonkFeatureResult';
  agent: Agent;
  feature: Feature;
  honk: FeatureHonk;
};

export type AgentInvitation = {
  __typename: 'AgentInvitation';
  claimKey: Scalars['String'];
  details: AgentInvitationDetails;
  id: Scalars['ID'];
  status: AgentInvitationStatus;
};

export type AgentInvitationDetails = {
  __typename: 'AgentInvitationDetails';
  agency?: Maybe<AgentInvitationDetailsAgency>;
  agencyName?: Maybe<Scalars['String']>;
  agentHasUser?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AgentInvitationDetailsAgency = {
  __typename: 'AgentInvitationDetailsAgency';
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export const AgentInvitationStatus = {
  active: 'active',
  claimed: 'claimed',
  expired: 'expired'
} as const;

export type AgentInvitationStatus = typeof AgentInvitationStatus[keyof typeof AgentInvitationStatus];
export type AgentNotificationPreferences = {
  __typename: 'AgentNotificationPreferences';
  claim_account_reminders?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type AgentOfferPreviewAction = {
  __typename: 'AgentOfferPreviewAction';
  agent: Agent;
  id: Scalars['ID'];
  offer: Offer;
};

export type AgentOnboarding = {
  __typename: 'AgentOnboarding';
  dismissed?: Maybe<Scalars['Boolean']>;
  listing?: Maybe<Listing>;
  /** @deprecated Use listing */
  listingId?: Maybe<Scalars['String']>;
};

export type AgentProfile = {
  __typename: 'AgentProfile';
  agency?: Maybe<ProfileAgency>;
  bio?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  links?: Maybe<Array<ProfileLink>>;
  showListings?: Maybe<Scalars['Boolean']>;
  socials?: Maybe<Array<ProfileSocialLink>>;
};

export type AgentProfileAgencyInput = {
  location?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AgentProfileLinkInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type AgentProfileSocialsInput = {
  socialNetwork: SocialNetwork;
  url: Scalars['String'];
};

export const AgentReferrer = {
  /** The managing agency through the app */
  agency: 'agency',
  /** DEPRECATED TheOneSpot */
  app_onespot: 'app_onespot',
  internal: 'internal',
  portal: 'portal',
  user_input: 'user_input'
} as const;

export type AgentReferrer = typeof AgentReferrer[keyof typeof AgentReferrer];
export const AgentRole = {
  office_admin: 'office_admin',
  sales_agent: 'sales_agent'
} as const;

export type AgentRole = typeof AgentRole[keyof typeof AgentRole];
export const AgentStatusType = {
  active: 'active',
  created: 'created',
  invited: 'invited'
} as const;

export type AgentStatusType = typeof AgentStatusType[keyof typeof AgentStatusType];
export type AgentsQueryResult = {
  __typename: 'AgentsQueryResult';
  agents: Array<Agent>;
};

export type Announcement = {
  __typename: 'Announcement';
  agents: Array<Maybe<Agent>>;
  buyers: Array<Buyer>;
  channels: Array<AnnouncementChannel>;
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  listing: Listing;
  message: Scalars['String'];
  sentAt?: Maybe<Scalars['ISODateString']>;
  shortUrl: Scalars['String'];
};

export const AnnouncementChannel = {
  email: 'email',
  sms: 'sms'
} as const;

export type AnnouncementChannel = typeof AnnouncementChannel[keyof typeof AnnouncementChannel];
export type AnonymousBuyer = {
  __typename: 'AnonymousBuyer';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type AnonymousOffer = {
  __typename: 'AnonymousOffer';
  amount: Scalars['Int'];
  buyer: AnonymousBuyer;
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
};

export type AnonymousOfferCreatedEvent = {
  __typename: 'AnonymousOfferCreatedEvent';
  buyer: AnonymousBuyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: AnonymousOffer;
};

export type AnonymousOfferUpdatedEvent = {
  __typename: 'AnonymousOfferUpdatedEvent';
  buyer: AnonymousBuyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: AnonymousOffer;
};

export type AppAgentOnboarding = {
  __typename: 'AppAgentOnboarding';
  activeStep?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
};

export type AssetUploadMetadata = {
  contentType: Scalars['String'];
  filename: Scalars['String'];
  type?: InputMaybe<LogoType>;
};

export type AssignAgentToListingInput = {
  agentId: Scalars['String'];
  listingId: Scalars['String'];
};

export type AssignAgentToListingResult = {
  __typename: 'AssignAgentToListingResult';
  agent: Agent;
  listing: Listing;
};

export type AttachedOfferCondition = CustomOfferCondition | OfferCondition;

export type BankAccountInput = {
  accountNo: Scalars['String'];
  bsb: Scalars['String'];
  name: Scalars['String'];
};

export type BestAndFinalNotice = INotice & {
  __typename: 'BestAndFinalNotice';
  agents: Array<Agent>;
  /** Associated announcement if existing buyers were notified */
  announcement?: Maybe<Announcement>;
  createdAt: Scalars['ISODateString'];
  /** Whether this notice is currently active or not */
  enabled?: Maybe<Scalars['Boolean']>;
  expiry?: Maybe<Scalars['ISODateString']>;
  id: Scalars['ID'];
  listing: Listing;
  message: Scalars['String'];
};

export type BillingGroup = {
  __typename: 'BillingGroup';
  consolidatedInvoices: Array<ConsolidatedInvoice>;
  id: Scalars['ID'];
  members: Array<BillingGroupMember>;
  name: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type BillingGroupMember = {
  __typename: 'BillingGroupMember';
  accountId: Scalars['String'];
  groupId: Scalars['String'];
  id: Scalars['ID'];
  joinedAt: Scalars['ISODateString'];
  stripeCustomerId: Scalars['String'];
};

export type BrandingColors = {
  __typename: 'BrandingColors';
  primary?: Maybe<Scalars['String']>;
};

export type BrandingColorsInput = {
  primary?: InputMaybe<Scalars['String']>;
};

/** Whether branding is enabled within these contexts */
export type BrandingContexts = {
  __typename: 'BrandingContexts';
  agency: Scalars['Boolean'];
  listing: Scalars['Boolean'];
  offer: Scalars['Boolean'];
};

export type BrandingContextsInput = {
  agency: Scalars['Boolean'];
  listing: Scalars['Boolean'];
  offer: Scalars['Boolean'];
};

export type BrandingLogosInput = {
  altText?: InputMaybe<Scalars['String']>;
};

export type BulkBillingReport = {
  __typename: 'BulkBillingReport';
  createdAt: Scalars['ISODateString'];
  csv: Scalars['String'];
  id: Scalars['ID'];
};

export const BulkBillingReportType = {
  eagle: 'eagle'
} as const;

export type BulkBillingReportType = typeof BulkBillingReportType[keyof typeof BulkBillingReportType];
export type Buyer = {
  __typename: 'Buyer';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  user?: Maybe<User>;
};

export type BuyerNotificationPreferences = {
  __typename: 'BuyerNotificationPreferences';
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  listing_announcements?: Maybe<Scalars['Boolean']>;
  new_offer_notification?: Maybe<Scalars['Boolean']>;
  transactional?: Maybe<Scalars['Boolean']>;
};

export type BuyerOfferPosition = {
  __typename: 'BuyerOfferPosition';
  offer: Offer;
  position: Scalars['Int'];
};

export type BuyerOrAnonymousBuyer = AnonymousBuyer | Buyer;

export const BuyerVerificationStatus = {
  failed: 'failed',
  pending: 'pending',
  verified: 'verified'
} as const;

export type BuyerVerificationStatus = typeof BuyerVerificationStatus[keyof typeof BuyerVerificationStatus];
export type CrmAccount = {
  __typename: 'CRMAccount';
  listing?: Maybe<Listing>;
};


export type CrmAccountListingArgs = {
  key: Scalars['ID'];
};

export type CrmIntegration = {
  agency: Agency;
  agentCount: Scalars['Int'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
};

export type CrmIntegrationAgentBox = CrmIntegration & {
  __typename: 'CRMIntegrationAgentBox';
  agency: Agency;
  agentCount: Scalars['Int'];
  apiKey: Scalars['String'];
  clientId: Scalars['String'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  filterByOffice?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
};

export type CrmIntegrationEagle = CrmIntegration & {
  __typename: 'CRMIntegrationEagle';
  agency: Agency;
  agentCount: Scalars['Int'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
};

export type CrmIntegrationMove = CrmIntegration & {
  __typename: 'CRMIntegrationMove';
  agency: Agency;
  agentCount: Scalars['Int'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  instance: Scalars['String'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  officeId: Scalars['String'];
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
};

export type CrmIntegrationRex = CrmIntegration & {
  __typename: 'CRMIntegrationRex';
  accountId: Scalars['ID'];
  agency: Agency;
  agentCount: Scalars['Int'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
  r2Prefix: Scalars['Boolean'];
};

export type CrmIntegrationVaultRe = CrmIntegration & {
  __typename: 'CRMIntegrationVaultRE';
  agency: Agency;
  agentCount: Scalars['Int'];
  apiToken: Scalars['String'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  filterByTeam?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
};

export type CrmIntegrationZenu = CrmIntegration & {
  __typename: 'CRMIntegrationZenu';
  agency: Agency;
  agentCount: Scalars['Int'];
  authId: Scalars['String'];
  authToken: Scalars['String'];
  crm: Scalars['String'];
  enabled: Scalars['Boolean'];
  filterAgents?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  listingCount: Scalars['Int'];
  listingUrlTemplate?: Maybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
};

export const CrmPullOperation = {
  attach_existing_agents: 'attach_existing_agents',
  attach_existing_listings: 'attach_existing_listings',
  discover_active_listings: 'discover_active_listings',
  update_existing_and_discover_recent: 'update_existing_and_discover_recent'
} as const;

export type CrmPullOperation = typeof CrmPullOperation[keyof typeof CrmPullOperation];
export type CancelBestAndFinalNoticeInput = {
  id: Scalars['String'];
};

export type ChangeUserPhoneInput = {
  verificationCode: Scalars['String'];
  verificationId: Scalars['String'];
};

export type ChangeUserPhoneResult = {
  __typename: 'ChangeUserPhoneResult';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type ChatBridgeInbox = {
  __typename: 'ChatBridgeInbox';
  hmacToken: Scalars['String'];
  inboxId: Scalars['Int'];
  websiteToken: Scalars['String'];
};

export type Checksum = {
  __typename: 'Checksum';
  algorithm: Scalars['String'];
  value: Scalars['String'];
};

/** claimAgent mutation input */
export type ClaimAgentInput = {
  agentId: Scalars['ID'];
  profile?: InputMaybe<CreateAgentInput>;
  uid: Scalars['ID'];
};

export type ClaimAgentInvitationInput = {
  claimKey: Scalars['String'];
  uid: Scalars['ID'];
};

export type ClaimAgentInvitationResult = {
  __typename: 'ClaimAgentInvitationResult';
  agent?: Maybe<Agent>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type ClaimAgentUsernameInput = {
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type ClaimLiteOfferInput = {
  buyerId: Scalars['ID'];
  id: Scalars['ID'];
};

export type ClaimLiteOfferResult = {
  __typename: 'ClaimLiteOfferResult';
  liteOffer: LiteOffer;
};

export type ClearAgentPhoneInput = {
  agentId: Scalars['ID'];
};

export type ClearAgentPhoneResult = {
  __typename: 'ClearAgentPhoneResult';
  agent: Agent;
};

export type ClearAgentProfileImageInput = {
  id: Scalars['ID'];
};

export type CloudStorageLocation = {
  __typename: 'CloudStorageLocation';
  bucket: Scalars['String'];
  name: Scalars['String'];
};

export type ConditionRevisionInput = {
  id: Scalars['String'];
  line: Scalars['String'];
  params: Array<ConditionRevisionParameterInput>;
  region: Scalars['String'];
};

export type ConditionRevisionParameterInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ConnectAgentToAgencyInput = {
  agencyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type ConnectAgentToAgencyResult = {
  __typename: 'ConnectAgentToAgencyResult';
  agency: Agency;
  agent: Agent;
};

/** connectDeveloperAppToAgency mutation input */
export type ConnectDeveloperAppToAgencyInput = {
  agencyId: Scalars['ID'];
  developerAppId: Scalars['ID'];
};

export type ConnectDeveloperAppToAgencyResult = {
  __typename: 'ConnectDeveloperAppToAgencyResult';
  agency: Agency;
  developerApp: DeveloperApp;
};

export type ConsolidateBillingGroupInvoicesInput = {
  from: Scalars['ISODateString'];
  groupId: Scalars['ID'];
  to: Scalars['ISODateString'];
};

export type ConsolidateBillingGroupInvoicesResult = {
  __typename: 'ConsolidateBillingGroupInvoicesResult';
  invoice: ConsolidatedInvoice;
};

export type ConsolidatedInvoice = {
  __typename: 'ConsolidatedInvoice';
  billingGroup: BillingGroup;
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  members: Array<ConsolidatedInvoiceMember>;
  periodEnd: Scalars['ISODateString'];
  periodStart: Scalars['ISODateString'];
  reports: Array<BulkBillingReport>;
  status: ConsolidatedInvoiceStatus;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type ConsolidatedInvoiceMember = {
  __typename: 'ConsolidatedInvoiceMember';
  invoiceIds: Array<Scalars['String']>;
  member: BillingGroupMember;
};

export const ConsolidatedInvoiceStatus = {
  draft: 'draft',
  paid: 'paid',
  sent: 'sent',
  void: 'void'
} as const;

export type ConsolidatedInvoiceStatus = typeof ConsolidatedInvoiceStatus[keyof typeof ConsolidatedInvoiceStatus];
export type ConveyancerInformationInput = {
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type: ConveyancerInformationInputType;
};

export const ConveyancerInformationInputType = {
  need_conveyancer_help: 'need_conveyancer_help',
  need_legal_process_help: 'need_legal_process_help',
  none: 'none',
  provided: 'provided'
} as const;

export type ConveyancerInformationInputType = typeof ConveyancerInformationInputType[keyof typeof ConveyancerInformationInputType];
/** Listing timer */
export type CountdownTimer = {
  __typename: 'CountdownTimer';
  enabled: Scalars['Boolean'];
  expiry?: Maybe<Scalars['ISODateString']>;
  label?: Maybe<Scalars['String']>;
  pauseListingOnExpiry?: Maybe<Scalars['Boolean']>;
};

/** createAgency mutation input */
export type CreateAgencyInput = {
  name: Scalars['String'];
  region: Scalars['String'];
  state: Scalars['String'];
};

export type CreateAgencyResult = {
  __typename: 'CreateAgencyResult';
  agency: Agency;
};

export type CreateAgentBoxCrmIntegrationInput = {
  agencyId: Scalars['ID'];
  apiKey: Scalars['String'];
  clientId: Scalars['String'];
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  filterByOffice?: InputMaybe<Scalars['String']>;
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
  pullAuctionListings?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAgentBoxCrmIntegrationResult = {
  __typename: 'CreateAgentBoxCRMIntegrationResult';
  crmIntegration: CrmIntegration;
};

export type CreateAgentChatwootSignInLinkInput = {
  agentId: Scalars['String'];
  conversationPath?: InputMaybe<Scalars['String']>;
};

export type CreateAgentChatwootSignInLinkResult = {
  __typename: 'CreateAgentChatwootSignInLinkResult';
  url: Scalars['String'];
};

/** createAgent mutation input */
export type CreateAgentInput = {
  agencyId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<AgentReferrer>;
};

export type CreateAgentResult = {
  __typename: 'CreateAgentResult';
  agent: Agent;
};

export type CreateAgentTokenInput = {
  agentId: Scalars['ID'];
  uid: Scalars['ID'];
};

export type CreateAgentTokenResult = {
  __typename: 'CreateAgentTokenResult';
  token: Scalars['String'];
  user: User;
};

export type CreateBestAndFinalNoticeInput = {
  agentId: Scalars['String'];
  /** If specified, creates an announcement with the same message to notify buyers */
  buyerIds: Array<Scalars['String']>;
  expiry: Scalars['String'];
  listingId: Scalars['String'];
  message: Scalars['String'];
};

export type CreateBillingGroupInput = {
  name: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

export type CreateBillingGroupResult = {
  __typename: 'CreateBillingGroupResult';
  billingGroup: BillingGroup;
};

/** createBuyer mutation input */
export type CreateBuyerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  uid?: InputMaybe<Scalars['ID']>;
};

export type CreateBuyerResult = {
  __typename: 'CreateBuyerResult';
  buyer: Buyer;
  /** A login token with the buyer role preloaded */
  token: Scalars['String'];
};

export type CreateCustomNoticeInput = {
  agentId: Scalars['String'];
  /** If specified, creates an announcement with the same message to notify buyers */
  buyerIds: Array<Scalars['String']>;
  /** Supports 'sms' and 'email' as channels for announcement */
  channels: Array<Scalars['String']>;
  listingId: Scalars['String'];
  message: Scalars['String'];
};

/** createDeveloperApp mutation input */
export type CreateDeveloperAppInput = {
  name: Scalars['String'];
};

export type CreateEagleCrmIntegrationInput = {
  agencyId: Scalars['ID'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
};

export type CreateEagleCrmIntegrationResult = {
  __typename: 'CreateEagleCRMIntegrationResult';
  crmIntegration: CrmIntegration;
};

export type CreateIncompleteListingAgentDetailsInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateListingInput = {
  agencyId?: InputMaybe<Scalars['String']>;
  agentIds?: InputMaybe<Array<Scalars['String']>>;
  identifiers?: InputMaybe<Array<ListingIdentifierInput>>;
  listingType?: InputMaybe<ListingType>;
  priceIndication: ListingPriceIndicationInput;
  property: PropertyInput;
  published?: InputMaybe<Scalars['Boolean']>;
  source: ListingCreateSourceInput;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateListingInputResult = {
  __typename: 'CreateListingInputResult';
  listing: Listing;
};

export type CreateLiteOfferInput = {
  /** Agent to send the invitation on behalf of. If omitted, sends it on behalf of the primary listing agent. */
  agentId?: InputMaybe<Scalars['String']>;
  /** Offer amount to pre-fill the offer form with. If omitted, does not pre-fill the offer amount. */
  amount?: InputMaybe<Scalars['String']>;
  /** The contact details of the buyer to include in the invitation and pre-fill details with. */
  buyerContact: LiteOfferBuyerContactInput;
  /** Whether to send the offer invitation to the buyer as an email. */
  inviteBuyer?: InputMaybe<Scalars['Boolean']>;
  listingId: Scalars['String'];
};

export type CreateLiteOfferResult = {
  __typename: 'CreateLiteOfferResult';
  liteOffer: LiteOffer;
};

export type CreateMoveCrmIntegrationInput = {
  agencyId: Scalars['ID'];
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  instance: Scalars['String'];
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
  officeId: Scalars['ID'];
  pullAuctionListings?: InputMaybe<Scalars['Boolean']>;
};

export type CreateMoveCrmIntegrationResult = {
  __typename: 'CreateMoveCRMIntegrationResult';
  crmIntegration: CrmIntegration;
};

export type CreateNoticeInput = {
  agentId: Scalars['String'];
  /** If specified, creates an announcement with the same message to notify buyers */
  buyerIds: Array<Scalars['String']>;
  listingId: Scalars['String'];
  message: Scalars['String'];
};

export type CreateRexcrmIntegrationInput = {
  accountId: Scalars['ID'];
  agencyId: Scalars['ID'];
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
  r2Prefix: Scalars['Boolean'];
};

export type CreateRexcrmIntegrationResult = {
  __typename: 'CreateREXCRMIntegrationResult';
  crmIntegration: CrmIntegration;
};

export type CreateRtaIntegrationInput = {
  agencyId: Scalars['String'];
  apiKey: Scalars['String'];
};

export type CreateRtaIntegrationResult = {
  __typename: 'CreateRtaIntegrationResult';
  rtaIntegration: RtaIntegration;
};

export type CreateSuperadminInput = {
  name: Scalars['String'];
  uid: Scalars['ID'];
};

export type CreateSuperadminResult = {
  __typename: 'CreateSuperadminResult';
  superadmin: Superadmin;
};

export type CreateUserForAgentResult = {
  __typename: 'CreateUserForAgentResult';
  agent: Agent;
  user: User;
};

export type CreateVaultreCrmIntegrationInput = {
  agencyId: Scalars['ID'];
  apiToken: Scalars['String'];
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  filterByTeam?: InputMaybe<Scalars['ID']>;
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
  pullAuctionListings: Scalars['Boolean'];
};

export type CreateVaultreCrmIntegrationResult = {
  __typename: 'CreateVaultreCRMIntegrationResult';
  crmIntegration: CrmIntegration;
};

export type CreateWebhookInput = {
  appId: Scalars['String'];
  filters: WebhookEventFiltersInput;
  sources: Array<WebhookEventSourceInput>;
  url: Scalars['String'];
};

export type CreateZenuCrmIntegrationInput = {
  agencyId: Scalars['ID'];
  authId: Scalars['String'];
  authToken: Scalars['String'];
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
  pullAuctionListings?: InputMaybe<Scalars['Boolean']>;
};

export type CreateZenuCrmIntegrationResult = {
  __typename: 'CreateZenuCRMIntegrationResult';
  crmIntegration: CrmIntegration;
};

export type CustomNotice = INotice & {
  __typename: 'CustomNotice';
  agents: Array<Agent>;
  /** Associated announcement if existing buyers were notified */
  announcement?: Maybe<Announcement>;
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  listing: Listing;
  message: Scalars['String'];
};

export type CustomOfferCondition = {
  __typename: 'CustomOfferCondition';
  content: Scalars['String'];
};

export const DefaultListingStatus = {
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  ENABLED: 'ENABLED'
} as const;

export type DefaultListingStatus = typeof DefaultListingStatus[keyof typeof DefaultListingStatus];
export type DeleteLiteOfferInput = {
  id: Scalars['ID'];
};

export type DeleteLiteOfferResult = {
  __typename: 'DeleteLiteOfferResult';
  listing: Listing;
};

export type DeleteRtaIntegrationInput = {
  agencyId: Scalars['String'];
};

export type DeleteRtaIntegrationResult = {
  __typename: 'DeleteRtaIntegrationResult';
  agency: Agency;
};

export const DepositPercentage = {
  five: 'five',
  other: 'other',
  ten: 'ten'
} as const;

export type DepositPercentage = typeof DepositPercentage[keyof typeof DepositPercentage];
export type DepositRequired = {
  __typename: 'DepositRequired';
  display: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type DeveloperApp = {
  __typename: 'DeveloperApp';
  agencies: Array<Agency>;
  automount?: Maybe<Scalars['Boolean']>;
  branding: DeveloperAppBrandingSettings;
  createdAt: Scalars['ISODateString'];
  domainAllowList: Array<Scalars['String']>;
  hasProvisionedApiKey: Scalars['Boolean'];
  id: Scalars['ID'];
  listing?: Maybe<Listing>;
  logos: LogoAssetGroup;
  name: Scalars['String'];
  /** @deprecated Use branding and logos instead. */
  profile?: Maybe<DeveloperAppProfile>;
  resolutionMode?: Maybe<DeveloperAppResolutionMode>;
  vendorLeadConfig: VendorLeadConfig;
  webhooks: Array<Webhook>;
};


export type DeveloperAppListingArgs = {
  foreignId?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
};

export type DeveloperAppBrandingInput = {
  colors: BrandingColorsInput;
  contexts: BrandingContextsInput;
  formFooter: FooterFooterBrandingInput;
  logos: BrandingLogosInput;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type DeveloperAppBrandingSettings = {
  __typename: 'DeveloperAppBrandingSettings';
  colors?: Maybe<BrandingColors>;
  /** @deprecated Use 'enabled' field instead */
  contexts: BrandingContexts;
  enabled: Scalars['Boolean'];
  formFooter?: Maybe<FormFooterBranding>;
  logos?: Maybe<LogoBranding>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type DeveloperAppBrandingToggleInput = {
  appId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type DeveloperAppProfile = {
  __typename: 'DeveloperAppProfile';
  logoUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export const DeveloperAppResolutionMode = {
  barry_plant: 'barry_plant',
  crm_integration: 'crm_integration',
  default: 'default'
} as const;

export type DeveloperAppResolutionMode = typeof DeveloperAppResolutionMode[keyof typeof DeveloperAppResolutionMode];
export type DeveloperAppResult = {
  __typename: 'DeveloperAppResult';
  developerApp: DeveloperApp;
};

/** Inputs */
export type DeveloperAppUpdateBrandingSettingsInput = {
  appId: Scalars['String'];
  branding: DeveloperAppBrandingInput;
};

export type DisableWebhookInput = {
  appId: Scalars['String'];
  webhookId: Scalars['ID'];
};

export type DisconnectAgentFromAgencyInput = {
  agencyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DisconnectAgentFromAgencyResult = {
  __typename: 'DisconnectAgentFromAgencyResult';
  agency: Agency;
  agent: Agent;
};

export type DispatchListingBuyerRegistrationInput = {
  listingId: Scalars['ID'];
  userUid: Scalars['ID'];
};

export type DispatchListingCampaignAssetNotificationsForAgencyInput = {
  agencyId: Scalars['ID'];
};

export type DispatchListingCampaignAssetNotificationsForAgencyResult = {
  __typename: 'DispatchListingCampaignAssetNotificationsForAgencyResult';
  success: Scalars['Boolean'];
};

export type DocumentRevisionInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type DraftOffer = {
  __typename: 'DraftOffer';
  /** Indicates that additional buyers may be involved */
  addTitleHoldersLater?: Maybe<Scalars['Boolean']>;
  /** For offers on referred listings - Information about the agents the offer should be sent to */
  agents?: Maybe<Array<OfferAgent>>;
  /** Agreements including terms, digital signature consent, contract acknowledgement. */
  agreements?: Maybe<Array<OfferAgreement>>;
  /** Offer amount in AUD (whole dollars) */
  amount?: Maybe<Scalars['Int']>;
  /** Agent attributed to the offer */
  attributedAgent?: Maybe<Agent>;
  /** List of conditions attached to the offer. */
  conditions?: Maybe<Array<AttachedOfferCondition>>;
  /** Conveyancer information */
  conveyancer?: Maybe<OfferConveyancerInformation>;
  /** Created at timestamp */
  createdAt: Scalars['ISODateString'];
  /** Whether the draft is explicit or implicit (preparation or 'real' offer) */
  disposition: DraftOfferDisposition;
  /** Date this offer expires (optional) */
  expiresAt?: Maybe<Scalars['ISODateString']>;
  /** Finance declaration */
  finance?: Maybe<OfferFinanceDeclaration>;
  id: Scalars['ID'];
  /** Identity verification status (this is always null for a draft offer as identity verification is processed at the time of submission) */
  identityVerification?: Maybe<Array<OfferBuyerVerification>>;
  /**
   * Last visited step in the form
   * @deprecated Not needed for Offer Form v2
   */
  lastVisitedStepPath?: Maybe<Array<Scalars['String']>>;
  /** Listing */
  listing: Listing;
  /** For offers on referred listings - Information about the property the offer is for */
  property?: Maybe<Property>;
  /** Elected settlement period. */
  settlement?: Maybe<OfferSettlementPeriod>;
  /** Parties listed on the purchase */
  signatories: Array<DraftOfferSignatory>;
  /** Signatures (this is always null for a draft offer as the signatures must be supplied at the time of submission. */
  signatures?: Maybe<Array<OfferSignature>>;
  /** Updated at timestamp */
  updatedAt: Scalars['ISODateString'];
};

export const DraftOfferDisposition = {
  explicit: 'explicit',
  implicit: 'implicit'
} as const;

export type DraftOfferDisposition = typeof DraftOfferDisposition[keyof typeof DraftOfferDisposition];
export type DraftOfferSignatory = {
  __typename: 'DraftOfferSignatory';
  /** Address in single line format */
  address: Scalars['String'];
  /** Buyer account this signatory represents */
  buyer?: Maybe<Buyer>;
  dateOfBirth: PlainDate;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  identityDocument: IdentityDocument;
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  onBehalfOf: RepresentedEntity;
  phone: Scalars['String'];
};

export type DraftOfferSignatoryInput = {
  address?: InputMaybe<Scalars['String']>;
  agreeToIdentityVerification?: InputMaybe<Scalars['Boolean']>;
  agreedContractRevision?: InputMaybe<DocumentRevisionInput>;
  agreedTermsRevision?: InputMaybe<TermsRevisionInput>;
  buyerId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  identityDocument?: InputMaybe<IdentityDocumentInput>;
  isPrimaryBuyer: Scalars['Boolean'];
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  onBehalfOf?: InputMaybe<RepresentedEntityInput>;
  phone?: InputMaybe<Scalars['String']>;
};

export type EagleAgent = {
  __typename: 'EagleAgent';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Embedder = {
  __typename: 'Embedder';
  account?: Maybe<CrmAccount>;
  id: Scalars['ID'];
};


export type EmbedderAccountArgs = {
  key: Scalars['ID'];
};

/** Enable agency wide offer boost (admin use) */
export type EnableAgencyWideOfferBoostInput = {
  agencyId: Scalars['String'];
};

export type EnableAgencyWideOfferBoostResponse = {
  __typename: 'EnableAgencyWideOfferBoostResponse';
  agency: Agency;
};

export type ExternalListingAssociation = {
  __typename: 'ExternalListingAssociation';
  /** The identifier for this listing used by the provider */
  key: Scalars['ID'];
  /** The listing */
  listing: Listing;
  /** The provider that this listing is associated with */
  provider?: Maybe<ListingProvider>;
};

export type Feature = {
  __typename: 'Feature';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FeatureHonk = {
  __typename: 'FeatureHonk';
  agent: Agent;
  createdAt: Scalars['ISODateString'];
  feature: Feature;
  id: Scalars['ID'];
};

export type FileResource = IFileResource & {
  __typename: 'FileResource';
  checksum?: Maybe<Checksum>;
  contentType: Scalars['String'];
  filename: Scalars['String'];
  location?: Maybe<CloudStorageLocation>;
  size?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type FileUploadInput = {
  bucket: Scalars['String'];
  path: Scalars['String'];
};

export type FinanceDeclarationInput = {
  date?: InputMaybe<Scalars['PlainDateString']>;
  institution?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FinanceDeclarationInputType>;
};

export const FinanceDeclarationInputType = {
  approved: 'approved',
  none: 'none',
  not_applicable: 'not_applicable',
  other: 'other'
} as const;

export type FinanceDeclarationInputType = typeof FinanceDeclarationInputType[keyof typeof FinanceDeclarationInputType];
export type FindAgentCriteria = {
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type FooterFooterBrandingInput = {
  content?: InputMaybe<Scalars['String']>;
};

export type FormBuyerStepStatusEvent = IFormEvent & {
  __typename: 'FormBuyerStepStatusEvent';
  buyer: Buyer;
  completedEvents: Array<Scalars['String']>;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  latestEvent: FormEvent;
  totalEvents: Scalars['Int'];
};

export type FormCompletedBuyerEvent = IFormEvent & {
  __typename: 'FormCompletedBuyerEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  revision: Scalars['Int'];
};

export type FormCompletedConditionsEvent = IFormEvent & {
  __typename: 'FormCompletedConditionsEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  revision: Scalars['Int'];
};

export type FormCompletedConveyancerEvent = IFormEvent & {
  __typename: 'FormCompletedConveyancerEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  revision: Scalars['Int'];
};

export type FormCompletedFinanceEvent = IFormEvent & {
  __typename: 'FormCompletedFinanceEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  revision: Scalars['Int'];
};

export type FormCompletedOfferAmountEvent = IFormEvent & {
  __typename: 'FormCompletedOfferAmountEvent';
  amount: Scalars['Int'];
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  revision: Scalars['Int'];
};

export type FormCompletedSettlementEvent = IFormEvent & {
  __typename: 'FormCompletedSettlementEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  revision: Scalars['Int'];
};

export type FormDocumentDownloadedEvent = IFormEvent & {
  __typename: 'FormDocumentDownloadedEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  documentId: Scalars['String'];
  documentType: Scalars['String'];
  documentUrl: Scalars['String'];
  eventId: Scalars['ID'];
};

export type FormEvent = FormCompletedBuyerEvent | FormCompletedConditionsEvent | FormCompletedConveyancerEvent | FormCompletedFinanceEvent | FormCompletedOfferAmountEvent | FormCompletedSettlementEvent | FormDocumentDownloadedEvent;

export type FormFooterBranding = {
  __typename: 'FormFooterBranding';
  content: FormFooterBrandingContent;
};

export type FormFooterBrandingContent = {
  __typename: 'FormFooterBrandingContent';
  html?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
};

export type ForwardOfferToVendorAction = {
  __typename: 'ForwardOfferToVendorAction';
  agent: Agent;
  id: Scalars['ID'];
  message: Scalars['String'];
  offer: Offer;
};

export type ForwardOfferToVendorInput = {
  agentId: Scalars['ID'];
  message: Scalars['String'];
  offerId: Scalars['ID'];
  vendor: VendorInput;
};

export type ForwardOfferToVendorResult = {
  __typename: 'ForwardOfferToVendorResult';
  action: ForwardOfferToVendorAction;
};

export type GenerateBillingGroupInvoiceReportInput = {
  consolidatedInvoiceId: Scalars['ID'];
  reportType: BulkBillingReportType;
};

export type GenerateBillingGroupInvoiceReportResult = {
  __typename: 'GenerateBillingGroupInvoiceReportResult';
  report: BulkBillingReport;
};

export type GenerateBillingGroupStripeInvoiceInput = {
  consolidatedInvoiceId: Scalars['ID'];
};

export type GenerateBillingGroupStripeInvoiceResult = {
  __typename: 'GenerateBillingGroupStripeInvoiceResult';
  consolidatedInvoice: ConsolidatedInvoice;
};

export type GenerateCampaignAssetsResult = {
  __typename: 'GenerateCampaignAssetsResult';
  status: Scalars['Boolean'];
};

export type GetAgencyNameInput = {
  agencyId: Scalars['ID'];
};

export type GetAgencyNameResult = {
  __typename: 'GetAgencyNameResult';
  name?: Maybe<Scalars['String']>;
};

export type HoldingDeposit = {
  __typename: 'HoldingDeposit';
  amount: Scalars['Int'];
  config: HoldingDepositConfig;
  status: HoldingDepositStatus;
};

export type HoldingDepositConfig = {
  __typename: 'HoldingDepositConfig';
  enabled: Scalars['Boolean'];
  minimum: Scalars['Int'];
  rate: Scalars['Float'];
  trustAccount?: Maybe<TrustAccount>;
};

export const HoldingDepositStatus = {
  received: 'received',
  refunded: 'refunded',
  submitted: 'submitted',
  unpaid: 'unpaid'
} as const;

export type HoldingDepositStatus = typeof HoldingDepositStatus[keyof typeof HoldingDepositStatus];
export type IFileResource = {
  checksum?: Maybe<Checksum>;
  contentType: Scalars['String'];
  filename: Scalars['String'];
  location?: Maybe<CloudStorageLocation>;
  size?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type IFormEvent = {
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type IListingEvent = {
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type INotice = {
  agents: Array<Agent>;
  /** Associated announcement if existing buyers were notified */
  announcement?: Maybe<Announcement>;
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  listing: Listing;
  message: Scalars['String'];
};

export type IOfferEvent = {
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: Offer;
};

export type IdentityDocument = {
  __typename: 'IdentityDocument';
  country: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  type: IdentityDocumentType;
};

export type IdentityDocumentInput = {
  country?: InputMaybe<Scalars['String']>;
  documentNo: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
  type: IdentityDocumentType;
};

export const IdentityDocumentType = {
  drivers_licence: 'drivers_licence',
  passport: 'passport'
} as const;

export type IdentityDocumentType = typeof IdentityDocumentType[keyof typeof IdentityDocumentType];
export type ImpersonateAgentInput = {
  agentId: Scalars['ID'];
  uid: Scalars['ID'];
};

export type ImpersonateAgentResult = {
  __typename: 'ImpersonateAgentResult';
  token: Scalars['String'];
};

export type ImportListingsCsvInput = {
  agencyId: Scalars['ID'];
  appId: Scalars['ID'];
  csv: Scalars['String'];
  publish?: InputMaybe<Scalars['Boolean']>;
};

export type ImportListingsCsvResult = {
  __typename: 'ImportListingsCSVResult';
  count: Scalars['Int'];
};

export type IsAllowedOriginForDeveloperAppInput = {
  appId: Scalars['String'];
  url: Scalars['String'];
};

export type IsAllowedOriginForDeveloperAppResult = {
  __typename: 'IsAllowedOriginForDeveloperAppResult';
  allowed: Scalars['Boolean'];
  app?: Maybe<DeveloperApp>;
};

export type IssueAgentInvitationResult = {
  __typename: 'IssueAgentInvitationResult';
  agentInvitation?: Maybe<AgentInvitation>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Listing = {
  __typename: 'Listing';
  acceptConditionalOffers: ListingAcceptConditionalOffers;
  acceptedOfferType: OfferType;
  /** Number of currently active offers on this listing */
  activeOfferCount: Scalars['Int'];
  /** Events which have occurred against this listing relevant to the agent */
  activity: ListingActivity;
  /** Events which have occurred against this listing relevant to the buyer */
  activityBuyer: ListingActivityBuyerConnection;
  agency?: Maybe<Agency>;
  agents: Array<Agent>;
  /** Currently active best and final notice (if it exists) */
  bestAndFinalNotice?: Maybe<BestAndFinalNotice>;
  branding: ListingBranding;
  /** Competition level information regarding buyers on this listing */
  buyerCompetition: ListingBuyerCompetitionEdge;
  buyerNewOfferNotifications?: Maybe<ListingBuyerNewOfferNotifications>;
  campaignAssets: Array<ListingDocument>;
  chatInbox?: Maybe<ChatBridgeInbox>;
  conditions: Array<OfferConditionsLine>;
  contract?: Maybe<ListingDocument>;
  countdownTimer?: Maybe<CountdownTimer>;
  createdAt: Scalars['ISODateString'];
  /** Whether a listing requires a Deposit */
  depositRequired: DepositRequired;
  /** Retrieves all visible documents for a listing by default */
  documents: Array<ListingDocument>;
  exposeCompetition: Scalars['Boolean'];
  externalAssociation?: Maybe<ExternalListingAssociation>;
  holdingDeposit: HoldingDepositConfig;
  id: Scalars['ID'];
  identifiers: Array<ListingIdentifier>;
  /** Whether a listing is accepting offers */
  isAcceptingOffers: Scalars['Boolean'];
  /** Whether a listing is a demo listing used by an agency to test listing settings */
  isDemo: Scalars['Boolean'];
  /** Whether a listing was created by a referring partner or portal */
  isReferred: Scalars['Boolean'];
  legallyBindingOffersAllowed: Scalars['Boolean'];
  /** Gets the lite offers associated with this listing. By default, excludes claimed and completed. */
  liteOffers?: Maybe<Array<LiteOffer>>;
  managed?: Maybe<Scalars['Boolean']>;
  /** Notices posted by an agent against this listing */
  notices: Array<Notice>;
  offerBoost?: Maybe<ListingOfferBoost>;
  offers: Array<Offer>;
  partnerReferrals?: Maybe<PartnerReferrals>;
  permalink: Scalars['String'];
  prepareToBuy: ListingPrepareToBuy;
  priceIndication: ListingPriceIndication;
  property: Property;
  published: Scalars['Boolean'];
  recommendedSettlementValues: Array<Scalars['Int']>;
  referredAgentDetails?: Maybe<Array<ListingPartialAgent>>;
  /** Buyers who have registered on the offer form for this property */
  registeredBuyers?: Maybe<ListingRegisteredBuyers>;
  rtaActivationStatus?: Maybe<RtaActivationStatus>;
  showDocuments: Scalars['Boolean'];
  source: ListingSource;
  status: ListingStatus;
  type: ListingType;
  url?: Maybe<Scalars['String']>;
  vendor?: Maybe<VendorProfile>;
  vendorLeadsEnabled: Scalars['Boolean'];
};


export type ListingActivityArgs = {
  filters?: InputMaybe<ListingActivityFilters>;
};


export type ListingDocumentsArgs = {
  input?: InputMaybe<ListingDocumentsInput>;
};


export type ListingLiteOffersArgs = {
  filters?: InputMaybe<LiteOfferFilters>;
};


export type ListingPermalinkArgs = {
  input?: InputMaybe<ListingPermalinkInput>;
};

/** Listing accept conditional offers */
export type ListingAcceptConditionalOffers = {
  __typename: 'ListingAcceptConditionalOffers';
  enabled: Scalars['Boolean'];
};

export type ListingAcceptConditionalOffersInput = {
  enabled: Scalars['Boolean'];
};

export type ListingActivity = {
  __typename: 'ListingActivity';
  /** Array of buyers referenced in the events */
  buyers: Array<Buyer>;
  /** Whether the activity data is returned from cache */
  cacheHit: Scalars['Boolean'];
  /** Paginated activity events */
  events: ActivityEventConnection;
  id: Scalars['ID'];
  /** Whether a background refetch was triggered SWR style */
  refetchTriggered: Scalars['Boolean'];
};


export type ListingActivityEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ListingActivityBuyer = {
  __typename: 'ListingActivityBuyer';
  /** Whether the activity data is returned from cache */
  cacheHit: Scalars['Boolean'];
  /** Paginated activity events */
  events: ActivityBuyerEventConnection;
  id: Scalars['ID'];
  /** Whether a background refetch was triggered SWR style */
  refetchTriggered: Scalars['Boolean'];
  /** Total events in feed earlier than cursor event. Will return -1 if cursor is invalid. */
  totalSince: Scalars['Int'];
};


export type ListingActivityBuyerEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ListingActivityBuyerTotalSinceArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};

export type ListingActivityBuyerConnection = {
  __typename: 'ListingActivityBuyerConnection';
  activity?: Maybe<ListingActivityBuyer>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type ListingActivityFilters = {
  buyerId?: InputMaybe<Scalars['ID']>;
};

export type ListingAnnouncementSentAggregateEvent = IListingEvent & {
  __typename: 'ListingAnnouncementSentAggregateEvent';
  announcement: Announcement;
  buyers: Array<Buyer>;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type ListingAnonymousBuyer = {
  __typename: 'ListingAnonymousBuyer';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ListingAnonymousOffer = {
  __typename: 'ListingAnonymousOffer';
  amount: Scalars['Int'];
  buyer: ListingAnonymousBuyer;
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
};

export type ListingBranding = {
  __typename: 'ListingBranding';
  colors: BrandingColors;
  formFooter: FormFooterBranding;
  logos: LogoBranding;
};

export type ListingBuyerAnnouncementSentEvent = IListingEvent & {
  __typename: 'ListingBuyerAnnouncementSentEvent';
  announcement: Announcement;
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type ListingBuyerCompetition = {
  __typename: 'ListingBuyerCompetition';
  highest?: Maybe<ListingAnonymousOffer>;
  lowest?: Maybe<ListingAnonymousOffer>;
  offer?: Maybe<BuyerOfferPosition>;
  totalOffers?: Maybe<Scalars['Int']>;
};

export type ListingBuyerCompetitionEdge = {
  __typename: 'ListingBuyerCompetitionEdge';
  /** If enabled, returns a buyer's relative offer positions */
  buyer?: Maybe<ListingBuyerCompetition>;
  state: ListingExposeCompetitionState;
};


export type ListingBuyerCompetitionEdgeBuyerArgs = {
  id: Scalars['ID'];
};

export type ListingBuyerDraftStartedEvent = IListingEvent & {
  __typename: 'ListingBuyerDraftStartedEvent';
  buyer: BuyerOrAnonymousBuyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type ListingBuyerNewOfferNotifications = {
  __typename: 'ListingBuyerNewOfferNotifications';
  enabled: Scalars['Boolean'];
};

export type ListingBuyerRegisteredEvent = IListingEvent & {
  __typename: 'ListingBuyerRegisteredEvent';
  buyer: BuyerOrAnonymousBuyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type ListingBuyerVendorLeadSubmittedEvent = IListingEvent & {
  __typename: 'ListingBuyerVendorLeadSubmittedEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  lead: VendorLead;
};

export type ListingCreateSourceInput = {
  appId: Scalars['String'];
  foreignId?: InputMaybe<Scalars['String']>;
};

export type ListingDocument = {
  __typename: 'ListingDocument';
  checksum: Scalars['String'];
  checksumAlgorithm: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  revision: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ListingDocumentMetadata = {
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  name: ListingDocumentType;
};

export type ListingDocumentRef = {
  filename?: InputMaybe<Scalars['String']>;
  name: ListingDocumentType;
};

export const ListingDocumentType = {
  COS: 'COS',
  custom: 'custom',
  sec32: 'sec32'
} as const;

export type ListingDocumentType = typeof ListingDocumentType[keyof typeof ListingDocumentType];
export type ListingDocumentsInput = {
  /** Fetch all viewable documents along with their previous versions */
  fetchAll?: InputMaybe<Scalars['Boolean']>;
  /** Include hidden documents in result */
  includeHidden?: InputMaybe<Scalars['Boolean']>;
};

export type ListingEventAcceptConditionalOffers = {
  __typename: 'ListingEventAcceptConditionalOffers';
  enabled: Scalars['Boolean'];
};

/** Listing expose competition settings */
export const ListingExposeCompetitionState = {
  HIDDEN: 'HIDDEN',
  OFFER_INFO: 'OFFER_INFO',
  POSITION: 'POSITION'
} as const;

export type ListingExposeCompetitionState = typeof ListingExposeCompetitionState[keyof typeof ListingExposeCompetitionState];
export type ListingFeatureToggleInput = {
  enable: Scalars['Boolean'];
  flag: Scalars['String'];
  id: Scalars['ID'];
};

export type ListingIdentifier = {
  __typename: 'ListingIdentifier';
  id: Scalars['ID'];
  type: ListingIdentifierType;
};

export type ListingIdentifierApp = {
  __typename: 'ListingIdentifierApp';
  app: DeveloperApp;
  appId: Scalars['String'];
};

export type ListingIdentifierInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type ListingIdentifierOther = {
  __typename: 'ListingIdentifierOther';
  name: Scalars['String'];
};

export type ListingIdentifierType = ListingIdentifierApp | ListingIdentifierOther;

export type ListingNewOfferNotificationsInput = {
  enabled: Scalars['Boolean'];
};

export type ListingNoticePostedEvent = IListingEvent & {
  __typename: 'ListingNoticePostedEvent';
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  notice: Notice;
};

export type ListingOfferBoost = {
  __typename: 'ListingOfferBoost';
  enabled: Scalars['Boolean'];
  priceTier: OfferBoostPriceTier;
  status: ListingOfferBoostStatus;
};

export type ListingOfferBoostInput = {
  enabled: Scalars['Boolean'];
};

export const ListingOfferBoostStatus = {
  active: 'active',
  locked: 'locked',
  ready: 'ready'
} as const;

export type ListingOfferBoostStatus = typeof ListingOfferBoostStatus[keyof typeof ListingOfferBoostStatus];
export type ListingPartialAgent = {
  __typename: 'ListingPartialAgent';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ListingPermalinkInput = {
  shortUrl: Scalars['Boolean'];
  trackingContext: ListingPermalinkTrackingContext;
};

export const ListingPermalinkTrackingContext = {
  APP_SHARE_LINK: 'APP_SHARE_LINK',
  APP_SHARE_QR: 'APP_SHARE_QR',
  GENERIC: 'GENERIC'
} as const;

export type ListingPermalinkTrackingContext = typeof ListingPermalinkTrackingContext[keyof typeof ListingPermalinkTrackingContext];
export type ListingPrepareToBuy = {
  __typename: 'ListingPrepareToBuy';
  enabled: Scalars['Boolean'];
};

export type ListingPriceIndication = {
  __typename: 'ListingPriceIndication';
  formatted: Scalars['String'];
  max?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Int']>;
  type: ListingPriceIndicationType;
};

export type ListingPriceIndicationInput = {
  max?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  min?: InputMaybe<Scalars['Int']>;
  type: ListingPriceIndicationType;
};

export const ListingPriceIndicationType = {
  custom: 'custom',
  min: 'min',
  range: 'range'
} as const;

export type ListingPriceIndicationType = typeof ListingPriceIndicationType[keyof typeof ListingPriceIndicationType];
export type ListingProvider = {
  __typename: 'ListingProvider';
  /** The id of the provider */
  id: Scalars['ID'];
  /** The name of the provider formatted for display */
  name: Scalars['String'];
};

export type ListingQueryConnection = {
  __typename: 'ListingQueryConnection';
  edges: Array<ListingQueryEdge>;
  pageInfo: PageInfo;
};

export type ListingQueryEdge = {
  __typename: 'ListingQueryEdge';
  cursor: Scalars['String'];
  node: Listing;
};

export type ListingRegisteredBuyers = {
  __typename: 'ListingRegisteredBuyers';
  buyers: Array<Buyer>;
  total: Scalars['Int'];
};

export type ListingSource = {
  __typename: 'ListingSource';
  app?: Maybe<DeveloperApp>;
  appId: Scalars['String'];
  foreignId: Scalars['String'];
};

export type ListingSourceInput = {
  appId: Scalars['String'];
  foreignId: Scalars['String'];
};

export const ListingStatus = {
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  ENABLED: 'ENABLED',
  PAUSED: 'PAUSED',
  SOLD: 'SOLD',
  UNDER_OFFER: 'UNDER_OFFER'
} as const;

export type ListingStatus = typeof ListingStatus[keyof typeof ListingStatus];
export type ListingStatusUpdatedEvent = IListingEvent & {
  __typename: 'ListingStatusUpdatedEvent';
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  status: ListingStatus;
};

export const ListingType = {
  demo: 'demo',
  native: 'native',
  referred: 'referred',
  test: 'test'
} as const;

export type ListingType = typeof ListingType[keyof typeof ListingType];
export type ListingUpdateAcceptConditionalOffersTacticEvent = IListingEvent & {
  __typename: 'ListingUpdateAcceptConditionalOffersTacticEvent';
  acceptConditionalOffers: ListingEventAcceptConditionalOffers;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
};

export type ListingsQueryInput = {
  appId?: InputMaybe<Scalars['String']>;
  foreignId?: InputMaybe<Scalars['String']>;
  /** List all orphaned listings (ie. those without an agency) */
  orphaned?: InputMaybe<Scalars['Boolean']>;
};

export type ListingsQueryResult = {
  __typename: 'ListingsQueryResult';
  listings: Array<Listing>;
};

export type LiteOffer = {
  __typename: 'LiteOffer';
  amount?: Maybe<Scalars['String']>;
  buyer: LiteOfferBuyer;
  claimed: Scalars['Boolean'];
  completed: Scalars['Boolean'];
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  listing: Listing;
  offer?: Maybe<Offer>;
};

export type LiteOfferBuyer = Buyer | LiteOfferBuyerContact;

export type LiteOfferBuyerContact = {
  __typename: 'LiteOfferBuyerContact';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type LiteOfferBuyerContactInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type LiteOfferFilters = {
  claimed?: InputMaybe<Scalars['Boolean']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  unclaimed?: InputMaybe<Scalars['Boolean']>;
};

/** Lock offer boost (admin use) */
export type LockOfferBoostInput = {
  listingId: Scalars['String'];
};

export type LockOfferBoostResult = {
  __typename: 'LockOfferBoostResult';
  listing: Listing;
};

export type LogoAsset = IFileResource & {
  __typename: 'LogoAsset';
  checksum?: Maybe<Checksum>;
  contentType: Scalars['String'];
  filename: Scalars['String'];
  height: Scalars['Int'];
  location?: Maybe<CloudStorageLocation>;
  logoType: LogoType;
  size?: Maybe<Scalars['Int']>;
  uploaded: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type LogoAssetGroup = {
  __typename: 'LogoAssetGroup';
  black?: Maybe<LogoAssetTypes>;
  color?: Maybe<LogoAssetTypes>;
  white?: Maybe<LogoAssetTypes>;
};

export type LogoAssetTypes = {
  __typename: 'LogoAssetTypes';
  original: LogoAsset;
  thumb: LogoAsset;
};

export type LogoBranding = {
  __typename: 'LogoBranding';
  altText?: Maybe<Scalars['String']>;
  logos?: Maybe<LogoAssetGroup>;
  url?: Maybe<Scalars['String']>;
};

export const LogoType = {
  black: 'black',
  color: 'color',
  white: 'white'
} as const;

export type LogoType = typeof LogoType[keyof typeof LogoType];
export type ModifyPolicyParentInput = {
  childId: Scalars['String'];
  parentId: Scalars['String'];
};

export type MoveAgent = {
  __typename: 'MoveAgent';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MoveOffice = {
  __typename: 'MoveOffice';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  ADMIN_registerNewAgent: AdminRegisterNewAgentResult;
  addDocumentToListing: Listing;
  addMemberToBillingGroup: AddMemberToBillingGroupResult;
  /** Add a parent to a policy document that the child will inherit permissions from */
  addParentToPolicy?: Maybe<Policy>;
  addTrustAccountToAgency?: Maybe<AddTrustAccountToAgencyResult>;
  addUserEmail: AddUserEmailResult;
  addUserPhone: AddUserPhoneResult;
  assignAgentToListing: AssignAgentToListingResult;
  cancelBestAndFinalNotice: BestAndFinalNotice;
  changeUserPhone: ChangeUserPhoneResult;
  /** Links a user to an agent */
  claimAgent: RegisterAgentResult;
  claimAgentInvitation: ClaimAgentInvitationResult;
  claimAgentUsername: UpdateAgentResult;
  /** claim a lite offer as a buyer */
  claimLiteOffer: ClaimLiteOfferResult;
  /** Delete a test listing and all associated offers based off listing ID */
  cleanupListing: Scalars['Boolean'];
  clearAgentPhone: ClearAgentPhoneResult;
  clearAgentProfileImage: UpdateAgentResult;
  connectAgentToAgency: ConnectAgentToAgencyResult;
  connectDeveloperAppToAgency: ConnectDeveloperAppToAgencyResult;
  consolidateBillingGroupInvoices: ConsolidateBillingGroupInvoicesResult;
  createAgency: CreateAgencyResult;
  /** Creates an agent on the backend without inviting them */
  createAgent: CreateAgentResult;
  createAgentBoxCRMIntegration: CreateAgentBoxCrmIntegrationResult;
  createAgentChatwootSignInLink: CreateAgentChatwootSignInLinkResult;
  createAgentToken: CreateAgentTokenResult;
  createBestAndFinalNotice: BestAndFinalNotice;
  createBillingGroup: CreateBillingGroupResult;
  createBuyer: CreateBuyerResult;
  createCustomNotice: CustomNotice;
  createDeveloperApp: DeveloperAppResult;
  createEagleCRMIntegration: CreateEagleCrmIntegrationResult;
  createListing: CreateListingInputResult;
  /** create an invitation for a buyer to complete an offer */
  createLiteOffer: CreateLiteOfferResult;
  createMoveCRMIntegration: CreateMoveCrmIntegrationResult;
  createREXCRMIntegration: CreateRexcrmIntegrationResult;
  createRtaIntegration: CreateRtaIntegrationResult;
  createSuperadmin: CreateSuperadminResult;
  createUserForAgent: CreateUserForAgentResult;
  createVaultreCRMIntegration: CreateVaultreCrmIntegrationResult;
  createWebhook: WebhookResult;
  createZenuCRMIntegration: CreateZenuCrmIntegrationResult;
  /** delete a lite offer */
  deleteLiteOffer: DeleteLiteOfferResult;
  deleteRtaIntegration: DeleteRtaIntegrationResult;
  disableWebhook: Scalars['Boolean'];
  disconnectAgentFromAgency: DisconnectAgentFromAgencyResult;
  /** Dispatch an email to agents on listing for new buyer registration */
  dispatchListingBuyerRegistration: Scalars['Boolean'];
  dispatchListingCampaignAssetNotificationsForAgency: DispatchListingCampaignAssetNotificationsForAgencyResult;
  enableAgencyWideOfferBoost: EnableAgencyWideOfferBoostResponse;
  forwardOfferToVendor: ForwardOfferToVendorResult;
  generateBillingGroupInvoiceReport: GenerateBillingGroupInvoiceReportResult;
  generateBillingGroupStripeInvoice: GenerateBillingGroupStripeInvoiceResult;
  generateCampaignAssets: GenerateCampaignAssetsResult;
  honkFeature: AgentHonkFeatureResult;
  impersonateAgent: ImpersonateAgentResult;
  importListingsCSV: ImportListingsCsvResult;
  issueAgentInvitation: IssueAgentInvitationResult;
  lockOfferBoost: LockOfferBoostResult;
  offerFeedback: OfferFeedbackResult;
  provisionAgentCards?: Maybe<ProvisionAgentCardResult>;
  provisionDeveloperAppApiKey: ProvisionDeveloperAppApiKeyResult;
  redeliverCRMPushWebhookDeadLetters: RedeliverCrmPushWebhookDeadLettersResult;
  /** Creates an agent for use via a registration flow on the frontend */
  registerAgent: RegisterAgentResult;
  /** Creates a new agent and sends a lead */
  registerNewAgent: RegisterNewAgentResult;
  removeAgentFromListing: RemoveAgentFromListingResult;
  /** Remove a parent from a policy document to no longer inherit permissions */
  removeParentFromPolicy?: Maybe<Policy>;
  removeUserEmail: RemoveUserEmailResult;
  removeUserPhone: RemoveUserPhoneResult;
  requestAgentInvitation: RequestAgentInvitationResult;
  resolveReferredListing: CreateListingInputResult;
  revokeUserRole: RevokeUserRoleResult;
  root?: Maybe<Scalars['String']>;
  runCRMPullOperation: RunCrmPullOperationResult;
  saveDraftOffer: SaveDraftOfferResult;
  saveVendorLead: VendorLeadResult;
  sendAgentInvitation: SendAgentInvitationResult;
  /** Send a notification to buyers regarding a listing */
  sendAnnouncement: Announcement;
  sendOfferAcknowledgement: SendOfferAcknowledgementResult;
  sendOfferDetailsToRta: SendOfferDetailsToRtaResult;
  sendProductUpgradeRequest: SendProductUpgradeRequestResult;
  setAgencyAgentClaimEmailTemplate: Agency;
  setAgentProfile: SetAgentProfileResult;
  setAgentRole: SetAgentRoleResult;
  settleBillingGroupInvoices: SettleBillingGroupInvoicesResult;
  startAgentOnboarding: StartAgentOnboardingResult;
  startPhoneVerification: StartPhoneVerificationResult;
  storeTemporaryReferrerListingData: StoreTemporaryReferrerListingData;
  submitOffer: SubmitOfferResult;
  submitVendorLead: VendorLeadResult;
  /** Allows offers to be submitted to listings which are under offer. */
  toggleAgencyAcceptOffersWhileUnderOffer: ToggleAgencyAcceptOffersWhileUnderOfferResult;
  toggleAgencyBranding: UpdateAgencyResult;
  toggleAgencyListingResolution: Agency;
  toggleAgencyNotificationsEnabled: ToggleAgencyNotificationsEnabledResult;
  toggleAgencyShouldSendClaimEmail: Agency;
  toggleAgencyWideOfferBoost: ToggleAgencyWideOfferBoostResponse;
  toggleAgentOnboarding: ToggleAgentOnboardingResult;
  toggleCRMIntegrationEnabled: ToggleCrmIntegrationEnabledResult;
  toggleConveyancingReferrals: Agency;
  toggleDeveloperAppBranding: DeveloperAppResult;
  toggleListingViewDocuments: Listing;
  unlinkAgentUser: UnlinkAgentUserResult;
  /** Unlink a buyer from a user */
  unlinkBuyer: Scalars['Boolean'];
  unlockOfferBoost: UnlockOfferBoostResponse;
  updateAgency: UpdateAgencyResult;
  /** Update the policy associated with the agency which grants permissions */
  updateAgencyAssignedFeatures: Agency;
  updateAgencyBillingAccount: UpdateAgencyBillingAccountResult;
  updateAgencyBrandingSettings: UpdateAgencyResult;
  updateAgencyOfferBoostAutomation: UpdateAgencyOfferBoostAutomationResponse;
  updateAgencyOfferBoostSettings: UpdateAgencyOfferBoostSettingsResponse;
  updateAgent: UpdateAgentResult;
  updateAgentNotificationPreferences: UpdateAgentNotificationPreferencesResult;
  updateAppAgentOnboarding: UpdateAppAgentOnboardingResult;
  updateBuyerNotificationPreferences: UpdateBuyerNotificationPreferencesResult;
  updateConsolidatedInvoiceStatus: UpdateConsolidatedInvoiceStatusResult;
  updateCrmIntegrationAgentFilter: UpdateCrmIntegrationAgentFilterResult;
  updateCrmIntegrationListingUrlTemplate: UpdateCrmIntegrationListingUrlTemplateResult;
  updateCrmIntegrationPullAuctionListings: UpdateCrmIntegrationPullAuctionListingsResult;
  updateCrmIntegrationPushEnabled: UpdateCrmIntegrationPushEnabledResult;
  updateDeveloperAppBrandingSettings: DeveloperAppResult;
  updateDeveloperAppDomainAllowList: DeveloperAppResult;
  updateDeveloperAppName: DeveloperAppResult;
  updateDeveloperAppResolutionMode: UpdateDeveloperAppResolutionModeResult;
  /** @deprecated Create and use granular update methods instead. */
  updateListing: UpdateListingInputResult;
  updateListingAcceptConditionalOffersSettings: Listing;
  /** @deprecated Removed */
  updateListingContract: UpdateListingResult;
  updateListingCountdownTimerSettings: Listing;
  updateListingDepositRequiredSettings: Listing;
  updateListingDocumentVisibility: Listing;
  updateListingExposeCompetitionSettings: Listing;
  updateListingHoldingDepositSettings?: Maybe<UpdateListingHoldingDepositSettingsResult>;
  updateListingNewOfferNotificationsSettings: Listing;
  updateListingOfferBoost: Listing;
  updateListingPrepareToBuySettings: Listing;
  updateListingStatus: UpdateListingStatusResult;
  updateListingVendor: UpdateListingResult;
  updateOfferBoostAgentList: UpdateOfferBoostAgentListResponse;
  updateOfferHoldingDepositStatus: UpdateOfferHoldingDepositStatusResult;
  updateOffersMailingList: UpdateOffersMailingListResult;
  /** Add or remove permissions from a Policy document where the state of undefined permissions are untouched */
  updatePolicyPermissions?: Maybe<Policy>;
  updateQRCodeMailingList: UpdateQrCodeMailingListResult;
  updateUserProfile?: Maybe<User>;
  updateVendorLeadConfig: UpdateVendorLeadConfigResult;
  updateWebhookSources: WebhookResult;
  uploadAgentProfileImage: UpdateAgentResult;
  uploadAssetToAgency: Agency;
  uploadAssetToDeveloperApp: DeveloperApp;
  withdrawOffer: WithdrawOfferResult;
};


export type MutationAdmin_RegisterNewAgentArgs = {
  input: AdminRegisterNewAgentInput;
};


export type MutationAddDocumentToListingArgs = {
  input: AddListingDocumentInput;
};


export type MutationAddMemberToBillingGroupArgs = {
  input: AddMemberToBillingGroupInput;
};


export type MutationAddParentToPolicyArgs = {
  input: ModifyPolicyParentInput;
};


export type MutationAddTrustAccountToAgencyArgs = {
  input: AddTrustAccountToAgencyInput;
};


export type MutationAddUserEmailArgs = {
  input: AddUserEmailInput;
};


export type MutationAddUserPhoneArgs = {
  input: AddUserPhoneInput;
};


export type MutationAssignAgentToListingArgs = {
  input: AssignAgentToListingInput;
};


export type MutationCancelBestAndFinalNoticeArgs = {
  input: CancelBestAndFinalNoticeInput;
};


export type MutationChangeUserPhoneArgs = {
  input: ChangeUserPhoneInput;
};


export type MutationClaimAgentArgs = {
  input: ClaimAgentInput;
};


export type MutationClaimAgentInvitationArgs = {
  input: ClaimAgentInvitationInput;
};


export type MutationClaimAgentUsernameArgs = {
  input: ClaimAgentUsernameInput;
};


export type MutationClaimLiteOfferArgs = {
  input: ClaimLiteOfferInput;
};


export type MutationCleanupListingArgs = {
  id: Scalars['String'];
};


export type MutationClearAgentPhoneArgs = {
  input: ClearAgentPhoneInput;
};


export type MutationClearAgentProfileImageArgs = {
  input: ClearAgentProfileImageInput;
};


export type MutationConnectAgentToAgencyArgs = {
  input: ConnectAgentToAgencyInput;
};


export type MutationConnectDeveloperAppToAgencyArgs = {
  input: ConnectDeveloperAppToAgencyInput;
};


export type MutationConsolidateBillingGroupInvoicesArgs = {
  input: ConsolidateBillingGroupInvoicesInput;
};


export type MutationCreateAgencyArgs = {
  input: CreateAgencyInput;
};


export type MutationCreateAgentArgs = {
  input: CreateAgentInput;
};


export type MutationCreateAgentBoxCrmIntegrationArgs = {
  input: CreateAgentBoxCrmIntegrationInput;
};


export type MutationCreateAgentChatwootSignInLinkArgs = {
  input: CreateAgentChatwootSignInLinkInput;
};


export type MutationCreateAgentTokenArgs = {
  input: CreateAgentTokenInput;
};


export type MutationCreateBestAndFinalNoticeArgs = {
  input: CreateBestAndFinalNoticeInput;
};


export type MutationCreateBillingGroupArgs = {
  input: CreateBillingGroupInput;
};


export type MutationCreateBuyerArgs = {
  input: CreateBuyerInput;
};


export type MutationCreateCustomNoticeArgs = {
  input: CreateCustomNoticeInput;
};


export type MutationCreateDeveloperAppArgs = {
  input: CreateDeveloperAppInput;
};


export type MutationCreateEagleCrmIntegrationArgs = {
  input: CreateEagleCrmIntegrationInput;
};


export type MutationCreateListingArgs = {
  input: CreateListingInput;
};


export type MutationCreateLiteOfferArgs = {
  input: CreateLiteOfferInput;
};


export type MutationCreateMoveCrmIntegrationArgs = {
  input: CreateMoveCrmIntegrationInput;
};


export type MutationCreateRexcrmIntegrationArgs = {
  input: CreateRexcrmIntegrationInput;
};


export type MutationCreateRtaIntegrationArgs = {
  input: CreateRtaIntegrationInput;
};


export type MutationCreateSuperadminArgs = {
  input: CreateSuperadminInput;
};


export type MutationCreateUserForAgentArgs = {
  agentId: Scalars['ID'];
};


export type MutationCreateVaultreCrmIntegrationArgs = {
  input: CreateVaultreCrmIntegrationInput;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationCreateZenuCrmIntegrationArgs = {
  input: CreateZenuCrmIntegrationInput;
};


export type MutationDeleteLiteOfferArgs = {
  input: DeleteLiteOfferInput;
};


export type MutationDeleteRtaIntegrationArgs = {
  input: DeleteRtaIntegrationInput;
};


export type MutationDisableWebhookArgs = {
  input: DisableWebhookInput;
};


export type MutationDisconnectAgentFromAgencyArgs = {
  input: DisconnectAgentFromAgencyInput;
};


export type MutationDispatchListingBuyerRegistrationArgs = {
  input: DispatchListingBuyerRegistrationInput;
};


export type MutationDispatchListingCampaignAssetNotificationsForAgencyArgs = {
  input: DispatchListingCampaignAssetNotificationsForAgencyInput;
};


export type MutationEnableAgencyWideOfferBoostArgs = {
  input: EnableAgencyWideOfferBoostInput;
};


export type MutationForwardOfferToVendorArgs = {
  input: ForwardOfferToVendorInput;
};


export type MutationGenerateBillingGroupInvoiceReportArgs = {
  input: GenerateBillingGroupInvoiceReportInput;
};


export type MutationGenerateBillingGroupStripeInvoiceArgs = {
  input: GenerateBillingGroupStripeInvoiceInput;
};


export type MutationGenerateCampaignAssetsArgs = {
  input: Scalars['String'];
};


export type MutationHonkFeatureArgs = {
  input: AgentHonkFeatureInput;
};


export type MutationImpersonateAgentArgs = {
  input: ImpersonateAgentInput;
};


export type MutationImportListingsCsvArgs = {
  input: ImportListingsCsvInput;
};


export type MutationIssueAgentInvitationArgs = {
  agentId: Scalars['ID'];
};


export type MutationLockOfferBoostArgs = {
  input: LockOfferBoostInput;
};


export type MutationOfferFeedbackArgs = {
  input: OfferFeedbackInput;
};


export type MutationProvisionAgentCardsArgs = {
  input: Array<ProvisionAgentCardInput>;
};


export type MutationProvisionDeveloperAppApiKeyArgs = {
  input: ProvisionDeveloperAppApiKeyInput;
};


export type MutationRegisterAgentArgs = {
  input: RegisterAgentInput;
};


export type MutationRegisterNewAgentArgs = {
  input: RegisterNewAgentInput;
};


export type MutationRemoveAgentFromListingArgs = {
  input: RemoveAgentFromListingInput;
};


export type MutationRemoveParentFromPolicyArgs = {
  input: ModifyPolicyParentInput;
};


export type MutationRemoveUserEmailArgs = {
  input: RemoveUserEmailInput;
};


export type MutationRemoveUserPhoneArgs = {
  input: RemoveUserPhoneInput;
};


export type MutationRequestAgentInvitationArgs = {
  claimKey: Scalars['String'];
};


export type MutationResolveReferredListingArgs = {
  input: ResolveReferredListingInput;
};


export type MutationRevokeUserRoleArgs = {
  input: RevokeUserRoleInput;
};


export type MutationRunCrmPullOperationArgs = {
  input: RunCrmPullOperationInput;
};


export type MutationSaveDraftOfferArgs = {
  input: SaveDraftOfferInput;
};


export type MutationSaveVendorLeadArgs = {
  input: SaveVendorLeadInput;
};


export type MutationSendAgentInvitationArgs = {
  agentId: Scalars['ID'];
};


export type MutationSendAnnouncementArgs = {
  input: SendAnnouncementInput;
};


export type MutationSendOfferAcknowledgementArgs = {
  input: SendOfferAcknowledgementInput;
};


export type MutationSendOfferDetailsToRtaArgs = {
  input: SendOfferDetailsToRtaInput;
};


export type MutationSendProductUpgradeRequestArgs = {
  input: SendProductUpgradeRequestInput;
};


export type MutationSetAgencyAgentClaimEmailTemplateArgs = {
  input: SetAgencyAgentClaimEmailTemplateInput;
};


export type MutationSetAgentProfileArgs = {
  input: SetAgentProfileInput;
};


export type MutationSetAgentRoleArgs = {
  input: SetAgentRoleInput;
};


export type MutationSettleBillingGroupInvoicesArgs = {
  input: SettleBillingGroupInvoicesInput;
};


export type MutationStartPhoneVerificationArgs = {
  input: StartPhoneVerificationInput;
};


export type MutationStoreTemporaryReferrerListingDataArgs = {
  input: StoreTemporaryReferrerListingDataInput;
};


export type MutationSubmitOfferArgs = {
  input: SubmitOfferInput;
};


export type MutationSubmitVendorLeadArgs = {
  input: SaveVendorLeadInput;
};


export type MutationToggleAgencyAcceptOffersWhileUnderOfferArgs = {
  input: ToggleAgencyAcceptOffersWhileUnderOfferInput;
};


export type MutationToggleAgencyBrandingArgs = {
  input: AgencyBrandingToggleInput;
};


export type MutationToggleAgencyListingResolutionArgs = {
  input: ToggleAgencyListingResolutionInput;
};


export type MutationToggleAgencyNotificationsEnabledArgs = {
  input: ToggleAgencyNotificationsEnabledInput;
};


export type MutationToggleAgencyShouldSendClaimEmailArgs = {
  input: ToggleAgencyShouldSendClaimEmailInput;
};


export type MutationToggleAgencyWideOfferBoostArgs = {
  input: ToggleAgencyWideOfferBoostInput;
};


export type MutationToggleCrmIntegrationEnabledArgs = {
  input: ToggleCrmIntegrationEnabledInput;
};


export type MutationToggleConveyancingReferralsArgs = {
  input: ToggleConveyancingReferralsInput;
};


export type MutationToggleDeveloperAppBrandingArgs = {
  input: DeveloperAppBrandingToggleInput;
};


export type MutationToggleListingViewDocumentsArgs = {
  input: ToggleListingViewDocumentsInput;
};


export type MutationUnlinkAgentUserArgs = {
  input: UnlinkAgentUserInput;
};


export type MutationUnlinkBuyerArgs = {
  input?: InputMaybe<UnlinkBuyerInput>;
};


export type MutationUnlockOfferBoostArgs = {
  input: UnlockOfferBoostInput;
};


export type MutationUpdateAgencyArgs = {
  input: UpdateAgencyInput;
};


export type MutationUpdateAgencyAssignedFeaturesArgs = {
  input: UpdateAgencyAssignedFeaturesInput;
};


export type MutationUpdateAgencyBillingAccountArgs = {
  input: UpdateAgencyBillingAccountInput;
};


export type MutationUpdateAgencyBrandingSettingsArgs = {
  input: AgencyUpdateBrandingSettingsInput;
};


export type MutationUpdateAgencyOfferBoostAutomationArgs = {
  input: UpdateAgencyOfferBoostAutomationInput;
};


export type MutationUpdateAgencyOfferBoostSettingsArgs = {
  input: UpdateAgencyOfferBoostSettingsInput;
};


export type MutationUpdateAgentArgs = {
  input: UpdateAgentInput;
};


export type MutationUpdateAgentNotificationPreferencesArgs = {
  input: UpdateAgentNotificationPreferencesInput;
};


export type MutationUpdateAppAgentOnboardingArgs = {
  input: UpdateAppAgentOnboardingInput;
};


export type MutationUpdateBuyerNotificationPreferencesArgs = {
  input: UpdateBuyerNotificationPreferencesInput;
};


export type MutationUpdateConsolidatedInvoiceStatusArgs = {
  input: UpdateConsolidatedInvoiceStatusInput;
};


export type MutationUpdateCrmIntegrationAgentFilterArgs = {
  input: UpdateCrmIntegrationAgentFilterInput;
};


export type MutationUpdateCrmIntegrationListingUrlTemplateArgs = {
  input: UpdateCrmIntegrationListingUrlTemplateInput;
};


export type MutationUpdateCrmIntegrationPullAuctionListingsArgs = {
  input: UpdateCrmIntegrationPullAuctionListingsInput;
};


export type MutationUpdateCrmIntegrationPushEnabledArgs = {
  input: UpdateCrmIntegrationPushEnabledInput;
};


export type MutationUpdateDeveloperAppBrandingSettingsArgs = {
  input: DeveloperAppUpdateBrandingSettingsInput;
};


export type MutationUpdateDeveloperAppDomainAllowListArgs = {
  input: UpdateDeveloperAppDomainAllowListInput;
};


export type MutationUpdateDeveloperAppNameArgs = {
  input: UpdateDeveloperAppNameInput;
};


export type MutationUpdateDeveloperAppResolutionModeArgs = {
  id: Scalars['ID'];
  resolutionMode: DeveloperAppResolutionMode;
};


export type MutationUpdateListingArgs = {
  input: UpdateListingInput;
};


export type MutationUpdateListingAcceptConditionalOffersSettingsArgs = {
  input: UpdateListingAcceptConditionalOffersSettingsInput;
};


export type MutationUpdateListingContractArgs = {
  input: UpdateListingContractInput;
};


export type MutationUpdateListingCountdownTimerSettingsArgs = {
  input: UpdateCountdownTimerSettingsInput;
};


export type MutationUpdateListingDepositRequiredSettingsArgs = {
  input: UpdateListingDepositRequiredSettingsInput;
};


export type MutationUpdateListingDocumentVisibilityArgs = {
  input: UpdateListingDocumentVisibilityInput;
};


export type MutationUpdateListingExposeCompetitionSettingsArgs = {
  input: UpdateListingExposeCompetitionSettingsInput;
};


export type MutationUpdateListingHoldingDepositSettingsArgs = {
  input: UpdateListingHoldingDepositSettingsInput;
};


export type MutationUpdateListingNewOfferNotificationsSettingsArgs = {
  input: UpdateListingNewOfferNotificationsSettingsInput;
};


export type MutationUpdateListingOfferBoostArgs = {
  input: UpdateListingOfferBoostInput;
};


export type MutationUpdateListingPrepareToBuySettingsArgs = {
  input: UpdateListingPrepareToBuySettingsInput;
};


export type MutationUpdateListingStatusArgs = {
  input: UpdateListingStatusInput;
};


export type MutationUpdateListingVendorArgs = {
  input: UpdateListingVendorInput;
};


export type MutationUpdateOfferBoostAgentListArgs = {
  input: UpdateOfferBoostAgentListInput;
};


export type MutationUpdateOfferHoldingDepositStatusArgs = {
  input: UpdateOfferHoldingDepositStatusInput;
};


export type MutationUpdateOffersMailingListArgs = {
  input: UpdateOffersMailingListInput;
};


export type MutationUpdatePolicyPermissionsArgs = {
  input: UpdatePolicyPermissionsInput;
};


export type MutationUpdateQrCodeMailingListArgs = {
  input: UpdateQrCodeMailingListInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateVendorLeadConfigArgs = {
  input: UpdateVendorLeadConfigInput;
};


export type MutationUpdateWebhookSourcesArgs = {
  input: UpdateWebhookSourcesInput;
};


export type MutationUploadAgentProfileImageArgs = {
  input: UploadAgentProfileImageInput;
};


export type MutationUploadAssetToAgencyArgs = {
  input: UploadAssetInput;
};


export type MutationUploadAssetToDeveloperAppArgs = {
  input: UploadAssetInput;
};


export type MutationWithdrawOfferArgs = {
  input: WithdrawOfferInput;
};

export type Node = {
  id: Scalars['String'];
};

export const NominalOfferAgreementType = {
  digital_signature_consent: 'digital_signature_consent',
  identity_verification: 'identity_verification'
} as const;

export type NominalOfferAgreementType = typeof NominalOfferAgreementType[keyof typeof NominalOfferAgreementType];
export type Notice = BestAndFinalNotice | CustomNotice;

export type NotificationPreferences = AgentNotificationPreferences | BuyerNotificationPreferences;

export type Offer = {
  __typename: 'Offer';
  /** Indicates that additional buyers may be involved */
  addTitleHoldersLater: Scalars['Boolean'];
  /** For offers on referred listings - Information about the agents the offer should be sent to */
  agents?: Maybe<Array<OfferAgent>>;
  /** Agreements including terms, digital signature consent, contract acknowledgement. */
  agreements: Array<OfferAgreement>;
  /** Offer amount in AUD (whole dollars) */
  amount: Scalars['Int'];
  /** Attachments */
  attachments: Array<OfferAttachment>;
  /** Agent attributed to the offer */
  attributedAgent?: Maybe<Agent>;
  /** List of buyers associated. */
  buyers: Array<OfferConnectedBuyer>;
  /** List of conditions attached to the offer. */
  conditions: Array<AttachedOfferCondition>;
  /** Contract */
  contract?: Maybe<OfferAttachment>;
  /** Conveyancer information */
  conveyancer?: Maybe<OfferConveyancerInformation>;
  /** Date and time this offer was created */
  createdAt: Scalars['ISODateString'];
  /** Purchase deposit amount */
  depositAmount?: Maybe<Scalars['Int']>;
  /** Purchase deposit selection */
  depositPercentage?: Maybe<DepositPercentage>;
  /** Date this offer expires (optional) */
  expiresAt?: Maybe<Scalars['ISODateString']>;
  /** Finance declaration */
  finance?: Maybe<OfferFinanceDeclaration>;
  financeMessage: Scalars['String'];
  holdingDeposit?: Maybe<HoldingDeposit>;
  id: Scalars['ID'];
  /** Identity verification status */
  identityVerification: Array<OfferBuyerVerification>;
  isAcknowledged: Scalars['Boolean'];
  /** If the offer is legally binding */
  isBinding: Scalars['Boolean'];
  /** If the offer has any optional conditions or expiry */
  isConditional: Scalars['Boolean'];
  /** Expiry date has passed or false if no expiry */
  isExpired: Scalars['Boolean'];
  isForwardedToVendor: Scalars['Boolean'];
  /** The latest offer for this listing by this buyer */
  latest: Offer;
  /** Listing that this offer is for */
  listing: Listing;
  /** The first offer in this series of offers */
  original: Offer;
  /** For offers on referred listings - Information about the property the offer is for */
  property?: Maybe<Property>;
  /** Receipt */
  receipt?: Maybe<OfferAttachment>;
  /** All other offers by this buyer on this listing */
  related: Array<Offer>;
  rtaOfferStatus?: Maybe<RtaOfferStatus>;
  /** Elected settlement period. */
  settlement: OfferSettlementPeriod;
  /** Elected settlement period as a formatted message */
  settlementMessage: Scalars['String'];
  /** Parties listed on the purchase */
  signatories: Array<OfferSignatory>;
  /** Signatures */
  signatures?: Maybe<Array<OfferSignature>>;
  /** Status */
  status?: Maybe<OfferStatus>;
  /** Offer that supersedes this offer. */
  supersededBy?: Maybe<Offer>;
  /** Offer that this offer replaces. */
  supersedes?: Maybe<Offer>;
};

export type OfferAcknowledgedEvent = IOfferEvent & {
  __typename: 'OfferAcknowledgedEvent';
  action: SendOfferAcknowledgementAction;
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: Offer;
};

export type OfferAction = AgentOfferPreviewAction | ForwardOfferToVendorAction | SendOfferAcknowledgementAction;

export type OfferAgent = {
  __typename: 'OfferAgent';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type OfferAgentInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type OfferAgreement = OfferAgreementContract | OfferAgreementNominal | OfferAgreementPlatformTerms;

export type OfferAgreementContract = {
  __typename: 'OfferAgreementContract';
  contract: ListingDocument;
};

export type OfferAgreementNominal = {
  __typename: 'OfferAgreementNominal';
  type: NominalOfferAgreementType;
};

export type OfferAgreementPlatformTerms = {
  __typename: 'OfferAgreementPlatformTerms';
  termsRevision: TermsRevision;
};

export type OfferAttachment = {
  __typename: 'OfferAttachment';
  file: FileResource;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: OfferAttachmentType;
};

export const OfferAttachmentType = {
  contract: 'contract',
  receipt: 'receipt'
} as const;

export type OfferAttachmentType = typeof OfferAttachmentType[keyof typeof OfferAttachmentType];
/** Update offerboost agent list */
export type OfferBoostAgentConfig = {
  agentId: Scalars['String'];
  includeAuctionListings: Scalars['Boolean'];
};

export const OfferBoostPriceTier = {
  all: 'all',
  individual: 'individual'
} as const;

export type OfferBoostPriceTier = typeof OfferBoostPriceTier[keyof typeof OfferBoostPriceTier];
export type OfferBuyerVerification = {
  __typename: 'OfferBuyerVerification';
  buyer: Buyer;
  identityDocument: IdentityDocument;
  signatory: OfferSignatory;
  status: BuyerVerificationStatus;
  statusMessage: Scalars['String'];
  submittedAt: Scalars['ISODateString'];
};

export type OfferCondition = {
  __typename: 'OfferCondition';
  contentHTML: Scalars['String'];
  params: Array<OfferConditionParams>;
  revision: OfferConditionsRevision;
};

export type OfferConditionParams = {
  __typename: 'OfferConditionParams';
  name: Scalars['String'];
  value: Scalars['String'];
};

export const OfferConditionRevisionInputType = {
  dollar: 'dollar',
  number: 'number',
  text: 'text'
} as const;

export type OfferConditionRevisionInputType = typeof OfferConditionRevisionInputType[keyof typeof OfferConditionRevisionInputType];
export type OfferConditionRevisionLegalReplacement = {
  __typename: 'OfferConditionRevisionLegalReplacement';
  /** Value to inject into the HTML instead of 'value' */
  legal: Scalars['String'];
  /** Value on select element */
  value: Scalars['String'];
};

export type OfferConditionRevisionOtherParams = {
  __typename: 'OfferConditionRevisionOtherParams';
  /** Optional description for the input field */
  description?: Maybe<Scalars['String']>;
  /** Optional label for the input field */
  label: Scalars['String'];
  /** Variable name in the HTML conditions */
  name: Scalars['String'];
  type: OfferConditionRevisionInputType;
};

export type OfferConditionRevisionParam = {
  __typename: 'OfferConditionRevisionParam';
  /** Whether a user can specify an 'Other' value */
  allowOther: Scalars['Boolean'];
  /** @deprecated Use defaultValue instead. */
  default?: Maybe<Scalars['String']>;
  /** Default value (if any) for the input */
  defaultValue?: Maybe<Scalars['String']>;
  /** Optional description for the input field */
  description?: Maybe<Scalars['String']>;
  /** Label for the input field */
  label: Scalars['String'];
  /** Legal text to replace values in 'contentHTML' */
  legalReplacements: Array<OfferConditionRevisionLegalReplacement>;
  /** Variable name in the HTML conditions */
  name: Scalars['String'];
  /** Input type of the 'Other' field if enabled */
  otherParams?: Maybe<OfferConditionRevisionOtherParams>;
  /** Type of the input field to be rendered on the client */
  type?: Maybe<OfferConditionRevisionParamsType>;
  values?: Maybe<Array<Scalars['String']>>;
};

export const OfferConditionRevisionParamsType = {
  amount: 'amount',
  dateText: 'dateText',
  select: 'select'
} as const;

export type OfferConditionRevisionParamsType = typeof OfferConditionRevisionParamsType[keyof typeof OfferConditionRevisionParamsType];
export type OfferConditionsLine = {
  __typename: 'OfferConditionsLine';
  _id: Scalars['ID'];
  currentRevision: OfferConditionsRevision;
  name: Scalars['String'];
  region: Scalars['String'];
  weight: Scalars['Int'];
};

export type OfferConditionsRevision = {
  __typename: 'OfferConditionsRevision';
  /** @deprecated Use id, lineName, and region as cache key. */
  _id: Scalars['ID'];
  contentHTML: Scalars['String'];
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  line: OfferConditionsLine;
  lineName: Scalars['String'];
  params: Array<OfferConditionRevisionParam>;
  region: Scalars['String'];
  title: Scalars['String'];
  versionName: Scalars['String'];
};

export type OfferConnectedBuyer = {
  __typename: 'OfferConnectedBuyer';
  buyer: Buyer;
  identityVerification: OfferBuyerVerification;
  isPrimaryBuyer: Scalars['Boolean'];
  signatory: OfferSignatory;
};

export type OfferConveyancerInformation = OfferConveyancerInformationFindConveyancerHelp | OfferConveyancerInformationLegalProcessHelp | OfferConveyancerInformationNone | OfferConveyancerInformationProvided;

export type OfferConveyancerInformationFindConveyancerHelp = {
  __typename: 'OfferConveyancerInformationFindConveyancerHelp';
  _?: Maybe<Scalars['Boolean']>;
};

export type OfferConveyancerInformationLegalProcessHelp = {
  __typename: 'OfferConveyancerInformationLegalProcessHelp';
  _?: Maybe<Scalars['Boolean']>;
};

export type OfferConveyancerInformationNone = {
  __typename: 'OfferConveyancerInformationNone';
  _?: Maybe<Scalars['Boolean']>;
};

export type OfferConveyancerInformationProvided = {
  __typename: 'OfferConveyancerInformationProvided';
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type OfferCreatedEvent = IOfferEvent & {
  __typename: 'OfferCreatedEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: Offer;
};

export type OfferEvent = OfferAcknowledgedEvent | OfferCreatedEvent | OfferForwardedEvent | OfferUpdatedEvent | OfferWithdrawnEvent;

export type OfferFeedbackInput = {
  buyerName: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  feeling?: InputMaybe<Scalars['Int']>;
};

export type OfferFeedbackResult = {
  __typename: 'OfferFeedbackResult';
  submitted: Scalars['Boolean'];
};

export type OfferFinanceDeclaration = OfferFinanceDeclarationApproved | OfferFinanceDeclarationNone | OfferFinanceDeclarationNotApplicable | OfferFinanceDeclarationOther;

export type OfferFinanceDeclarationApproved = {
  __typename: 'OfferFinanceDeclarationApproved';
  date: PlainDate;
  institution: Scalars['String'];
};

export type OfferFinanceDeclarationNone = {
  __typename: 'OfferFinanceDeclarationNone';
  note?: Maybe<Scalars['String']>;
};

export type OfferFinanceDeclarationNotApplicable = {
  __typename: 'OfferFinanceDeclarationNotApplicable';
  note?: Maybe<Scalars['String']>;
};

export type OfferFinanceDeclarationOther = {
  __typename: 'OfferFinanceDeclarationOther';
  note?: Maybe<Scalars['String']>;
};

export type OfferForwardedEvent = IOfferEvent & {
  __typename: 'OfferForwardedEvent';
  action: ForwardOfferToVendorAction;
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: Offer;
};

export type OfferSettlementInput = {
  date?: InputMaybe<Scalars['PlainDateString']>;
  days?: InputMaybe<Scalars['Int']>;
  type: OfferSettlementPeriodType;
};

export type OfferSettlementPeriod = OfferSettlementPeriodDate | OfferSettlementPeriodDays | OfferSettlementPeriodDefault;

export type OfferSettlementPeriodDate = {
  __typename: 'OfferSettlementPeriodDate';
  date: PlainDate;
};

export type OfferSettlementPeriodDays = {
  __typename: 'OfferSettlementPeriodDays';
  days: Scalars['Int'];
};

export type OfferSettlementPeriodDefault = {
  __typename: 'OfferSettlementPeriodDefault';
  _?: Maybe<Scalars['Boolean']>;
};

export const OfferSettlementPeriodType = {
  date: 'date',
  days: 'days',
  default: 'default'
} as const;

export type OfferSettlementPeriodType = typeof OfferSettlementPeriodType[keyof typeof OfferSettlementPeriodType];
export type OfferSignatory = {
  __typename: 'OfferSignatory';
  /** Address in single line format */
  address: Scalars['String'];
  alias: Scalars['String'];
  /** Buyer account this signatory represents */
  buyer: Buyer;
  dateOfBirth: PlainDate;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  /** Identity document */
  identityDocument: IdentityDocument;
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  name: Scalars['String'];
  onBehalfOf: RepresentedEntity;
  /** `onBehalfOf` field formatted as a string for display */
  onBehalfOfMessage: Scalars['String'];
  phone: Scalars['String'];
  signature?: Maybe<OfferSignature>;
};

export type OfferSignatoryInput = {
  address: Scalars['String'];
  agreeToIdentityVerification: Scalars['Boolean'];
  agreedContractRevision?: InputMaybe<DocumentRevisionInput>;
  agreedPrivacyPolicyRevision: TermsRevisionInput;
  agreedTermsRevision: TermsRevisionInput;
  buyerId: Scalars['ID'];
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocument: IdentityDocumentInput;
  isPrimaryBuyer: Scalars['Boolean'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  onBehalfOf: RepresentedEntityInput;
  phone: Scalars['String'];
  /** Base64 encoded png of signature image */
  signatureData?: InputMaybe<Scalars['String']>;
};

export type OfferSignature = {
  __typename: 'OfferSignature';
  date: Scalars['ISODateString'];
  file: FileResource;
  signatory: OfferSignatory;
};

export const OfferStatus = {
  active: 'active',
  expired: 'expired',
  withdrawn: 'withdrawn'
} as const;

export type OfferStatus = typeof OfferStatus[keyof typeof OfferStatus];
export const OfferType = {
  binding: 'binding',
  nonbinding: 'nonbinding'
} as const;

export type OfferType = typeof OfferType[keyof typeof OfferType];
export type OfferUpdatedEvent = IOfferEvent & {
  __typename: 'OfferUpdatedEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: Offer;
};

export type OfferWithdrawnEvent = IOfferEvent & {
  __typename: 'OfferWithdrawnEvent';
  buyer: Buyer;
  createdAt: Scalars['ISODateString'];
  eventId: Scalars['ID'];
  offer: Offer;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type PaginatedListingQueryFilters = {
  agencyId?: InputMaybe<Scalars['String']>;
  /** Filter by an array of listing statuses. If omitted, all listings will be returned. */
  statuses?: InputMaybe<Array<ListingStatus>>;
};

export type PartnerReferral = {
  __typename: 'PartnerReferral';
  enabled: Scalars['Boolean'];
};

export type PartnerReferrals = {
  __typename: 'PartnerReferrals';
  conveyancing?: Maybe<PartnerReferral>;
};

export type PermissionState = {
  __typename: 'PermissionState';
  /** @deprecated Use `granted` field */
  enabled: Scalars['Boolean'];
  granted: Scalars['Boolean'];
  key: Scalars['ID'];
};

export type PermissionStateInput = {
  granted: Scalars['Boolean'];
  key: Scalars['String'];
};

export type PlainDate = {
  __typename: 'PlainDate';
  day: Scalars['Int'];
  formatted: Scalars['String'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

/** Policy documents provide permissions to entities on Propps to access features */
export type Policy = {
  __typename: 'Policy';
  id: Scalars['ID'];
  /** Information about how the permissions were inherited by parent policies */
  inheritance: Array<PolicyInheritance>;
  /** Policies which this policy inherits from */
  inheritedPolicies: Array<Policy>;
  permissions: Array<Scalars['String']>;
};

export type PolicyInheritance = {
  __typename: 'PolicyInheritance';
  /** Permission which the information refers to */
  permission: Scalars['String'];
  /** Policy ID which the permission was inherited from */
  policyId: Scalars['String'];
  /** Trace array which shows the traversal of policy IDs to the given Policy ID */
  trace: Array<Scalars['String']>;
};

export type ProfileAgency = {
  __typename: 'ProfileAgency';
  location?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type ProfileImage = IFileResource & {
  __typename: 'ProfileImage';
  checksum?: Maybe<Checksum>;
  contentType: Scalars['String'];
  filename: Scalars['String'];
  height: Scalars['Int'];
  location?: Maybe<CloudStorageLocation>;
  size?: Maybe<Scalars['Int']>;
  uploaded: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type ProfileLink = {
  __typename: 'ProfileLink';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ProfileSocialLink = {
  __typename: 'ProfileSocialLink';
  socialNetwork: SocialNetwork;
  url: Scalars['String'];
};

export type Property = {
  __typename: 'Property';
  address: Address;
};

export type PropertyInput = {
  address: AddressInput;
};

export type ProppsProduct = {
  __typename: 'ProppsProduct';
  id: Scalars['ID'];
  name: ProppsProductName;
  policy?: Maybe<Policy>;
};

export const ProppsProductName = {
  AGENT_PLUS: 'AGENT_PLUS',
  OFFERS_PLUS: 'OFFERS_PLUS',
  OFFICE_PLUS: 'OFFICE_PLUS',
  PROPPS_PLUS: 'PROPPS_PLUS',
  UNKNOWN: 'UNKNOWN'
} as const;

export type ProppsProductName = typeof ProppsProductName[keyof typeof ProppsProductName];
export type ProppsSubscription = {
  __typename: 'ProppsSubscription';
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  products: Array<ProppsProduct>;
};

export type ProvisionAgentCardInput = {
  agentId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type ProvisionAgentCardResult = {
  __typename: 'ProvisionAgentCardResult';
  cards: Array<AgentCard>;
  csv: AgentCardResultCsv;
};

export type ProvisionDeveloperAppApiKeyInput = {
  appId: Scalars['String'];
  force?: InputMaybe<Scalars['Boolean']>;
};

export type ProvisionDeveloperAppApiKeyResult = {
  __typename: 'ProvisionDeveloperAppApiKeyResult';
  /** This API key is not stored on the database, make sure you save it somewhere! */
  apiKey: Scalars['String'];
  app: DeveloperApp;
};

export type QrAssets = {
  __typename: 'QRAssets';
  pdfLink?: Maybe<Scalars['String']>;
  pdfShortUrl?: Maybe<Scalars['String']>;
  svgLink?: Maybe<Scalars['String']>;
  svgShortUrl?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename: 'Query';
  agencies: Array<Maybe<Agency>>;
  agency?: Maybe<Agency>;
  agent?: Maybe<Agent>;
  agentCard?: Maybe<AgentCard>;
  agentForPhoneNumber?: Maybe<Agent>;
  agentForUsername?: Maybe<Agent>;
  agentHasUser?: Maybe<Scalars['Boolean']>;
  agentInvitation?: Maybe<AgentInvitation>;
  agentsForEmail?: Maybe<AgentsQueryResult>;
  announcement?: Maybe<Announcement>;
  billingGroup?: Maybe<BillingGroup>;
  billingGroups?: Maybe<Array<BillingGroup>>;
  buyer?: Maybe<Buyer>;
  buyerNotificationPreferences?: Maybe<BuyerNotificationPreferences>;
  chatwootInboxHashForListingBuyer?: Maybe<Scalars['String']>;
  /** Fetch a consolidated invoice for a billing group by ID */
  consolidatedInvoice?: Maybe<ConsolidatedInvoice>;
  crmIntegration?: Maybe<CrmIntegration>;
  crmPushCountWebhookDeadLetters: Scalars['Int'];
  developerApp?: Maybe<DeveloperApp>;
  developerAppForApiKeyHash?: Maybe<DeveloperApp>;
  developerApps: Array<DeveloperApp>;
  draftOffer?: Maybe<DraftOffer>;
  embedder?: Maybe<Embedder>;
  featureHonks: Array<FeatureHonk>;
  findAgent?: Maybe<Agent>;
  getCurrentOfferByListingAndBuyer?: Maybe<Offer>;
  getFoo?: Maybe<Scalars['String']>;
  isAllowedOriginForDeveloperApp: IsAllowedOriginForDeveloperAppResult;
  /** Get recent offers in the Propps system */
  latestOffers: Array<Offer>;
  listing?: Maybe<Listing>;
  listings: ListingsQueryResult;
  listingsPaginated: ListingQueryConnection;
  liteOffer?: Maybe<LiteOffer>;
  me?: Maybe<User>;
  notice?: Maybe<Notice>;
  notificationPreferences?: Maybe<NotificationPreferences>;
  offer?: Maybe<Offer>;
  offerAction?: Maybe<OfferAction>;
  ping?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
  queryAgentBoxAgents: Array<AgentBoxAgent>;
  queryAgentBoxOffices: Array<AgentBoxOffice>;
  queryEagleAgents: Array<EagleAgent>;
  queryMoveAgents?: Maybe<Array<MoveAgent>>;
  queryMoveOffices?: Maybe<Array<MoveOffice>>;
  queryREXAccounts: Array<Maybe<RexAccount>>;
  queryREXAgentsByAccountId: Array<RexAgent>;
  queryVaultreAgents: Array<VaultreAgent>;
  queryVaultreTeams: Array<VaultreTeam>;
  queryVaultreUsers: Array<VaultreUser>;
  queryZenuAgents?: Maybe<Array<ZenuAgent>>;
  retrieveTemporaryReferrerListingData?: Maybe<StoreTemporaryReferrerListingData>;
  root?: Maybe<Scalars['String']>;
  serviceCore?: Maybe<Scalars['String']>;
  termsLine?: Maybe<TermsLine>;
  user?: Maybe<User>;
  vendorLead?: Maybe<VendorLead>;
  webhook?: Maybe<Webhook>;
};


export type QueryAgencyArgs = {
  id: Scalars['ID'];
};


export type QueryAgentArgs = {
  id: Scalars['ID'];
};


export type QueryAgentCardArgs = {
  id: Scalars['ID'];
};


export type QueryAgentForPhoneNumberArgs = {
  number: Scalars['String'];
};


export type QueryAgentForUsernameArgs = {
  username: Scalars['ID'];
};


export type QueryAgentHasUserArgs = {
  id: Scalars['ID'];
};


export type QueryAgentInvitationArgs = {
  claimKey: Scalars['String'];
};


export type QueryAgentsForEmailArgs = {
  email: Scalars['String'];
};


export type QueryAnnouncementArgs = {
  id: Scalars['ID'];
};


export type QueryBillingGroupArgs = {
  id: Scalars['ID'];
};


export type QueryBuyerArgs = {
  id: Scalars['ID'];
};


export type QueryBuyerNotificationPreferencesArgs = {
  hash: Scalars['String'];
};


export type QueryChatwootInboxHashForListingBuyerArgs = {
  buyerId: Scalars['ID'];
  listingId: Scalars['ID'];
};


export type QueryConsolidatedInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryCrmIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryDeveloperAppArgs = {
  id: Scalars['ID'];
};


export type QueryDeveloperAppForApiKeyHashArgs = {
  hash: Scalars['String'];
};


export type QueryDraftOfferArgs = {
  buyerId: Scalars['ID'];
  listingId: Scalars['ID'];
};


export type QueryEmbedderArgs = {
  key: Scalars['ID'];
};


export type QueryFeatureHonksArgs = {
  key: Scalars['ID'];
};


export type QueryFindAgentArgs = {
  criteria: FindAgentCriteria;
};


export type QueryGetCurrentOfferByListingAndBuyerArgs = {
  buyerId: Scalars['ID'];
  listingId: Scalars['ID'];
};


export type QueryIsAllowedOriginForDeveloperAppArgs = {
  input: IsAllowedOriginForDeveloperAppInput;
};


export type QueryLatestOffersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListingArgs = {
  id: Scalars['ID'];
};


export type QueryListingsArgs = {
  input: ListingsQueryInput;
};


export type QueryListingsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PaginatedListingQueryFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLiteOfferArgs = {
  id: Scalars['ID'];
};


export type QueryNoticeArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationPreferencesArgs = {
  hash: Scalars['String'];
};


export type QueryOfferArgs = {
  id: Scalars['ID'];
};


export type QueryOfferActionArgs = {
  id: Scalars['ID'];
  offerId?: InputMaybe<Scalars['ID']>;
};


export type QueryPolicyArgs = {
  id: Scalars['ID'];
};


export type QueryQueryAgentBoxAgentsArgs = {
  input: QueryAgentBoxAgentsInput;
};


export type QueryQueryAgentBoxOfficesArgs = {
  input: QueryAgentBoxOfficesInput;
};


export type QueryQueryEagleAgentsArgs = {
  input: QueryEagleAgentsInput;
};


export type QueryQueryMoveAgentsArgs = {
  input: QueryMoveAgentsInput;
};


export type QueryQueryMoveOfficesArgs = {
  input: QueryMoveOfficesInput;
};


export type QueryQueryRexAgentsByAccountIdArgs = {
  input: QueryRexAgentsByAccountIdInput;
};


export type QueryQueryVaultreAgentsArgs = {
  input: QueryVaultreAgentsInput;
};


export type QueryQueryVaultreTeamsArgs = {
  input: QueryVaultreTeamsInput;
};


export type QueryQueryVaultreUsersArgs = {
  input: QueryVaultreUsersInput;
};


export type QueryQueryZenuAgentsArgs = {
  input: QueryZenuAgentsInput;
};


export type QueryRetrieveTemporaryReferrerListingDataArgs = {
  id: Scalars['ID'];
};


export type QueryTermsLineArgs = {
  name: Scalars['String'];
};


export type QueryUserArgs = {
  uid: Scalars['ID'];
};


export type QueryVendorLeadArgs = {
  source: VendorLeadSourceInput;
};


export type QueryWebhookArgs = {
  id: Scalars['ID'];
};

export type QueryAgentBoxAgentsInput = {
  apiKey: Scalars['String'];
  clientId: Scalars['String'];
};

export type QueryAgentBoxOfficesInput = {
  apiKey: Scalars['String'];
  clientId: Scalars['String'];
};

export type QueryEagleAgentsInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type QueryMoveAgentsInput = {
  instance?: InputMaybe<Scalars['String']>;
  officeId?: InputMaybe<Scalars['String']>;
};

export type QueryMoveOfficesInput = {
  instance?: InputMaybe<Scalars['String']>;
};

export type QueryRexAgentsByAccountIdInput = {
  id: Scalars['ID'];
};

export type QueryVaultreAgentsInput = {
  token: Scalars['String'];
};

export type QueryVaultreTeamsInput = {
  token: Scalars['String'];
};

export type QueryVaultreUsersInput = {
  token: Scalars['String'];
};

export type QueryZenuAgentsInput = {
  authId: Scalars['String'];
  authToken: Scalars['String'];
};

export type RexAccount = {
  __typename: 'REXAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RexAgent = {
  __typename: 'REXAgent';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type RtaIntegration = {
  __typename: 'RTAIntegration';
  agency: Agency;
  apiKey: Scalars['String'];
  id: Scalars['ID'];
};

export type RedeliverCrmPushWebhookDeadLettersResult = {
  __typename: 'RedeliverCRMPushWebhookDeadLettersResult';
  count?: Maybe<Scalars['Int']>;
};

export type RegisterAgentCustomAgencyInput = {
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** registerAgent mutation input */
export type RegisterAgentInput = {
  customAgency?: InputMaybe<RegisterAgentCustomAgencyInput>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  referrer?: InputMaybe<AgentReferrer>;
};

export type RegisterAgentResult = {
  __typename: 'RegisterAgentResult';
  agent: Agent;
  /** A login token with the agent role preloaded */
  token: Scalars['String'];
};

export type RegisterNewAgentInput = {
  agency: Scalars['String'];
  country: Scalars['String'];
  office?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  teamSize?: InputMaybe<Scalars['String']>;
};

export type RegisterNewAgentResult = {
  __typename: 'RegisterNewAgentResult';
  /** A login token with the agent role preloaded */
  token: Scalars['String'];
};

export type RemoveAgentFromListingInput = {
  agentEmail?: InputMaybe<Scalars['String']>;
  agentId: Scalars['String'];
  listingId: Scalars['String'];
};

export type RemoveAgentFromListingResult = {
  __typename: 'RemoveAgentFromListingResult';
  agent: Agent;
  listing: Listing;
};

export type RemoveUserEmailInput = {
  email: Scalars['String'];
  uid: Scalars['ID'];
};

export type RemoveUserEmailResult = {
  __typename: 'RemoveUserEmailResult';
  user: User;
};

export type RemoveUserPhoneInput = {
  uid: Scalars['ID'];
};

export type RemoveUserPhoneResult = {
  __typename: 'RemoveUserPhoneResult';
  user: User;
};

export type RepresentedEntity = RepresentedEntityCompany | RepresentedEntityPerson | RepresentedEntitySelf;

export type RepresentedEntityCompany = {
  __typename: 'RepresentedEntityCompany';
  name: Scalars['String'];
};

export type RepresentedEntityInput = {
  name?: InputMaybe<Scalars['String']>;
  type: RepresentedEntityInputType;
};

export const RepresentedEntityInputType = {
  company: 'company',
  person: 'person',
  self: 'self'
} as const;

export type RepresentedEntityInputType = typeof RepresentedEntityInputType[keyof typeof RepresentedEntityInputType];
export type RepresentedEntityPerson = {
  __typename: 'RepresentedEntityPerson';
  name: Scalars['String'];
};

export type RepresentedEntitySelf = {
  __typename: 'RepresentedEntitySelf';
  _?: Maybe<Scalars['Boolean']>;
};

export type RequestAgentInvitationResult = {
  __typename: 'RequestAgentInvitationResult';
  agentInvitation?: Maybe<AgentInvitation>;
  success: Scalars['Boolean'];
};

export type ResolveReferredListingInput = {
  agentDetails: Array<CreateIncompleteListingAgentDetailsInput>;
  agentIds?: InputMaybe<Array<Scalars['String']>>;
  identifiers?: InputMaybe<Array<ListingIdentifierInput>>;
  listingType?: InputMaybe<ListingType>;
  priceIndication: ListingPriceIndicationInput;
  property: PropertyInput;
  published?: InputMaybe<Scalars['Boolean']>;
  source: ListingSourceInput;
  url?: InputMaybe<Scalars['String']>;
};

export type RevokeUserRoleInput = {
  role: Scalars['String'];
  uid: Scalars['ID'];
};

export type RevokeUserRoleResult = {
  __typename: 'RevokeUserRoleResult';
  user: User;
};

export const RtaActivationStatus = {
  contract_ready: 'contract_ready',
  no_contract: 'no_contract',
  not_linked: 'not_linked'
} as const;

export type RtaActivationStatus = typeof RtaActivationStatus[keyof typeof RtaActivationStatus];
export const RtaOfferStatus = {
  contract_ready: 'contract_ready',
  no_contract: 'no_contract',
  not_linked: 'not_linked',
  offer_forwarded: 'offer_forwarded'
} as const;

export type RtaOfferStatus = typeof RtaOfferStatus[keyof typeof RtaOfferStatus];
export type RunCrmPullOperationInput = {
  crmIntegrationId: Scalars['ID'];
  operation: CrmPullOperation;
};

export type RunCrmPullOperationResult = {
  __typename: 'RunCRMPullOperationResult';
  crmIntegration: CrmIntegration;
  operation: CrmPullOperation;
};

export type SaveDraftOfferInput = {
  /** Allow additional signatories to be added before title transfer */
  addAdditionalSignatoriesLater?: InputMaybe<Scalars['Boolean']>;
  /** For offers on referred listings - Information about the agents the offer should be sent to */
  agents?: InputMaybe<Array<OfferAgentInput>>;
  /** Agree to use of digital signatures on contract */
  agreeToUseOfDigitalSignatures?: InputMaybe<Scalars['Boolean']>;
  /** Offer amount in AUD (whole dollars) */
  amount?: InputMaybe<Scalars['Int']>;
  /** ID of Agent attributed to the offer */
  attributedAgentId?: InputMaybe<Scalars['String']>;
  /** Buyer ID */
  buyerId: Scalars['ID'];
  /** List of revision ids of conditions to add to offer. */
  conditionRevisions: Array<ConditionRevisionInput>;
  /** Conveyancer information (optional) */
  conveyancer?: InputMaybe<ConveyancerInformationInput>;
  /** Content of custom condition (optional) */
  customConditionContent?: InputMaybe<Scalars['String']>;
  /** Disposition, 'explicit' by default */
  disposition?: InputMaybe<DraftOfferDisposition>;
  /** Expiry date (optional) */
  expiresAt?: InputMaybe<Scalars['ISODateString']>;
  /** Finance declaration (optional) */
  finance?: InputMaybe<FinanceDeclarationInput>;
  lastVisitedStepPath?: InputMaybe<Array<Scalars['String']>>;
  /** Listing ID */
  listingId: Scalars['ID'];
  /** For offers on referred listings - Information about the property the offer is for */
  property?: InputMaybe<PropertyInput>;
  /** Settlement */
  settlement?: InputMaybe<OfferSettlementInput>;
  /** Signatories */
  signatories: Array<DraftOfferSignatoryInput>;
};

export type SaveDraftOfferResult = {
  __typename: 'SaveDraftOfferResult';
  draftOffer: DraftOffer;
};

export type SaveVendorLeadDataInput = {
  address?: InputMaybe<AddressInput>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  situation?: InputMaybe<Scalars['String']>;
};

export type SaveVendorLeadInput = {
  data: SaveVendorLeadDataInput;
  source: VendorLeadSourceInput;
};

export type SendAgentInvitationResult = {
  __typename: 'SendAgentInvitationResult';
  agentInvitation?: Maybe<AgentInvitation>;
  success: Scalars['Boolean'];
};

export type SendAnnouncementInput = {
  agentId: Scalars['String'];
  buyerIds: Array<Scalars['String']>;
  /** Supports 'sms' and 'email' as channels for announcement */
  channels: Array<Scalars['String']>;
  listingId: Scalars['String'];
  message: Scalars['String'];
};

export type SendOfferAcknowledgementAction = {
  __typename: 'SendOfferAcknowledgementAction';
  agent: Agent;
  buyer: Buyer;
  id: Scalars['ID'];
  message: Scalars['String'];
  offer: Offer;
};

export type SendOfferAcknowledgementInput = {
  agentId: Scalars['ID'];
  buyerId: Scalars['ID'];
  message: Scalars['String'];
  offerId: Scalars['ID'];
};

export type SendOfferAcknowledgementResult = {
  __typename: 'SendOfferAcknowledgementResult';
  action: SendOfferAcknowledgementAction;
};

export type SendOfferDetailsToRtaInput = {
  agencyId: Scalars['ID'];
  id: Scalars['ID'];
  listingId: Scalars['ID'];
};

export type SendOfferDetailsToRtaResult = {
  __typename: 'SendOfferDetailsToRtaResult';
  offer: Offer;
};

export type SendProductUpgradeRequestInput = {
  agencyName: Scalars['String'];
  email: Scalars['String'];
  interests: Array<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  source: Scalars['String'];
  state: Scalars['String'];
};

export type SendProductUpgradeRequestResult = {
  __typename: 'SendProductUpgradeRequestResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type SetAgencyAgentClaimEmailTemplateInput = {
  id: Scalars['ID'];
  template: AgentClaimEmailTemplateType;
};

export type SetAgentProfileDataInput = {
  agency?: InputMaybe<AgentProfileAgencyInput>;
  bio?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<AgentProfileLinkInput>>;
  showListings?: InputMaybe<Scalars['Boolean']>;
  socials?: InputMaybe<Array<AgentProfileSocialsInput>>;
};

export type SetAgentProfileInput = {
  agentId: Scalars['ID'];
  data: SetAgentProfileDataInput;
};

export type SetAgentProfileResult = {
  __typename: 'SetAgentProfileResult';
  agent?: Maybe<Agent>;
};

export type SetAgentRoleInput = {
  agentId: Scalars['ID'];
  role: AgentRole;
};

export type SetAgentRoleResult = {
  __typename: 'SetAgentRoleResult';
  agent: Agent;
};

export type SettleBillingGroupInvoicesInput = {
  consolidatedInvoiceId: Scalars['ID'];
};

export type SettleBillingGroupInvoicesResult = {
  __typename: 'SettleBillingGroupInvoicesResult';
  consolidatedInvoice: ConsolidatedInvoice;
};

export const SocialNetwork = {
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin',
  tiktok: 'tiktok',
  twitter: 'twitter',
  youtube: 'youtube'
} as const;

export type SocialNetwork = typeof SocialNetwork[keyof typeof SocialNetwork];
export type StartAgentOnboardingResult = {
  __typename: 'StartAgentOnboardingResult';
  agent: Agent;
};

export type StartPhoneVerificationInput = {
  phone: Scalars['String'];
};

export type StartPhoneVerificationResult = {
  __typename: 'StartPhoneVerificationResult';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  verificationId?: Maybe<Scalars['String']>;
};

export type StoreTemporaryReferrerListingData = {
  __typename: 'StoreTemporaryReferrerListingData';
  appId: Scalars['String'];
  createdAt: Scalars['ISODateString'];
  foreignId: Scalars['String'];
  id: Scalars['ID'];
  payload: Scalars['String'];
};

export type StoreTemporaryReferrerListingDataInput = {
  appId: Scalars['String'];
  foreignId: Scalars['String'];
  payload: Scalars['String'];
};

export type SubmitOfferInput = {
  /** Allow additional signatories to be added before title transfer */
  addAdditionalSignatoriesLater: Scalars['Boolean'];
  /** For offers on referred listings - Information about the agents the offer should be sent to */
  agents?: InputMaybe<Array<OfferAgentInput>>;
  /** Agree to use of digital signatures on contract */
  agreeToUseOfDigitalSignatures: Scalars['Boolean'];
  /** Offer amount in AUD (whole dollars) */
  amount: Scalars['Int'];
  /** Agent attributed to the offer (optional) */
  attributedAgentId?: InputMaybe<Scalars['String']>;
  /** List of revision ids of conditions to add to offer. */
  conditionRevisions: Array<ConditionRevisionInput>;
  /** Conveyancer information (optional) */
  conveyancer?: InputMaybe<ConveyancerInformationInput>;
  /** Content of custom condition (optional) */
  customConditionContent?: InputMaybe<Scalars['String']>;
  /** Purchase deposit amount */
  depositAmount?: InputMaybe<Scalars['Int']>;
  /** Purchase deposit selection */
  depositPercentage?: InputMaybe<DepositPercentage>;
  /** Expiry date (optional) */
  expiresAt?: InputMaybe<Scalars['ISODateString']>;
  /** Finance declaration (optional) */
  finance?: InputMaybe<FinanceDeclarationInput>;
  /** Whether the offer to submit is intended to be binding. The offer will be rejected if the wrong type is submitted. */
  isBinding: Scalars['Boolean'];
  listingId: Scalars['ID'];
  /** For offers on referred listings - Information about the property the offer is for */
  property?: InputMaybe<PropertyInput>;
  /** Settlement */
  settlement: OfferSettlementInput;
  /** Signatories */
  signatories: Array<OfferSignatoryInput>;
  /** ID of offer to replace, must be provided when updating an offer. */
  supersedes?: InputMaybe<Scalars['ID']>;
};

export type SubmitOfferResult = {
  __typename: 'SubmitOfferResult';
  offer: Offer;
};

export type Superadmin = {
  __typename: 'Superadmin';
  id: Scalars['ID'];
  name: Scalars['String'];
  user?: Maybe<User>;
};

export type TermsLine = {
  __typename: 'TermsLine';
  currentRevision: TermsRevision;
  name: Scalars['String'];
  revision?: Maybe<TermsRevision>;
};


export type TermsLineRevisionArgs = {
  id: Scalars['ID'];
};

export type TermsRevision = {
  __typename: 'TermsRevision';
  contentHTML: Scalars['String'];
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  line: TermsLine;
  versionName: Scalars['String'];
};

export type TermsRevisionInput = {
  id: Scalars['String'];
  line: Scalars['String'];
};

export type ToggleAgencyAcceptOffersWhileUnderOfferInput = {
  agencyId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export type ToggleAgencyAcceptOffersWhileUnderOfferResult = {
  __typename: 'ToggleAgencyAcceptOffersWhileUnderOfferResult';
  agency: Agency;
};

export type ToggleAgencyListingResolutionInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
};

export type ToggleAgencyNotificationsEnabledInput = {
  agencyId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export type ToggleAgencyNotificationsEnabledResult = {
  __typename: 'ToggleAgencyNotificationsEnabledResult';
  agency: Agency;
};

export type ToggleAgencyShouldSendClaimEmailInput = {
  dispatchToExistingAgents?: InputMaybe<Scalars['Boolean']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Toggle agency wide offer boost (admin use) */
export type ToggleAgencyWideOfferBoostInput = {
  agencyId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type ToggleAgencyWideOfferBoostResponse = {
  __typename: 'ToggleAgencyWideOfferBoostResponse';
  agency: Agency;
};

export type ToggleAgentOnboardingResult = {
  __typename: 'ToggleAgentOnboardingResult';
  agent: Agent;
};

export type ToggleCrmIntegrationEnabledInput = {
  crmIntegrationId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export type ToggleCrmIntegrationEnabledResult = {
  __typename: 'ToggleCRMIntegrationEnabledResult';
  crmIntegration: CrmIntegration;
};

export type ToggleConveyancingReferralsInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
};

export type ToggleListingViewDocumentsInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type TrustAccount = {
  __typename: 'TrustAccount';
  accountNo: Scalars['String'];
  agency: Agency;
  bsb: Scalars['String'];
  createdAt: Scalars['ISODateString'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UnlinkAgentUserInput = {
  agentId: Scalars['ID'];
};

export type UnlinkAgentUserResult = {
  __typename: 'UnlinkAgentUserResult';
  agent: Agent;
  user: User;
};

export type UnlinkBuyerInput = {
  buyerId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Unlock offer boost */
export type UnlockOfferBoostInput = {
  listingId: Scalars['String'];
};

export type UnlockOfferBoostResponse = {
  __typename: 'UnlockOfferBoostResponse';
  listing: Listing;
};

export type UpdateAgencyAssignedFeaturesInput = {
  flags: Array<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateAgencyBillingAccountInput = {
  agencyId: Scalars['String'];
  representative: AgencyBillingAccountRepresentativeInput;
};

export type UpdateAgencyBillingAccountResult = {
  __typename: 'UpdateAgencyBillingAccountResult';
  billingAccount: AgencyBillingAccount;
};

/** updateAgency mutation input */
export type UpdateAgencyInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Skip Agency OfferBoost automation  */
export type UpdateAgencyOfferBoostAutomationInput = {
  agencyId: Scalars['String'];
  skipBilling: Scalars['Boolean'];
  skipUnlockNewListings: Scalars['Boolean'];
};

export type UpdateAgencyOfferBoostAutomationResponse = {
  __typename: 'UpdateAgencyOfferBoostAutomationResponse';
  agency: Agency;
};

/** Update agency wide offer boost settings */
export type UpdateAgencyOfferBoostSettingsInput = {
  agencyId: Scalars['String'];
  includeAuctionListings: Scalars['Boolean'];
};

export type UpdateAgencyOfferBoostSettingsResponse = {
  __typename: 'UpdateAgencyOfferBoostSettingsResponse';
  agency: Agency;
};

export type UpdateAgencyResult = {
  __typename: 'UpdateAgencyResult';
  agency: Agency;
};

/** updateAgent mutation input */
export type UpdateAgentInput = {
  detailsVerified?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
};

export type UpdateAgentNotificationPreferencesInput = {
  hash: Scalars['String'];
  preferences: UpdateAgentNotificationPreferencesPreferencesInput;
};

export type UpdateAgentNotificationPreferencesPreferencesInput = {
  claim_account_reminders?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAgentNotificationPreferencesResult = {
  __typename: 'UpdateAgentNotificationPreferencesResult';
  preferences: AgentNotificationPreferences;
};

export type UpdateAgentResult = {
  __typename: 'UpdateAgentResult';
  agent: Agent;
};

export type UpdateAppAgentOnboardingDataInput = {
  activeStep: Scalars['String'];
  completed: Scalars['Boolean'];
};

export type UpdateAppAgentOnboardingInput = {
  agentId: Scalars['ID'];
  data: UpdateAppAgentOnboardingDataInput;
};

export type UpdateAppAgentOnboardingResult = {
  __typename: 'UpdateAppAgentOnboardingResult';
  agent: Agent;
};

export type UpdateBuyerNotificationPreferencesInput = {
  hash: Scalars['String'];
  preferences: UpdateBuyerNotificationPreferencesPreferencesInput;
};

export type UpdateBuyerNotificationPreferencesPreferencesInput = {
  listing_announcements?: InputMaybe<Scalars['Boolean']>;
  new_offer_notification?: InputMaybe<Scalars['Boolean']>;
  transactional?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBuyerNotificationPreferencesResult = {
  __typename: 'UpdateBuyerNotificationPreferencesResult';
  preferences: BuyerNotificationPreferences;
};

export type UpdateConsolidatedInvoiceStatusInput = {
  consolidatedInvoiceId: Scalars['ID'];
  status: ConsolidatedInvoiceStatus;
};

export type UpdateConsolidatedInvoiceStatusResult = {
  __typename: 'UpdateConsolidatedInvoiceStatusResult';
  consolidatedInvoice: ConsolidatedInvoice;
};

export type UpdateCountdownTimerSettingsInput = {
  enabled: Scalars['Boolean'];
  expiry?: InputMaybe<Scalars['ISODateString']>;
  label?: InputMaybe<Scalars['String']>;
  listingId: Scalars['String'];
  pauseListingOnExpiry?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCrmIntegrationAgentFilterInput = {
  filterAgents?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
};

export type UpdateCrmIntegrationAgentFilterResult = {
  __typename: 'UpdateCrmIntegrationAgentFilterResult';
  crmIntegration: CrmIntegration;
};

export type UpdateCrmIntegrationListingUrlTemplateInput = {
  id: Scalars['ID'];
  listingUrlTemplate?: InputMaybe<Scalars['String']>;
};

export type UpdateCrmIntegrationListingUrlTemplateResult = {
  __typename: 'UpdateCrmIntegrationListingUrlTemplateResult';
  crmIntegration: CrmIntegration;
};

export type UpdateCrmIntegrationPullAuctionListingsInput = {
  id: Scalars['ID'];
  pullAuctionListings: Scalars['Boolean'];
};

export type UpdateCrmIntegrationPullAuctionListingsResult = {
  __typename: 'UpdateCrmIntegrationPullAuctionListingsResult';
  crmIntegration: CrmIntegration;
};

export type UpdateCrmIntegrationPushEnabledInput = {
  id: Scalars['ID'];
  pushEnabled: Scalars['Boolean'];
};

export type UpdateCrmIntegrationPushEnabledResult = {
  __typename: 'UpdateCrmIntegrationPushEnabledResult';
  crmIntegration: CrmIntegration;
};

export type UpdateDeveloperAppDomainAllowListInput = {
  appId: Scalars['String'];
  domains: Array<Scalars['String']>;
};

export type UpdateDeveloperAppNameInput = {
  appId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateDeveloperAppResolutionModeResult = {
  __typename: 'UpdateDeveloperAppResolutionModeResult';
  developerApp?: Maybe<DeveloperApp>;
};

/** Update listing accept conditional offers settings */
export type UpdateListingAcceptConditionalOffersSettingsInput = {
  acceptConditionalOffers: ListingAcceptConditionalOffersInput;
  id: Scalars['ID'];
};

export type UpdateListingContractInput = {
  contract: FileUploadInput;
  id: Scalars['ID'];
};

export type UpdateListingDepositRequiredSettingsInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type UpdateListingDocumentVisibilityInput = {
  documentRef: ListingDocumentRef;
  id: Scalars['ID'];
  visible: Scalars['Boolean'];
};

export type UpdateListingExposeCompetitionSettingsInput = {
  id: Scalars['ID'];
  state: ListingExposeCompetitionState;
};

export type UpdateListingHoldingDepositSettingsInput = {
  enabled: Scalars['Boolean'];
  listingId: Scalars['ID'];
  trustAccountId?: InputMaybe<Scalars['ID']>;
};

export type UpdateListingHoldingDepositSettingsResult = {
  __typename: 'UpdateListingHoldingDepositSettingsResult';
  listing: Listing;
};

export type UpdateListingInput = {
  acceptedOfferType?: InputMaybe<OfferType>;
  id: Scalars['ID'];
  priceIndication?: InputMaybe<ListingPriceIndicationInput>;
  property?: InputMaybe<PropertyInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<ListingSourceInput>;
  status?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateListingInputResult = {
  __typename: 'UpdateListingInputResult';
  listing: Listing;
};

/** Update new offer notification settings */
export type UpdateListingNewOfferNotificationsSettingsInput = {
  id: Scalars['ID'];
  newOfferNotifications: ListingNewOfferNotificationsInput;
};

/** Update offer boost */
export type UpdateListingOfferBoostInput = {
  id: Scalars['String'];
  offerBoost: ListingOfferBoostInput;
};

export type UpdateListingPrepareToBuySettingsInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type UpdateListingResult = {
  __typename: 'UpdateListingResult';
  listing: Listing;
};

export type UpdateListingStatusInput = {
  listingId: Scalars['ID'];
  status: ListingStatus;
};

export type UpdateListingStatusResult = {
  __typename: 'UpdateListingStatusResult';
  listing: Listing;
};

export type UpdateListingVendorInput = {
  listingId: Scalars['String'];
  vendor?: InputMaybe<VendorProfileInput>;
};

export type UpdateOfferBoostAgentListInput = {
  agencyId: Scalars['String'];
  agentConfigs: Array<OfferBoostAgentConfig>;
};

export type UpdateOfferBoostAgentListResponse = {
  __typename: 'UpdateOfferBoostAgentListResponse';
  agency: Agency;
};

export type UpdateOfferHoldingDepositStatusInput = {
  offerId: Scalars['ID'];
  status: HoldingDepositStatus;
};

export type UpdateOfferHoldingDepositStatusResult = {
  __typename: 'UpdateOfferHoldingDepositStatusResult';
  offer: Offer;
};

export type UpdateOffersMailingListInput = {
  agencyId: Scalars['ID'];
  offersMailingList: Array<Scalars['String']>;
};

export type UpdateOffersMailingListResult = {
  __typename: 'UpdateOffersMailingListResult';
  agency?: Maybe<Agency>;
};

export type UpdatePolicyPermissionsInput = {
  permissions: Array<PermissionStateInput>;
  policyId: Scalars['String'];
};

export type UpdateQrCodeMailingListInput = {
  agencyId: Scalars['ID'];
  qrCodeMailingList: Array<Scalars['String']>;
};

export type UpdateQrCodeMailingListResult = {
  __typename: 'UpdateQRCodeMailingListResult';
  agency?: Maybe<Agency>;
};

export type UpdateUserProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  uid: Scalars['ID'];
};

export type UpdateVendorLeadConfigAdditionalRecipientsInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateVendorLeadConfigDataInput = {
  additionalRecipients: Array<UpdateVendorLeadConfigAdditionalRecipientsInput>;
  dispatchToListingAgents: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type UpdateVendorLeadConfigEntityInput = {
  agencyId?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['String']>;
};

export type UpdateVendorLeadConfigInput = {
  data: UpdateVendorLeadConfigDataInput;
  entity: UpdateVendorLeadConfigEntityInput;
};

export type UpdateVendorLeadConfigResult = {
  __typename: 'UpdateVendorLeadConfigResult';
  vendorLeadConfig: VendorLeadConfig;
};

export type UpdateWebhookSourcesInput = {
  appId: Scalars['String'];
  sources: Array<WebhookEventSourceInput>;
  webhookId: Scalars['ID'];
};

export type UploadAgentProfileImageInput = {
  contentType: Scalars['String'];
  id: Scalars['ID'];
  upload: FileUploadInput;
};

export type UploadAssetInput = {
  assetType: UploadAssetType;
  entityId: Scalars['ID'];
  metadata: AssetUploadMetadata;
  upload: FileUploadInput;
};

export const UploadAssetType = {
  document: 'document',
  logo: 'logo'
} as const;

export type UploadAssetType = typeof UploadAssetType[keyof typeof UploadAssetType];
export type User = {
  __typename: 'User';
  _id: Scalars['ID'];
  agent?: Maybe<Agent>;
  agents: Array<Agent>;
  buyer?: Maybe<Buyer>;
  chatIdentifier: Scalars['String'];
  /** @deprecated Use emails instead */
  email?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  superadmin?: Maybe<Superadmin>;
  uid: Scalars['ID'];
};

export type VaultreAgent = {
  __typename: 'VaultreAgent';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type VaultreTeam = {
  __typename: 'VaultreTeam';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VaultreUser = {
  __typename: 'VaultreUser';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VendorInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type VendorLead = {
  __typename: 'VendorLead';
  /** Vendor property address */
  address?: Maybe<Address>;
  /** Buyer account connected to vendor lead */
  buyer?: Maybe<Buyer>;
  /** Vendor email address */
  email: Scalars['String'];
  /** A vendor lead is keyed against a listingId and buyerId */
  id: Scalars['ID'];
  /** Listing which this lead originated from */
  listing?: Maybe<Listing>;
  /** Vendor full name */
  name: Scalars['String'];
  /** Vendor phone number */
  phone: Scalars['String'];
  /** When the lead was submitted */
  sentAt?: Maybe<Scalars['ISODateString']>;
  /** What the vendor wants to do (sell now, in future or no) */
  situation?: Maybe<VendorLeadSituation>;
  /** Whether the lead has been submitted, stored as a draft otherwise */
  submitted: Scalars['Boolean'];
};

/** Configuration object for vendor leads */
export type VendorLeadConfig = {
  __typename: 'VendorLeadConfig';
  /** Additional recipients who should receive the lead email */
  additionalRecipients: Array<VendorLeadConfigRecipient>;
  createdAt: Scalars['ISODateString'];
  /** Whether the leads should be sent to the agents assigned to the originating listing */
  dispatchToListingAgents: Scalars['Boolean'];
  /** Whether the entity wants vendor leads to be enabled at their scope */
  enabled: Scalars['Boolean'];
  /** Entity which this configuration represents */
  entityId: Scalars['ID'];
};

export type VendorLeadConfigRecipient = {
  __typename: 'VendorLeadConfigRecipient';
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type VendorLeadResult = {
  __typename: 'VendorLeadResult';
  vendorLead: VendorLead;
};

export const VendorLeadSituation = {
  future: 'future',
  listed: 'listed',
  no: 'no',
  noowner: 'noowner',
  soon: 'soon'
} as const;

export type VendorLeadSituation = typeof VendorLeadSituation[keyof typeof VendorLeadSituation];
export type VendorLeadSourceInput = {
  buyerId: Scalars['String'];
  listingId: Scalars['String'];
};

export type VendorProfile = {
  __typename: 'VendorProfile';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type VendorProfileInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Webhook = {
  __typename: 'Webhook';
  app: DeveloperApp;
  createdAt: Scalars['ISODateString'];
  filters: WebhookEventFilters;
  id: Scalars['ID'];
  logs: Array<WebhookLog>;
  sources: Array<WebhookEventSource>;
  type: Scalars['String'];
  url: Scalars['String'];
};

export type WebhookEventFilters = {
  __typename: 'WebhookEventFilters';
  agencies?: Maybe<Array<Maybe<Agency>>>;
  apps?: Maybe<Array<Maybe<DeveloperApp>>>;
  listings?: Maybe<Array<Maybe<Listing>>>;
};

export type WebhookEventFiltersInput = {
  agencyIds?: InputMaybe<Array<Scalars['String']>>;
  appIds?: InputMaybe<Array<Scalars['String']>>;
  listingIds?: InputMaybe<Array<Scalars['String']>>;
};

export type WebhookEventSource = {
  __typename: 'WebhookEventSource';
  event: Scalars['String'];
  omitFields?: Maybe<Array<Scalars['String']>>;
  pickFields?: Maybe<Array<Scalars['String']>>;
  topic: Scalars['String'];
};

export type WebhookEventSourceInput = {
  event: Scalars['String'];
  omitFields?: InputMaybe<Array<Scalars['String']>>;
  pickFields?: InputMaybe<Array<Scalars['String']>>;
  topic: Scalars['String'];
};

export type WebhookLog = {
  __typename: 'WebhookLog';
  appId: Scalars['String'];
  cat: Scalars['ISODateString'];
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  request: WebhookLogRequest;
  requestId: Scalars['ID'];
  response: WebhookLogResponse;
  webhookId: Scalars['String'];
};

export type WebhookLogRequest = {
  __typename: 'WebhookLogRequest';
  body?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type WebhookLogResponse = {
  __typename: 'WebhookLogResponse';
  body?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  status: Scalars['Int'];
};

export type WebhookResult = {
  __typename: 'WebhookResult';
  webhook: Webhook;
};

export type WithdrawOfferInput = {
  id: Scalars['ID'];
};

export type WithdrawOfferResult = {
  __typename: 'WithdrawOfferResult';
  offer: Offer;
};

export type ZenuAgent = {
  __typename: 'ZenuAgent';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export const Join__Graph = {
  AGENCY_SERVICE: 'AGENCY_SERVICE',
  AGENTS_SERVICE: 'AGENTS_SERVICE',
  BULK_BILLING: 'BULK_BILLING',
  CONTRACT_EXCHANGE: 'CONTRACT_EXCHANGE',
  CRM_CONNECT: 'CRM_CONNECT',
  FEATURE_HONK: 'FEATURE_HONK',
  LISTINGS: 'LISTINGS',
  NOTIFICATIONS_SERVICE: 'NOTIFICATIONS_SERVICE',
  NOTIFICATION_PREFERENCES: 'NOTIFICATION_PREFERENCES',
  OFFERS: 'OFFERS',
  SERVICE_CORE: 'SERVICE_CORE',
  USER_SERVICE: 'USER_SERVICE'
} as const;

export type Join__Graph = typeof Join__Graph[keyof typeof Join__Graph];
export const Link__Purpose = {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  EXECUTION: 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  SECURITY: 'SECURITY'
} as const;

export type Link__Purpose = typeof Link__Purpose[keyof typeof Link__Purpose];