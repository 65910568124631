import {
  Auth,
  RESTClient,
  useAuthVerification,
  useTemporaryFirebaseApp,
} from '@propps-au/client'
import { getAuth } from 'firebase/auth'
import { AnimatePresence } from 'framer-motion'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { FIREBASE_CONFIG } from '../../../firebase'
import { PageTransition } from '../../page-transition'
import { Buyer, SignatoryCreateData } from '../buyer'
import { AddBuyer } from './add-buyer'
import { BuyerCreateData } from './create-buyer'
import { AddBuyerVerifyEmail } from './verify-email'
import { AddBuyerVerifyPhone } from './verify-phone'
import { AddBuyer_ListingFragment } from './__generated__/add-buyer.generated'

const CORE_REST_ENDPOINT = process.env.REACT_APP_CORE_REST_ENDPOINT!

export type AddBuyerState =
  | {
      status: 'new-signatory-form'
      buyer?: BuyerCreateData
      error?: string
    }
  | {
      status: 'validate-buyer-phone'
      buyer: BuyerCreateData
    }
  | {
      status: 'validate-buyer-email'
      buyer: BuyerCreateData
    }
  | {
      status: 'signatory-details'
      signatory: SignatoryCreateData
    }

export function AddBuyerRoot({
  listing,
  onContinue,
}: {
  listing: AddBuyer_ListingFragment
  onContinue: () => void
}) {
  const match = useRouteMatch()
  const history = useHistory<AddBuyerState | undefined>()

  // Create seperate firebase app and auth instance for additional buyer.
  const app = useTemporaryFirebaseApp(FIREBASE_CONFIG)
  const [auth] = useState(
    () =>
      new Auth(getAuth(app), new RESTClient({ basepath: CORE_REST_ENDPOINT }))
  )
  const [buyer, setBuyer] = useState<BuyerCreateData | undefined>()

  useEffect(() => {
    return () => auth.destroy()
  }, [auth])

  const client = useMemo(
    () => new RESTClient({ basepath: CORE_REST_ENDPOINT, auth }),
    [auth]
  )
  const verification = useAuthVerification({ client })

  return (
    <Fragment>
      {history.location.pathname.startsWith(match.url) &&
      !history.location.state ? (
        <Redirect
          to={{
            pathname: match.url,
            state: { status: 'new-signatory-form' },
          }}
        />
      ) : null}
      <AnimatePresence exitBeforeEnter initial={false}>
        <PageTransition key={history.location.state?.status}>
          {history.location.state?.status === 'new-signatory-form' ? (
            <AddBuyer
              listing={listing}
              onContinue={onContinue}
              buyer={buyer}
              setBuyer={setBuyer}
              error={history.location.state?.error}
            />
          ) : null}
          {history.location.state?.status === 'validate-buyer-phone' ? (
            <AddBuyerVerifyPhone
              auth={auth}
              buyer={history.location.state.buyer}
              verification={verification}
            />
          ) : null}
          {history.location.state?.status === 'validate-buyer-email' ? (
            <AddBuyerVerifyEmail
              auth={auth}
              buyer={history.location.state.buyer}
              verification={verification}
            />
          ) : null}
          {history.location.state?.status === 'signatory-details' ? (
            <Buyer
              listing={listing}
              onContinue={onContinue}
              signatory={history.location.state.signatory}
              index={null}
            />
          ) : null}
        </PageTransition>
      </AnimatePresence>
    </Fragment>
  )
}
