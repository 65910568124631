import { getError } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Article,
  ArticleTitle,
  CommonError,
  DatePicker,
  IconClock,
  Label,
  Select,
  Title,
} from '@propps-au/ui'
import {
  addHours,
  differenceInHours,
  format,
  formatISO,
  isValid,
  setHours,
  startOfTomorrow,
} from 'date-fns'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { useOfferForm } from '../context'
import { ConditionStatus } from './condition-status'

const expiryTimes = [
  { value: 9, label: '9:00AM' },
  { value: 12, label: '12:00PM' },
  { value: 17, label: '5:00PM' },
]

export const nextAvailableDate = (times: { value: number }[]) => {
  const now = new Date()
  const tomorrow = startOfTomorrow()

  const earliestTime = times.find(
    (time) => differenceInHours(setHours(tomorrow, time.value), now) >= 24
  )

  return earliestTime
    ? setHours(tomorrow, earliestTime.value)
    : setHours(addHours(tomorrow, 24), times[0].value)
}

export function Expiry({ onContinue }: { onContinue: () => void }) {
  const analytics = useAnalytics()
  const { state, update } = useOfferForm()
  const [hasExpiry] = useState(!!state.expiry)

  const form = useFormik({
    initialValues: {
      expiry: state.expiry ?? nextAvailableDate(expiryTimes),
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      update((current) => ({
        ...current,
        expiry: values.expiry,
      }))

      analytics.logPixelEvent<Pixel.BuyerEvent.AddOfferExpiry>({
        type: BuyerEvent.ADD_OFFER_EXPIRY,
        expiry: formatISO(values.expiry),
        ...analytics.getEventMetadata(),
      })

      onContinue && onContinue()
    },
  })

  const handleRemove = () => {
    update((s) => ({
      ...s,
      expiry: null,
    }))

    analytics.logPixelEvent<Pixel.BuyerEvent.RemoveOfferExpiry>({
      type: BuyerEvent.REMOVE_OFFER_EXPIRY,
      ...analytics.getEventMetadata(),
    })

    onContinue()
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>Offer expiry</Title>
      <Article>
        <ConditionStatus
          included={hasExpiry}
          onAdd={form.submitForm}
          onRemove={handleRemove}
        />
      </Article>
      <Article>
        <ArticleTitle>Condition options</ArticleTitle>
        <fieldset>
          <Label label="Date">
            <DatePicker
              value={form.values.expiry}
              onChange={(value) => {
                form.setFieldTouched('expiry')
                form.setFieldValue('expiry', value)
              }}
              format={(date) => format(date, 'dd MMM yyyy')}
              minDate={nextAvailableDate(expiryTimes)}
            />
          </Label>
          <Label label="Time">
            <Select
              icon={{ svg: IconClock }}
              onChange={(e) => {
                form.setFieldTouched('expiry')
                form.setFieldValue(
                  'expiry',
                  setHours(form.values.expiry, parseInt(e.target.value))
                )
              }}
            >
              {expiryTimes.map((time) => (
                <option
                  value={time.value}
                  disabled={
                    differenceInHours(
                      setHours(form.values.expiry, time.value),
                      new Date()
                    ) < 24
                  }
                >
                  {time.label}
                </option>
              ))}
            </Select>
          </Label>
        </fieldset>
        {getError(form, 'expiry') && (
          <CommonError>{getError(form, 'expiry')}</CommonError>
        )}
        <p className="grey">
          You can withdraw your offer at any time prior to vendor acceptance by
          notifying the agent.
        </p>
      </Article>
      <Article>
        <ArticleTitle>Condition text</ArticleTitle>
        <ol>
          <li>
            This offer is valid until{' '}
            <b>
              {
                expiryTimes.find(
                  (time) => time.label === format(form.values.expiry, 'h:mma')
                )?.label
              }
            </b>{' '}
            on <b>{format(form.values.expiry, 'dd MMM yyyy')}</b>.
          </li>
        </ol>
      </Article>
      {hasExpiry ? (
        <PrimaryButton label="Done" onClick={form.submitForm} />
      ) : (
        <PrimaryButton label="Done" onClick={onContinue} />
      )}
    </form>
  )
}

const ValidationSchema = Yup.object({
  expiry: Yup.date()
    .nullable()
    .test(
      'is-future',
      'Please choose a date at least 24 hours in the future.',
      (value?: Date | null): value is Date | null =>
        !value || (isValid(value) && differenceInHours(value, new Date()) >= 24)
    )
    .label('Expiry date'),
})
