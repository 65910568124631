import { GraphQLHeaders } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { PixelFrontendClient } from '@propps-au/pixel-client'
import { SessionEventConfigs } from '@propps-au/pixel-event-configs'
import Amplitude from 'amplitude-js'
import { History, Location } from 'history'
import { BuyerEventConfigs } from './event-configs'

declare global {
  interface Window {
    ga: Function
  }
}

type EventMetadata = {
  listingId: string
  foreignId: string
  appId: string
}
export class Analytics {
  gaTrackingId: string | undefined
  amplitudeApiKey: string | undefined
  userId: string | null = null
  pixel: PixelFrontendClient | null = null
  user: Pixel.User = { uid: null, role: null }
  metadata: EventMetadata | null = null

  constructor(private history: History) {}

  init({
    gaTrackingId,
    amplitudeApiKey,
    headers,
  }: {
    gaTrackingId?: string
    amplitudeApiKey?: string
    headers?: GraphQLHeaders
  }) {
    this.gaTrackingId = gaTrackingId
    if (this.gaTrackingId) {
      this.history.listen(this.handleLocationChange.bind(this))
    }

    let amplitude: Amplitude.AmplitudeClient | null = null
    if (amplitudeApiKey) {
      this.amplitudeApiKey = amplitudeApiKey
      amplitude = Amplitude.getInstance()
      amplitude.init(amplitudeApiKey)
    }
    this.pixel = new PixelFrontendClient({
      mailroom: {
        basePath: process.env.REACT_APP_MAILROOM_ENDPOINT ?? '',
        setRequestHeaders: (customHeaders: {}) => {
          headers?.setHeaders(customHeaders)
        },
      },
      amplitude: amplitude ?? undefined,
      configs: {
        ...BuyerEventConfigs,
        ...SessionEventConfigs,
      },
    })
    // async
    this.pixel.initialise()
  }

  handleLocationChange(location: Location) {
    gtag('config', this.gaTrackingId!, {
      page_title: document.title,
      page_path: location.pathname,
    })
    gtag('event', 'screen_view', {
      app_name: window.location.origin,
      screen_name: location.pathname,
    })
  }

  logPixelEvent<T extends Pixel.Event>(event: T) {
    this.pixel?.dispatch<T>(event)
  }

  logAmplitudeEvent(event: string, data?: any) {
    this.pixel?.amplitude?.logEvent(event, data)
  }

  onUserChange(user: Pixel.User) {
    this.user = user
    this.pixel?.setUser(user)
    this.setUserId(user.uid ?? null)
  }

  setUserId(id: string | null) {
    this.pixel?.setUserId(id)
  }

  setEventMetadata(metadata: EventMetadata) {
    this.metadata = { ...metadata }
  }

  getEventMetadata(): EventMetadata {
    return this.metadata!
  }
}
