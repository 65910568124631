import { useAuthState } from '@propps-au/client'
import { Attributes, SplitIO } from '@propps-au/splitio-react'

type Feature = 'buyer_devtools' | 'frames_chat'

const { useFeature, SplitProvider } = SplitIO.create<Feature>()

const useFeatureWithKey = (
  feature: Feature,
  options?: { subscribe?: boolean; attributes?: Attributes }
) => {
  const auth = useAuthState()
  return useFeature(auth.uid, feature, options)
}

export { useFeatureWithKey as useFeature, SplitProvider }
