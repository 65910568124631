/** @jsx jsx */
import { gql } from '@apollo/client'
import { jsx } from '@emotion/react'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Alert,
  Article,
  ArticleTitle,
  BindingWarningAlert,
  ButtonCTA,
  CommonError,
  Icon,
  IconShield,
  IconShieldOff,
  IconUserPlus,
  List,
  Modal,
  Title,
  useModalState,
} from '@propps-au/ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { OfferHubHistoryState } from '../../../pages/listing/offer-hub'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { SignInReminder } from '../../sign-in-reminder'
import { hasAcceptedAdditionalConditions } from '../conditions/conditions'
import { useOfferForm } from '../context'
import { OfferFormMode } from '../mode'
import { OfferFormNavigationFn } from '../routes'
import { useVendorLead } from '../selling/vendor-lead-context'
import { OfferStep, StepStatus } from '../status'
import { ConveyancerType, FinanceType, Signatory } from '../values'
import { OverviewPreroll } from './preroll-info'
import { BuyerDataStepListItem, OfferStepListItem } from './step-list-item'
import {
  OfferFormIndex_ListingFragment,
  OfferForm_Overview_BuyerFragment,
} from './__generated__/overview.generated'

function getNextSignatoryAgreement(signatories: Signatory[]) {
  const index = signatories.findIndex(
    (signatory) => signatory.agreedTermsRevision === null
  )
  if (index === -1) {
    return 0
  }
  return index
}
export interface Step {
  title: string
  descriptiveTitle: string
  goTo: () => void
  status: StepStatus
  skipped?: boolean
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  data?: () => JSX.Element
}

function createSteps<T extends Record<string, Step>>(items: T) {
  return items
}

export function Overview({
  listing,
  buyer,
  goToStep,
  descriptiveTitles = true,
}: {
  listing: OfferFormIndex_ListingFragment
  buyer: OfferForm_Overview_BuyerFragment | null
  goToStep: OfferFormNavigationFn
  descriptiveTitles?: boolean
}) {
  const analytics = useAnalytics()
  const history = useHistory<OfferHubHistoryState>()
  const confirmSendOfferModal = useModalState()
  const {
    state,
    status,
    submit: submitOffer,
    submissionError,
    mode,
  } = useOfferForm()
  const { state: vendorLead, submit: submitVendorLead } = useVendorLead()

  const fromPrepare = history?.location?.state?.fromPrepare

  const enableSellingStep = listing.vendorLeadsEnabled
  const buyerWantsToSell = ['soon'].includes(vendorLead.situation ?? '')
  const sellingStepText =
    vendorLead.situation &&
    (vendorLead.situation === 'soon'
      ? 'Yes'
      : vendorLead.situation === 'listed'
      ? 'Listed'
      : 'No')

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbOverview>({
        type: BuyerEvent.VIEW_PTB_OVERVIEW,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferOverview>({
        type: BuyerEvent.VIEW_OFFER_OVERVIEW,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isPending, setPending] = useState(false)
  const handleSubmit = async () => {
    setPending(true)
    try {
      if (enableSellingStep && buyerWantsToSell) {
        await Promise.all([submitOffer(), submitVendorLead()])
      }
      await submitOffer()
    } finally {
      setPending(false)
    }
  }

  const STEPS = useMemo(
    () =>
      createSteps({
        [OfferStep.AMOUNT]: {
          title: 'Offer amount',
          descriptiveTitle: `How much you'd like to offer`,
          goTo: () => goToStep(OfferStep.AMOUNT),
          status: status.steps[OfferStep.AMOUNT],
          data: () => (
            <NumberFormat
              value={state?.amount?.toString()}
              displayType="text"
              thousandSeparator
              prefix="$ "
            />
          ),
        },
        [OfferStep.CONDITIONS]: {
          title: 'Conditions',
          descriptiveTitle: `Conditions with your offer`,
          goTo: () => goToStep(OfferStep.CONDITIONS),
          status: StepStatus.NotApplicable,
          data: () => (
            <span>
              {state.conditions.length > 0 &&
                (hasAcceptedAdditionalConditions(listing.conditions, state)
                  ? 'Additional conditions'
                  : 'No additional conditions')}
            </span>
          ),
        },
        [OfferStep.CONVEYANCER]: {
          title: 'Legal',
          descriptiveTitle: `Getting legal representation`,
          goTo: () => goToStep(OfferStep.CONVEYANCER),
          status: StepStatus.NotApplicable,
          data: () => (
            <span>
              {state?.conveyancer?.type &&
                (state?.conveyancer?.type === ConveyancerType.PROVIDED
                  ? 'Engaged'
                  : 'Not engaged')}
            </span>
          ),
        },
        [OfferStep.DOCUMENTS]: {
          title: 'Review documents',
          descriptiveTitle: `Review documents`,
          goTo: () => goToStep(OfferStep.DOCUMENTS),
          status: StepStatus.NotApplicable,
        },
        [OfferStep.FINANCE]: {
          title: 'Finance',
          descriptiveTitle: `Getting your finances ready`,
          goTo: () => goToStep(OfferStep.FINANCE),
          status: StepStatus.NotApplicable,
          data: () => (
            <span>
              {state?.finance?.type &&
                (state?.finance?.type === FinanceType.NONE
                  ? 'Not prepared'
                  : 'Prepared')}
            </span>
          ),
        },
        [OfferStep.PROPERTY_AGENT_DETAILS]: {
          title: 'Property details',
          descriptiveTitle: 'Details about the property',
          goTo: () => goToStep('property-agent-details'),
          status: StepStatus.NotApplicable,
          data: () => (
            <span>
              {listing.property.address.line1 || state.property?.address.line1}
            </span>
          ),
        },
        [OfferStep.SELLING]: {
          title: 'Selling',
          descriptiveTitle: 'Would you like to sell your current property?',
          goTo: () => goToStep(OfferStep.SELLING),
          status: StepStatus.NotApplicable,
          data: () => <span>{sellingStepText}</span>,
        },
        [OfferStep.SETTLEMENT]: {
          title: 'Settlement',
          descriptiveTitle: `When you'd like to get the keys (settlement)`,
          goTo: () => goToStep(OfferStep.SETTLEMENT),
          status: StepStatus.NotApplicable,
          data: () => (
            <span>
              {(state?.settlement?.type === 'days' &&
                `${state.settlement.days} day${
                  state.settlement.days > 1 ? 's' : ''
                }`) ||
                (state?.settlement?.type === 'date' && state.settlement.date)}
            </span>
          ),
        },
        [OfferStep.SIGNATORIES]: {
          title: 'Add/edit buyers',
          descriptiveTitle: `People you're buying with and/or for`,
          goTo: () => goToStep(OfferStep.SIGNATORIES),
          status: StepStatus.NotApplicable,
          icon: IconUserPlus,
          data: () => (
            <Fragment>
              {state.signatories.length > 0 &&
                state.signatories.map((signatory, index) => (
                  <BuyerDataStepListItem
                    signatory={signatory}
                    showSignature={
                      mode !== OfferFormMode.PREPARE_OFFER &&
                      listing.acceptedOfferType === 'binding'
                    }
                    onClick={
                      mode !== OfferFormMode.PREPARE_OFFER &&
                      listing.acceptedOfferType === 'binding'
                        ? () => goToStep('signature', { index })
                        : undefined
                    }
                    key={index}
                  />
                ))}
            </Fragment>
          ),
        },
        agreement: {
          title: 'Terms & conditions',
          descriptiveTitle: 'Terms & conditions',
          goTo: () =>
            goToStep('signature', {
              index: getNextSignatoryAgreement(state.signatories),
            }),
          status: status.steps[OfferStep.SIGNATORIES],
        },
      }),
    [fromPrepare, goToStep, listing, mode, state, status.steps, sellingStepText]
  )

  const shouldShowDocuments =
    listing.showDocuments && !!listing.documents.length

  const STEP_GROUPS = useMemo(() => {
    const offerDetailsTitle = descriptiveTitles
      ? 'Preparing an offer'
      : 'Offer details'
    const gettingOrganisedTitle = 'Getting organised'
    const buyersTitle = state.addTitleHoldersLater ? 'Buyers *' : 'Buyers'
    if (mode === OfferFormMode.PREPARE_OFFER) {
      return [
        {
          title: gettingOrganisedTitle,
          steps: [
            STEPS[OfferStep.FINANCE],
            STEPS[OfferStep.CONVEYANCER],
            ...(enableSellingStep ? [STEPS[OfferStep.SELLING]] : []),
            ...(shouldShowDocuments ? [STEPS[OfferStep.DOCUMENTS]] : []),
          ],
        },
        {
          title: buyersTitle,
          steps: [STEPS[OfferStep.SIGNATORIES]],
        },
        {
          title: offerDetailsTitle,
          steps: [
            STEPS[OfferStep.CONDITIONS],
            STEPS[OfferStep.SETTLEMENT],
            STEPS[OfferStep.AMOUNT],
          ],
        },
      ]
    } else {
      return [
        {
          title: offerDetailsTitle,
          steps: [
            ...(listing.type === 'referred'
              ? [STEPS[OfferStep.PROPERTY_AGENT_DETAILS]]
              : []),
            STEPS[OfferStep.AMOUNT],
            STEPS[OfferStep.SETTLEMENT],
            STEPS[OfferStep.CONDITIONS],
          ],
        },
        {
          title: gettingOrganisedTitle,
          steps: [
            STEPS[OfferStep.FINANCE],
            STEPS[OfferStep.CONVEYANCER],
            ...(enableSellingStep ? [STEPS[OfferStep.SELLING]] : []),
            ...(shouldShowDocuments ? [STEPS[OfferStep.DOCUMENTS]] : []),
          ],
        },
        {
          title: buyersTitle,
          steps: [STEPS[OfferStep.SIGNATORIES]],
        },
        ...(listing.acceptedOfferType === 'nonbinding' &&
        state.signatories.length > 0
          ? [{ title: 'Agreement', steps: [STEPS['agreement']] }]
          : []),
      ]
    }
  }, [
    descriptiveTitles,
    state.addTitleHoldersLater,
    state.signatories.length,
    mode,
    STEPS,
    enableSellingStep,
    shouldShowDocuments,
    listing.type,
    listing.acceptedOfferType,
  ])

  const title = useMemo(() => {
    if (mode === OfferFormMode.UPDATE_OFFER) {
      return 'Update your offer for'
    }
    if (mode === OfferFormMode.PREPARE_OFFER) {
      return 'Prepare to buy'
    }
    if (status.started) {
      return 'Your offer for'
    }
    return 'Make an offer for'
  }, [mode, status.started])

  return (
    <Fragment>
      <Title
        sub={
          state.property
            ? state.property.address.line1
            : listing.property.address.line1
        }
      >
        {title}
      </Title>
      {mode === OfferFormMode.CREATE_OFFER && !status.started ? (
        <OverviewPreroll />
      ) : null}
      {STEP_GROUPS.map(({ title, steps }, index) => {
        return (
          <Article key={index}>
            <ArticleTitle>{title}</ArticleTitle>
            <List>
              {steps.map((step: Step, index: number) => (
                <Fragment key={index}>
                  {title.includes('Buyers') ? step.data && <step.data /> : null}
                  <OfferStepListItem
                    key={index}
                    title={
                      descriptiveTitles ? step.descriptiveTitle : step.title
                    }
                    status={step.status}
                    customIcon={step.icon}
                    data={title.includes('Buyers') ? undefined : step.data}
                    onClick={step.goTo}
                  />
                  {title.includes('Buyers') && state.addTitleHoldersLater ? (
                    <p>* Additional buyers may be nominated at a later date.</p>
                  ) : null}
                </Fragment>
              ))}
            </List>
          </Article>
        )
      })}
      {mode === OfferFormMode.PREPARE_OFFER && buyer && status.completed ? (
        <Article>
          <ArticleTitle marginBottom>Ready to buy?</ArticleTitle>
          <Alert
            title="Ready to make an offer?"
            variant="success"
            aria-label="ready to make an offer alert"
          >
            Now that you've covered all the main areas, it looks like you should
            be ready to make an offer and try secure this property!
          </Alert>
        </Article>
      ) : null}

      {mode === OfferFormMode.CREATE_OFFER &&
        // TODO: remove /clean temporary REV specific hack
        (listing?.agency?.connectedDeveloperApps.some((app) =>
          ['app_t23hYdPp', 'app_ONUCvEav'].includes(app.id)
        ) ? (
          <Alert
            variant="info"
            icon={
              <Icon
                svg={
                  listing?.acceptedOfferType === 'binding'
                    ? IconShield
                    : IconShieldOff
                }
                fill="currentColor"
              />
            }
          >
            {listing?.acceptedOfferType === 'binding'
              ? 'Offers submitted can become a legally-binding agreement if accepted and signed by the vendor.'
              : 'Offers submitted give you the opportunity to start negotiations with the agent for your desired property. Offers submitted will be non-binding.'}
          </Alert>
        ) : listing?.acceptedOfferType === 'binding' ? (
          <BindingWarningAlert
            isBinding
            side="buyer"
            aria-label="binding offer alert"
          />
        ) : (
          <BindingWarningAlert
            isBinding={false}
            side="buyer"
            aria-label="non-binding offer alert"
          />
        ))}
      {!buyer &&
        (mode === OfferFormMode.PREPARE_OFFER ? (
          <p>
            Already started preparing?{' '}
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a
              onClick={() =>
                history.push(history.location.pathname + '/overview')
              }
              data-testid="p_signIn"
            >
              Sign in
            </a>{' '}
            with your phone number to continue where you left off.
          </p>
        ) : (
          <SignInReminder
            goToSignIn={() =>
              history.push(history.location.pathname + '/overview')
            }
          />
        ))}
      {submissionError ? (
        <CommonError>
          {typeof submissionError === 'string'
            ? submissionError
            : (submissionError as Error).message}
        </CommonError>
      ) : null}
      <PrimaryButton
        onClick={
          status.completed
            ? mode === OfferFormMode.PREPARE_OFFER
              ? handleSubmit
              : () => {
                  confirmSendOfferModal.show()
                }
            : !isNaN(status.latestStep?.index!)
            ? () =>
                goToStep(status.latestStep.route.name, {
                  index: status.latestStep.index!,
                })
            : () => goToStep(status.latestStep.route.name)
        }
        label={
          (status.completed &&
            mode === OfferFormMode.CREATE_OFFER &&
            'Send offer') ||
          (status.completed &&
            mode === OfferFormMode.PREPARE_OFFER &&
            'Make an offer') ||
          (status.completed &&
            mode === OfferFormMode.UPDATE_OFFER &&
            'Update offer') ||
          (status.started && 'Continue') ||
          'Get started'
        }
        pending={isPending}
        data-testid="submit-button"
      />
      <Modal
        size="sm"
        state={confirmSendOfferModal}
        title="Ready to send your offer?"
        aria-label="send offer confirmation modal"
      >
        <p className="grey">
          {(mode === OfferFormMode.CREATE_OFFER &&
            `We'll notify the agent and email you a confirmation. Good luck!`) ||
            (mode === OfferFormMode.UPDATE_OFFER &&
              `By sending your updated offer, your previous offer will be withdrawn.`)}
        </p>
        <ButtonCTA
          onClick={() => {
            handleSubmit()
            confirmSendOfferModal.hide()
          }}
        >
          Send
        </ButtonCTA>
      </Modal>
    </Fragment>
  )
}

export const GRAPHQL = gql`
  fragment OfferFormIndex_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    type
    acceptedOfferType
    property {
      address {
        line1
        state
      }
    }
    conditions {
      name
      region
      weight
      currentRevision {
        lineName
        region
        title
        isRequired
      }
    }
    documents {
      id
    }
    agents {
      id
    }
    vendorLeadsEnabled
    agency {
      id
      # TODO: remove /clean temporary REV specific hack
      connectedDeveloperApps {
        id
      }
    }
    showDocuments
  }

  fragment OfferForm_Overview_Buyer on Buyer {
    id
  }
`
