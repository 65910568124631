/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export function SignInReminder({ goToSignIn }: { goToSignIn: () => void }) {
  return (
    <p>
      Already made an offer or started one?{' '}
      <a onClick={goToSignIn} data-testid="p_signIn">
        Sign in
      </a>{' '}
      with your phone number to continue where you left off or check your offer
      status.
    </p>
  )
}
