import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OfferForm_CurrentOfferBuyerIdvStatusQueryVariables = Types.Exact<{
  buyerId: Types.Scalars['ID'];
  listingId: Types.Scalars['ID'];
}>;


export type OfferForm_CurrentOfferBuyerIdvStatusQuery = { offer?: { __typename: 'Offer', id: string, identityVerification: Array<{ __typename: 'OfferBuyerVerification', status: Types.BuyerVerificationStatus, statusMessage: string, buyer: { __typename: 'Buyer', id: string } }> } | null };


export const OfferForm_CurrentOfferBuyerIdvStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OfferForm_CurrentOfferBuyerIdvStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"buyerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"offer"},"name":{"kind":"Name","value":"getCurrentOfferByListingAndBuyer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"listingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}}},{"kind":"Argument","name":{"kind":"Name","value":"buyerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"buyerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"identityVerification"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"statusMessage"}},{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<OfferForm_CurrentOfferBuyerIdvStatusQuery, OfferForm_CurrentOfferBuyerIdvStatusQueryVariables>;