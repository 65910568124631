/** @jsx jsx */
import { gql } from '@apollo/client'
import { jsx } from '@emotion/react'
import { Activity } from '@propps-au/ui'
import { styles } from './listing-buyer-announcement-sent'
import { ListingActivity_ListingNoticePostedEventFragment } from './__generated__/listing-notice-posted.generated'

export const ListingNoticePostedEvent = ({
  event,
}: {
  event: ListingActivity_ListingNoticePostedEventFragment
}) => {
  if (
    event.notice.__typename === 'BestAndFinalNotice' &&
    !event.notice.enabled
  ) {
    return null
  }
  const agent = event.notice.agents[0]
  return (
    <Activity.Event
      firstName={agent?.firstName ?? 'An agent'}
      lastName={agent?.lastName ?? ''}
      createdAt={event.createdAt}
      title=" posted an announcement"
    >
      <p css={styles.preformatted}>{event.notice.message}</p>
    </Activity.Event>
  )
}

gql`
  fragment ListingActivity_ListingNoticePostedEvent on ListingNoticePostedEvent {
    createdAt
    eventId
    notice {
      ... on INotice {
        id
        agents {
          id
          firstName
          lastName
        }
        message
      }
      ... on BestAndFinalNotice {
        enabled
      }
    }
  }
`
