import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BuyerAuthFlow_ListingFragment = { __typename: 'Listing', legallyBindingOffersAllowed: boolean, property: { __typename: 'Property', address: { __typename: 'Address', line1: string } } };

export type GetCurrentBuyerQuery2QueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentBuyerQuery2Query = { me?: { __typename: 'User', uid: string, phone?: string | null, buyer?: { __typename: 'Buyer', id: string } | null } | null };

export type GetCurrentUserPhoneNumberQuery2QueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserPhoneNumberQuery2Query = { user?: { __typename: 'User', uid: string, phone?: string | null } | null };

export type CreateBuyerMutationVariables = Types.Exact<{
  input: Types.CreateBuyerInput;
}>;


export type CreateBuyerMutation = { result: { __typename: 'CreateBuyerResult', token: string, buyer: { __typename: 'Buyer', id: string } } };

export const BuyerAuthFlow_ListingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BuyerAuthFlow_Listing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Listing"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"property"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"line1"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"legallyBindingOffersAllowed"}}]}}]} as unknown as DocumentNode<BuyerAuthFlow_ListingFragment, unknown>;
export const GetCurrentBuyerQuery2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentBuyerQuery2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetCurrentBuyerQuery2Query, GetCurrentBuyerQuery2QueryVariables>;
export const GetCurrentUserPhoneNumberQuery2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentUserPhoneNumberQuery2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"user"},"name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}}]}}]}}]} as unknown as DocumentNode<GetCurrentUserPhoneNumberQuery2Query, GetCurrentUserPhoneNumberQuery2QueryVariables>;
export const CreateBuyerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBuyerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBuyerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"result"},"name":{"kind":"Name","value":"createBuyer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"token"}}]}}]}}]} as unknown as DocumentNode<CreateBuyerMutation, CreateBuyerMutationVariables>;