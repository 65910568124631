/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  formatPhoneNumber,
  FormattedPhoneNumberInput,
  getErrors,
  useAuthVerification,
  VerificationErrorCode,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  buttonReset,
  CommonError,
  Input,
  Label,
  link,
  Title,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import isMobile from 'is-mobile'
import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { VerificationChannel } from '..'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'

const isMobileDevice = isMobile()

export function AuthSendTo({
  title,
  sub,
  verification,
  channel,
  switchToSMS,
  switchToEmail,
  onComplete,
}: {
  title?: string
  sub?: string
  verification: ReturnType<typeof useAuthVerification>
  channel: VerificationChannel
  switchToSMS: () => void
  switchToEmail: () => void
  onComplete: (sendTo: string) => void
}) {
  const analytics = useAnalytics()
  const [error, setError] = useState('')
  const form = useFormik({
    initialValues: { sendTo: '' },
    validationSchema: Yup.object({
      sendTo: Yup.string().required(
        `Please enter your ${
          channel === 'sms' ? 'phone number' : 'email address'
        }.`
      ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        let sendTo: string
        if (channel === 'sms') {
          sendTo = formatPhoneNumber(values.sendTo, {
            defaultCountry: 'AU',
          })
        } else {
          sendTo = values.sendTo
        }
        await verification.start(channel, sendTo)
        onComplete(sendTo)
      } catch (err) {
        setError(handleStartVerificationError(err))
      }
    },
  })

  useEffect(() => {
    verification.reset()
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewLogin>({
      type: BuyerEvent.VIEW_LOGIN,
      channel,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.resetForm()
    if (form.errors.sendTo) {
      form.setErrors({ sendTo: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel])

  return (
    <Fragment>
      <Title sub={sub}>{title || 'Sign in to Propps'}</Title>
      <form onSubmit={form.handleSubmit}>
        {channel === 'sms' && (
          <Fragment>
            <Label label="Mobile number">
              <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                autoFocus={!isMobileDevice}
                component={Input}
                defaultCountry="AU"
                type="text"
                placeholder="..."
                {...form.getFieldProps(`sendTo`)}
                onChange={(value) => form.setFieldValue(`sendTo`, value)}
                errors={getErrors(form, `sendTo`)}
                autoComplete="tel"
                inputMode="tel"
              />
            </Label>
            <p className="grey">
              We’ll send you an SMS to verify your number, as we use it to save
              and retrieve your data. You can also{' '}
              <button
                type="button"
                onClick={() => switchToEmail()}
                css={[buttonReset, link]}
              >
                use email instead
              </button>
              .
            </p>
          </Fragment>
        )}
        {channel === 'email' && (
          <Fragment>
            <Label label="Email">
              <Input
                type="email"
                autoComplete="email"
                autoFocus={!isMobileDevice}
                {...form.getFieldProps('sendTo')}
                errors={getErrors(form, `sendTo`)}
              />
            </Label>
            <p className="grey">
              We’ll send you a code to verify your email, as we use it to save
              and retrieve your data. You can also{' '}
              <button
                type="button"
                onClick={() => switchToSMS()}
                css={[buttonReset, link]}
              >
                use SMS instead
              </button>
              .
            </p>
          </Fragment>
        )}
        {error && <CommonError>{error}</CommonError>}
        <PrimaryButton
          label="Continue"
          pending={form.isSubmitting}
          onClick={form.submitForm}
        />
      </form>
    </Fragment>
  )
}

export function handleStartVerificationError(err: any) {
  switch (err.code) {
    case VerificationErrorCode.PHONE_NUMBER_INCORRECT:
      return 'This phone number is not associated with your account.'
    case VerificationErrorCode.PHONE_NUMBER_TAKEN:
      return 'This phone number is already in use.'
    case VerificationErrorCode.EMAIL_ADDRESS_TAKEN:
      return 'This email address is already in use.'
    case VerificationErrorCode.TOO_MANY_ATTEMPTS:
      return 'Too many attempts. Please wait up to 10 minutes and then try again. You can contact help@propps.com for support.'
    default:
      return `We were unable to send you a verification code. Try again later or contact help@propps.com for support.`
  }
}
