import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorReporting, useAuthState } from '@propps-au/client'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useListing } from '../../../pages/listing/listing-context'
import {
  SaveVendorLeadInput,
  VendorLeadSituation as _VendorLeadSituation,
} from '../../../__generated__/types'
import {
  SaveVendorLeadMutationDocument,
  SubmitVendorLeadMutationDocument,
  VendorLeadBuyerQueryDocument,
  VendorLeadBuyer_BuyerFragment,
  VendorLeadQueryDocument,
  VendorLead_VendorLeadFragment,
} from './__generated__/vendor-lead-context.generated'

export type VendorLeadSituation = _VendorLeadSituation

export type VendorLeadValues = {
  situation: VendorLeadSituation | null
  address: {
    line1: string
    city: string
    postcode: string
    state: string
  } | null
}

const VENDOR_LEAD_DEFAULT_VALUES = {
  situation: null,
  address: null,
}

type VendorLeadContextType = {
  state: VendorLeadValues
  update: (state: VendorLeadValues) => Promise<void>
  submit: () => Promise<void>
  error: string
}

export const VendorLeadContext = createContext<VendorLeadContextType | null>(
  null
)

export function VendorLeadProvider({
  children,
}: {
  children?: React.ReactNode
}) {
  const [state, setState] = useState<VendorLeadValues>(
    VENDOR_LEAD_DEFAULT_VALUES
  )
  const [error, setError] = useState('')
  const { id: listingId } = useListing()
  const auth = useAuthState()
  const { data: leadData } = useQuery(VendorLeadQueryDocument, {
    variables: { source: { listingId, buyerId: auth.role?.id! } },
    skip: !auth.role,
  })
  const { data: userData } = useQuery(VendorLeadBuyerQueryDocument)
  const [saveLead] = useMutation(SaveVendorLeadMutationDocument)
  const [submitLead] = useMutation(SubmitVendorLeadMutationDocument)

  const buyer = userData?.me?.buyer
  const lead = leadData?.vendorLead

  useEffect(() => {
    if (lead) {
      setState(mapGraphQLTypeToState(lead))
    }
  }, [lead])

  const update = async (newState: VendorLeadValues) => {
    if (!state || !buyer) {
      return
    }

    const { data, errors } = await saveLead({
      variables: {
        input: constructMutationInput(listingId, buyer, newState),
      },
    })

    if (data) {
      setState(mapGraphQLTypeToState(data.saveVendorLead.vendorLead))
    }

    if (errors) {
      ErrorReporting.report(errors)
      setError(errors[0].message)
    }
  }

  const submit = async () => {
    if (!state || !buyer) {
      return
    }

    const { errors } = await submitLead({
      variables: {
        input: constructMutationInput(listingId, buyer, state),
      },
    })

    if (errors) {
      ErrorReporting.report(errors)
      setError(errors[0].message)
    }
  }

  return (
    <VendorLeadContext.Provider value={{ state, update, submit, error }}>
      {children}
    </VendorLeadContext.Provider>
  )
}

export function useVendorLead() {
  const context = useContext(VendorLeadContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!context) {
      throw new Error(
        'useVendorLead muse be called from within a <VendorLeadProvider>'
      )
    }
  }

  return context!
}

gql`
  fragment VendorLead_VendorLead on VendorLead {
    id
    name
    email
    phone
    situation
    address {
      line1
      city
      postcode
      state
    }
    submitted
  }

  fragment VendorLead_Listing on Listing {
    vendorLeadsEnabled
  }

  fragment VendorLeadBuyer_Buyer on Buyer {
    id
    firstName
    lastName
    phone
    email
  }

  query VendorLeadBuyerQuery {
    me {
      uid
      buyer {
        ...VendorLeadBuyer_Buyer
      }
    }
  }

  query VendorLeadQuery($source: VendorLeadSourceInput!) {
    vendorLead(source: $source) {
      ...VendorLead_VendorLead
    }
  }

  mutation SaveVendorLeadMutation($input: SaveVendorLeadInput!) {
    saveVendorLead(input: $input) {
      vendorLead {
        ...VendorLead_VendorLead
      }
    }
  }

  mutation SubmitVendorLeadMutation($input: SaveVendorLeadInput!) {
    submitVendorLead(input: $input) {
      vendorLead {
        ...VendorLead_VendorLead
      }
    }
  }
`

function mapGraphQLTypeToState(
  data: VendorLead_VendorLeadFragment
): VendorLeadValues {
  return {
    address: data.address
      ? {
          line1: data.address.line1,
          city: data.address.city,
          state: data.address.state,
          postcode: data.address.postcode,
        }
      : null,
    situation: data.situation ?? null,
  }
}

function constructMutationInput(
  listingId: string,
  buyer: VendorLeadBuyer_BuyerFragment,
  state: VendorLeadValues
): SaveVendorLeadInput {
  return {
    data: {
      name: [buyer.firstName, buyer.lastName].join(' '),
      phone: buyer.phone,
      email: buyer.email,
      address: state.address,
      situation: state.situation,
    },
    source: {
      listingId: listingId,
      buyerId: buyer.id,
    },
  }
}
