import { useLazyQuery, useMutation } from '@apollo/client'
import {
  ReferrerPayloadSchema,
  ReferrerReferenceSchema,
} from '@propps/frames-url-params'
import gql from 'graphql-tag'
import qs from 'qs'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { z } from 'zod'
import { Loading } from '../../components/loading'
import {
  ListingIdentifierInput,
  ListingPriceIndicationInput,
  ResolveReferredListingInput,
} from '../../__generated__/types'
import { ErrorPage } from '../error'
import { OfferHubHistoryState } from '../listing/offer-hub'
import {
  ResolveDocument,
  RetrieveDocument,
} from './__generated__/landing.generated'

export default function ReferrerRoute() {
  const location = useLocation()
  const history = useHistory<OfferHubHistoryState>()
  const [retrieve, { data: retrievedPayload, error: retrieveError }] =
    useLazyQuery(RetrieveDocument)

  const [resolve, { data: resolvedListing, error: resolveError }] =
    useMutation(ResolveDocument)

  const payload = useMemo(() => {
    if (retrievedPayload) {
      const _payload =
        retrievedPayload.retrieveTemporaryReferrerListingData!.payload
      return ReferrerPayloadSchema.parse(JSON.parse(_payload))
    }

    const result = SearchSchema.parse(qs.parse(location.search.substr(1)))

    if ('refId' in result) {
      retrieve({
        variables: {
          retrieveTemporaryReferrerListingDataId: result.refId,
        },
      })
      return null
    }
    return result
  }, [location.search, retrieve, retrievedPayload])

  useEffect(() => {
    if (payload) {
      resolve({ variables: { input: convertPayloadToMutationInput(payload) } })
    }
  }, [payload, resolve])

  useEffect(() => {
    if (resolvedListing) {
      const listingId = resolvedListing.resolveReferredListing.listing.id
      history.replace(['listing', listingId, 'make-offer'].join('/'), {
        fromRedirect: true,
      })
    }
  }, [history, resolvedListing])

  if (!payload) {
    return null
  }

  if (retrieveError || resolveError) {
    return (
      <ErrorPage
        title="Something went wrong"
        errorMessage="We're working on it."
      />
    )
  }

  return <Loading />
}

const convertPayloadToMutationInput = (
  payload: z.infer<typeof ReferrerPayloadSchema>
): ResolveReferredListingInput => {
  return {
    property: {
      address: {
        line1: orEmptyString(payload.address.line1),
        city: orEmptyString(payload.address.city),
        postcode: orEmptyString(payload.address.postcode),
        state: orEmptyString(payload.address.state),
      },
    },
    source: payload.source,
    agentDetails: payload.agents?.map((agent) => ({ ...agent })) ?? [],
    priceIndication: (payload.priceIndication
      ? {
          ...payload.priceIndication,
          min: payload.priceIndication.min
            ? parseInt(payload.priceIndication.min!)
            : undefined,
          max: payload.priceIndication.max
            ? parseInt(payload.priceIndication.max!)
            : undefined,
        }
      : {
          type: 'custom',
          message: '',
        }) as ListingPriceIndicationInput,
    listingType: 'referred',
    identifiers: payload.identifiers as ListingIdentifierInput[],
    url: payload.url,
    published: true,
  }
}

const orEmptyString = (value: string | undefined | null) => value ?? ''

const SearchSchema = z.union([ReferrerPayloadSchema, ReferrerReferenceSchema])

export const GRAPHQL = gql`
  query Retrieve($retrieveTemporaryReferrerListingDataId: ID!) {
    retrieveTemporaryReferrerListingData(
      id: $retrieveTemporaryReferrerListingDataId
    ) {
      appId
      createdAt
      foreignId
      id
      payload
    }
  }

  mutation Resolve($input: ResolveReferredListingInput!) {
    resolveReferredListing(input: $input) {
      listing {
        id
        source {
          appId
          foreignId
        }
      }
    }
  }
`
