/** @jsx jsx */
import { gql } from '@apollo/client'
import { jsx } from '@emotion/react'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Icon,
  IconArrowOutUpRight,
  IconFileSingle,
  List,
  ListItem,
} from '@propps-au/ui'
import { Fragment } from 'react'
import { useAnalytics } from '../analytics'
import { formatBytes } from './formatBytes'
import { DocumentsList_ListingFragment } from './__generated__/index.generated'

export function defaultFilename(name: string) {
  switch (name) {
    case 'COS':
      return 'Contract of Sale'
    case 'sec32':
      return 'Section 32'
    default:
      return name
  }
}

export function DocumentsList({
  documents,
}: {
  documents: DocumentsList_ListingFragment['documents']
}) {
  const analytics = useAnalytics()
  return (
    <List>
      {documents.map((document, index) => (
        <ListItem
          onClick={() => {
            analytics.logPixelEvent<Pixel.BuyerEvent.DownloadDocument>({
              type: BuyerEvent.DOWNLOAD_DOCUMENT,
              ...analytics.getEventMetadata(),
              documentId: document.id,
              documentType: document.name,
              documentUrl: document.url,
            })
            window.open(document?.url ?? '', '_blank')
          }}
          key={`document-item-${index}`}
          start={<Icon svg={IconFileSingle} />}
          end={<Icon svg={IconArrowOutUpRight} />}
        >
          {document.filename || defaultFilename(document.name)}

          {document.size && (
            <Fragment>
              <br />
              <small>{formatBytes(document.size)}</small>
            </Fragment>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export const GRAPHQL = gql`
  fragment DocumentsList_Listing on Listing {
    documents {
      id
      name
      filename
      url
      size
    }
  }
`
