import { Auth, ErrorReporting, useAuthVerification } from '@propps-au/client'
import { CommonError } from '@propps-au/ui'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  AuthVerifyCode,
  handleStartVerificationError,
} from '../../buyer-auth-flow/steps'
import { BuyerCreateData } from './create-buyer'

export function AddBuyerVerifyPhone({
  auth,
  buyer,
  verification,
}: {
  auth: Auth
  buyer: BuyerCreateData
  verification: ReturnType<typeof useAuthVerification>
}) {
  const match = useRouteMatch()
  const history = useHistory()
  const [error, setError] = useState('')

  useEffect(() => {
    verification.start('sms', buyer.phone).catch((err: any) => {
      setError(handleStartVerificationError(err))
      ErrorReporting.report(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onComplete = async (token: string) => {
    await auth.signIn(token)
    history.push(match.url, {
      status: 'validate-buyer-email',
      buyer,
    })
  }
  return (
    <>
      <AuthVerifyCode
        channel="sms"
        sendTo={buyer.phone}
        verification={verification}
        onComplete={onComplete}
      />
      {error && <CommonError>{error}</CommonError>}
    </>
  )
}
