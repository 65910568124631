export function flattenObject<T extends Record<string, any>>(
  object: T,
  path: string | null = null,
  separator = '.'
): T {
  return Object.keys(object).reduce((acc: T, key: string): T => {
    let newPath: string
    if (!isNaN(Number(key))) {
      newPath = path + '[' + key + ']'
    } else {
      newPath = [path, key].filter(Boolean).join(separator)
    }
    return typeof object?.[key] === 'object'
      ? { ...acc, ...flattenObject(object[key], newPath, separator) }
      : { ...acc, [newPath]: object[key] }
  }, {} as T)
}

export function flatten<T>(arr: T[][]) {
  return arr.reduce((a, b) => a.concat(b), [] as T[])
}
