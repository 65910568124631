import { z } from 'zod'

export enum _EventType {
  LISTING_PAGE_VISITED = 'listing-page-visited',
}

export namespace ScriptEvent {
  export import EventType = _EventType

  export interface ListingPageVisited {
    type: _EventType.LISTING_PAGE_VISITED
    appId: string
    listingId: string
    foreignId: string
    url: string
  }

  export const ListingPageVisitedEventSchema = z.object({
    type: z.literal(_EventType.LISTING_PAGE_VISITED),
    appId: z.string(),
    listingId: z.string(),
    foreignId: z.string(),
    url: z.string(),
  })

  export type Event = ListingPageVisited

  export const Validators: { [key in _EventType]: z.ZodObject<any> } = {
    [_EventType.LISTING_PAGE_VISITED]: ListingPageVisitedEventSchema,
  }
}
