import { createContext, useContext, useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'

export const FrameVisibilityContext = createContext<BehaviorSubject<boolean>>(
  null!
)

export function useFrameVisibility() {
  const subject = useContext(FrameVisibilityContext)

  const [value, setValue] = useState(subject.value)

  useEffect(() => {
    const sub = subject.subscribe((next) => {
      setValue(next)
    })
    return () => sub.unsubscribe()
  }, [subject])

  return value
}
