export type OfferFormValues = {
  property: Property | null
  agents: Agent[]
  expiry: Date | null
  attributedAgentId: string | null
  amount: number | null
  depositAmount: number | null
  depositPercentage: 'five' | 'ten' | 'other' | null
  settlement: Settlement | null
  hasReviewedConditions: boolean
  conditions: Condition[]
  customCondition: string
  customConditionAdded: boolean
  finance: Finance | null
  conveyancer: Conveyancer | null
  reviewedDocumentIds: string[] | null
  addTitleHoldersLater: boolean
  signatories: Signatory[]
}

export type Settlement =
  | { type: 'date'; date: string }
  | { type: 'days'; days: number }

export type Condition = {
  region: string
  line: string
  id: string
  params: { name: string; value: string }[]
}
export enum FinanceType {
  NONE = 'none',
  APPROVED = 'approved',
  NOT_APPLICABLE = 'not_applicable',
  OTHER = 'other',
}

export type Finance =
  | {
      type: FinanceType.NONE
      institution: null
      date: null
      note: null
    }
  | {
      type: FinanceType.APPROVED
      institution: string
      date: string
      note: null
    }
  | {
      type: FinanceType.NOT_APPLICABLE
      institution: null
      date: null
      note: string | null
    }
  | {
      type: FinanceType.OTHER
      institution: null
      date: null
      note: string | null
    }

export enum ConveyancerType {
  NONE = 'none',
  PROVIDED = 'provided',
  LEGAL_PROCESS_HELP = 'need_legal_process_help',
  CONVEYANCER_HELP = 'need_conveyancer_help',
}

export type Conveyancer =
  | {
      type: ConveyancerType.PROVIDED
      name: string
      phone: string | null
    }
  | {
      type: ConveyancerType.NONE
      name: null
      phone: null
    }
  | {
      type: ConveyancerType.LEGAL_PROCESS_HELP
      name: null
      phone: null
    }
  | {
      type: ConveyancerType.CONVEYANCER_HELP
      name: null
      phone: null
    }

export type ContractRevision = { id: string; type: string }

export type TermsRevision = { id: string; line: string }

export type Signatory = {
  address: string
  agreedContractRevision: ContractRevision | null
  agreedPrivacyPolicyRevision: TermsRevision | null
  agreedTermsRevision: TermsRevision | null
  agreeToIdentityVerification: boolean
  buyerId: string
  dateOfBirth: string
  email: string
  firstName: string
  identityDocument: IdentityDocument
  isPrimaryBuyer: boolean
  lastName: string
  middleName: string
  onBehalfOf: RepresentedEntity
  phone: string
  signatureData: string | null
}

export type RepresentedEntity =
  | {
      type: 'self'
    }
  | {
      type: 'person' | 'company'
      name: string
    }

export type IdentityDocument =
  | {
      country: string
      documentNo: string
      region: string
      type: 'drivers_licence'
    }
  | {
      country: string
      documentNo: string
      type: 'passport'
    }

export type Property = {
  address: {
    line1: string
    city: string
    state: string
    postcode: string
  }
}

export type Agent = {
  id?: string
  firstName: string
  lastName: string
  phone: string
  email: string
}

export const DEFAULT_OFFER_FORM_VALUES: OfferFormValues = {
  property: null,
  agents: [],
  expiry: null,
  attributedAgentId: null,
  amount: null,
  depositAmount: null,
  depositPercentage: null,
  settlement: { type: 'days', days: 1 },
  hasReviewedConditions: true,
  conditions: [],
  customCondition: '',
  customConditionAdded: false,
  finance: {
    type: FinanceType.NONE,
    institution: null,
    date: null,
    note: null,
  },
  conveyancer: {
    type: ConveyancerType.NONE,
    name: null,
    phone: null,
  },
  reviewedDocumentIds: [],
  addTitleHoldersLater: false,
  signatories: [],
}
