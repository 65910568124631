/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { motion } from 'framer-motion'
import { useContext } from 'react'
import useMedia from 'use-media'
import { IsCIContext } from './is-ci'

export function PageTransition({
  children,
  disabled = false,
}: {
  children?: React.ReactNode
  disabled?: boolean
}) {
  const isCI = useContext(IsCIContext)

  const prefersReducedMotion: boolean = useMedia('(prefers-reduced-motion)')

  if (prefersReducedMotion || disabled || isCI) {
    return (
      <div
        css={css`
          height: 100%;
        `}
      >
        {children}
      </div>
    )
  }

  return (
    <motion.div
      initial={{ y: 64, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -64, opacity: 0 }}
      transition={{ type: 'spring', damping: 40, stiffness: 400 }}
      css={css`
        height: 100%;
      `}
    >
      {children}
    </motion.div>
  )
}
