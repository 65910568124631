import { deleteApp, FirebaseOptions, initializeApp } from 'firebase/app'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'

export function useTemporaryFirebaseApp(config: FirebaseOptions) {
  const [app] = useState(() => initializeApp(config, nanoid()))
  useEffect(() => {
    return () => {
      deleteApp(app)
    }
  }, [app])

  return app
}
