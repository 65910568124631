import { gql, useQuery } from '@apollo/client'
import { parseISO } from 'date-fns'
import React, { createContext, useContext } from 'react'
import { useListing } from '../../pages/listing/listing-context'
import { ListingBestAndFinalNoticeDocument } from './__generated__/best-final.generated'

type BestAndFinal = {
  message: string
  expiry: Date | null
}

type BestAndFinalContextData = {
  ready: boolean
  notice: BestAndFinal | null
}

export const BestAndFinalContext =
  createContext<BestAndFinalContextData | null>(null)

export const BestAndFinalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { id } = useListing()
  const { data, loading } = useQuery(ListingBestAndFinalNoticeDocument, {
    variables: {
      listingId: id,
    },
    errorPolicy: 'all',
  })
  const n = data?.listing?.bestAndFinalNotice
  const notice = n
    ? {
        message: n.message,
        expiry: n.expiry ? parseISO(n.expiry) : null,
      }
    : null

  const context = {
    ready: !loading && !!data,
    notice,
  }
  return (
    <BestAndFinalContext.Provider value={context}>
      {children}
    </BestAndFinalContext.Provider>
  )
}

export const useBestAndFinal = () => {
  const value = useContext(BestAndFinalContext)
  if (!value) {
    throw new Error(
      'useBestAndFinal must be used within a <BestAndFinalProvider />'
    )
  }
  return value
}

gql`
  query ListingBestAndFinalNotice($listingId: ID!) {
    listing(id: $listingId) {
      id
      bestAndFinalNotice {
        id
        message
        expiry
      }
    }
  }
`
