import { ApolloLink } from '@apollo/client'
import { DocumentNode, visit } from 'graphql'

export function OperationNamePrefixLink(prefix: string) {
  return new ApolloLink((operation, forward) => {
    const operationName = `${prefix}_${operation.operationName}`

    const updated = updateOperationName(
      operation.operationName,
      operationName,
      operation.query
    )

    if (updated) {
      operation.operationName = operationName
      operation.query = updated
    }

    return forward(operation)
  })
}

export function updateOperationName(
  target: string,
  value: string,
  ast: DocumentNode
) {
  let found = false

  const result: DocumentNode = visit(ast, {
    OperationDefinition: (node) => {
      if (node.name && node.name?.value === target) {
        found = true
        return {
          ...node,
          name: {
            ...node.name,
            value,
          },
        }
      }
    },
  })

  if (found) {
    return result
  }

  return null
}
