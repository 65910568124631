import { AuthStateResolved } from './auth'

/** @deprecated use RoleObject instead */
export type RoleRecord = RoleObject
export type RoleObject = { name: Role; id: string }
export type AuthCheck = (
  auth: Pick<AuthStateResolved, 'role' | 'uid'>
) => boolean

export enum Role {
  ADMIN = 'admin',
  BUYER = 'buyer',
  AGENT = 'agent',
}

export const isAdmin: AuthCheck = ({ role }) => {
  return role?.name === Role.ADMIN
}

export const isAgent: AuthCheck = ({ role }) => {
  return role?.name === Role.AGENT
}

export const isBuyer: AuthCheck = ({ role }) => {
  return role?.name === Role.BUYER
}

export const isSignedIntoRole: AuthCheck = ({ role }) => {
  return !!role
}

export const isNotSignedIntoRole: AuthCheck = ({ role }) => !role
