import { z } from 'zod'
import { PixelEventBaseSchema } from '../pixel-event'

export enum _EventType {
  SESSION_INIT = 'init',
  SESSION_FINGERPRINT_USER_LINK = 'fingerprint-user-link',
  SESSION_ATTRIBUTION = 'attribution',
}
export namespace SessionEvent {
  export import EventType = _EventType
  interface ISessionEvent {
    type: string
    sessionId: string
  }

  const BaseSchema = PixelEventBaseSchema.extend({
    sessionId: z.string(),
  })

  export interface SessionInit extends ISessionEvent {
    type: _EventType.SESSION_INIT
    fingerprint: string | null
    referrer: {
      href: string | null
      path: string | null
      host: string | null
    } | null
  }

  export const SessionInitEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SESSION_INIT),
    fingerprint: z.string().nullable(),
    referrer: z
      .object({
        href: z.string().nullable(),
        path: z.string().nullable(),
        host: z.string().nullable(),
      })
      .nullable(),
  })

  export interface SessionFigerprintUserLink extends ISessionEvent {
    type: _EventType.SESSION_FINGERPRINT_USER_LINK
    fingerprint: string | null
    uid: string
  }

  export const SessionFigerprintUserLinkEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SESSION_FINGERPRINT_USER_LINK),
    fingerprint: z.string().nullable(),
    uid: z.string(),
  })

  export interface SessionAttribution extends ISessionEvent {
    type: _EventType.SESSION_ATTRIBUTION
    attributionId: string
  }

  export const SessionAttributionEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SESSION_ATTRIBUTION),
    attributionId: z.string(),
  })

  export type Event =
    | SessionInit
    | SessionFigerprintUserLink
    | SessionAttribution

  export const Validators: { [key in _EventType]: z.ZodObject<any> } = {
    [_EventType.SESSION_INIT]: SessionInitEventSchema,
    [_EventType.SESSION_FINGERPRINT_USER_LINK]:
      SessionFigerprintUserLinkEventSchema,
    [_EventType.SESSION_ATTRIBUTION]: SessionAttributionEventSchema,
  }
}
