import { gql, useQuery } from '@apollo/client'
import {
  Alert,
  CommonError,
  Flex,
  FlexItem,
  Icon,
  IconCircleInfo,
  IconEye,
  pxToRem,
  StackHeader,
  StackMain,
  Title,
  Tooltip,
} from '@propps-au/ui'
import React, { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ActivityFeed } from '../../../components/activity-feed/activity-feed'
import { ListingActivity_ActivityBuyerEventConnectionFragment } from '../../../components/activity-feed/__generated__/activity-feed.generated'
import { useAnalytics } from '../../../components/analytics'
import { CountdownTimer } from '../../../components/countdown-timer'
import { useCountdownTimer } from '../../../components/countdown-timer-provider'
import { FrameContentLayout } from '../../../components/frame-content-layout'
import { PrimaryButton } from '../../../components/primary-button'
import { useSignOutAndClose } from '../../../components/sign-out'
import {
  Activity_ListingFragment,
  ListingActivityBuyerQueryDocument,
  ListingRegisteredBuyersDocument,
} from './__generated__/activity.generated'
const Layout = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory()
  const signOut = useSignOutAndClose()
  const countdownTimer = useCountdownTimer()

  return (
    <Fragment>
      <StackHeader
        variant="frames"
        onBack={() => history.goBack()}
        onSignOut={() => {
          signOut()
          history.goBack()
        }}
      >
        {countdownTimer.countdownTimer?.enabled &&
          countdownTimer.countdownTimer?.expiry && (
            <CountdownTimer
              variant="alternate"
              status={countdownTimer.countdownTimer.label}
              timer={{
                loading: !countdownTimer.ready,
                size: 'sm',
                endDate: countdownTimer.countdownTimer.expiry,
              }}
            />
          )}
      </StackHeader>
      <StackMain variant="frames">{children}</StackMain>
    </Fragment>
  )
}

export function Activity({ listing }: { listing: Activity_ListingFragment }) {
  const analytics = useAnalytics()

  const { data, loading, error, fetchMore, startPolling } = useQuery(
    ListingActivityBuyerQueryDocument,
    {
      variables: {
        id: listing.id,
        first: 40,
        after: null,
      },
    }
  )

  useEffect(() => {
    window.scroll(0, 0)
    startPolling(15e3)
  }, [])

  const buyerCompetitionState = data?.listing?.buyerCompetition?.state

  useEffect(() => {
    analytics.pixel?.amplitude?.logEvent(
      `view activity (${buyerCompetitionState})`
    )
  }, [analytics, buyerCompetitionState])

  const { data: listingData } = useQuery(ListingRegisteredBuyersDocument, {
    variables: {
      id: listing.id,
    },
  })

  const pageInfo = data?.listing?.activityBuyer.activity?.events.pageInfo
  const onLoadMore = () => {
    if (pageInfo?.hasNextPage) {
      return fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      })
    }
    return Promise.resolve(null)
  }

  const totalBuyers = listingData?.listing?.registeredBuyers?.total || 0
  const exposeCompetition = listingData?.listing?.exposeCompetition

  return (
    <Layout>
      <FrameContentLayout>
        <Title sub={listing.property.address.line1}>Latest updates for</Title>
        {exposeCompetition && totalBuyers >= 2 && (
          <Alert icon={<Icon svg={IconEye} />}>
            <Flex
              xs={{
                wrap: 'nowrap',
                alignItems: 'center',
              }}
            >
              <FlexItem>
                <b>{totalBuyers} people</b> are interested in this property.
              </FlexItem>
              <FlexItem xs={{ margin: { left: true } }}>
                <Tooltip
                  icon={{ svg: IconCircleInfo, size: pxToRem(18) }}
                  content="This is the number of buyers who have either made an offer on this property, or registered their interest in receiving updates via the Propps Platform. There may be other offers submitted directly to the agent."
                />
              </FlexItem>
            </Flex>
          </Alert>
        )}
        <ActivityFeed
          events={extractEvents(data?.listing?.activityBuyer.activity?.events)}
          loadMore={pageInfo?.hasNextPage ? onLoadMore : undefined}
          loading={loading && !data}
        />
        {!!error && <CommonError>Could not load latest updates</CommonError>}
        {loading && <PrimaryButton pending />}
      </FrameContentLayout>
    </Layout>
  )
}

function extractEvents(
  connection?: ListingActivity_ActivityBuyerEventConnectionFragment
) {
  if (!connection) return []
  return connection.edges.map((edge) => edge.node)
}

export const GRAPHQL = gql`
  fragment Activity_Listing on Listing {
    id
    property {
      address {
        line1
      }
    }
    agency {
      id
    }
    agents {
      id
    }
  }

  query ListingRegisteredBuyers($id: ID!) {
    listing(id: $id) {
      id
      exposeCompetition
      registeredBuyers {
        total
      }
    }
  }

  query ListingActivityBuyerQuery($id: ID!, $first: Int!, $after: String) {
    listing(id: $id) {
      id
      buyerCompetition {
        state
      }
      activityBuyer {
        id
        activity {
          id
          events(first: $first, after: $after) {
            ...ListingActivity_ActivityBuyerEventConnection
          }
        }
      }
    }
  }
`
