import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ListingBestAndFinalNoticeQueryVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
}>;


export type ListingBestAndFinalNoticeQuery = { listing?: { __typename: 'Listing', id: string, bestAndFinalNotice?: { __typename: 'BestAndFinalNotice', id: string, message: string, expiry?: string | null } | null } | null };


export const ListingBestAndFinalNoticeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListingBestAndFinalNotice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"bestAndFinalNotice"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"expiry"}}]}}]}}]}}]} as unknown as DocumentNode<ListingBestAndFinalNoticeQuery, ListingBestAndFinalNoticeQueryVariables>;