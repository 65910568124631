/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import { BUYER_TERMS_LINE, PRIVACY_POLICY_LINE } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Article,
  ArticleTitle,
  Checkbox,
  CommonError,
  Flex,
  List,
  ListItem,
  pxToRem,
  Title,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import { Fragment, useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { defaultFilename } from '../documents-list'
import { PrimaryButton } from '../primary-button'
import SignatureCanvas from '../SignatureCanvas'
import { useOfferForm } from './context'
import { ContractRevision, Signatory, TermsRevision } from './values'
import {
  SignatureGetTermsQueryDocument,
  Signature_ListingFragment,
} from './__generated__/signature.generated'

enum AgreementNames {
  ContractOfSale = 'COS',
  Section32 = 'sec32',
  TermsAndConditions = 'tnc',
  PrivacyPolicy = 'privacyPolicy',
}

type Document = {
  displayName: string
  name: string
  url: string
  revision?: string
}

type Agreement = {
  name: string
  agreed: boolean
  revision: ContractRevision | TermsRevision
}
type SignatureFormValues = {
  signatureData: string
  agreements: Agreement[]
}

function updateSignatories(
  index: number,
  values: SignatureFormValues,
  signatories: Signatory[]
) {
  let updatedSignatories = signatories
  updatedSignatories[index] = {
    ...signatories[index],
    signatureData: values.signatureData,
    agreedContractRevision:
      (values.agreements.find(
        ({ name }) => name === AgreementNames.ContractOfSale
      )?.revision as ContractRevision) || null,
    agreedPrivacyPolicyRevision:
      (values.agreements.find(
        ({ name }) => name === AgreementNames.PrivacyPolicy
      )?.revision as TermsRevision) || null,
    agreedTermsRevision:
      (values.agreements.find(
        ({ name }) => name === AgreementNames.TermsAndConditions
      )?.revision as TermsRevision) || null,
  }
  return updatedSignatories
}

function addTermsToAllSignatories(
  values: SignatureFormValues,
  signatories: Signatory[]
) {
  return signatories.map((signatory) => ({
    ...signatory,
    agreedPrivacyPolicyRevision:
      (values.agreements.find(
        ({ name }) => name === AgreementNames.PrivacyPolicy
      )?.revision as TermsRevision) || null,
    agreedTermsRevision:
      (values.agreements.find(
        ({ name }) => name === AgreementNames.TermsAndConditions
      )?.revision as TermsRevision) || null,
  }))
}

export function Signature({
  listing,
  index,
  label = 'Done',
  onContinue,
}: {
  listing: Signature_ListingFragment
  index: number
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update } = useOfferForm()

  const isBinding = listing.acceptedOfferType === 'binding'

  useEffect(() => {
    if (isBinding) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferSignature>({
        type: BuyerEvent.VIEW_OFFER_SIGNATURE,
        ...analytics.getEventMetadata(),
      })
    }
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferAgreements>({
      type: BuyerEvent.VIEW_OFFER_AGREEMENTS,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: termsData, loading: loadingTerms } = useQuery(
    SignatureGetTermsQueryDocument,
    {
      variables: {
        termsLineName: BUYER_TERMS_LINE,
        privacyPolicyLineName: PRIVACY_POLICY_LINE,
      },
    }
  )

  const signatory = state.signatories[index]

  const documents: Document[] = useMemo(
    () => [
      ...(isBinding ? listing?.documents || [] : [])
        .filter((document) =>
          [
            AgreementNames.ContractOfSale as string,
            AgreementNames.Section32 as string,
          ].includes(document.name)
        )
        .map((document) => ({
          displayName: defaultFilename(document.name),
          ...document,
        })),
      {
        displayName: 'Propps Offer Terms and Conditions',
        name: AgreementNames.TermsAndConditions,
        url: termsData?.terms
          ? `${process.env.PUBLIC_URL}/terms/${BUYER_TERMS_LINE}/${termsData.terms.currentRevision.id}`
          : '#',
      },
    ],
    [listing?.documents, isBinding, termsData?.terms]
  )

  const form = useFormik<SignatureFormValues>({
    initialValues: {
      signatureData: isBinding ? signatory.signatureData || '' : '',
      agreements: [
        ...documents
          .filter(
            (document) =>
              ![AgreementNames.TermsAndConditions as string].includes(
                document.name
              )
          )
          .map((document) => ({
            name: document.name,
            agreed:
              document.name === AgreementNames.ContractOfSale
                ? !!signatory.agreedContractRevision
                : false,
            revision: { id: document.revision!, type: document.name },
          })),
        {
          name: AgreementNames.TermsAndConditions,
          agreed: !!signatory.agreedTermsRevision,
          revision: {
            id: termsData?.terms?.currentRevision.id!,
            line: BUYER_TERMS_LINE,
          },
        },
        {
          name: AgreementNames.PrivacyPolicy,
          agreed: true, // No UI for privacy policy
          revision: {
            id: termsData?.privacyPolicy?.currentRevision.id!,
            line: PRIVACY_POLICY_LINE,
          },
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: isBinding
      ? bindingValidationSchema
      : nonBindingValidationSchema,
    onSubmit: (values, helpers) => {
      update((s) => ({
        ...s,
        signatories: isBinding
          ? updateSignatories(index, values, state.signatories)
          : addTermsToAllSignatories(values, state.signatories),
      }))

      onContinue()
    },
  })

  if (loadingTerms) {
    return <PrimaryButton pending />
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <Title sub={isBinding && getFullName(signatory)}>
        {isBinding ? "It's time to sign" : 'Terms & conditions'}
      </Title>
      <Article>
        {isBinding ? <ArticleTitle>Agreement</ArticleTitle> : null}
        <List>
          {documents.map((document, index: number) => (
            <ListItem as="label" key={index}>
              <Checkbox
                {...form.getFieldProps({
                  type: 'checkbox',
                  name: `agreements[${index}].agreed`,
                })}
                data-testid={`${document.name}-agreement-checkbox`}
                label={
                  <Fragment>
                    I agree to the{' '}
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href={document.url} target="_blank">
                      {document.displayName}
                    </a>
                  </Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
        {form.touched.agreements?.length === form.values.agreements.length &&
        form.errors.agreements &&
        (form.errors.agreements as []).filter((error) => error !== null)
          .length > 0 ? (
          <CommonError>
            <span>Please acknowledge you agree to the above.</span>
          </CommonError>
        ) : null}
      </Article>
      {isBinding ? (
        <Fragment>
          <Article>
            <ArticleTitle>Signature</ArticleTitle>
            <SignatureCanvas
              style={{
                width: '100%',
                height: pxToRem(240),
              }}
              value={form.values.signatureData}
              onChange={(value) => form.setFieldValue('signatureData', value)}
              data-testid="signature-canvas"
            />
            <Flex
              xs={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              css={css`
                margin-top: ${pxToRem(-88)};
              `}
            >
              <b>{getFullName(signatory)}</b>
            </Flex>
          </Article>
          {form.touched.signatureData && form.errors.signatureData && (
            <CommonError>{form.errors.signatureData}</CommonError>
          )}
        </Fragment>
      ) : null}
      <PrimaryButton
        onClick={form.submitForm}
        label={label}
        pending={form.status}
      />
    </form>
  )
}

const bindingValidationSchema = Yup.object({
  signatureData: Yup.string().required('Please sign to complete your offer.'),
  agreements: Yup.array()
    .of(
      Yup.object({
        agreed: Yup.boolean()
          .oneOf([true], 'Please acknowledge you agree to the above.')
          .required('Please acknowledge you agree to the above.'),
      })
    )
    .required(),
})

const nonBindingValidationSchema = Yup.object({
  agreements: Yup.array()
    .of(
      Yup.object({
        agreed: Yup.boolean()
          .oneOf([true], 'Please acknowledge you agree to the above.')
          .required('Please acknowledge you agree to the above.'),
      })
    )
    .required(),
})

function getFullName(obj: {
  firstName: string
  middleName: string
  lastName: string
}) {
  return [obj.firstName, obj.middleName, obj.lastName]
    .filter((part) => !!part)
    .join(' ')
}

export const GRAPHQL = gql`
  fragment Signature_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    acceptedOfferType
    documents {
      id
      name
      filename
      url
      revision
    }
  }

  query SignatureGetTermsQuery(
    $termsLineName: String!
    $privacyPolicyLineName: String!
  ) {
    terms: termsLine(name: $termsLineName) {
      currentRevision {
        id
      }
    }
    privacyPolicy: termsLine(name: $privacyPolicyLineName) {
      currentRevision {
        id
      }
    }
  }
`
