import { gql, useQuery } from '@apollo/client'
import { parseISO } from 'date-fns'
import React, { createContext, useContext } from 'react'
import { useListing } from '../pages/listing/listing-context'
import { ListingCountdownTimerDocument } from './__generated__/countdown-timer-provider.generated'

type CountdownTimer = {
  enabled: boolean
  expiry: Date | null
  label: string
  pauseListingOnExpiry: boolean
}

type CountdownTimerContextData = {
  ready: boolean
  countdownTimer: CountdownTimer
}

export const CountdownTimerContext =
  createContext<CountdownTimerContextData | null>(null)

export const CountdownTimerProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { id } = useListing()
  const { data, loading } = useQuery(ListingCountdownTimerDocument, {
    variables: {
      listingId: id,
    },
    errorPolicy: 'all',
  })
  const countdownTimer = data?.listing?.countdownTimer

  const context = {
    ready: !loading && !!data,
    countdownTimer: {
      enabled: countdownTimer?.enabled ?? false,
      expiry: countdownTimer?.expiry ? parseISO(countdownTimer.expiry) : null,
      label: countdownTimer?.label ?? '',
      pauseListingOnExpiry: countdownTimer?.pauseListingOnExpiry ?? false,
    },
  }
  return (
    <CountdownTimerContext.Provider value={context}>
      {children}
    </CountdownTimerContext.Provider>
  )
}

export const useCountdownTimer = () => {
  const value = useContext(CountdownTimerContext)
  if (!value) {
    throw new Error(
      'useCountdownTimer must be used within a <CountdownTimerProvider />'
    )
  }
  return value
}

gql`
  query ListingCountdownTimer($listingId: ID!) {
    listing(id: $listingId) {
      id
      countdownTimer {
        enabled
        expiry
        label
        pauseListingOnExpiry
      }
    }
  }
`
