import {
  formatDollarAmountForInput,
  FormattedNumberInput,
  getErrors,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { Input, Label, Select, Title } from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import isMobile from 'is-mobile'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { OfferFormMode } from './mode'
import { Amount_ListingFragment } from './__generated__/amount.generated'

const isMobileDevice = isMobile()

export function Amount({
  listing,
  label = 'Done',
  onContinue,
}: {
  listing: Amount_ListingFragment
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbAmount>({
        type: BuyerEvent.VIEW_PTB_AMOUNT,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferAmount>({
        type: BuyerEvent.VIEW_OFFER_AMOUNT,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const depositRequired =
    listing.depositRequired.display && listing.depositRequired.enabled

  const form = useFormik<{
    amount: string
    purchaseDepositSelect: 'five' | 'ten' | 'other' | ''
    depositAmount: string
  }>({
    enableReinitialize: true,
    initialValues: {
      amount: (state.amount ?? '') as string,
      purchaseDepositSelect: depositRequired
        ? state.depositPercentage ?? 'ten'
        : '',
      depositAmount: (state.depositAmount ?? '') as string,
    },
    validationSchema,
    onSubmit: (values, helpers) => {
      const amount = parseInt(values.amount)
      const depositPercentage = values.purchaseDepositSelect || null
      const depositAmount = isNaN(parseInt(values.depositAmount))
        ? null
        : parseInt(values.depositAmount)

      update((s) => ({
        ...s,
        amount,
        depositPercentage,
        depositAmount,
      }))

      analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferAmount>({
        type: BuyerEvent.COMPLETE_OFFER_AMOUNT,
        amount,
        ...analytics.getEventMetadata(),
      })

      onContinue()
    },
  })

  const depositSelectHandle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    form.setFieldValue('purchaseDepositSelect', e.target.value)
    if (e.target.value !== 'other') {
      form.setFieldValue('depositAmount', '')
      form.setFieldTouched('depositAmount', false)
    }
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>How much would you like to offer for this property?</Title>
      <Label label="Offer amount">
        <FormattedNumberInput<React.ComponentProps<typeof Input>>
          autoFocus={!isMobileDevice}
          component={Input}
          format={formatDollarAmountForInput}
          size="xl"
          centered
          type="text"
          placeholder="$"
          {...form.getFieldProps('amount')}
          onChange={(value) => form.setFieldValue('amount', value)}
          errors={getErrors(form, 'amount')}
          inputMode="numeric"
        />
      </Label>
      {depositRequired && (
        <>
          <Label label="Property deposit">
            <Select
              id="purchaseDepositSelect"
              {...form.getFieldProps('purchaseDepositSelect')}
              onChange={depositSelectHandle}
            >
              <option value="five">5%</option>
              <option value="ten">10%</option>
              <option value="other">Other</option>
            </Select>
          </Label>
          {form.values.purchaseDepositSelect === 'other' && (
            <Label label="Deposit amount">
              <FormattedNumberInput<React.ComponentProps<typeof Input>>
                id="deposit-amount"
                autoFocus={!isMobileDevice}
                component={Input}
                format={formatDollarAmountForInput}
                centered
                type="text"
                placeholder="$"
                {...form.getFieldProps('depositAmount')}
                onChange={(value) => form.setFieldValue('depositAmount', value)}
                errors={getErrors(form, 'depositAmount')}
                inputMode="numeric"
              />
            </Label>
          )}
        </>
      )}
      {listing.priceIndication.formatted ? (
        <p className="grey">{listing.priceIndication.formatted}</p>
      ) : null}
      {depositRequired && (
        <p className="grey">
          The Property deposit is the amount that you are required to pay upon
          acceptance of your offer by the Vendor. It is held in accordance with
          the Contract of Sale and applied towards the total purchase price of
          the Property.
        </p>
      )}
      <PrimaryButton
        onClick={form.submitForm}
        label={label}
        pending={form.status}
      />
    </form>
  )
}

export const GRAPHQL = gql`
  fragment Amount_Listing on Listing {
    id
    agency {
      id
    }
    source {
      appId
      foreignId
    }
    priceIndication {
      formatted
    }
    depositRequired {
      enabled
      display
    }
  }
`

const validationSchema = Yup.object({
  amount: Yup.number()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError('${label} must be a number.')
    .integer()
    .positive("Nothing's free, pal!")
    .required('Please enter a number.')
    .label('Amount'),

  depositAmount: Yup.mixed().when(
    'purchaseDepositSelect',
    (purchaseDepositSelect, schema: Yup.AnySchema) => {
      return purchaseDepositSelect === 'other'
        ? Yup.number()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError('${label} must be a number.')
            .integer()
            .required('Please enter a number.')
            .label('Deposit Amount')
        : schema
    }
  ),
})
