import { gql, useQuery } from '@apollo/client'
import { Role, useAuthState } from '@propps-au/client'
import { ButtonCTA, StackHeader, StackMain } from '@propps-au/ui'
import { LocationDescriptor } from 'history'
import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { OfferForm, OfferFormMode } from '../../components/offer-form'
import { useSearchParams } from '../../components/use-search-params'
import { GetReadyQueryDocument } from './__generated__/get-ready.generated'

export function GetReady({
  offerHubRoute,
  ...rest
}: Omit<React.ComponentProps<typeof OfferForm>, 'mode'> & {
  offerHubRoute: LocationDescriptor
}) {
  const params = useSearchParams<{ fromAuth?: string }>()
  const auth = useAuthState()

  const skip = auth.role?.name !== Role.BUYER
  const { data, error } = useQuery(GetReadyQueryDocument, {
    variables: {
      listingId: rest.listingId,
      buyerId: auth.role?.id!,
    },
    fetchPolicy: 'network-only',
    skip,
  })

  if (error) {
    throw error
  }

  if (!skip && !data) {
    return <Loading />
  }

  if (
    data &&
    params.fromAuth === 'true' &&
    (data.currentOffer || data.draftOffer?.disposition === 'explicit')
  ) {
    /** The user just signed in because they tried to go to prepare to buy but they already have an offer or draft */
    return <Redirect to={offerHubRoute} />
  }

  return <OfferForm {...rest} mode={OfferFormMode.PREPARE_OFFER} fromDraft />
}

function Loading() {
  return (
    <Fragment>
      <StackHeader variant="frames" />
      <StackMain variant="frames">
        <ButtonCTA pending />
      </StackMain>
    </Fragment>
  )
}

export default GetReady

export const GRAPHQL = gql`
  query GetReadyQuery($listingId: ID!, $buyerId: ID!) {
    currentOffer: getCurrentOfferByListingAndBuyer(
      listingId: $listingId
      buyerId: $buyerId
    ) {
      id
    }
    draftOffer(listingId: $listingId, buyerId: $buyerId) {
      id
      disposition
    }
  }
`
