import { StackApp, StackHeader } from '@propps-au/ui'
import React, { Fragment } from 'react'
import { PreRollBanner } from './pre-roll-banner'
import { PrimaryButton } from './primary-button'

export function Loading() {
  return (
    <Fragment>
      <StackHeader variant="frames" />
      <PreRollBanner />
      <StackApp variant="frames">
        <PrimaryButton pending />
      </StackApp>
    </Fragment>
  )
}
