/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  Button,
  color,
  Flex,
  FlexItem,
  Icon,
  IconChevronRight,
  IconCircleEmpty,
  IconCircleMinus,
  IconCircleTick,
  IconCircleWarning,
  IconUserCross,
  IconUserSingle,
  IconUserTick,
  ListItem,
} from '@propps-au/ui'
import React, { Fragment } from 'react'
import { BuyerVerificationStatus } from '../../../__generated__/types'
import { StepStatus } from '../status'
import { useIdvStatus } from '../use-idv-status'
import { Signatory } from '../values'

function statusIcon(
  status: StepStatus,
  customIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
) {
  if (customIcon) {
    return <Icon svg={customIcon} fill={color.grey20} />
  }
  switch (status) {
    case StepStatus.Skipped:
      return (
        <Icon
          svg={IconCircleMinus}
          fill={color.grey20}
          data-testid="skipped-icon"
        />
      )
    case StepStatus.NeedsAttention:
      return (
        <Icon
          svg={IconCircleWarning}
          fill={color.orange}
          data-testid="needsAttention-icon"
        />
      )
    case StepStatus.Completed:
    case StepStatus.NotApplicable:
      return (
        <Icon
          svg={IconCircleTick}
          fill={color.green}
          aria-label="Done:"
          data-testid="completed-icon"
        />
      )
    default:
      return (
        <Icon
          svg={IconCircleEmpty}
          fill={color.grey20}
          data-testid="incomplete-icon"
        />
      )
  }
}

export function OfferStepListItem({
  title,
  status,
  data: Data,
  onClick,
  customIcon,
}: {
  children?: React.ReactNode
  title: string
  status: StepStatus
  data?: () => JSX.Element
  onClick?: () => void
  customIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}) {
  return (
    <ListItem
      size="lg"
      onClick={onClick}
      start={statusIcon(status, customIcon)}
      disabled={status === StepStatus.NotApplicable}
      end={<Icon svg={IconChevronRight} aria-label="View" />}
    >
      <Flex xs={{ wrap: 'nowrap' }}>
        <FlexItem xs={{ grow: true }}>
          <b>{title}</b>
        </FlexItem>
        {Data && (
          <FlexItem
            css={css`
              text-align: right;
            `}
          >
            <Data />
          </FlexItem>
        )}
      </Flex>
    </ListItem>
  )
}

export function BuyerDataStepListItem({
  signatory,
  showSignature,
  onClick,
}: {
  signatory: Signatory
  showSignature: boolean
  onClick?: () => void | undefined
}) {
  const idv = useIdvStatus({ buyerId: signatory.buyerId })
  return (
    <ListItem
      size="lg"
      onClick={onClick}
      start={idvStatusIcon(idv.status)}
      end={
        signatory.signatureData && (
          <div
            css={css`
              height: 100%;
              display: flex;
              align-items: center;
            `}
          >
            <Icon svg={IconChevronRight} aria-label="View" />
          </div>
        )
      }
    >
      <Flex xs={{ wrap: 'nowrap', justifyContent: 'space-between' }}>
        <FlexItem xs={{ grow: true }}>
          <b>{`${signatory.firstName} ${signatory.middleName} ${signatory.lastName}`}</b>
          <Fragment>
            <br />
            <small>
              {idvStatusMessage(idv.status)}
              {showSignature && (
                <Fragment>
                  <br />
                  {signatory.signatureData ? 'Signed' : 'Not yet signed'}
                </Fragment>
              )}
            </small>
          </Fragment>
        </FlexItem>
        <FlexItem
          css={css`
            width: 40%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          {showSignature ? (
            signatory.signatureData ? (
              <img
                src={signatory.signatureData}
                alt="signature"
                css={css`
                  height: auto;
                  width: 100%;
                  justify-self: flex-end;
                `}
              />
            ) : (
              onClick && (
                <Button
                  size="sm"
                  pill
                  onClick={(e: any) => {
                    e.stopPropagation()
                    onClick()
                  }}
                >
                  Add signature
                </Button>
              )
            )
          ) : null}
        </FlexItem>
      </Flex>
    </ListItem>
  )
}

const idvStatusIcon = (status?: BuyerVerificationStatus) => {
  switch (status) {
    case BuyerVerificationStatus.verified:
      return <Icon svg={IconUserTick} />
    case BuyerVerificationStatus.failed:
      return <Icon svg={IconUserCross} />
    default:
      return <Icon svg={IconUserSingle} />
  }
}

const idvStatusMessage = (status?: BuyerVerificationStatus) => {
  switch (status) {
    case BuyerVerificationStatus.verified:
      return 'Identity verified'
    case BuyerVerificationStatus.failed:
      return 'Identity verification failed'
    default:
      return 'Identity verification pending'
  }
}
