/** @jsx jsx */
import { gql, useQuery } from '@apollo/client'
import { jsx } from '@emotion/react'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { StackHeader, StackMain, Title } from '@propps-au/ui'
import { Fragment, useEffect } from 'react'
import { useAnalytics } from '../../../components/analytics'
import { CountdownTimer } from '../../../components/countdown-timer'
import { useCountdownTimer } from '../../../components/countdown-timer-provider'
import { DocumentsList } from '../../../components/documents-list'
import { FrameContentLayout } from '../../../components/frame-content-layout'
import { PrimaryButton } from '../../../components/primary-button'
import { useSignOutAndClose } from '../../../components/sign-out'
import {
  ViewDocuments_ListingFragment,
  ViewListingDocumentsQueryDocument,
} from './__generated__/index.generated'

export function ViewDocuments({
  listing,
  goBack,
}: {
  listing: ViewDocuments_ListingFragment
  goBack: () => void
}) {
  const analytics = useAnalytics()
  const signOut = useSignOutAndClose()
  const countdownTimer = useCountdownTimer()

  const { data, loading } = useQuery(ViewListingDocumentsQueryDocument, {
    variables: { id: listing.id },
  })

  useEffect(() => {
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewListingDocuments>({
      type: BuyerEvent.VIEW_LISTING_DOCUMENTS,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <StackHeader
        variant="frames"
        onBack={goBack}
        onSignOut={() => {
          signOut()
          goBack()
        }}
      >
        {countdownTimer.countdownTimer?.enabled &&
          countdownTimer.countdownTimer?.expiry && (
            <CountdownTimer
              to="./activity"
              variant="alternate"
              status={countdownTimer.countdownTimer.label}
              timer={{
                loading: !countdownTimer.ready,
                size: 'sm',
                endDate: countdownTimer.countdownTimer.expiry,
              }}
            />
          )}
      </StackHeader>
      <StackMain variant="frames">
        <FrameContentLayout>
          <Title sub={listing.property.address.line1}>Documents for</Title>
          {data?.listing?.documents && (
            <DocumentsList documents={data.listing.documents} />
          )}
          <PrimaryButton
            onClick={goBack}
            label="Done"
            data-testid="submit-button"
            pending={loading}
          />
        </FrameContentLayout>
      </StackMain>
    </Fragment>
  )
}

export const GRAPHQL = gql`
  query ViewListingDocumentsQuery($id: ID!) {
    listing(id: $id) {
      id
      ...DocumentsList_Listing
    }
  }

  fragment ViewDocuments_Listing on Listing {
    id
    property {
      address {
        line1
      }
    }
  }
`
