import { gql } from '@apollo/client'
import { formatDollarAmount } from '@propps-au/client'
import { Article, ArticleTitle, Input, Label } from '@propps-au/ui'
import React, { Fragment } from 'react'
import { HoldingDepositInstructions_OfferFragment } from './__generated__/holding-deposit-instructions.generated'

export function HoldingDepositInstructions({
  offer,
}: {
  offer: HoldingDepositInstructions_OfferFragment
}) {
  const agent = offer.listing.agents[0]

  return (
    <Fragment>
      <Article>
        <ArticleTitle>Holding deposit amount</ArticleTitle>
        <Label label="Amount">
          <Input
            type="text"
            value={formatDollarAmount(offer.holdingDeposit!.amount)}
            disabled
          />
        </Label>
        <p>
          All that's left is to send the holding deposit to your agent's trust
          account whose details can be found below.
        </p>
        <p>
          The holding deposit (
          {formatDollarAmount(offer.listing.holdingDeposit.minimum, false)} or{' '}
          {offer.listing.holdingDeposit.rate * 100}% of the offer amount,
          whichever is greater) shows the agent you're a serious buyer. This
          amount will be refunded if you're unsuccessful in securing the
          property or come off the sale price if you do secure the property.
        </p>
        {/* <p>We&lsquo;vs also sent a copy of these details to your email.</p> */}
      </Article>
      <Article>
        <ArticleTitle>Agent account details</ArticleTitle>
        <fieldset>
          <Label label="Account name">
            <Input
              type="text"
              value={offer.listing.holdingDeposit.trustAccount?.name}
              disabled
            />
          </Label>
          <Label label="BSB">
            <Input
              type="text"
              value={offer.listing.holdingDeposit.trustAccount?.bsb}
              disabled
            />
          </Label>
          <Label label="Account no.">
            <Input
              type="text"
              value={offer.listing.holdingDeposit.trustAccount?.accountNo}
              disabled
            />
          </Label>
          <Label label="Reference ID">
            <Input
              type="text"
              value={formatReferenceId(offer.listing.property.address.line1)}
              disabled
            />
          </Label>
        </fieldset>
      </Article>
      <Article>
        <ArticleTitle>Agent contact details</ArticleTitle>
        <fieldset>
          <Label label="Name">
            <Input
              type="text"
              value={`${agent.firstName} ${agent.lastName}`}
              disabled
            />
          </Label>
          <Label label="Email">
            <Input type="email" value={agent.email} disabled />
          </Label>
          <Label label="Phone number">
            <Input type="tel" value={agent.phone ?? ''} disabled />
          </Label>
        </fieldset>
      </Article>
    </Fragment>
  )
}

export const GRAPHQL = gql`
  fragment HoldingDepositInstructions_Offer on Offer {
    id
    holdingDeposit {
      amount
    }
    listing {
      id
      holdingDeposit {
        enabled
        trustAccount {
          id
          name
          bsb
          accountNo
        }
        minimum
        rate
      }
      agents {
        id
        firstName
        lastName
        email
        phone
      }
      property {
        address {
          line1
        }
      }
    }
  }
`

function formatReferenceId(line1: string) {
  return line1
    .replace(/\s/g, '')
    .toUpperCase()
    .replace(/[^0-9A-Z]/g, ' ')
    .substr(0, 11)
}
