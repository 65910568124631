import { gql, useMutation } from '@apollo/client'
import { formatISO } from 'date-fns'
import { useCallback } from 'react'
import { OfferFormMode } from './mode'
import { OfferFormValues, Settlement, Signatory, TermsRevision } from './values'
import { OfferFormSubmitOfferMutationDocument } from './__generated__/submit-offer-mutation.generated'

type SignatoryWithTerms = Signatory & {
  agreedPrivacyPolicyRevision: TermsRevision
  agreedTermsRevision: TermsRevision
}

export type OfferFormCompleteValues = OfferFormValues & {
  amount: number
  settlement: Settlement
  signatories: SignatoryWithTerms[]
}

export function useSubmitOfferMutation() {
  const [mutate, result] = useMutation(OfferFormSubmitOfferMutationDocument, {
    update: (cache) => {
      cache.modify({
        fields: {
          currentOffer: (value, { INVALIDATE }) => INVALIDATE,
        },
      })
    },
  })

  const submit = useCallback(
    (
      values: OfferFormCompleteValues,
      options: {
        listingId: string
        isBinding: boolean
        mode: OfferFormMode
      }
    ) => {
      return mutate({
        variables: {
          input: {
            listingId: options.listingId,
            isBinding: options.isBinding,
            amount: values.amount,
            depositPercentage: values.depositPercentage,
            depositAmount: values.depositAmount,
            settlement: values.settlement,
            expiresAt: values.expiry ? formatISO(values.expiry) : null,
            conditionRevisions: values.conditions,
            customConditionContent: values.customConditionAdded
              ? values.customCondition
              : null,
            signatories: options.isBinding
              ? values.signatories
              : values.signatories.map((signatory: SignatoryWithTerms) => ({
                  ...signatory,
                  signatureData: null,
                  agreedContractRevision: null,
                })),
            addAdditionalSignatoriesLater: values.addTitleHoldersLater,
            agreeToUseOfDigitalSignatures: !!values.conditions.find(
              (condition) => condition.line === 'digital-signatures'
            ),
            finance: values.finance,
            conveyancer: values.conveyancer,
            attributedAgentId: values.attributedAgentId,
            property: values.property,
            agents: values.agents,
          },
        },
      })
    },
    [mutate]
  )

  return [submit, result] as const
}

export const GRAPHQL = gql`
  mutation OfferFormSubmitOfferMutation($input: SubmitOfferInput!) {
    result: submitOffer(input: $input) {
      offer {
        id
      }
    }
  }
`
