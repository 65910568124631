import { gql, useMutation, useQuery } from '@apollo/client'
import { formatPhoneNumber, useAuthState } from '@propps-au/client'
import {
  Alert,
  Article,
  ArticleTitle,
  Button,
  ButtonCTA,
  Input,
  Label,
  StackHeader,
  StackMain,
  Title,
} from '@propps-au/ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  BuyerDevtoolsQueryDocument,
  BuyerDevtoolsResetBuyerAccountMutationDocument,
} from './__generated__/buyer-devtools.generated'

export function BuyerDevtools() {
  const history = useHistory()
  const { data, loading } = useQuery(BuyerDevtoolsQueryDocument)
  const auth = useAuthState()
  const [unlinkAccount, { loading: unlinkLoading }] = useMutation(
    BuyerDevtoolsResetBuyerAccountMutationDocument
  )

  const onUnlinkAccount = async () => {
    if (unlinkLoading) {
      return
    }

    const result = await unlinkAccount()

    if (result.data?.unlinkBuyer) {
      auth.signOut()
      history.goBack()
    }
  }

  if (loading) {
    return <ButtonCTA pending />
  }

  return (
    <>
      {unlinkLoading && <ButtonCTA pending />}
      <StackHeader variant="frames" onBack={() => history.goBack()} />
      <StackMain variant="frames">
        <Title>Account Devtools</Title>

        <Alert variant="warning" title="Developer zone">
          The following actions will modify the roles of the currently logged in
          user. These actions are irreversible.
        </Alert>

        <Article>
          <ArticleTitle>Account details</ArticleTitle>
          <Label label="ID">
            <Input type="text" readOnly defaultValue={data?.user?.uid} />
          </Label>
          <Label label="Name">
            <Input
              type="text"
              readOnly
              defaultValue={`${data?.user?.firstName ?? ''} ${
                data?.user?.lastName ?? ''
              }`}
            />
          </Label>
          <Label label="Phone">
            <Input
              type="text"
              readOnly
              defaultValue={
                data?.user?.phone
                  ? formatPhoneNumber(data?.user?.phone, {
                      defaultCountry: 'AU',
                    })
                  : ''
              }
            />
          </Label>
          <Label label="Email">
            <Input
              type="email"
              readOnly
              defaultValue={data?.user?.email ?? ''}
            />
          </Label>
        </Article>

        <Article>
          <ArticleTitle>Reset account</ArticleTitle>
          <p>
            Unlink the buyer account and any related offers from the user
            associated with this phone number. It will also sign this user out
            of any Propps sites.
          </p>
          <Button variant="danger" type="submit" onClick={onUnlinkAccount}>
            Reset
          </Button>
        </Article>
      </StackMain>
    </>
  )
}

export const GRAPHQL = gql`
  query BuyerDevtoolsQuery {
    user: me {
      uid
      firstName
      lastName
      phone
      email
    }
  }

  mutation BuyerDevtoolsResetBuyerAccountMutation {
    unlinkBuyer
  }
`
