import type { Pixel } from '@propps-au/pixel-analytics-types'
import type Amplitude from 'amplitude-js'
import PixelBaseClient, { IPixelClient, PixelBaseClientOptions } from './client'
export interface PixelFrontendClientOptions extends PixelBaseClientOptions {
  amplitude?: Amplitude.AmplitudeClient
}

/**
 * Pixel Frontend Client inßterfaces with Propps Mailroom and `amplitude-js`
 * client library.
 */
export class PixelFrontendClient
  extends PixelBaseClient
  implements IPixelClient
{
  /** Amplitude client */
  public amplitude: Amplitude.AmplitudeClient | null = null

  constructor(options: PixelFrontendClientOptions) {
    super({ mailroom: options.mailroom, configs: options.configs })
    if (options.amplitude) {
      this.amplitude = options.amplitude
    }
  }

  /**
   * Dispatches an analytics event to the Propps Mailroom and Amplitude based on
   * the event configuration.
   * @param event payload
   */
  async dispatch<T extends Pixel.Event>(event: T) {
    if (!(event.type in this.configs)) {
      console.error(
        `[Propps Pixel] Config for event: \`${event.type}\` was not provided. Event dispatch will be skipped.`
      )
      return
    }
    const { mailroom, amplitude } = this.configs[event.type]!
    const payload = omit(event, 'type')

    if (mailroom.dispatch) {
      this.dispatchMailroom(mailroom.topic, event.type, this.user, event)
    }

    if (amplitude.dispatch) {
      this.dispatchAmplitude(amplitude.event, payload)
    }
  }

  /**
   * Clears the current user context passed along through Mailroom and Amplitude
   * events.
   */
  clearUser() {
    this._clearUser()
    this.setUserId(null)
  }

  /**
   * Sets the session user id.
   * @param uid user id
   */
  async setUserId(uid: string | null) {
    this._setUserId(uid)
    this.amplitude?.setUserId(uid)
  }

  /**
   * Sets a user for the current session. The `user.uid` will be forwarded to
   * Amplitude + LD and the `user` object injected to mailroom events. Amplitude
   * + LD user properties will be set using role data.
   * @param user User details
   */
  async setUser(user: Pixel.User) {
    this.clearUser()
    if (user.uid) {
      this._setUser(user)
      this.setUserId(user.uid)
    }
    if (user.role && user.uid) {
      if (user.role.id && user.role.type) {
        this.amplitude?.setUserProperties({
          roleId: user.role.id,
          role: user.role.type,
        })
      } else {
        this.amplitude?.setUserProperties({ roleId: null, role: null })
      }
    }
  }

  /**
   * Dispatches an Amplitude event using the `amplitude-js` library.
   * @param name name of the amplitude event
   * @param event payload
   */
  async dispatchAmplitude(name: string, event: any) {
    this.amplitude?.logEvent(name, event)
  }
}

interface Omit {
  <T extends object, K extends [...(keyof T)[]]>(obj: T, ...keys: K): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2]
  }
}

const omit: Omit = (obj, ...keys) => {
  const ret = {} as {
    [K in keyof typeof obj]: typeof obj[K]
  }
  let key: keyof typeof obj
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key]
    }
  }
  return ret
}
