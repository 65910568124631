import { datadogRum } from '@datadog/browser-rum'

type Args = {
  env: string
  version: string
  clientToken?: string
}

export const initializeDatadog = ({ env, version, clientToken }: Args) => {
  if (!clientToken) {
    return
  }

  datadogRum.init({
    applicationId: '0c4fae80-949f-436c-93be-3f37c65313a5',
    clientToken,
    site: 'datadoghq.com',
    service: 'offer-form',
    env,
    version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

export const setUser = datadogRum.setUser
export const removeUser = datadogRum.removeUser
