import { css } from '@emotion/react'
import {
  ButtonCTA,
  Icon,
  IconHeroSurprised,
  pxToRem,
  StackMain,
  Title,
} from '@propps-au/ui'
import React from 'react'
import { FrameContentLayout } from '../../components/frame-content-layout'
import { useFrameTransport } from '../../components/FrameTransport'

export function Paused() {
  const transport = useFrameTransport()

  return (
    <StackMain variant="topCenter">
      <FrameContentLayout>
        <Icon
          svg={IconHeroSurprised}
          size={pxToRem(128)}
          css={css`
            margin: 0 auto;
          `}
        />
        <Title sub="not accepting offers">This property is</Title>
        <p>
          If you'd like to enquire about this property please contact the agent.
        </p>
        <ButtonCTA
          type="submit"
          onClick={() => transport.send({ type: 'close' })}
          fixed={false}
        >
          Close
        </ButtonCTA>
      </FrameContentLayout>
    </StackMain>
  )
}
