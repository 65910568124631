import { gql, useApolloClient, useMutation } from '@apollo/client'
import {
  AuthStateResolved,
  RESTClient,
  Role,
  useAuth,
  useAuthVerification,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { CommonError, StackHeader, StackMain } from '@propps-au/ui'
import { AuthCredential } from 'firebase/auth'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { useAnalytics } from '../analytics'
import { FlatSwitch } from '../flat-switch'
import { PageTransition } from '../page-transition'
import {
  AuthSendTo,
  AuthVerifyCode,
  BuyerRegistration,
  handleStartVerificationError,
} from './steps'
import {
  CreateBuyerMutationDocument,
  GetCurrentBuyerQuery2Document,
} from './__generated__/index.generated'

const CORE_REST_ENDPOINT = process.env.REACT_APP_CORE_REST_ENDPOINT!
export type VerificationChannel = 'sms' | 'email'

enum Routes {
  AUTH_SEND_TO = '/',
  AUTH_VERIFY = '/verify-code',
  BUYER_REGISTRATION = '/register',
  AUTH_VERIFY_AGAIN = '/verify-again',
}

export type NewBuyerDetails = {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export function BuyerAuthFlow({
  sendTo: initialSendTo,
  onComplete,
}: {
  sendTo?: string
  onComplete: (isNewBuyer: boolean) => void
}) {
  const analytics = useAnalytics()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const auth = useAuth()
  const apollo = useApolloClient()
  const client = useMemo(
    () =>
      new RESTClient({
        basepath: CORE_REST_ENDPOINT,
        auth,
      }),
    [auth]
  )
  const verification = useAuthVerification({ client })
  const [channel, setChannel] = useState<VerificationChannel>('sms')
  const [sendTo, setSendTo] = useState(initialSendTo ?? '')
  const [userPhone, setUserPhone] = useState('')
  const [newBuyer, setNewBuyer] = useState<NewBuyerDetails>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  })
  const [error, setError] = useState('')
  const isNewBuyer = useRef(false)
  const [createBuyer] = useMutation(CreateBuyerMutationDocument, {
    update: (cache, { data }) => {
      if (!data) return
      cache.modify({
        id: cache.identify({
          __typename: 'User',
          uid: (auth.state as AuthStateResolved).uid,
        }),
        fields: {
          buyer: (value, { INVALIDATE }) => {
            return INVALIDATE
          },
        },
      })
    },
  })

  useEffect(() => {
    auth.signOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useCallback(
    (route: Routes) => {
      history.push(match.url + route + location.search)
    },
    [history, location.search, match.url]
  )

  const isUserRegisteredAsBuyer = useCallback(async () => {
    const { data } = await apollo.query({
      query: GetCurrentBuyerQuery2Document,
      fetchPolicy: 'network-only',
    })

    data?.me?.phone && setUserPhone(data?.me?.phone)

    return !!data.me?.buyer
  }, [apollo])

  const signIn = useCallback(
    async (credential: string | AuthCredential) => {
      await auth.signIn(credential)

      analytics.logPixelEvent<Pixel.BuyerEvent.Login>({
        type: BuyerEvent.LOGIN,
        ...analytics.getEventMetadata(),
      })
    },
    [analytics, auth]
  )

  const startNextVerification = useCallback(
    async (buyer: NewBuyerDetails) => {
      try {
        setError('')
        const values = getVerifyAgainProps(channel, buyer)
        await verification.start(values.channel, values.sendTo, true)
        navigate(Routes.AUTH_VERIFY_AGAIN)
      } catch (err) {
        setError(handleStartVerificationError(err))
      }
    },
    [channel, navigate, verification]
  )

  const registerBuyer = useCallback(async () => {
    const { uid } = auth.state as AuthStateResolved

    if (!uid) {
      throw new Error(
        'Invariant violation. User must be logged in to submit this step.'
      )
    }

    const { data } = await createBuyer({
      variables: {
        input: {
          uid,
          firstName: newBuyer.firstName,
          lastName: newBuyer.lastName,
          phone: newBuyer.phone,
          email: newBuyer.email.trim(),
        },
      },
    })

    await auth.signIn(data!.result.token)

    analytics.logPixelEvent<Pixel.BuyerEvent.Register>({
      type: BuyerEvent.REGISTER,
      buyerId: data!.result.buyer.id,
      ...analytics.getEventMetadata(),
    })

    isNewBuyer.current = true
  }, [
    analytics,
    auth,
    createBuyer,
    newBuyer.email,
    newBuyer.firstName,
    newBuyer.lastName,
    newBuyer.phone,
  ])

  const complete = useCallback(async () => {
    if (!auth.state.ready || !auth.state.uid) {
      throw new Error(
        'Buyer auth flow managed to complete without authenticating the user.'
      )
    }
    if (auth.state.role?.name !== Role.BUYER) {
      await auth.activateRole(Role.BUYER)
    }
    apollo.cache.evict({ fieldName: 'me' })
    apollo.cache.evict({ fieldName: 'getCurrentOfferByListingAndBuyer' })
    apollo.cache.evict({ fieldName: 'draftOffer' })
    apollo.cache.gc()

    onComplete(isNewBuyer.current)
  }, [apollo.cache, auth, onComplete])

  return (
    <>
      <StackHeader variant="frames" onBack={() => history.goBack()} />
      <StackMain variant="frames">
        <AnimatePresence exitBeforeEnter>
          <PageTransition key={location.pathname}>
            <FlatSwitch location={location}>
              <Route exact path={match.url}>
                <AuthSendTo
                  title="Welcome"
                  sub="Have we met?"
                  verification={verification}
                  channel={channel}
                  switchToEmail={() => {
                    setChannel('email')
                    analytics.logPixelEvent<Pixel.BuyerEvent.SwitchToEmailLogin>(
                      {
                        type: BuyerEvent.SWITCH_TO_EMAIL_LOGIN,
                        ...analytics.getEventMetadata(),
                      }
                    )
                  }}
                  switchToSMS={() => {
                    setChannel('sms')
                    analytics.logPixelEvent<Pixel.BuyerEvent.SwitchToSmsLogin>({
                      type: BuyerEvent.SWITCH_TO_SMS_LOGIN,
                      ...analytics.getEventMetadata(),
                    })
                  }}
                  onComplete={(sendTo: string) => {
                    setSendTo(sendTo)
                    navigate(Routes.AUTH_VERIFY)
                  }}
                />
              </Route>
              <Route path={match.url + Routes.AUTH_VERIFY}>
                {sendTo ? (
                  <AuthVerifyCode
                    verification={verification}
                    channel={channel}
                    sendTo={sendTo}
                    onComplete={async (token) => {
                      await signIn(token)
                      const shouldRegister = !(await isUserRegisteredAsBuyer())
                      if (shouldRegister) {
                        navigate(Routes.BUYER_REGISTRATION)
                      } else {
                        await complete()
                      }
                    }}
                  />
                ) : (
                  <Redirect to={match.url} />
                )}
              </Route>
              <Route path={match.url + Routes.BUYER_REGISTRATION}>
                {sendTo ? (
                  () => {
                    const props = getRegistrationProps(channel, sendTo)
                    return (
                      <BuyerRegistration
                        phone={props.phone || userPhone}
                        email={props.email}
                        onComplete={async (buyer) => {
                          setNewBuyer(buyer)
                          await startNextVerification(buyer)
                        }}
                      />
                    )
                  }
                ) : (
                  <Redirect to={match.url} />
                )}
              </Route>
              <Route path={match.url + Routes.AUTH_VERIFY_AGAIN}>
                {newBuyer ? (
                  () => {
                    const props = getVerifyAgainProps(channel, newBuyer)
                    return (
                      <AuthVerifyCode
                        title={props.title}
                        verification={verification}
                        channel={props.channel}
                        sendTo={props.sendTo}
                        onComplete={async () => {
                          await registerBuyer()
                          await complete()
                        }}
                      />
                    )
                  }
                ) : (
                  <Redirect to={match.url} />
                )}
              </Route>
            </FlatSwitch>
            {error && <CommonError>{error}</CommonError>}
          </PageTransition>
        </AnimatePresence>
      </StackMain>
    </>
  )
}

function getRegistrationProps(channel: VerificationChannel, sendTo: string) {
  if (channel === 'email') {
    return {
      phone: undefined,
      email: sendTo,
    }
  }
  return {
    phone: sendTo,
    email: undefined,
  }
}

function getVerifyAgainProps(
  channel: VerificationChannel,
  buyer: NewBuyerDetails
) {
  if (channel === 'email') {
    return {
      title: `We’ll also need to verify your phone number`,
      channel: 'sms' as VerificationChannel,
      sendTo: buyer.phone,
    }
  }

  return {
    title: `We’ll also need to verify your email address`,
    channel: 'email' as VerificationChannel,
    sendTo: buyer.email,
  }
}

export const GRAPHQL = gql`
  fragment BuyerAuthFlow_Listing on Listing {
    property {
      address {
        line1
      }
    }
    legallyBindingOffersAllowed
  }

  query GetCurrentBuyerQuery2 {
    me {
      uid
      phone
      buyer {
        id
      }
    }
  }

  query GetCurrentUserPhoneNumberQuery2 {
    user: me {
      uid
      phone
    }
  }

  mutation CreateBuyerMutation($input: CreateBuyerInput!) {
    result: createBuyer(input: $input) {
      buyer {
        id
      }
      token
    }
  }
`
