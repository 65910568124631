import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import React, { useState } from 'react'

export function FormattedPhoneNumberInput<
  P extends {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    value?: string | number | readonly string[] | undefined
  }
>({
  component: Component,
  onChange,
  onBlur,
  onKeyDown,
  value,
  defaultCountry,
  ...rest
}: {
  component: React.ComponentType<P>
  defaultCountry: string
  onChange?: (value: string) => void
} & Omit<P, 'onChange'>) {
  if (typeof value !== 'string') {
    throw new Error(
      "<FormattedPhoneNumberInput can only be used with a value of type 'string'"
    )
  }

  const [initialValue] = useState(value)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value

    if (
      e.target.value.startsWith(value) &&
      e.target.value.length === value.length + 1
    ) {
      newValue = new AsYouType({
        defaultCountry: defaultCountry as any,
      }).input(e.target.value)
    }

    onChange && onChange(newValue)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onChange && onChange(formatPhoneNumber(value, { defaultCountry }))
    onBlur && onBlur(e)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onChange && onChange(formatPhoneNumber(value, { defaultCountry }))
    }
    onKeyDown && onKeyDown(e)
  }

  return React.createElement(Component, {
    ...rest,
    onChange: handleChange,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    value:
      value === initialValue
        ? formatPhoneNumber(value, { defaultCountry })
        : value,
  } as any as P)
}

export function formatPhoneNumber(
  value: string,
  { defaultCountry }: { defaultCountry?: string } = {}
) {
  const phoneNumber = parsePhoneNumberFromString(value, {
    defaultCountry: defaultCountry as any,
  })

  if (phoneNumber) {
    return phoneNumber.formatInternational()
  }

  return value
}
