import { gql, useMutation } from '@apollo/client'
import { formatISO } from 'date-fns'
import { omit } from 'ramda'
import { useCallback } from 'react'
import { OfferFormMode } from './mode'
import { OfferFormValues } from './values'
import { OfferForm_SaveDraftOfferDocument } from './__generated__/save-draft-offer-mutation.generated'

export function useSaveDraftOfferMutation() {
  const [mutate, result] = useMutation(OfferForm_SaveDraftOfferDocument, {
    update: (cache) => {
      cache.modify({
        fields: {
          draftOffer: (value, { INVALIDATE }) => INVALIDATE,
        },
      })
    },
  })

  const submit = useCallback(
    (
      values: OfferFormValues,
      options: {
        listingId: string
        buyerId: string
        mode: OfferFormMode
      }
    ) => {
      return mutate({
        variables: {
          input: {
            listingId: options.listingId,
            buyerId: options.buyerId,
            property: values.property,
            agents: values.agents,
            attributedAgentId: values.attributedAgentId,
            amount: values.amount,
            expiresAt: values.expiry ? formatISO(values.expiry) : null,
            settlement: values.settlement,
            conditionRevisions: values.conditions,
            customConditionContent: values.customConditionAdded
              ? values.customCondition
              : null,
            signatories: values.signatories.map((signatory) =>
              omit([
                'signatureData',
                'agreedContractRevision',
                'agreedPrivacyPolicyRevision',
                'agreedTermsRevision',
              ])(signatory)
            ),
            addAdditionalSignatoriesLater: values.addTitleHoldersLater,
            finance: values.finance,
            conveyancer: values.conveyancer,
            lastVisitedStepPath: ['summary'],
            disposition:
              options.mode === OfferFormMode.CREATE_OFFER
                ? 'explicit'
                : 'implicit',
          },
        },
      })
    },
    [mutate]
  )

  return [submit, result] as const
}

export const GRAPHQL = gql`
  mutation OfferForm_SaveDraftOffer($input: SaveDraftOfferInput!) {
    result: saveDraftOffer(input: $input) {
      draftOffer {
        id
        disposition
      }
    }
  }
`
