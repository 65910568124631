/** @jsx jsx */
import { gql } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import { Activity, Icon, IconUserSingle, pxToRem } from '@propps-au/ui'
import NumberFormat from 'react-number-format'
import { OfferEventContentCard } from './offer-event-content-card'
import { AnonymousOfferCreated_AnonymousOfferCreatedEventFragment } from './__generated__/anonymous-offer-created.generated'

const styles = {
  content: css`
    padding-top: ${pxToRem(8)};
  `,
}

export const AnonymousOfferCreatedEvent = ({
  event,
}: {
  event: AnonymousOfferCreated_AnonymousOfferCreatedEventFragment
}) => {
  return (
    <Activity.Event
      label="Someone"
      firstName={event.buyer.label[0]}
      lastName={event.buyer.label[2]}
      createdAt={event.createdAt}
      title=" made an offer"
    >
      <div css={styles.content}>
        <OfferEventContentCard
          start={<Icon svg={IconUserSingle} />}
          end={
            <NumberFormat
              value={event.offer.amount}
              displayType="text"
              thousandSeparator
              prefix="$ "
            />
          }
        >
          <b>Buyer {event.buyer.label.replace(' ', '')}</b>
        </OfferEventContentCard>
      </div>
    </Activity.Event>
  )
}

gql`
  fragment AnonymousOfferCreated_AnonymousOfferCreatedEvent on AnonymousOfferCreatedEvent {
    createdAt
    eventId
    buyer {
      id
      label
    }
    offer {
      amount
    }
  }
`
