import { onError } from '@apollo/client/link/error'
import { ErrorReporting } from '../../error-reporting'

export function ErrorReportingLink() {
  return onError(({ networkError, graphQLErrors, operation }) => {
    if (networkError) {
      ErrorReporting.report(
        Object.assign(
          new Error('[GraphQL Network Error] ' + networkError.message),
          {
            networkError,
            operationName: operation.operationName,
          }
        )
      )
    }
    if (graphQLErrors?.length) {
      ErrorReporting.report(
        Object.assign(
          new Error(
            '[GraphQL] ' +
              `${operation.operationName} - ` +
              (graphQLErrors[0]?.message || 'error in execution')
          ),
          {
            graphQLErrors,
            operationName: operation.operationName,
          }
        )
      )
    }
  })
}
