import type * as Types from '../../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Condition_OfferConditionsLineFragment = { __typename: 'OfferConditionsLine', name: string, region: string, currentRevision: { __typename: 'OfferConditionsRevision', id: string, title: string, region: string, contentHTML: string, isRequired: boolean, line: string, params: Array<{ __typename: 'OfferConditionRevisionParam', name: string, label: string, description?: string | null, type?: Types.OfferConditionRevisionParamsType | null, values?: Array<string> | null, defaultValue?: string | null, allowOther: boolean, otherParams?: { __typename: 'OfferConditionRevisionOtherParams', name: string, label: string, description?: string | null, type: Types.OfferConditionRevisionInputType } | null }> } };

export type Condition_OfferConditionRevisionParamFragment = { __typename: 'OfferConditionRevisionParam', name: string, label: string, description?: string | null, type?: Types.OfferConditionRevisionParamsType | null, values?: Array<string> | null, defaultValue?: string | null, allowOther: boolean, otherParams?: { __typename: 'OfferConditionRevisionOtherParams', name: string, label: string, description?: string | null, type: Types.OfferConditionRevisionInputType } | null };

export type Condition_OfferConditionsRevisionFragment = { __typename: 'OfferConditionsRevision', id: string, title: string, region: string, contentHTML: string, isRequired: boolean, line: string, params: Array<{ __typename: 'OfferConditionRevisionParam', name: string, label: string, description?: string | null, type?: Types.OfferConditionRevisionParamsType | null, values?: Array<string> | null, defaultValue?: string | null, allowOther: boolean, otherParams?: { __typename: 'OfferConditionRevisionOtherParams', name: string, label: string, description?: string | null, type: Types.OfferConditionRevisionInputType } | null }> };

export const Condition_OfferConditionRevisionParamFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Condition_OfferConditionRevisionParam"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OfferConditionRevisionParam"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"values"}},{"kind":"Field","name":{"kind":"Name","value":"defaultValue"}},{"kind":"Field","name":{"kind":"Name","value":"allowOther"}},{"kind":"Field","name":{"kind":"Name","value":"otherParams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<Condition_OfferConditionRevisionParamFragment, unknown>;
export const Condition_OfferConditionsRevisionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Condition_OfferConditionsRevision"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OfferConditionsRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","alias":{"kind":"Name","value":"line"},"name":{"kind":"Name","value":"lineName"}},{"kind":"Field","name":{"kind":"Name","value":"region"}},{"kind":"Field","name":{"kind":"Name","value":"contentHTML"}},{"kind":"Field","name":{"kind":"Name","value":"isRequired"}},{"kind":"Field","name":{"kind":"Name","value":"params"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Condition_OfferConditionRevisionParam"}}]}}]}},...Condition_OfferConditionRevisionParamFragmentDoc.definitions]} as unknown as DocumentNode<Condition_OfferConditionsRevisionFragment, unknown>;
export const Condition_OfferConditionsLineFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Condition_OfferConditionsLine"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OfferConditionsLine"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"region"}},{"kind":"Field","name":{"kind":"Name","value":"currentRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Condition_OfferConditionsRevision"}}]}}]}},...Condition_OfferConditionsRevisionFragmentDoc.definitions]} as unknown as DocumentNode<Condition_OfferConditionsLineFragment, unknown>;