import { gql } from '@apollo/client'
import { BlurFormatInput, getErrors } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { Input, Label, Select, Title } from '@propps-au/ui'
import { isFuture, isPast, subDays } from 'date-fns'
import { useFormik } from 'formik'
import isMobile from 'is-mobile'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { DateSchema, formatInputDate, parseDateString } from './date-schema'
import { OfferFormMode } from './mode'
import { Settlement as SettlementType } from './values'
import { Settlement_ListingFragment } from './__generated__/settlement.generated'

const isMobileDevice = isMobile()

type SettlementFormType = {
  type: 'custom-days' | 'date' | number | ''
  days: string
  date: string
}

export function Settlement({
  listing,
  label = 'Done',
  onContinue,
}: {
  listing: Settlement_ListingFragment
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbSettlement>({
        type: BuyerEvent.VIEW_PTB_SETTLEMENT,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferSettlement>({
        type: BuyerEvent.VIEW_OFFER_SETTLEMENT,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik<SettlementFormType>({
    initialValues: {
      type:
        state.settlement?.type === 'date'
          ? 'date'
          : state.settlement?.type === 'days'
          ? listing.recommendedSettlementValues.includes(state.settlement.days)
            ? state.settlement.days
            : 'custom-days'
          : '',
      days:
        state.settlement?.type === 'days'
          ? listing.recommendedSettlementValues.includes(state.settlement.days)
            ? ''
            : state.settlement.days.toString()
          : '',
      date: state.settlement?.type === 'date' ? state.settlement.date : '',
    },
    validationSchema,
    onSubmit: (values) => {
      const settlement: SettlementType | null =
        values.type === 'custom-days'
          ? { type: 'days', days: parseInt(values.days) }
          : values.type === 'date'
          ? {
              type: 'date',
              date: formatInputDate(values.date.trim()),
            }
          : typeof values.type === 'number'
          ? { type: 'days', days: values.type }
          : null

      update((s) => ({
        ...s,
        settlement,
      }))

      if (settlement) {
        analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferSettlement>({
          type: BuyerEvent.COMPLETE_OFFER_SETTLEMENT,
          settlement: getValue(settlement),
          ...analytics.getEventMetadata(),
        })
      }

      onContinue()
    },
  })

  const handleSelectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case '':
      case 'custom-days':
      case 'date':
        form.setFieldValue('type', e.target.value)
        break
      default:
        const days = parseInt(e.target.value)
        if (isNaN(days)) {
          form.setFieldValue('type', '')
        } else {
          form.setFieldValue('type', days)
        }
        break
    }
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>When would you prefer the settlement date to be?</Title>
      <fieldset>
        <Label label="Settlement">
          <Select
            autoFocus={!isMobileDevice}
            name="type"
            value={form.values.type}
            onChange={handleSelectType}
            onBlur={form.handleBlur}
            errors={getErrors(form, 'type')}
          >
            <option value="" disabled>
              ...
            </option>
            <optgroup label="From signing">
              {listing.recommendedSettlementValues.map((value, i) => {
                return (
                  <option
                    value={value.toString()}
                    key={value}
                    data-testid="settlement-days-option"
                  >
                    {value} days from signing
                  </option>
                )
              })}
              {listing.property.address.state !== 'nsw' ? (
                <option
                  value="custom-days"
                  data-testid="settlement-specdays-option"
                >
                  Specific no. of days
                </option>
              ) : null}
            </optgroup>
            <option value="date" data-testid="settlement-date-option">
              By date
            </option>
          </Select>
        </Label>

        {form.values.type === 'custom-days' && (
          <Label label="Period">
            <BlurFormatInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="number"
              placeholder="..."
              {...form.getFieldProps('days')}
              errors={getErrors(form, 'days')}
              format={formatDays}
            />
          </Label>
        )}

        {form.values.type === 'date' && (
          <Label label="Settlement date">
            <BlurFormatInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="text"
              placeholder="DD / MM / YYYY"
              {...form.getFieldProps('date')}
              errors={getErrors(form, 'date')}
              format={(value) => formatInputDate(value)}
              onBlur={(event) => {
                form.setFieldValue('date', formatInputDate(form.values.date))
                form.handleBlur(event)
              }}
            />
          </Label>
        )}
      </fieldset>
      <p className="grey">
        This is when the transaction will occur, funds are transferred and you
        can take possession of the property.
      </p>
      <PrimaryButton
        onClick={form.submitForm}
        label={label}
        data-testid="submit-button"
      />
    </form>
  )
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Please select an option'),
  date: Yup.string().when('type', (value: string, schema: Yup.StringSchema) =>
    value === 'date'
      ? FutureDateSchema.required('Please enter the settlement date.')
      : schema
  ),
  days: Yup.string().when('type', (value: string, schema: Yup.StringSchema) =>
    value === 'custom-days' ? NumberRangeSchema : schema
  ),
})

const NumberRangeSchema = Yup.string()
  .required('Please enter the settlement period in days.')
  .test(
    'number-range',
    'The value must be between 0 and 999.',
    (value: string | undefined): value is string => {
      if (typeof value === 'undefined') return false
      let number = parseInt(value)
      return !isNaN(number) && number <= 999 && number > 0
    }
  )

const FutureDateSchema = DateSchema.test(
  'date-future',
  'The date must be in the future.',
  (value: string | undefined): value is string =>
    !value || isFuture(parseDateString(value))
).test(
  'date-too-far',
  'The settlement date must be within 999 days from now.',
  (value: string | undefined): value is string =>
    !value || isPast(subDays(parseDateString(value), 999))
)

const formatDays = (value: string) => {
  const number = parseInt(value)
  if (!isNaN(number)) {
    return `${number} days`
  }
  return value
}

function getValue(settlement: SettlementType | null) {
  switch (settlement?.type) {
    case 'days':
      return `${settlement.days} days`
    case 'date':
      return settlement.date
    default:
      return 'not set'
  }
}

export const GRAPHQL = gql`
  fragment Settlement_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    property {
      address {
        state
      }
    }
    recommendedSettlementValues
  }
`
