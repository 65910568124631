import { useMutation, useQuery } from '@apollo/client'
import { useAuthState } from '@propps-au/client'
import { BuyerEvent, Pixel } from '@propps-au/pixel-analytics-types'
import gql from 'graphql-tag'
import { useCallback, useMemo } from 'react'
import { useAnalytics } from './analytics'
import { storage } from './storage'
import {
  ClaimLiteOfferMutationDocument,
  LiteOfferFragment,
  LiteOfferQueryDocument,
} from './__generated__/use-lite-offer.generated'

export function useLiteOffer({ skip }: { skip: boolean }) {
  const auth = useAuthState()
  const analytics = useAnalytics()
  const liteOfferId = useMemo(
    () => storage.get('lite_offer_id') as string | undefined,
    []
  )
  const { data, loading } = useQuery(LiteOfferQueryDocument, {
    variables: { id: liteOfferId! },
    skip: !liteOfferId || skip,
  })
  const [claim, { loading: claiming }] = useMutation(
    ClaimLiteOfferMutationDocument
  )

  const liteOffer = data?.liteOffer
  const claimLiteOffer = useCallback(async () => {
    if (claiming) return
    if (!liteOffer) return
    if (liteOffer.claimed) return
    if (!auth.role?.id) return
    await claim({
      variables: { input: { buyerId: auth.role.id, id: liteOffer.id } },
    })
    analytics.logPixelEvent<Pixel.BuyerEvent.ClaimLiteOffer>({
      type: BuyerEvent.CLAIM_LITE_OFFER,
      liteOfferId: liteOffer.id,
      ...analytics.getEventMetadata(),
    })
  }, [claiming, liteOffer, auth.role, claim, analytics])

  return useMemo(
    () => ({
      liteOffer: liteOffer ?? null,
      contact:
        liteOffer?.buyer.__typename === 'LiteOfferBuyerContact'
          ? liteOffer.buyer
          : null,
      claimLiteOffer,
      loading,
    }),
    [claimLiteOffer, liteOffer, loading]
  )
}

export type { LiteOfferFragment }

gql`
  fragment LiteOffer on LiteOffer {
    id
    amount
    claimed
    buyer {
      ... on LiteOfferBuyerContact {
        firstName
        lastName
        phone
        email
      }
    }
  }

  query LiteOfferQuery($id: ID!) {
    liteOffer(id: $id) {
      ...LiteOffer
    }
  }

  mutation ClaimLiteOfferMutation($input: ClaimLiteOfferInput!) {
    claimLiteOffer(input: $input) {
      liteOffer {
        ...LiteOffer
      }
    }
  }
`
