import { useAuth } from '@propps-au/client'
import { SessionEvent } from '@propps-au/pixel-analytics-types/event-types'
import React, { Fragment, useEffect } from 'react'
import { setUser } from '../datadog'
import { useAnalytics } from './analytics'
import { useMe } from './use-me'

export function AnalyticsUserReporter({
  children,
}: {
  children: React.ReactNode
}) {
  const auth = useAuth()
  const analytics = useAnalytics()
  const me = useMe()

  useEffect(() => {
    const unsub = auth.onStateChanged(async (state) => {
      analytics.onUserChange({
        uid: state.uid,
        role: state.role
          ? {
              type: state.role.name,
              id: state.role.id,
            }
          : null,
      })

      if (state.uid) {
        analytics.pixel?.dispatch({
          type: SessionEvent.SESSION_FINGERPRINT_USER_LINK,
          fingerprint: '',
          sessionId: await analytics.pixel.getSessionId(),
          uid: state.uid,
        })
      }
    })

    return () => unsub()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auth.state.ready && auth.state.uid) {
      setUser({
        id: auth.state.uid,
        name: [me?.buyer?.firstName, me?.buyer?.lastName].join(' '),
        email: me?.buyer?.email,
        role: auth.state.role,
      })
    }
  }, [auth, me])

  return <Fragment>{children}</Fragment>
}
