/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import { Role, useAuthState } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  Accordion,
  ActionCard,
  Article,
  ArticleTitle,
  color,
  DropdownMenuItem,
  Icon,
  IconCircleInfo2,
  IconLargeDocuments,
  IconLargeList,
  IconLargeOffer,
  IconUserSingle,
  pxToRem,
  StackHeader,
  StackMain,
  TextPlaceholder,
  Title,
} from '@propps-au/ui'
import gql from 'graphql-tag'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAnalytics } from '../../../components/analytics'
import { ConfettiMania } from '../../../components/confetti-mania'
import { CountdownTimer } from '../../../components/countdown-timer'
import { useCountdownTimer } from '../../../components/countdown-timer-provider'
import { ExposeCompetition } from '../../../components/expose-competition'
import { FrameContentLayout } from '../../../components/frame-content-layout'
import { useFrameTransport } from '../../../components/FrameTransport'
import { PreRollBanner } from '../../../components/pre-roll-banner'
import {
  PrimaryButtonHost,
  usePrimaryButton,
} from '../../../components/primary-button'
import { SignInReminder } from '../../../components/sign-in-reminder'
import { useFeature } from '../../../split'
import { OfferAnnouncementsCard } from './offer-announcements-card'
import { ShowOfferStatus } from './offer-hub-offer-status'
import {
  OfferHubCurrentOfferQueryDocument,
  OfferHubInitial_ListingFragment,
  OfferHubListingQueryDocument,
  OfferHub_BuyerFragment,
} from './__generated__/offer-hub.generated'

export type OfferHubHistoryState = {
  fromOfferSubmit?: boolean
  fromRedirect?: boolean
  fromPrepare?: boolean
}

const styles = {
  countdownTimer: css`
    margin-top: ${pxToRem(-32)};
  `,
  accordion: css`
    margin-top: ${pxToRem(32)};
  `,
}

interface goTo {
  makeNewOffer: () => void
  makeOfferFromRedirect: () => void
  continueOffer: () => void
  prepareToBuy: () => void
  documents: () => void
  signIn: () => void
  activity: () => void
}

interface goToOffer {
  update: () => void
  holdingDeposit: (offerId: string) => void
}

export const OfferHub = ({
  listing: initialListing,
  buyer,
  goTo,
  goToOffer,
}: {
  listing: OfferHubInitial_ListingFragment
  buyer?: OfferHub_BuyerFragment
  goTo: goTo
  goToOffer: goToOffer
}) => {
  const history = useHistory<OfferHubHistoryState>()
  const analytics = useAnalytics()
  const auth = useAuthState()
  const transport = useFrameTransport()
  const button = usePrimaryButton()
  const countdownTimer = useCountdownTimer()
  const [headerClicks, setHeaderClicks] = useState(0)
  const buyerDevtools = useFeature('buyer_devtools')

  const listingId = initialListing?.id ?? null
  const isBuyer = auth.role?.name === Role.BUYER

  const fromOfferSubmit = !!history?.location?.state?.fromOfferSubmit

  const { data, loading: _loading } = useQuery(
    OfferHubCurrentOfferQueryDocument,
    {
      variables: { listingId, buyerId: auth?.role?.id! },
      fetchPolicy: fromOfferSubmit ? 'network-only' : 'cache-and-network',
      skip: !isBuyer,
    }
  )

  const { data: listingData, loading: _loading2 } = useQuery(
    OfferHubListingQueryDocument,
    { variables: { id: listingId } }
  )

  const loading = (_loading && !data) || (_loading2 && !listingData)

  const listing = listingData?.listing

  const hasExplicitDraftOffer = !!(
    data?.draftOffer && data.draftOffer.disposition === 'explicit'
  )

  const hasCurrentActiveOffer = data?.currentOffer?.status === 'active'

  const isActivityBuyerEnabled = listingData?.listing?.activityBuyer?.enabled

  useEffect(() => {
    if (headerClicks === 5) {
      ConfettiMania()
    }
  }, [headerClicks])

  useEffect(() => {
    if (listing?.id) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferHub>({
        type: BuyerEvent.VIEW_OFFER_HUB,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing?.id])

  const CARDS = [
    {
      key: 'make-offer',
      component: () => (
        <ActionCard
          icon={{ svg: IconLargeOffer }}
          title="Make an offer"
          onClick={() => {
            goTo.makeNewOffer()
            analytics.logAmplitudeEvent('[offer hub] click make an offer')
          }}
        >
          Ready to buy? Make an offer easily online.
        </ActionCard>
      ),
      show: !hasExplicitDraftOffer && !data?.currentOffer,
    },
    {
      key: 'continue-offer',
      component: () => (
        <ActionCard
          icon={{ svg: IconLargeOffer }}
          title="Continue with your offer"
          onClick={() => {
            goTo.continueOffer()
            analytics.logAmplitudeEvent('[offer hub] continue with your offer')
          }}
        >
          Continue or, if you like, you can{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
          <a
            key="start-again"
            onClick={(e) => {
              goTo.makeNewOffer()
              analytics.logAmplitudeEvent('[offer hub] click start again offer')
              e.stopPropagation()
            }}
          >
            start again
          </a>
          .
        </ActionCard>
      ),
      show: !hasCurrentActiveOffer && hasExplicitDraftOffer,
    },
    {
      key: 'activity',
      component: () => (
        <OfferAnnouncementsCard
          onClick={() => {
            goTo.activity()
            analytics.logAmplitudeEvent('[offer hub] click offer announcements')
          }}
        />
      ),
      show: true,
    },
    {
      key: 'property-docs',
      component: () => (
        <ActionCard
          icon={{ svg: IconLargeDocuments }}
          title="Property documents"
          onClick={() => {
            goTo.documents()
            analytics.logAmplitudeEvent('[offer hub] click property documents')
          }}
        >
          View the contract and any documents.
        </ActionCard>
      ),
      show: false,
    },
    {
      key: 'prepare-to-buy',
      component: () => (
        <ActionCard
          icon={{ svg: IconLargeList }}
          title="Prepare to buy"
          onClick={() => {
            goTo.prepareToBuy()
            analytics.logAmplitudeEvent('[offer hub] click prepare to buy')
          }}
        >
          We'll help you get organised and ready to buy.
        </ActionCard>
      ),
      show: false,
    },
  ].filter((card) => card.show === true)

  const onlyMakeOffer = useMemo(() => {
    return (
      !loading &&
      !hasCurrentActiveOffer &&
      CARDS.length === 1 &&
      CARDS.some((card) => card.key === 'make-offer')
    )
  }, [CARDS, hasCurrentActiveOffer, loading])

  if (onlyMakeOffer) {
    goTo.makeOfferFromRedirect()
  }

  return (
    <Fragment>
      <StackHeader
        variant="frames"
        user={
          isBuyer && (buyer?.firstName || buyer?.lastName)
            ? { firstName: buyer?.firstName, lastName: buyer?.lastName }
            : undefined
        }
        onSignIn={!isBuyer ? goTo.signIn : undefined}
        onSignOut={
          isBuyer
            ? () => {
                auth.signOut()
                transport.send({ type: 'close' })
                analytics.logPixelEvent<Pixel.BuyerEvent.Logout>({
                  type: BuyerEvent.LOGOUT,
                  ...analytics.getEventMetadata(),
                })
              }
            : undefined
        }
        menu={
          (buyerDevtools?.enabled ||
            process.env.REACT_APP_ENABLE_ACCOUNT_DEVTOOLS === 'true') &&
          isBuyer
            ? [
                <DropdownMenuItem
                  icon={{ svg: IconUserSingle }}
                  onClick={() => {
                    history.push('/buyer-devtools')
                  }}
                >
                  Account Devtools
                </DropdownMenuItem>,
              ]
            : undefined
        }
      >
        {countdownTimer.countdownTimer?.enabled &&
          countdownTimer.countdownTimer?.expiry && (
            <CountdownTimer
              to="./activity"
              variant="label"
              status={countdownTimer.countdownTimer?.label ?? ''}
              timer={{
                loading: !countdownTimer.ready,
                size: 'sm',
                endDate: countdownTimer.countdownTimer.expiry,
              }}
            />
          )}
      </StackHeader>
      <PreRollBanner onClick={() => setHeaderClicks(headerClicks + 1)} />
      <StackMain variant="frames">
        <FrameContentLayout>
          <Title
            sub={initialListing?.property.address.line1 || <TextPlaceholder />}
          >
            Offer hub for
          </Title>
          {countdownTimer.countdownTimer?.enabled &&
            countdownTimer.countdownTimer?.expiry && (
              <Fragment>
                <CountdownTimer
                  status={countdownTimer.countdownTimer.label}
                  timer={{
                    loading: !countdownTimer.ready,
                    endDate: countdownTimer.countdownTimer.expiry,
                  }}
                  css={styles.countdownTimer}
                />

                <Accordion
                  start={<Icon svg={IconCircleInfo2} fill={color.orange} />}
                  title="What happens when the timer ends?"
                  css={styles.accordion}
                >
                  This property is being offered under private treaty rules.
                  Accordingly, the vendor is under no obligation to accept an
                  offer at the expiry of the Countdown Timer and is free to
                  withdraw the property from sale at any time.{' '}
                  {!countdownTimer.countdownTimer.pauseListingOnExpiry &&
                    'You will still be able to submit updates to your offer, provided the property is not sold.'}
                </Accordion>
              </Fragment>
            )}
          <ShowOfferStatus
            listing={initialListing}
            buyer={buyer}
            currentOffer={data?.currentOffer}
            hasCurrentActiveOffer={hasCurrentActiveOffer}
            hasExplicitDraftOffer={hasExplicitDraftOffer}
            goToOfferFormUpdate={goToOffer.update}
            goToHoldingDepositPage={goToOffer.holdingDeposit}
            goToMakeNewOffer={goTo.makeNewOffer}
          />
          {hasCurrentActiveOffer && buyer?.id && (
            <ExposeCompetition
              listingId={listingId}
              fromOfferSubmit={fromOfferSubmit}
              offerId={data?.currentOffer?.id}
            />
          )}
          {loading ? (
            <Article>
              <ActionCard title="" icon={{ svg: IconLargeOffer }} loading>
                &nbsp;
              </ActionCard>
              <ActionCard title="" icon={{ svg: IconLargeOffer }} loading>
                &nbsp;
              </ActionCard>
              <ActionCard title="" icon={{ svg: IconLargeOffer }} loading>
                &nbsp;
              </ActionCard>
            </Article>
          ) : (
            CARDS.some((card) => card.show) && (
              <Article>
                {hasCurrentActiveOffer && (
                  <ArticleTitle marginBottom>More</ArticleTitle>
                )}
                {CARDS.map(({ component: Component }, index) => (
                  <Component key={index} />
                ))}
              </Article>
            )
          )}
          {!isBuyer && <SignInReminder goToSignIn={goTo.signIn} />}
        </FrameContentLayout>
        <PrimaryButtonHost context={button} />
      </StackMain>
    </Fragment>
  )
}

export const GRAPHQL = gql`
  fragment OfferHubInitial_Listing on Listing {
    id
    property {
      address {
        line1
      }
    }
    agency {
      id
    }
    agents {
      id
    }
  }

  query OfferHubListingQuery($id: ID!) {
    listing(id: $id) {
      ...OfferHub_Listing
    }
  }

  fragment OfferHub_Listing on Listing {
    id
    documents {
      name
    }
    prepareToBuy {
      enabled
    }
    hasBuyerDocumentsEnabled: showDocuments
    activityBuyer {
      id
      enabled
    }
  }

  fragment OfferHub_Buyer on Buyer {
    id
    firstName
    lastName
  }

  query OfferHubCurrentOfferQuery($listingId: ID!, $buyerId: ID!) {
    currentOffer: getCurrentOfferByListingAndBuyer(
      listingId: $listingId
      buyerId: $buyerId
    ) {
      ...OfferHubOffer_Offer
    }
    draftOffer: draftOffer(listingId: $listingId, buyerId: $buyerId) {
      id
      disposition
    }
  }

  fragment OfferHubOffer_Offer on Offer {
    id
    ...OfferStatus_Offer
    supersedes {
      id
    }
  }
`
