import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  TypePolicies,
} from '@apollo/client'
import { createHttpLink } from '@apollo/client/link/http'
import { IAuth } from '../../auth'
import { AuthLink } from './auth'
import { ErrorReportingLink } from './error-reporting'
import { GraphQLHeaders, HeaderLink } from './headers'
import { OperationNamePrefixLink } from './operation-name'
import { typePolicies } from './type-policies'

export type ApolloClientOptions = {
  endpoint: string
  auth?: IAuth | null
  headers?: GraphQLHeaders | null
  possibleTypes?: InMemoryCacheConfig['possibleTypes']
  name?: string
  version?: string
  typePolicies?: TypePolicies
}

export function createApolloClient(
  options: ApolloClientOptions
): ApolloClient<NormalizedCacheObject>
/**
 * @deprecated Please provide an options object instead
 */
export function createApolloClient(
  endpoint: string,
  auth: IAuth | null,
  headers: GraphQLHeaders | null,
  possibleTypes?: InMemoryCacheConfig['possibleTypes']
): ApolloClient<NormalizedCacheObject>
export function createApolloClient(
  endpoint: string | ApolloClientOptions,
  auth?: IAuth | null,
  headers?: GraphQLHeaders | null,
  possibleTypes?: InMemoryCacheConfig['possibleTypes']
): ApolloClient<NormalizedCacheObject> {
  if (typeof endpoint === 'string') {
    return createClient({
      endpoint,
      auth,
      headers,
      possibleTypes,
    })
  } else {
    return createClient(endpoint)
  }
}

function createClient(options: ApolloClientOptions) {
  return new ApolloClient({
    link: ApolloLink.from(
      [
        options.name ? OperationNamePrefixLink(options.name) : null,
        ErrorReportingLink(),
        options.auth ? AuthLink(options.auth) : null,
        options.headers ? HeaderLink(options.headers) : null,
        createHttpLink({ uri: options.endpoint }),
      ].filter((item): item is NonNullable<typeof item> => !!item)
    ),
    cache: new InMemoryCache({
      possibleTypes: options.possibleTypes,
      typePolicies: options.typePolicies ?? typePolicies,
    }),
    name: options.name,
    version: options.version,
    connectToDevTools: process.env.NODE_ENV === 'development',
  })
}
