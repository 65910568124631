import { gql, useQuery } from '@apollo/client'
import { Alert } from '@propps-au/ui'
import Confetti from 'canvas-confetti'
import React, { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { OfferHubHistoryState } from '.'
import { OfferStatus } from '../../../components/offer-status'
import { OfferStatusListingQueryDocument } from './__generated__/offer-hub-offer-status.generated'
import {
  OfferHubCurrentOfferQuery,
  OfferHub_BuyerFragment,
} from './__generated__/offer-hub.generated'

export function ShowOfferStatus({
  listing,
  buyer,
  currentOffer,
  hasCurrentActiveOffer,
  hasExplicitDraftOffer,
  goToOfferFormUpdate,
  goToHoldingDepositPage,
  goToMakeNewOffer,
}: {
  listing: { id: string }
  buyer?: OfferHub_BuyerFragment
  currentOffer?: OfferHubCurrentOfferQuery['currentOffer']
  hasCurrentActiveOffer: boolean
  hasExplicitDraftOffer: boolean
  goToOfferFormUpdate: () => void
  goToHoldingDepositPage: (offerId: string) => void
  goToMakeNewOffer: () => void
}) {
  const history = useHistory<OfferHubHistoryState>()
  const showOfferSentAlert =
    history?.location?.state?.fromOfferSubmit && hasCurrentActiveOffer

  const { data: listingData, loading } = useQuery(
    OfferStatusListingQueryDocument,
    {
      variables: { id: listing.id },
      skip: !(currentOffer && showOfferSentAlert),
    }
  )

  const agents = listingData?.listing?.agents || []
  const agentPhone = agents.length > 0 && agents[0].phone

  useEffect(() => {
    if (
      !loading &&
      history?.location?.state?.fromOfferSubmit &&
      hasCurrentActiveOffer
    ) {
      Confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      })
    }
  }, [
    hasCurrentActiveOffer,
    history?.location?.state?.fromOfferSubmit,
    loading,
  ])

  if (!currentOffer || !buyer) {
    return null
  }

  if (hasExplicitDraftOffer && !hasCurrentActiveOffer) {
    return null
  }

  return (
    <Fragment>
      {showOfferSentAlert && (
        <Alert title="Offer sent" variant="success" closable>
          Hooray! Your offer has been sent! We’ll keep our fingers crossed. It
          might be a good idea to give the agent a follow-up{' '}
          {agentPhone ? <a href={`tel:${agentPhone}`}>call</a> : 'call'} too.
          Good luck!
        </Alert>
      )}
      <OfferStatus
        buyer={buyer}
        offer={currentOffer}
        goToOfferFormUpdate={goToOfferFormUpdate}
        goToHoldingDepositPage={goToHoldingDepositPage}
        goToMakeNewOffer={goToMakeNewOffer}
      />
    </Fragment>
  )
}

gql`
  query OfferStatusListingQuery($id: ID!) {
    listing(id: $id) {
      id
      agents {
        id
        phone
      }
    }
  }
`
