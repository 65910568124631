/** @jsx jsx */
import { gql } from '@apollo/client'
import { jsx } from '@emotion/react'
import { Activity } from '@propps-au/ui'
import { ListingUpdateAcceptConditionalOffersTactic_ListingUpdateAcceptConditionalOffersTacticEventFragment } from './__generated__/listing-update-accept-conditional-offers-tactic.generated'

export const ListingUpdateAcceptConditionalOffersTacticEvent = ({
  event,
}: {
  event: ListingUpdateAcceptConditionalOffersTactic_ListingUpdateAcceptConditionalOffersTacticEventFragment
}) => {
  const title = ` is now accepting ${
    event.acceptConditionalOffers.enabled
      ? 'offers with conditions'
      : 'unconditional offers only'
  } for this property.`

  return (
    <Activity.Event
      label="The agent"
      createdAt={event.createdAt}
      title={title}
    />
  )
}

gql`
  fragment ListingUpdateAcceptConditionalOffersTactic_ListingUpdateAcceptConditionalOffersTacticEvent on ListingUpdateAcceptConditionalOffersTacticEvent {
    createdAt
    eventId
    acceptConditionalOffers {
      enabled
    }
  }
`
