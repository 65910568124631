import { useEffect, useState } from 'react'
import { ErrorReporting } from '../../error-reporting'
import { useStableCallback } from '../../util'
import { EventType } from './address-finder-types'

export type AddressMetaData = {
  address_line_1: string
  address_line_2: string
  locality_name: string
  state_territory: string
  postcode: string
}

type EventListener = (data: string, metadata: AddressMetaData) => void
type EmptyEventListener = () => void

type Listeners = {
  onSelect: EventListener
  onAddressSelect: EventListener
  onAddressSelectPre: EventListener
  onLocationSelect: EventListener
  onLocationSelectPre: EventListener
  onPointOfInterestSelect: EventListener
  onPointOfInterestSelectPre: EventListener
  onResultsUpdate: EmptyEventListener
  onResultsEmpty: EmptyEventListener
}

export function useAddressFinderWidget<T>(
  ref: React.RefObject<HTMLElement | undefined>,
  listeners: Partial<Listeners> = {},
  enabled: boolean = true
) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (enabled) {
      let cancelled = false

      load().then(() => {
        if (!cancelled) {
          setLoaded(true)
        }
      })

      return () => {
        cancelled = true
      }
    }
  }, [enabled])

  const handleEvent = useStableCallback(
    (event: EventType, data: string, metadata: AddressMetaData) => {
      switch (event) {
        case 'result:select':
          listeners.onSelect && listeners.onSelect(data, metadata)
          break
        case 'address:select':
          listeners.onAddressSelect && listeners.onAddressSelect(data, metadata)
          break
        case 'address:select:pre':
          listeners.onAddressSelectPre &&
            listeners.onAddressSelectPre(data, metadata)
          break
        case 'location:select':
          listeners.onLocationSelect &&
            listeners.onLocationSelect(data, metadata)
          break
        case 'location:select:pre':
          listeners.onLocationSelectPre &&
            listeners.onLocationSelectPre(data, metadata)
          break
        case 'point_of_interest:select':
          listeners.onPointOfInterestSelect &&
            listeners.onPointOfInterestSelect(data, metadata)
          break
        case 'point_of_interest:select:pre':
          listeners.onPointOfInterestSelectPre &&
            listeners.onPointOfInterestSelectPre(data, metadata)
          break
        case 'results:update':
          listeners.onResultsUpdate && listeners.onResultsUpdate()
          break
        case 'results:empty':
          listeners.onResultsEmpty && listeners.onResultsEmpty()
          break
      }
    },
    [listeners]
  )

  useEffect(() => {
    if (loaded && enabled) {
      try {
        const widget = new AddressFinder.Widget(
          ref.current!,
          process.env.REACT_APP_ADDRESS_FINDER_API_KEY!,
          'AU',
          {
            byline: false,
            empty_content:
              'Cannot find your address. Please manually enter the full address.',
          }
        )

        for (const event of [
          'result:select',
          'address:select',
          'address:select:pre',
          'location:select',
          'location:select:pre',
          'point_of_interest:select',
          'point_of_interest:select:pre',
          'results:update',
          'results:empty',
        ] as EventType[]) {
          widget.on(event, (...args) => handleEvent(event, ...args))
        }

        return () => {
          widget.disable()
        }
      } catch (err) {
        ErrorReporting.report(err)
      }
    }
  }, [handleEvent, loaded, ref, enabled])
}

const ADDRESS_FINDER_WIDGET_SCRIPT_ID = 'address-finder-widget-js'
const ADDRESS_FINDER_WIDGET_SCRIPT_SRC =
  'https://api.addressfinder.io/assets/v3/widget.js'
function load() {
  if (!document.getElementById(ADDRESS_FINDER_WIDGET_SCRIPT_ID)) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.onload = resolve
      script.setAttribute('id', ADDRESS_FINDER_WIDGET_SCRIPT_ID)
      script.src = ADDRESS_FINDER_WIDGET_SCRIPT_SRC
      script.async = true
      document.head.appendChild(script)
    })
  }

  return Promise.resolve()
}
