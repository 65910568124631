/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  buttonReset,
  CommonError,
  link,
  List,
  ListItem,
  Radio,
  SectionTitle,
  Title,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import { Fragment, useEffect } from 'react'
import * as Yup from 'yup'
import { VendorLeadSituation } from '../../../__generated__/types'
import { AddressInput } from '../../address-input'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { useVendorLead } from './vendor-lead-context'

export function Selling({
  label = 'Done',
  onContinue,
}: {
  label?: string
  onContinue: () => void
}) {
  const { state, update } = useVendorLead()
  const analytics = useAnalytics()
  useEffect(() => {
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferSelling>({
      type: BuyerEvent.VIEW_OFFER_SELLING,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const form = useFormik({
    initialValues: {
      situation: state.situation || '',
      address: {
        line1: state.address?.line1 || '',
        city: state.address?.city || '',
        state: state.address?.state || '',
        postcode: state.address?.postcode || '',
      },
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { situation, address } = values
      update({
        situation: situation as VendorLeadSituation,
        address:
          situation !== 'soon'
            ? null
            : {
                line1: address.line1,
                city: address.city,
                state: address.state,
                postcode: address.postcode,
              },
      })
      analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferSelling>({
        type: Pixel.BuyerEvent.EventType.COMPLETE_OFFER_SELLING,
        ...analytics.getEventMetadata(),
        situation,
      })
      onContinue()
    },
  })

  const skip = (e: any) => {
    e.preventDefault()
    update({
      situation: 'no',
      address: null,
    })
    // Sends a general event stating that the selling step has been completed
    analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferSelling>({
      type: Pixel.BuyerEvent.EventType.COMPLETE_OFFER_SELLING,
      ...analytics.getEventMetadata(),
      situation: 'skipped',
    })
    // Explicitly logs that the selling is skipped
    analytics.logAmplitudeEvent('selling skipped', analytics.getEventMetadata())
    onContinue()
  }

  return (
    <Fragment>
      <Title>Do you have a property to sell?</Title>
      <form onSubmit={form.handleSubmit}>
        <SectionTitle>Current status</SectionTitle>
        <List>
          <ListItem as="label">
            <Radio
              name="soon"
              value="soon"
              checked={form.values.situation === 'soon'}
              onClick={() => form.setFieldValue('situation', 'soon')}
              label="Yes, I need to sell a property soon."
            />
          </ListItem>
          <ListItem as="label">
            <Radio
              name="listed"
              value="listed"
              checked={form.values.situation === 'listed'}
              onClick={() => form.setFieldValue('situation', 'listed')}
              label="Yes, but I have already listed it for sale."
            />
          </ListItem>
          <ListItem as="label">
            <Radio
              name="noowner"
              value="noowner"
              checked={form.values.situation === 'noowner'}
              onClick={() => form.setFieldValue('situation', 'noowner')}
              label="No, I don't currently own any property."
            />
          </ListItem>
          <ListItem as="label">
            <Radio
              name="no"
              value="no"
              checked={form.values.situation === 'no'}
              onClick={() => form.setFieldValue('situation', 'no')}
              label="No, I don't need to sell a property now."
            />
          </ListItem>
          {form.errors.situation && (
            <CommonError>{form.errors.situation}</CommonError>
          )}
        </List>
        {form.values.situation === 'soon' && (
          <Fragment>
            <SectionTitle>Property to sell</SectionTitle>
            <AddressInput
              address={form.values.address}
              setAddress={(address) =>
                form.setValues({ ...form.values, address })
              }
            />
          </Fragment>
        )}
        <p className="grey">
          If you need to sell prior to settlement, it’s best to get organised
          early as possible. You can also{' '}
          <button
            type="button"
            data-testid="skip-step-button"
            onClick={skip}
            css={[buttonReset, link]}
          >
            skip this step
          </button>
          .
        </p>
        <PrimaryButton
          onClick={form.submitForm}
          label={label}
          pending={form.status}
        />
      </form>
    </Fragment>
  )
}

const validationSchema = Yup.object({
  situation: Yup.string().required('Please choose an option'),
  address: Yup.object().nullable(true),
})
