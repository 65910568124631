/// <reference types="@emotion/react/types/css-prop" />
import { ApolloProvider } from '@apollo/client'
import {
  Auth,
  AuthProvider,
  createApolloClient,
  ErrorReporting,
  FirebaseAppProvider,
  GraphQLHeaderProvider,
  GraphQLHeaders,
  RESTClient,
} from '@propps-au/client'
import { FrameTransport } from '@propps-au/frame-transport'
import { SessionEvent } from '@propps-au/pixel-analytics-types/event-types'
import PossibleTypes from '@propps-au/supergraph-schema/possible-types.json'
import { FrameMessage } from '@propps/frames-transport-messages'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { createBrowserHistory } from 'history'
import qs from 'qs'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { filter } from 'rxjs/operators'
import 'url-search-params-polyfill'
import { Analytics } from './analytics'
import App from './App'
import { AnalyticsProvider } from './components/analytics'
import { ErrorBoundary } from './components/error-boundary'
import { FrameVisibilityContext } from './components/frame-visibility'
import { FrameTransportProvider } from './components/FrameTransport'
import { IsCIContext } from './components/is-ci'
import { storage } from './components/storage'
import { initializeDatadog } from './datadog'
import { FIREBASE_CONFIG } from './firebase'
import { SplitProvider } from './split'

const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID
const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_ANALYTICS_ID
const CORE_REST_ENDPOINT = process.env.REACT_APP_CORE_REST_ENDPOINT!
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT!
const DEPLOYMENT_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV!
const GIT_SHA = process.env.REACT_APP_GIT_SHA
const SPLIT_API_KEY = process.env.REACT_APP_SPLIT_API_KEY!
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN

async function run() {
  initializeDatadog({
    env: DEPLOYMENT_ENVIRONMENT,
    version: GIT_SHA ? 'propps-mono@' + GIT_SHA : 'propps-mono@unknown',
    clientToken: DATADOG_CLIENT_TOKEN,
  })

  const app = initializeApp(FIREBASE_CONFIG)
  const history = createBrowserHistory()
  const analytics = new Analytics(history)
  const transport = new FrameTransport(window.parent, '*')
  const auth = new Auth(
    getAuth(app),
    new RESTClient({ basepath: CORE_REST_ENDPOINT })
  )
  const headers = new GraphQLHeaders({})
  const apollo = createApolloClient({
    endpoint: GRAPHQL_ENDPOINT,
    name: 'Frames',
    auth,
    headers,
    possibleTypes: PossibleTypes.possibleTypes,
  })
  const isFrameVisible = new BehaviorSubject(false)

  const isCI =
    new URLSearchParams(window.location.search.substr(1)).get('__ci__') !== null

  analytics.init({
    gaTrackingId: GA_TRACKING_ID,
    amplitudeApiKey: AMPLITUDE_API_KEY,
    headers,
  })

  /**
   * Override pixel session ID if it exists
   */
  transport
    .messages()
    .pipe(filter(FrameMessage.isTriggerMounted))
    .subscribe((message) => {
      analytics.pixel?.setSessionId(message.sessionId)
    })

  /**
   * Store any metadata passed through the script in the store to pass along
   * to Pixel if a buyer signs in.
   */
  transport
    .messages()
    .pipe(filter(FrameMessage.isSetSessionMetadata))
    .subscribe(async (message) => {
      if (!analytics.pixel) return
      analytics.pixel.setCustomMetadata(message.customMetadata)
      const sessionId = await analytics.pixel.getSessionId()
      const key = `${sessionId}:${message.customMetadata}`
      const sent = storage.get(key)
      if (!sent) {
        // ensure session initialised only once
        analytics.pixel.dispatch({
          type: SessionEvent.SESSION_INIT,
          sessionId,
          fingerprint: '',
          referrer: message.referrer,
        })
        storage.set(key, 'true')
      }
    })

  /**
   * Parse search params to determine whether we have any metadata to store in
   * this session
   */
  transport
    .messages()
    .pipe(filter(FrameMessage.isSetSessionMetadata))
    .subscribe(async (message) => {
      const search = qs.parse(message.search, { ignoreQueryPrefix: true })
      if (search.propps_loid) {
        analytics.logAmplitudeEvent('[form] [add offer] open lite offer link')
        storage.set(`lite_offer_id`, search.propps_loid)
      }
    })

  /**
   * Handle the `navigate` message If the current path includes the path
   * from the message, we know that the frame is opening the same listing so
   * the route doesn't need to be updated. This prevents the page from
   * resetting when closed and reopened.
   */
  transport
    .messages()
    .pipe(filter(FrameMessage.isNavigate))
    .subscribe((message) => {
      if (!window.location.pathname.includes(message.path)) {
        history.replace(message.path)
      }
    })

  if (isCI) {
    isFrameVisible.next(true)
  }

  isInIframe(transport).then((value) => {
    if (!value && !isFrameVisible.value) {
      isFrameVisible.next(true)
    }
  })

  transport
    .messages()
    .pipe(filter(FrameMessage.isSetVisibility))
    .subscribe((message) => {
      isFrameVisible.next(message.value)
    })

  function OpenTransport() {
    useEffect(() => {
      transport.open()
    }, [])

    return null
  }

  const root = ReactDOM.createRoot(document.getElementById('root')!)

  root.render(
    <React.StrictMode>
      <FrameTransportProvider value={transport}>
        <ErrorBoundary>
          <IsCIContext.Provider value={isCI}>
            <FrameVisibilityContext.Provider value={isFrameVisible}>
              <GraphQLHeaderProvider headers={headers}>
                <AnalyticsProvider analytics={analytics}>
                  <FirebaseAppProvider value={app}>
                    <AuthProvider auth={auth}>
                      <SplitProvider apiKey={SPLIT_API_KEY}>
                        <ApolloProvider client={apollo}>
                          <Router history={history}>
                            <CompatRouter>
                              <App />
                              <OpenTransport />
                            </CompatRouter>
                          </Router>
                        </ApolloProvider>
                      </SplitProvider>
                    </AuthProvider>
                  </FirebaseAppProvider>
                </AnalyticsProvider>
              </GraphQLHeaderProvider>
            </FrameVisibilityContext.Provider>
          </IsCIContext.Provider>
        </ErrorBoundary>
      </FrameTransportProvider>
    </React.StrictMode>
  )
}

function isInIframe(transport: FrameTransport) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(false)
    }, 1000)

    firstValueFrom(
      transport
        .messages()
        .pipe(filter((message) => FrameMessage.isConfigure(message)))
    ).then(() => {
      resolve(true)
    })
  })
}

run().catch((err) => {
  console.error('[Frames] Failed to boot.')
  console.error(err)
  ErrorReporting.report(err)
})
