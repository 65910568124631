import { gql, useQuery } from '@apollo/client'
import { useAuthState } from '@propps-au/client'
import { useCallback, useEffect } from 'react'
import { useFeature } from '../../split'
import { ChatQueryDocument } from './__generated__/chat.generated'

declare global {
  interface Window {
    $chatwoot: any
    chatwootSDK: any
    chatwootSettings: any
  }

  interface HTMLElement {
    src: string
    defer: boolean
  }
}

export function useChatWidget({
  listingId,
  agencyId = '',
}: {
  listingId: string
  agencyId?: string
}) {
  const chat = useFeature('frames_chat', {
    attributes: {
      listingId,
      agencyId,
    },
  })
  const auth = useAuthState()
  const { data } = useQuery(ChatQueryDocument, {
    variables: { listingId, buyerId: auth.role?.id! },
    skip: !chat?.enabled || !listingId || !auth.role?.id,
  })

  const buyerId = data?.me?.buyer?.id
  const name = [data?.me?.buyer?.firstName, data?.me?.buyer?.lastName]
    .filter(Boolean)
    .join(' ')
  const email = data?.me?.buyer?.email
  const phone_number = data?.me?.buyer?.phone
  const identifier_hash = data?.identifier

  const websiteToken =
    buyerId && identifier_hash ? data?.listing?.chatInbox?.websiteToken : null

  const setChatUser = useCallback(() => {
    if (!window.$chatwoot) return
    if (buyerId && identifier_hash && 'setUser' in window.$chatwoot) {
      window.$chatwoot?.setUser(buyerId, {
        name,
        email,
        phone_number,
        identifier_hash,
      })
    }
  }, [buyerId, name, email, phone_number, identifier_hash])

  useEffect(() => {
    if (!chat?.enabled) {
      return
    }

    /// NOTE: test what happens when user changes
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right',
      locale: 'en',
      type: 'standard',
    }

    if (websiteToken) {
      ;(function (d, t) {
        var BASE_URL = `${process.env.REACT_APP_CHAT_BASE_URL}`
        var g = d.createElement(t),
          s = d.getElementsByTagName(t)[0]
        g.src = BASE_URL + '/packs/js/sdk.js'
        g.defer = true
        s.parentNode?.insertBefore(g, s)
        g.onload = function () {
          window.chatwootSDK.run({
            websiteToken,
            baseUrl: BASE_URL,
          })
        }
      })(document, 'script')
    }
  }, [websiteToken, chat?.enabled])

  useEffect(() => {
    function initChat() {
      setChatUser()
    }
    window.addEventListener('chatwoot:ready', initChat)
    return () => window.removeEventListener('chatwoot:ready', initChat)
  }, [setChatUser])

  useEffect(() => {
    window.$chatwoot?.reset()
    if ('$chatwoot' in window) {
      if (auth.uid) {
        window.$chatwoot.hide?.('false')
      } else {
        window.$chatwoot.hide?.('true')
      }
    }
  }, [auth.uid])

  useEffect(() => {
    setChatUser()
  }, [setChatUser])

  return null
}

export const GRAPHQL = gql`
  query ChatQuery($listingId: ID!, $buyerId: ID!) {
    me {
      uid
      buyer {
        id
        firstName
        lastName
        email
        phone
      }
    }
    listing(id: $listingId) {
      id
      chatInbox {
        websiteToken
      }
    }
    identifier: chatwootInboxHashForListingBuyer(
      listingId: $listingId
      buyerId: $buyerId
    )
  }
`
