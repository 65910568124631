/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  color,
  CountdownTimer as Countdown,
  fontSize,
  linkReset,
  pxToRem,
} from '@propps-au/ui'
import { isPast } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const styles = {
  container: (size: React.ComponentProps<typeof Countdown>['size']) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;

    ${size === 'sm' &&
    css`
      height: ${pxToRem(24)};
    `}

    ${size === 'md' &&
    css`
      height: ${pxToRem(56)};
    `}
  `,
  label: css`
    display: inline-block;
    padding: ${pxToRem(3)} ${pxToRem(16)};
    border: 2px solid;
    border-radius: ${pxToRem(16)};
    text-transform: uppercase;
    font-size: ${fontSize.xs};
    line-height: ${fontSize.sm};
    font-weight: bold;
    letter-spacing: 0.08em;
    color: ${color.red};
  `,
  motionDiv: css`
    line-height: 0;
  `,
}

function CountdownTimerVariants({
  ...props
}: React.ComponentProps<typeof CountdownTimer>) {
  const { variant, status } = props

  const [countdownVisible, setCountdownVisible] = useState(false)

  useEffect(() => {
    const handle = setTimeout(
      () => setCountdownVisible(!countdownVisible),
      countdownVisible ? 5000 : 8000
    )
    return () => clearTimeout(handle)
  }, [countdownVisible])

  if (variant === 'timer') {
    return <Countdown {...props.timer} />
  }

  if (variant === 'label' && status) {
    return <span css={styles.label}>{status}</span>
  }

  if (variant === 'alternate' && status) {
    return (
      <AnimatePresence exitBeforeEnter>
        {countdownVisible ? (
          <motion.div
            key="countdown"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            css={styles.motionDiv}
          >
            <Countdown {...props.timer} />
          </motion.div>
        ) : (
          <motion.div
            key="label"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            css={styles.motionDiv}
          >
            <span css={styles.label}>{status}</span>
          </motion.div>
        )}
      </AnimatePresence>
    )
  }

  return null
}

export function CountdownTimer({
  to,
  variant = 'timer',
  status,
  timer,
  children,
  ...props
}: {
  to?: string
  variant?: 'timer' | 'label' | 'alternate'
  status?: string
  timer: React.ComponentProps<typeof Countdown>
  children?: React.ReactNode
}) {
  if (variant !== 'label' && isPast(timer.endDate) && !timer.completedLabel) {
    return null
  }

  return to ? (
    <Link
      to={to}
      css={[
        css`
          ${linkReset}
        `,
        styles.container(timer?.size),
      ]}
      {...props}
    >
      <CountdownTimerVariants variant={variant} status={status} timer={timer} />
      {children}
    </Link>
  ) : (
    <div css={styles.container(timer?.size)} {...props}>
      <CountdownTimerVariants variant={variant} status={status} timer={timer} />
      {children}
    </div>
  )
}
