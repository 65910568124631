import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { HoldingDepositInstructions_OfferFragmentDoc } from '../../../components/__generated__/holding-deposit-instructions.generated';
export type HoldingDepositPageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HoldingDepositPageQuery = { offer?: { __typename: 'Offer', id: string, holdingDeposit?: { __typename: 'HoldingDeposit', amount: number, status: Types.HoldingDepositStatus } | null, listing: { __typename: 'Listing', id: string, holdingDeposit: { __typename: 'HoldingDepositConfig', enabled: boolean, minimum: number, rate: number, trustAccount?: { __typename: 'TrustAccount', id: string, name: string, bsb: string, accountNo: string } | null }, agents: Array<{ __typename: 'Agent', id: string, firstName: string, lastName: string, email: string, phone?: string | null }>, property: { __typename: 'Property', address: { __typename: 'Address', line1: string } } } } | null };

export type HoldingDepositPageUpdateOfferStatusMutationVariables = Types.Exact<{
  input: Types.UpdateOfferHoldingDepositStatusInput;
}>;


export type HoldingDepositPageUpdateOfferStatusMutation = { result: { __typename: 'UpdateOfferHoldingDepositStatusResult', offer: { __typename: 'Offer', id: string, holdingDeposit?: { __typename: 'HoldingDeposit', status: Types.HoldingDepositStatus } | null } } };

export type HoldingDepositPage_OfferFragment = { __typename: 'Offer', id: string, holdingDeposit?: { __typename: 'HoldingDeposit', status: Types.HoldingDepositStatus } | null };

export const HoldingDepositPage_OfferFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"HoldingDepositPage_Offer"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Offer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"holdingDeposit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<HoldingDepositPage_OfferFragment, unknown>;
export const HoldingDepositPageQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"HoldingDepositPageQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"HoldingDepositInstructions_Offer"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"HoldingDepositPage_Offer"}}]}}]}},...HoldingDepositInstructions_OfferFragmentDoc.definitions,...HoldingDepositPage_OfferFragmentDoc.definitions]} as unknown as DocumentNode<HoldingDepositPageQuery, HoldingDepositPageQueryVariables>;
export const HoldingDepositPageUpdateOfferStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"HoldingDepositPageUpdateOfferStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateOfferHoldingDepositStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"result"},"name":{"kind":"Name","value":"updateOfferHoldingDepositStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"HoldingDepositPage_Offer"}}]}}]}}]}},...HoldingDepositPage_OfferFragmentDoc.definitions]} as unknown as DocumentNode<HoldingDepositPageUpdateOfferStatusMutation, HoldingDepositPageUpdateOfferStatusMutationVariables>;