import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { get, set } from 'idb-keyval'
import { useEffect, useState } from 'react'
import { useListing } from '../../pages/listing/listing-context'
import { ListingActivityUnseenNotificationDocument } from './__generated__/use-unseen-activity-notification.generated'

export function useUnseenActivityNotification() {
  const { id } = useListing()

  const [failed, setFailed] = useState(false)

  const [query, { data, loading }] = useLazyQuery(
    ListingActivityUnseenNotificationDocument
  )

  useEffect(() => {
    getLatestCursor(id)
      .then((cursor) => {
        query({
          variables: {
            id,
            cursor,
          },
        })
      })
      .catch(() => setFailed(true))
  }, [id, query])

  if (failed) return { loading: false, unseen: null }

  const totalSince = data?.listing?.activityBuyer.activity?.totalSince

  return { loading, unseen: totalSince === -1 ? null : totalSince }
}

export async function getLatestCursor(id: string) {
  try {
    const cursor = await get<string>(`${id}-cursor`)
    return cursor || null
  } catch (err) {
    console.error('Error getting cursor for activity notification', err)
    throw err
  }
}

async function setLatestCursor(id: string, cursor: string) {
  try {
    await set(`${id}-cursor`, cursor)
  } catch (err) {
    console.error('Error setting cursor for activity notification', err)
    return
  }
}

export async function setViewedLatest(id: string, cursor: string) {
  await setLatestCursor(id, cursor)
}

gql`
  query ListingActivityUnseenNotification($id: ID!, $cursor: String) {
    listing(id: $id) {
      id
      activityBuyer {
        id
        activity {
          id
          totalSince(cursor: $cursor)
        }
      }
    }
  }
`
