import React, { createContext, useContext } from 'react'
import { OfferFormMode } from './offer-form'
import { OfferFormStatus } from './status'
import { OfferFormValues } from './values'

export type UpdateFn<T> = (updater: ((current: T) => T) | T) => void

export type SubmissionError = String | Error | null

type OfferFormContextType = {
  state: OfferFormValues
  status: OfferFormStatus
  update: UpdateFn<OfferFormValues>
  mode: OfferFormMode
  submit: () => Promise<void>
  submissionError: SubmissionError
}

export const OfferFormContext = createContext<OfferFormContextType | null>(null)

export function OfferFormProvider({
  children,
  value,
}: {
  children?: React.ReactNode
  value: OfferFormContextType
}) {
  return (
    <OfferFormContext.Provider value={value}>
      {children}
    </OfferFormContext.Provider>
  )
}

export function useOfferForm() {
  const context = useContext(OfferFormContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!context) {
      throw new Error(
        'useOfferForm muse be called from within a <OfferFormProvider>'
      )
    }
  }

  return context!
}
