import type * as Types from '../../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OfferStatus_OfferFragmentDoc } from '../../../../components/offer-status/__generated__/index.generated';
export type OfferHubInitial_ListingFragment = { __typename: 'Listing', id: string, property: { __typename: 'Property', address: { __typename: 'Address', line1: string } }, agency?: { __typename: 'Agency', id: string } | null, agents: Array<{ __typename: 'Agent', id: string }> };

export type OfferHubListingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type OfferHubListingQuery = { listing?: { __typename: 'Listing', id: string, hasBuyerDocumentsEnabled: boolean, documents: Array<{ __typename: 'ListingDocument', name: string }>, prepareToBuy: { __typename: 'ListingPrepareToBuy', enabled: boolean }, activityBuyer: { __typename: 'ListingActivityBuyerConnection', id: string, enabled: boolean } } | null };

export type OfferHub_ListingFragment = { __typename: 'Listing', id: string, hasBuyerDocumentsEnabled: boolean, documents: Array<{ __typename: 'ListingDocument', name: string }>, prepareToBuy: { __typename: 'ListingPrepareToBuy', enabled: boolean }, activityBuyer: { __typename: 'ListingActivityBuyerConnection', id: string, enabled: boolean } };

export type OfferHub_BuyerFragment = { __typename: 'Buyer', id: string, firstName: string, lastName: string };

export type OfferHubCurrentOfferQueryVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  buyerId: Types.Scalars['ID'];
}>;


export type OfferHubCurrentOfferQuery = { currentOffer?: { __typename: 'Offer', id: string, amount: number, createdAt: string, isConditional: boolean, expiresAt?: string | null, isBinding: boolean, status?: Types.OfferStatus | null, depositPercentage?: Types.DepositPercentage | null, depositAmount?: number | null, supersedes?: { __typename: 'Offer', id: string } | null, listing: { __typename: 'Listing', agency?: { __typename: 'Agency', id: string } | null }, buyers: Array<{ __typename: 'OfferConnectedBuyer', isPrimaryBuyer: boolean, buyer: { __typename: 'Buyer', id: string, firstName: string, lastName: string, phone: string } }>, signatories: Array<{ __typename: 'OfferSignatory', firstName: string, lastName: string }>, holdingDeposit?: { __typename: 'HoldingDeposit', amount: number, status: Types.HoldingDepositStatus } | null } | null, draftOffer?: { __typename: 'DraftOffer', id: string, disposition: Types.DraftOfferDisposition } | null };

export type OfferHubOffer_OfferFragment = { __typename: 'Offer', id: string, amount: number, createdAt: string, isConditional: boolean, expiresAt?: string | null, isBinding: boolean, status?: Types.OfferStatus | null, depositPercentage?: Types.DepositPercentage | null, depositAmount?: number | null, supersedes?: { __typename: 'Offer', id: string } | null, listing: { __typename: 'Listing', agency?: { __typename: 'Agency', id: string } | null }, buyers: Array<{ __typename: 'OfferConnectedBuyer', isPrimaryBuyer: boolean, buyer: { __typename: 'Buyer', id: string, firstName: string, lastName: string, phone: string } }>, signatories: Array<{ __typename: 'OfferSignatory', firstName: string, lastName: string }>, holdingDeposit?: { __typename: 'HoldingDeposit', amount: number, status: Types.HoldingDepositStatus } | null };

export const OfferHubInitial_ListingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfferHubInitial_Listing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Listing"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"property"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"line1"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"agency"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"agents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<OfferHubInitial_ListingFragment, unknown>;
export const OfferHub_ListingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfferHub_Listing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Listing"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"documents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"prepareToBuy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"hasBuyerDocumentsEnabled"},"name":{"kind":"Name","value":"showDocuments"}},{"kind":"Field","name":{"kind":"Name","value":"activityBuyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]} as unknown as DocumentNode<OfferHub_ListingFragment, unknown>;
export const OfferHub_BuyerFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfferHub_Buyer"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Buyer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]} as unknown as DocumentNode<OfferHub_BuyerFragment, unknown>;
export const OfferHubOffer_OfferFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfferHubOffer_Offer"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Offer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferStatus_Offer"}},{"kind":"Field","name":{"kind":"Name","value":"supersedes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...OfferStatus_OfferFragmentDoc.definitions]} as unknown as DocumentNode<OfferHubOffer_OfferFragment, unknown>;
export const OfferHubListingQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OfferHubListingQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferHub_Listing"}}]}}]}},...OfferHub_ListingFragmentDoc.definitions]} as unknown as DocumentNode<OfferHubListingQuery, OfferHubListingQueryVariables>;
export const OfferHubCurrentOfferQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OfferHubCurrentOfferQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"buyerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"currentOffer"},"name":{"kind":"Name","value":"getCurrentOfferByListingAndBuyer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"listingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}}},{"kind":"Argument","name":{"kind":"Name","value":"buyerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"buyerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferHubOffer_Offer"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"draftOffer"},"name":{"kind":"Name","value":"draftOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"listingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"listingId"}}},{"kind":"Argument","name":{"kind":"Name","value":"buyerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"buyerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"disposition"}}]}}]}},...OfferHubOffer_OfferFragmentDoc.definitions]} as unknown as DocumentNode<OfferHubCurrentOfferQuery, OfferHubCurrentOfferQueryVariables>;