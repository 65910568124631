/** @jsx jsx */
import { jsx } from '@emotion/react'
import { getErrors } from '@propps-au/client'
import { Article, ArticleTitle, Textarea, Title } from '@propps-au/ui'
import { useFormik } from 'formik'
import React, { useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { PrimaryButton } from '../../primary-button'
import { useOfferForm } from '../context'
import { ConditionStatus } from './condition-status'

export function CustomCondition({ onContinue }: { onContinue: () => void }) {
  const { state, update } = useOfferForm()
  const done = useRef(false)

  const form = useFormik({
    initialValues: {
      customCondition: state.customCondition,
      customConditionAdded: state.customConditionAdded,
    },
    validationSchema,
    onSubmit: (values) => {
      update((current) => {
        return {
          ...current,
          customCondition: values.customCondition.trim(),
          customConditionAdded: values.customConditionAdded,
        }
      })

      if (done.current) {
        onContinue()
      }
    },
  })

  const handleDone = async () => {
    done.current = true
    form.submitForm()
  }

  useEffect(() => {
    done.current = false
  }, [form.values])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleDone()
      }}
    >
      <Title>Custom condition</Title>
      <Article>
        <ConditionStatus
          included={form.values.customConditionAdded}
          onAdd={async () => {
            form.setFieldValue('customConditionAdded', true)
          }}
          onRemove={() => {
            form.setFieldValue('customConditionAdded', false)
          }}
        />
      </Article>
      <Article>
        <p>
          It's a good idea to have a legal professional review any custom
          conditions.
        </p>
        <ArticleTitle>Condition text</ArticleTitle>
        <Textarea
          placeholder="Type here..."
          rows={11}
          {...form.getFieldProps('customCondition')}
          onChange={async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            await form.setFieldValue('customCondition', e.target.value)
            await form.setFieldTouched('customCondition', true)
            if (e.target.value.trim() && !form.values.customConditionAdded) {
              form.setFieldValue('customConditionAdded', true)
            }
            if (!e.target.value.trim() && form.values.customConditionAdded) {
              form.setFieldValue('customConditionAdded', false)
            }
          }}
          errors={getErrors(form, 'customCondition')}
        />
      </Article>
      <PrimaryButton label="Done" onClick={handleDone} />
    </form>
  )
}

const validationSchema = Yup.object({
  customCondition: Yup.mixed().when(
    'customConditionAdded',
    (customConditionAdded: boolean) =>
      customConditionAdded
        ? Yup.string().required(
            'Please fill in the condition text to add a condition.'
          )
        : Yup.string()
  ),
  customConditionAdded: Yup.boolean(),
})
