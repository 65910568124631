import type * as Types from '../../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VendorLead_VendorLeadFragment = { __typename: 'VendorLead', id: string, name: string, email: string, phone: string, situation?: Types.VendorLeadSituation | null, submitted: boolean, address?: { __typename: 'Address', line1: string, city: string, postcode: string, state: string } | null };

export type VendorLead_ListingFragment = { __typename: 'Listing', vendorLeadsEnabled: boolean };

export type VendorLeadBuyer_BuyerFragment = { __typename: 'Buyer', id: string, firstName: string, lastName: string, phone: string, email: string };

export type VendorLeadBuyerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VendorLeadBuyerQuery = { me?: { __typename: 'User', uid: string, buyer?: { __typename: 'Buyer', id: string, firstName: string, lastName: string, phone: string, email: string } | null } | null };

export type VendorLeadQueryVariables = Types.Exact<{
  source: Types.VendorLeadSourceInput;
}>;


export type VendorLeadQuery = { vendorLead?: { __typename: 'VendorLead', id: string, name: string, email: string, phone: string, situation?: Types.VendorLeadSituation | null, submitted: boolean, address?: { __typename: 'Address', line1: string, city: string, postcode: string, state: string } | null } | null };

export type SaveVendorLeadMutationVariables = Types.Exact<{
  input: Types.SaveVendorLeadInput;
}>;


export type SaveVendorLeadMutation = { saveVendorLead: { __typename: 'VendorLeadResult', vendorLead: { __typename: 'VendorLead', id: string, name: string, email: string, phone: string, situation?: Types.VendorLeadSituation | null, submitted: boolean, address?: { __typename: 'Address', line1: string, city: string, postcode: string, state: string } | null } } };

export type SubmitVendorLeadMutationVariables = Types.Exact<{
  input: Types.SaveVendorLeadInput;
}>;


export type SubmitVendorLeadMutation = { submitVendorLead: { __typename: 'VendorLeadResult', vendorLead: { __typename: 'VendorLead', id: string, name: string, email: string, phone: string, situation?: Types.VendorLeadSituation | null, submitted: boolean, address?: { __typename: 'Address', line1: string, city: string, postcode: string, state: string } | null } } };

export const VendorLead_VendorLeadFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VendorLead_VendorLead"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VendorLead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"situation"}},{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"line1"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"postcode"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}},{"kind":"Field","name":{"kind":"Name","value":"submitted"}}]}}]} as unknown as DocumentNode<VendorLead_VendorLeadFragment, unknown>;
export const VendorLead_ListingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VendorLead_Listing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Listing"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendorLeadsEnabled"}}]}}]} as unknown as DocumentNode<VendorLead_ListingFragment, unknown>;
export const VendorLeadBuyer_BuyerFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VendorLeadBuyer_Buyer"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Buyer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]} as unknown as DocumentNode<VendorLeadBuyer_BuyerFragment, unknown>;
export const VendorLeadBuyerQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"VendorLeadBuyerQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VendorLeadBuyer_Buyer"}}]}}]}}]}},...VendorLeadBuyer_BuyerFragmentDoc.definitions]} as unknown as DocumentNode<VendorLeadBuyerQuery, VendorLeadBuyerQueryVariables>;
export const VendorLeadQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"VendorLeadQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"source"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VendorLeadSourceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendorLead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"source"},"value":{"kind":"Variable","name":{"kind":"Name","value":"source"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VendorLead_VendorLead"}}]}}]}},...VendorLead_VendorLeadFragmentDoc.definitions]} as unknown as DocumentNode<VendorLeadQuery, VendorLeadQueryVariables>;
export const SaveVendorLeadMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveVendorLeadMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveVendorLeadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveVendorLead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendorLead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VendorLead_VendorLead"}}]}}]}}]}},...VendorLead_VendorLeadFragmentDoc.definitions]} as unknown as DocumentNode<SaveVendorLeadMutation, SaveVendorLeadMutationVariables>;
export const SubmitVendorLeadMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubmitVendorLeadMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveVendorLeadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitVendorLead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vendorLead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VendorLead_VendorLead"}}]}}]}}]}},...VendorLead_VendorLeadFragmentDoc.definitions]} as unknown as DocumentNode<SubmitVendorLeadMutation, SubmitVendorLeadMutationVariables>;