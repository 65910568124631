import { useCallback, useLayoutEffect, useRef } from 'react'

/**
 * Create a callback to be used as an event handler.
 *
 * Returns a function with an immutable reference. There
 * are no deps, all values in a closure are captured from
 * the last render (and are therefore always up to date).
 *
 * Do not call a useEvent handler during render or in a
 * useLayoutEffect
 */
export function useEvent<T extends Function>(handler: T) {
  const ref = useRef<T | null>(null)

  useLayoutEffect(() => {
    ref.current = handler
  })

  return useCallback(
    ((...args: any[]) => {
      if (!ref.current) {
        throw new Error(
          'Do not call a useEvent handler outside an event or effect.'
        )
      }
      return ref.current(...args)
    }) as any as T,
    []
  )
}
