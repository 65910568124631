import React from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import { Switch } from 'react-router-dom'

/**
 * `react-router-dom`'s switch but with children flattened
 * by `react-keyed-flatten-children`.
 *
 * Allows you to use `<Fragment>` in the direct children of `<Switch>`
 */
export function FlatSwitch({
  children,
  ...props
}: React.ComponentProps<typeof Switch>) {
  return <Switch {...props}>{flattenChildren(children)}</Switch>
}
