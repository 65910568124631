import { MailroomClient } from '@propps-au/mailroom-client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { get, set } from 'idb-keyval'
import { base62_custom } from '../util/generate-id'

export type PixelBaseClientOptions = {
  mailroom: {
    basePath: string
    setRequestHeaders?: (headers: { [key: string]: string | undefined }) => void
  }
  configs: Record<string, Pixel.EventConfig>
}

export interface IPixelBaseClient {
  _setUserId(uid: string): void
  dispatchMailroom<T = null>(
    topic: Pixel.PixelEventTopic,
    name: string,
    user: Pixel.User | null,
    data: T
  ): Promise<void>
}

export interface PixelSessionData {
  id: string
  lastAt: Date
  startedAt: Date
}

export interface IPixelClient extends IPixelBaseClient {
  setUserId(uid: string): void
  dispatch<T extends Pixel.Event>(event: T): Promise<void>
}

/**
 * Base Pixel Client which implements shared methods for the frontend and node clients.
 */
export default class PixelBaseClient implements IPixelBaseClient {
  mailroom: MailroomClient
  userId: string | null
  public user: Pixel.User | null
  readonly configs: Record<string, Pixel.EventConfig>
  private _session: PixelSessionData | null
  constructor(options: PixelBaseClientOptions) {
    this.mailroom = new MailroomClient({
      basepath: options.mailroom.basePath,
      setHeaders: options.mailroom.setRequestHeaders,
    })
    this.userId = null
    this.user = null

    // internal data
    this.configs = options.configs
    this._session = null
  }

  async initialise() {
    if (!this._session) {
      await this.getSessionId()
    }
  }

  /**
   * Set internal Pixel Client class userId. Is not necessarily transmitted.
   * @private
   */
  _setUserId(uid: string | null) {
    this.userId = uid
    if (!this.user || this.user.uid !== uid) {
      this.user = { uid, role: null }
    }
  }

  /**
   * Set internal Pixel user which is injected to Mailroom events.
   * @private
   */
  _setUser(user: Pixel.User) {
    this.user = user
    this.mailroom.setUser(user)
  }

  /**
   * Clears the pixel user to no longer transmit user information to mailroom events.
   * @private
   */
  _clearUser() {
    this.mailroom.clearUser()
    this.user = null
    this.userId = null
  }

  /**
   * Dispatch an event to the mailroom API to broadcast through the Propps internal
   * pub/sub system.
   * @param topic the mailroom topic to dispatch to
   * @param eventName the event name within the topic
   * @param event event payload
   */
  async dispatchMailroom<T = null>(
    topic: Pixel.PixelEventTopic,
    name: string,
    user: Pixel.User | null,
    data: T
  ) {
    const sid = await this.getSessionId()
    this.mailroom.setSessionId(sid)
    await this.mailroom.dispatch({
      topic,
      event: name,
      id: generateId('evt_'),
      data: {
        type: name,
        cat: new Date(),
        sid,
        user: user ?? this.user,
        ...data,
      },
    })
    await this.keepSessionAlive()
  }

  get sessionId() {
    return this._session?.id
  }

  async setCustomMetadata(metadata: string | null) {
    this.mailroom.setMetadata(metadata)
  }

  private async keepSessionAlive(): Promise<void> {
    if (!this._session) {
      return
    }
    this._session = { ...this._session, lastAt: new Date() }
    await this.handleSessionChange()
  }

  async setSessionId(id: string): Promise<string> {
    this._session = { id, startedAt: new Date(), lastAt: new Date() }
    await this.handleSessionChange()

    return id
  }

  async getSessionId(): Promise<string> {
    const now = new Date()
    // try pulling session from storage
    const storedSession = await this.getSession()
    if (storedSession) {
      if (differenceInMinutes(now, storedSession.lastAt) < 30) {
        return storedSession.id
      }
    }
    // create a new session
    const newSession = await this.createSession()
    return newSession.id
  }

  /**
   * Fetches session data from instance or local persistent storage if it exists.
   * @returns Session data or null if not in storage
   */
  private async getSession(): Promise<PixelSessionData | null> {
    if (this._session) {
      return this._session
    }
    try {
      const data = await get<PixelSessionData>('__pixel_session')
      return data ?? null
    } catch (e) {
      console.error('[Propps Pixel] Could not retrieve session data.', e)
      return null
    }
  }

  /**
   * Create a new session and store session info into persistent storage
   * @returns Session data
   */
  private async createSession(): Promise<PixelSessionData> {
    const now = new Date()
    const sessionId = generateId('ses_')
    const session = {
      id: sessionId,
      lastAt: now,
      startedAt: now,
    }
    this._session = session
    await this.handleSessionChange()
    return session
  }

  private async handleSessionChange() {
    if (this._session) {
      await set('__pixel_session', this._session).catch((e) =>
        console.error('[Propps Pixel] Could not store session data.', e)
      )
      await this.mailroom.setSessionId(this._session.id)
    }
  }
}

const generateId = (prefix: string) => base62_custom(32, prefix)()
