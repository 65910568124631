/**
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * @param num number for format, only for integers
 */
export const formatThousandsSeparators = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatDollarAmountForInput = (num: number) =>
  '$ ' + formatThousandsSeparators(num)

export const formatDollarAmount = (num: number, space = true) =>
  '$' + (space ? ' ' : '') + formatThousandsSeparators(num)
