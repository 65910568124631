import { Pixel, SessionEvent } from '@propps-au/pixel-analytics-types'

const config = (): Pixel.EventConfig => ({
  amplitude: { dispatch: false },
  mailroom: { dispatch: true, topic: Pixel.PixelEventTopic.Session },
})

export const SessionEventConfigs: Record<
  Pixel.SessionEvent.EventType,
  Pixel.EventConfig
> = {
  [SessionEvent.SESSION_INIT]: config(),
  [SessionEvent.SESSION_FINGERPRINT_USER_LINK]: config(),
  [SessionEvent.SESSION_ATTRIBUTION]: config(),
}
