import { customAlphabet } from 'nanoid'

const BASE_62 = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

/**
 * custom size base62 id generator with optional prefix
 */
export const base62_custom = (size: number, prefix?: string) => {
  const generateId = customAlphabet(BASE_62, size)

  if (prefix) {
    return () => prefix + generateId()
  }

  return generateId
}
