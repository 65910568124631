import type * as Types from '../../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OfferForm_GetCurrentBuyerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OfferForm_GetCurrentBuyerQuery = { me?: { __typename: 'User', uid: string, buyer?: { __typename: 'Buyer', id: string } | null } | null };

export type OfferForm_CreateBuyerMutationVariables = Types.Exact<{
  input: Types.CreateBuyerInput;
}>;


export type OfferForm_CreateBuyerMutation = { result: { __typename: 'CreateBuyerResult', buyer: { __typename: 'Buyer', id: string } } };


export const OfferForm_GetCurrentBuyerQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OfferForm_GetCurrentBuyerQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<OfferForm_GetCurrentBuyerQuery, OfferForm_GetCurrentBuyerQueryVariables>;
export const OfferForm_CreateBuyerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OfferForm_CreateBuyerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBuyerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"result"},"name":{"kind":"Name","value":"createBuyer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<OfferForm_CreateBuyerMutation, OfferForm_CreateBuyerMutationVariables>;