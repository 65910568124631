import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { fromPromise } from '@apollo/client/link/utils'
import { path } from 'ramda'
import { AuthStateResolved, IAuth } from '../../auth'

export function AuthLink(auth: IAuth) {
  return ApolloLink.from([
    IdTokenRevokedLink(auth),
    IdTokenExpiredLink(auth),
    AuthHeaderLink(auth),
  ])
}

export function AuthHeaderLink(auth: IAuth) {
  const ready = new Promise<void>((resolve) => {
    const unsub = auth.onStateChanged(() => {
      resolve()
      unsub()
    })
  })

  return setContext(async (operation, { headers }) => {
    await ready
    const token = (auth.state as AuthStateResolved).token

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    }
  })
}

export function IdTokenExpiredLink(auth: IAuth) {
  /** If the token is expired or malformed, try refreshing the token */
  return onError(({ operation, networkError, forward, graphQLErrors }) => {
    const getErrorCode = path<string>([
      'extensions',
      'response',
      'body',
      'error',
      'code',
    ])
    if (
      graphQLErrors &&
      graphQLErrors.some(
        (error) =>
          getErrorCode(error) &&
          ['auth/argument-error', 'auth/id-token-expired'].includes(
            getErrorCode(error)!
          )
      )
    ) {
      return fromPromise(auth.refreshToken()).flatMap(() => forward(operation))
    }
    // TODO: remove once all frontends moved ot supergraph
    if (
      networkError &&
      'result' in networkError &&
      networkError.result.error?.code &&
      ['auth/argument-error', 'auth/id-token-expired'].includes(
        networkError.result.error.code
      )
    ) {
      return fromPromise(auth.refreshToken()).flatMap(() => forward(operation))
    }
  })
}

export function IdTokenRevokedLink(auth: IAuth) {
  /** If the id token is revoked sign out the user */
  const onRevokedSignOutAndRetry = onError(
    ({ operation, networkError, forward }) => {
      if (
        networkError &&
        'result' in networkError &&
        networkError.result.error?.code === 'auth/id-token-revoked'
      ) {
        return fromPromise(auth.signOut()).flatMap(() => forward(operation))
      }
    }
  )

  /** Retry after delay in case auth is in the middle of retrieving a new token */
  const onRevokedRetry = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (err) => {
        return (
          'result' in err && err.result.error?.code === 'auth/id-token-revoked'
        )
      },
    },
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
  })

  return ApolloLink.from([onRevokedSignOutAndRetry, onRevokedRetry])
}
