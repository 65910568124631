/** @jsx jsx */
import { gql } from '@apollo/client'
import { css, jsx } from '@emotion/react'
import { Activity, Icon, IconUserSingle, pxToRem } from '@propps-au/ui'
import NumberFormat from 'react-number-format'
import { OfferEventContentCard } from './offer-event-content-card'
import { OfferUpdated_OfferUpdatedEventFragment } from './__generated__/offer-updated.generated'

const styles = {
  content: css`
    padding-top: ${pxToRem(8)};
  `,
}

export const OfferUpdatedEvent = ({
  event,
}: {
  event: OfferUpdated_OfferUpdatedEventFragment
}) => {
  return (
    <Activity.Event
      label="You"
      firstName={event.buyer.firstName}
      lastName={event.buyer.lastName}
      createdAt={event.createdAt}
      title=" updated an offer"
    >
      <div css={styles.content}>
        <OfferEventContentCard
          start={<Icon svg={IconUserSingle} />}
          end={
            <NumberFormat
              value={event.offer.amount}
              displayType="text"
              thousandSeparator
              prefix="$ "
            />
          }
        >
          <b>
            {event.buyer.firstName} {event.buyer.lastName}
          </b>
        </OfferEventContentCard>
      </div>
    </Activity.Event>
  )
}

gql`
  fragment OfferUpdated_OfferUpdatedEvent on OfferUpdatedEvent {
    createdAt
    eventId
    buyer {
      id
      firstName
      lastName
    }
    offer {
      amount
    }
  }
`
