import type * as Types from '../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OfferStatus_OfferFragmentDoc } from './index.generated';
export type OfferStatusWithdrawOfferMutationVariables = Types.Exact<{
  input: Types.WithdrawOfferInput;
}>;


export type OfferStatusWithdrawOfferMutation = { result: { __typename: 'WithdrawOfferResult', offer: { __typename: 'Offer', id: string, amount: number, createdAt: string, isConditional: boolean, expiresAt?: string | null, isBinding: boolean, status?: Types.OfferStatus | null, depositPercentage?: Types.DepositPercentage | null, depositAmount?: number | null, listing: { __typename: 'Listing', agency?: { __typename: 'Agency', id: string } | null }, buyers: Array<{ __typename: 'OfferConnectedBuyer', isPrimaryBuyer: boolean, buyer: { __typename: 'Buyer', id: string, firstName: string, lastName: string, phone: string } }>, signatories: Array<{ __typename: 'OfferSignatory', firstName: string, lastName: string }>, holdingDeposit?: { __typename: 'HoldingDeposit', amount: number, status: Types.HoldingDepositStatus } | null } } };


export const OfferStatusWithdrawOfferMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OfferStatusWithdrawOfferMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WithdrawOfferInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"result"},"name":{"kind":"Name","value":"withdrawOffer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferStatus_Offer"}}]}}]}}]}},...OfferStatus_OfferFragmentDoc.definitions]} as unknown as DocumentNode<OfferStatusWithdrawOfferMutation, OfferStatusWithdrawOfferMutationVariables>;