import { formatDollarAmount } from '@propps-au/client'
import {
  ActionCard,
  Alert,
  Article,
  ArticleTitle,
  Button,
  color,
  Icon,
  IconCircleInfo2,
  IconCircleMinus2,
  IconCircleTick,
  IconLargePayment,
  OfferCard,
  useModalState,
} from '@propps-au/ui'
import { parseISO } from 'date-fns'
import gql from 'graphql-tag'
import React, { Fragment, useEffect } from 'react'
import { useAnalytics } from '../analytics'
import { PrimaryButton } from '../primary-button'
import { WithdrawOfferModal } from './withdraw-offer-modal'
import {
  OfferStatus_BuyerFragment,
  OfferStatus_OfferFragment,
} from './__generated__/index.generated'

export function OfferStatus({
  buyer,
  offer,
  goToOfferFormUpdate,
  goToHoldingDepositPage,
  goToMakeNewOffer,
}: {
  buyer: OfferStatus_BuyerFragment
  offer: OfferStatus_OfferFragment
  goToOfferFormUpdate: () => void
  goToHoldingDepositPage: (offerId: string) => void
  goToMakeNewOffer: () => void
}) {
  const analytics = useAnalytics()
  const modal = useModalState()

  const showWithdrawModal = () => {
    modal.show()
  }

  const isAdditionalBuyer =
    offer?.buyers.find((item: any) => item.isPrimaryBuyer)!.buyer.id !==
    buyer.id

  const isWithdrawn = offer.status === 'withdrawn'

  useEffect(() => {
    if (isAdditionalBuyer) {
      analytics.logAmplitudeEvent('[offer-form] view offer as additional buyer')
    }
  }, [analytics, isAdditionalBuyer])

  return (
    <Fragment>
      {offer && (
        <Fragment>
          <Article>
            {isWithdrawn && (
              <Alert title="Offer withdrawn" variant="info" closable>
                Your offer has been withdrawn and we’ve notified the agent. If
                you have paid a deposit, please contact the agent to request a
                refund.
              </Alert>
            )}
            <ArticleTitle
              end={
                isAdditionalBuyer || isWithdrawn ? null : (
                  <Button size="xs" pill onClick={() => showWithdrawModal()}>
                    Withdraw
                  </Button>
                )
              }
              marginBottom
            >
              Your offer
            </ArticleTitle>
            <OfferCard
              firstName={offer.signatories[0].firstName}
              lastName={offer.signatories[0].lastName}
              amount={offer.amount.toString()}
              holdingDeposit={{
                amount: offer.holdingDeposit?.amount,
                status: offer.holdingDeposit?.status,
              }}
              deposit={{
                percentage: offer.depositPercentage,
                amount: offer.depositAmount,
              }}
              timestamp={parseISO(offer.createdAt)}
              showIdentityVerification={false}
              multipleBuyers={offer.signatories.length > 1}
              conditional={offer.isConditional}
              expiry={offer.expiresAt ? parseISO(offer.expiresAt) : null}
              onClick={
                isAdditionalBuyer || isWithdrawn
                  ? undefined
                  : goToOfferFormUpdate
              }
              withdrawn={isWithdrawn}
            />
            {isAdditionalBuyer ? (
              <Alert
                title="Additional buyer"
                icon={<Icon svg={IconCircleInfo2} fill="currentColor" />}
              >
                You're listed as an additional buyer on this offer. To update or
                withdraw this offer, please{' '}
                <a
                  href={`tel:${
                    offer.buyers.find((item: any) => item.isPrimaryBuyer)!.buyer
                      .phone
                  }`}
                >
                  contact
                </a>{' '}
                <b>
                  {
                    offer.buyers.find((item: any) => item.isPrimaryBuyer)!.buyer
                      .firstName
                  }{' '}
                  {
                    offer.buyers.find((item: any) => item.isPrimaryBuyer)!.buyer
                      .lastName
                  }
                </b>
                .
              </Alert>
            ) : null}

            {offer?.holdingDeposit &&
              !(offer?.holdingDeposit.status === 'unpaid' && isWithdrawn) && (
                <ActionCard
                  icon={{ svg: IconLargePayment }}
                  title="Holding deposit"
                  end={
                    isAdditionalBuyer ? undefined : (
                      <Fragment>
                        {offer.holdingDeposit.status === 'unpaid' && (
                          <Icon svg={IconCircleInfo2} fill={color.orange} />
                        )}
                        {offer.holdingDeposit.status === 'received' && (
                          <Icon svg={IconCircleTick} fill={color.green} />
                        )}
                        {offer.holdingDeposit.status === 'submitted' && (
                          <Icon svg={IconCircleTick} fill={color.green} />
                        )}
                        {offer.holdingDeposit.status === 'refunded' && (
                          <Icon svg={IconCircleMinus2} fill={color.orange} />
                        )}
                      </Fragment>
                    )
                  }
                  onClick={
                    isAdditionalBuyer
                      ? undefined
                      : () => goToHoldingDepositPage(offer.id)
                  }
                >
                  {formatDollarAmount(offer.holdingDeposit.amount)}.00{' '}
                  {offer.holdingDeposit.status &&
                  offer.holdingDeposit.status === 'unpaid'
                    ? 'deposit has not been paid yet.'
                    : `deposit has been marked as ${offer.holdingDeposit.status}.`}
                </ActionCard>
              )}
          </Article>
          {!isAdditionalBuyer ? (
            isWithdrawn ? (
              <PrimaryButton
                onClick={() => goToMakeNewOffer()}
                label="Make an offer"
              />
            ) : (
              <PrimaryButton
                onClick={() => goToOfferFormUpdate()}
                label="Update offer"
              />
            )
          ) : null}
        </Fragment>
      )}
      <WithdrawOfferModal state={modal} offerId={offer.id} />
    </Fragment>
  )
}

export const GRAPHQL = gql`
  fragment OfferStatus_Buyer on Buyer {
    id
  }

  fragment OfferStatus_Offer on Offer {
    id
    amount
    createdAt
    listing {
      agency {
        id
      }
    }
    buyers {
      buyer {
        id
        firstName
        lastName
        phone
      }
      isPrimaryBuyer
    }
    signatories {
      firstName
      lastName
    }
    isConditional
    expiresAt
    isBinding
    holdingDeposit {
      amount
      status
    }
    status
    depositPercentage
    depositAmount
  }
`
