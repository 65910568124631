import {
  Auth as FirebaseAuth,
  AuthCredential,
  getIdTokenResult,
  signInWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'
import { equals } from 'ramda'
import { AuthService, RESTClient } from '../api/rest'
import { Role, RoleObject } from './role'

export type AuthStateUnresolved = {
  ready: false
}

export type AuthStateResolved =
  | {
      ready: true
      uid: null
      role: null
      token: null
    }
  | {
      ready: true
      uid: string
      role: RoleObject | null
      token: string
    }

export type AuthState = AuthStateUnresolved | AuthStateResolved

export interface IAuth {
  state: AuthState
  onStateChanged(handler: (state: AuthStateResolved) => void): () => void
  getResolvedState(): Promise<AuthStateResolved>
  signIn(credential: AuthCredential | string): Promise<AuthStateResolved>
  activateRole(role: Role): Promise<AuthStateResolved>
  signOut(): Promise<AuthStateResolved>
  refreshToken(): Promise<AuthStateResolved>
  destroy(): void
}

export class Auth implements IAuth {
  private _state: AuthState = {
    ready: false,
    uid: null,
    role: null,
    token: null,
  }
  private readonly unsub: () => void
  private readonly subs: Set<(state: AuthStateResolved) => void> = new Set()

  constructor(
    private readonly auth: FirebaseAuth,
    private readonly client: RESTClient
  ) {
    client.setAuth(this)
    this.unsub = this.auth.onIdTokenChanged(() => {
      this.sync()
    })
  }

  public get state() {
    return this._state
  }

  private async sync({
    forceRefresh,
  }: {
    forceRefresh?: boolean
  } = {}): Promise<AuthStateResolved> {
    const user = this.auth.currentUser
    let state: AuthStateResolved
    if (user) {
      const result = await getIdTokenResult(user, forceRefresh)

      state = {
        ready: true as const,
        uid: user.uid,
        role:
          (result.claims.role as { name: Role; id: string } | undefined) ??
          null,
        token: result.token,
      }
    } else {
      state = {
        ready: true,
        uid: null,
        role: null,
        token: null,
      }
    }

    const changed = !equals(state, this._state)
    this._state = state

    if (changed) {
      for (const handler of this.subs) {
        handler(this._state)
      }
    }

    return state
  }

  public getResolvedState() {
    return new Promise<AuthStateResolved>((resolve) => {
      const unsub = this.onStateChanged((state) => {
        unsub()
        resolve(state)
      })
    })
  }

  public onStateChanged(
    handler: (state: AuthStateResolved) => void
  ): () => void {
    this.subs.add(handler)

    const state = this._state
    if (state.ready) {
      Promise.resolve().then(() => {
        handler(state)
      })
    }
    return () => this.subs.delete(handler)
  }

  public async signIn(credential: AuthCredential | string) {
    await (typeof credential === 'string'
      ? signInWithCustomToken(this.auth, credential)
      : signInWithCredential(this.auth, credential))
    return await this.sync()
  }

  public async activateRole(role: Role) {
    const { token } = await this.client.call(AuthService.activateRole, {
      body: { role },
    })
    await signInWithCustomToken(this.auth, token)
    return await this.sync()
  }

  public async signOut() {
    await this.auth.signOut()
    return await this.sync()
  }

  public async refreshToken() {
    return await this.sync({ forceRefresh: true })
  }

  public destroy() {
    this.unsub()
  }
}
