import { z } from 'zod'

export enum _EventType {
  OPEN_APP = 'open_app',

  // Get started events
  REGISTER_NEW_AGENT = 'register_new_agent',
  REGISTER_EXISTING_AGENT = 'register_existing_agent',

  // Log in events
  SWITCH_TO_SMS_LOGIN = 'switch_to_sms_login',
  SWITCH_TO_EMAIL_LOGIN = 'switch_to_email_login',
  RESEND_CODE = 'resend-code',
  VIEW_LOGIN = 'view_login',
  VIEW_LOGIN_VERIFY = 'view_login_verify',
  VIEW_REGISTER = 'view_register',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',

  // Agent pages events
  VIEW_AGENT_EDIT_PROFILE = 'view_agent_edit_profile',
  VIEW_AGENT_LISTINGS = 'view_agent_listings',
  VIEW_AGENT_SHARING = 'view_agent_sharing',
  COPY_AGENT_FORM_URL = 'copy_agent_form_url',

  // Offer events
  VIEW_OFFER = 'view_offer',
  CHANGE_OFFER_LIST_VIEW = 'change_offer_list_view',
  VIEW_ACTIVITY_FEED = 'view_activity_feed',
  VIEW_LISTING_SETTINGS = 'view_listing_settings',

  // Agent card events
  VIEW_AGENT_CARD = 'view_agent_card',
  INTERACT_AGENT_CARD = 'interact_agent_card',

  // Share form events
  SHARE_AGENT_CARD = 'share_agent_card',
  SHARE_OFFER_FORM = 'share_offer_form',
}

export namespace AppEvent {
  export import EventType = _EventType
  interface IAppEvent {
    type: string
    userId?: string
    agentId?: string
    buyerId?: string
    adminId?: string
    products?: string[]
  }

  const BaseSchema = z.object({
    userId: z.string().optional(),
    agentId: z.string().optional(),
    buyerId: z.string().optional(),
    adminId: z.string().optional(),
    products: z.array(z.string()).optional(),
  })

  export interface OpenApp extends IAppEvent {
    type: _EventType.OPEN_APP
  }

  export const OpenAppEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.OPEN_APP),
  })

  export interface RegisterNewAgent extends IAppEvent {
    type: _EventType.REGISTER_NEW_AGENT
  }
  export const RegisterNewAgentEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REGISTER_NEW_AGENT),
  })
  export interface RegisterExistingAgent extends IAppEvent {
    type: _EventType.REGISTER_EXISTING_AGENT
  }
  export const RegisterExistingAgentEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REGISTER_EXISTING_AGENT),
  })
  export interface SwitchToSmsLogin extends IAppEvent {
    type: _EventType.SWITCH_TO_SMS_LOGIN
  }
  export const SwitchToSmsLoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SWITCH_TO_SMS_LOGIN),
  })
  export interface SwitchToEmailLogin extends IAppEvent {
    type: _EventType.SWITCH_TO_EMAIL_LOGIN
  }
  export const SwitchToEmailLoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.SWITCH_TO_EMAIL_LOGIN),
  })
  export interface ResendCode extends IAppEvent {
    type: _EventType.RESEND_CODE
    channel: 'sms' | 'email'
  }
  export const ResendCodeEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.RESEND_CODE),
    channel: z.string(),
  })
  export interface ViewLogin extends IAppEvent {
    type: _EventType.VIEW_LOGIN
    channel: 'sms' | 'email'
  }
  export const ViewLoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN),
    channel: z.string(),
  })
  export interface ViewLoginVerify extends IAppEvent {
    type: _EventType.VIEW_LOGIN_VERIFY
    channel: 'sms' | 'email'
  }
  export const ViewLoginVerifyEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LOGIN_VERIFY),
    channel: z.string(),
  })

  export interface ViewRegister extends IAppEvent {
    type: _EventType.VIEW_REGISTER
  }
  export const ViewRegisterEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_REGISTER),
  })
  export interface Login extends IAppEvent {
    type: _EventType.LOGIN
    channel?: string
  }
  export const LoginEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.LOGIN),
    channel: z.string().optional(),
  })
  export interface Logout extends IAppEvent {
    type: _EventType.LOGOUT
  }
  export const LogoutEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.LOGOUT),
  })
  export interface Register extends IAppEvent {
    type: _EventType.REGISTER
  }
  export const RegisterEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.REGISTER),
  })

  /* Agent pages events */
  export interface ViewAgentEditProfile extends IAppEvent {
    type: _EventType.VIEW_AGENT_EDIT_PROFILE
  }
  export const ViewAgentEditProfileEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_AGENT_EDIT_PROFILE),
  })
  export interface ViewAgentListings extends IAppEvent {
    type: _EventType.VIEW_AGENT_LISTINGS
  }
  export const ViewAgentListingsEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_AGENT_LISTINGS),
  })
  export interface ViewAgentSharing extends IAppEvent {
    type: _EventType.VIEW_AGENT_SHARING
  }
  export const ViewAgentSharingEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_AGENT_SHARING),
  })

  export interface CopyAgentFormUrl extends IAppEvent {
    type: _EventType.COPY_AGENT_FORM_URL
    listingId: string
  }
  export const CopyAgentFormUrlEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.COPY_AGENT_FORM_URL),
    listingId: z.string(),
  })
  export interface ChangeOfferListView extends IAppEvent {
    type: _EventType.CHANGE_OFFER_LIST_VIEW
    action: 'show' | 'sort' | 'group'
    value: string
  }
  export const ChangeOfferListViewEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.CHANGE_OFFER_LIST_VIEW),
    action: z.string(),
    value: z.string(),
  })
  export interface ViewActivityFeed extends IAppEvent {
    type: _EventType.VIEW_ACTIVITY_FEED
  }
  export const ViewActivityFeedEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_ACTIVITY_FEED),
  })

  export interface ViewOffer extends IAppEvent {
    type: _EventType.VIEW_OFFER
    offerId: string
    listingId: string
  }
  export const ViewOfferEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_OFFER),
    offerId: z.string(),
    listingId: z.string(),
  })

  export interface ViewListingSettings extends IAppEvent {
    type: _EventType.VIEW_LISTING_SETTINGS
    listingId: string
  }
  export const ViewListingSettingsSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_LISTING_SETTINGS),
    listingId: z.string(),
  })

  export interface ViewAgentCard extends IAppEvent {
    type: _EventType.VIEW_AGENT_CARD
    agentId: string
  }
  export const ViewAgentCardSchema = BaseSchema.extend({
    type: z.literal(_EventType.VIEW_AGENT_CARD),
    agentId: z.string(),
  })

  export interface InteractAgentCard extends IAppEvent {
    type: _EventType.INTERACT_AGENT_CARD
    agentId: string
    interaction: string
    value?: string
  }
  export const InteractAgentCardSchema = BaseSchema.extend({
    type: z.literal(_EventType.INTERACT_AGENT_CARD),
    agentId: z.string(),
    interaction: z.string(),
    value: z.string().optional(),
  })

  export interface ShareAgentCard extends IAppEvent {
    type: _EventType.SHARE_AGENT_CARD
    agentId: string
  }
  export const ShareAgentCardSchema = BaseSchema.extend({
    type: z.literal(_EventType.SHARE_AGENT_CARD),
    agentId: z.string(),
  })

  export interface ShareOfferForm extends IAppEvent {
    type: _EventType.SHARE_OFFER_FORM
    listingId: string
  }
  export const ShareOfferFormSchema = BaseSchema.extend({
    type: z.literal(_EventType.SHARE_OFFER_FORM),
    listingId: z.string(),
  })

  export type Event =
    | OpenApp
    | RegisterNewAgent
    | RegisterExistingAgent
    | SwitchToSmsLogin
    | SwitchToEmailLogin
    | ResendCode
    | ViewLogin
    | ViewLoginVerify
    | ViewRegister
    | Login
    | Logout
    | Register
    | ViewAgentEditProfile
    | ViewAgentListings
    | ViewAgentSharing
    | CopyAgentFormUrl
    | ChangeOfferListView
    | ViewOffer
    | ViewActivityFeed
    | ViewListingSettings
    | ViewAgentCard
    | InteractAgentCard
    | ShareAgentCard
    | ShareOfferForm

  export const Validators: { [key in _EventType]: z.ZodObject<any> } = {
    [_EventType.OPEN_APP]: OpenAppEventSchema,
    [_EventType.REGISTER_NEW_AGENT]: RegisterNewAgentEventSchema,
    [_EventType.REGISTER_EXISTING_AGENT]: RegisterExistingAgentEventSchema,
    [_EventType.SWITCH_TO_SMS_LOGIN]: SwitchToSmsLoginEventSchema,
    [_EventType.SWITCH_TO_EMAIL_LOGIN]: SwitchToEmailLoginEventSchema,
    [_EventType.RESEND_CODE]: ResendCodeEventSchema,
    [_EventType.VIEW_LOGIN]: ViewLoginEventSchema,
    [_EventType.VIEW_LOGIN_VERIFY]: ViewLoginVerifyEventSchema,
    [_EventType.LOGIN]: LoginEventSchema,
    [_EventType.LOGOUT]: LogoutEventSchema,
    [_EventType.REGISTER]: RegisterEventSchema,
    [_EventType.VIEW_REGISTER]: ViewRegisterEventSchema,
    [_EventType.VIEW_AGENT_EDIT_PROFILE]: ViewAgentEditProfileEventSchema,
    [_EventType.VIEW_AGENT_LISTINGS]: ViewAgentListingsEventSchema,
    [_EventType.VIEW_AGENT_SHARING]: ViewAgentSharingEventSchema,
    [_EventType.COPY_AGENT_FORM_URL]: CopyAgentFormUrlEventSchema,
    [_EventType.CHANGE_OFFER_LIST_VIEW]: ChangeOfferListViewEventSchema,
    [_EventType.VIEW_OFFER]: ViewOfferEventSchema,
    [_EventType.VIEW_ACTIVITY_FEED]: ViewActivityFeedEventSchema,
    [_EventType.VIEW_LISTING_SETTINGS]: ViewListingSettingsSchema,
    [_EventType.VIEW_AGENT_CARD]: ViewAgentCardSchema,
    [_EventType.INTERACT_AGENT_CARD]: InteractAgentCardSchema,
    [_EventType.SHARE_AGENT_CARD]: ShareAgentCardSchema,
    [_EventType.SHARE_OFFER_FORM]: ShareOfferFormSchema,
  }
}
