import { Role, useAuthState } from '@propps-au/client'
import {
  DropdownMenuItem,
  IconHome,
  IconListBullets,
  IconUserSingle,
  StackHeader,
  StackMain,
} from '@propps-au/ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { OfferHubHistoryState } from '../../pages/listing/offer-hub'
import { CountdownTimer } from '../countdown-timer'
import { useCountdownTimer } from '../countdown-timer-provider'
import { PreRollBanner } from '../pre-roll-banner'
import { useSignOutAndClose } from '../sign-out'

export function OfferFormLayout({
  children,
  goToSignIn,
  goBack,
  goToOverview,
  goToOfferHub,
  isOnOverview = false,
}: {
  children?: React.ReactNode
  goToSignIn: () => void
  goBack: () => void
  goToOverview?: () => void
  goToOfferHub: () => void
  isOnOverview?: boolean
}) {
  const auth = useAuthState()
  const history = useHistory<OfferHubHistoryState>()
  const signOut = useSignOutAndClose()
  const countdownTimer = useCountdownTimer()

  const isBuyer = auth.role?.name === Role.BUYER
  const redirectedFromOfferHub = history?.location?.state?.fromRedirect

  return (
    <>
      <StackHeader
        variant="frames"
        onBack={redirectedFromOfferHub ? undefined : goBack}
        onSignIn={!isBuyer && isOnOverview ? goToSignIn : undefined}
        onSignOut={
          isBuyer
            ? () => {
                signOut()
                goToOfferHub()
              }
            : undefined
        }
        menu={
          isBuyer
            ? [
                <>
                  {!isOnOverview && (
                    <DropdownMenuItem
                      icon={{ svg: IconListBullets }}
                      onClick={() => {
                        goToOverview && goToOverview()
                      }}
                    >
                      Show all steps
                    </DropdownMenuItem>
                  )}
                  {!redirectedFromOfferHub && (
                    <DropdownMenuItem
                      icon={{ svg: IconHome }}
                      onClick={() => {
                        goToOfferHub()
                      }}
                    >
                      Offer hub home
                    </DropdownMenuItem>
                  )}
                  {process.env.REACT_APP_ENABLE_ACCOUNT_DEVTOOLS === 'true' &&
                    isBuyer && (
                      <DropdownMenuItem
                        icon={{ svg: IconUserSingle }}
                        onClick={() => {
                          history.push('/buyer-devtools')
                        }}
                      >
                        Account devtools
                      </DropdownMenuItem>
                    )}
                </>,
              ]
            : undefined
        }
      >
        {countdownTimer.countdownTimer?.enabled &&
          countdownTimer.countdownTimer?.expiry && (
            <CountdownTimer
              variant="alternate"
              status={countdownTimer.countdownTimer.label}
              timer={{
                loading: !countdownTimer.ready,
                size: 'sm',
                endDate: countdownTimer.countdownTimer.expiry,
              }}
            />
          )}
      </StackHeader>
      {redirectedFromOfferHub && <PreRollBanner data-testid="banner" />}
      <StackMain variant="frames">{children}</StackMain>
    </>
  )
}
