/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateRoleRequest } from '../models/ActivateRoleRequest';
import type { CheckVerificationMethodRequest } from '../models/CheckVerificationMethodRequest';
import type { CheckVerificationRequest } from '../models/CheckVerificationRequest';
import type { StartVerificationRequest } from '../models/StartVerificationRequest';
import { IOpenAPIClient } from '../core/request';

export class AuthService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async activateRole(client: IOpenAPIClient,
    {
        body,
    }: {
        body: ActivateRoleRequest,
    },): Promise<{
        token: string,
    }> {
        const result = await client.request({
            method: 'POST',
            path: `/auth/login`,
            body: body,
            mediaType: 'application/json',
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async checkVerificationMethod(client: IOpenAPIClient,
    {
        body,
    }: {
        body: CheckVerificationMethodRequest,
    },): Promise<any> {
        const result = await client.request({
            method: 'POST',
            path: `/auth/verifications/check-method`,
            body: body,
            mediaType: 'application/json',
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async startVerification(client: IOpenAPIClient,
    {
        body,
    }: {
        body: StartVerificationRequest,
    },): Promise<{
        verificationId: string,
    }> {
        const result = await client.request({
            method: 'POST',
            path: `/auth/verifications`,
            body: body,
            mediaType: 'application/json',
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async checkVerification(client: IOpenAPIClient,
    {
        verificationId,
        body,
    }: {
        verificationId: string,
        body: CheckVerificationRequest,
    },): Promise<{
        token: string,
        uid: string,
    }> {
        const result = await client.request({
            method: 'PATCH',
            path: `/auth/verifications/${verificationId}`,
            body: body,
            mediaType: 'application/json',
        });
        return result.body;
    }

}