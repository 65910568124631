/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  ErrorReporting,
  FormattedPhoneNumberInput,
  getErrors,
  useAuthVerification,
  VerificationErrorCode,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  buttonReset,
  CommonError,
  Input,
  Label,
  link,
  Title,
  Tooltip,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import isMobile from 'is-mobile'
import { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { VerificationChannel } from '..'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { handleStartVerificationError } from './auth-send-to'

const styles = {
  countdown: css`
    font-variant: tabular-nums;
  `,
}

export function AuthVerifyCode({
  title,
  verification,
  channel,
  sendTo,
  onComplete,
}: {
  title?: string
  verification: ReturnType<typeof useAuthVerification>
  channel: VerificationChannel
  sendTo: string
  onComplete?: (token: string) => Promise<void>
}) {
  const analytics = useAnalytics()
  const [error, setError] = useState('')
  const [isResendDisabled, setIsResendDisabled] = useState(true)
  const [timeRemaining, setTimeRemaining] = useState(30)
  const form = useFormik({
    initialValues: { verificationCode: '' },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setFieldValue }) => {
      try {
        const verificationCode = values.verificationCode.trim()
        setFieldValue('verificationCode', verificationCode)
        const { token } = await verification.complete(verificationCode)
        await onComplete?.(token)
      } catch (err) {
        setError(getCompleteVerificationErrorMessage(err))
      }
    },
  })

  useEffect(() => {
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewLoginVerify>({
      type: BuyerEvent.VIEW_LOGIN_VERIFY,
      channel,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultTitles = {
    email: `Let’s just confirm your email address before we continue`,
    sms: `Let’s just confirm your number before we continue`,
  }

  const channelDisplayName = {
    email: 'Email',
    sms: 'SMS',
  }

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isResendDisabled) {
      let tRemain = 30
      timer = setInterval(() => {
        setTimeRemaining(Math.max(--tRemain, 1))
      }, 1e3)
    }
    return () => clearInterval(timer)
  }, [isResendDisabled])

  useEffect(() => {
    let resendTimer: NodeJS.Timeout
    if (isResendDisabled) {
      resendTimer = setTimeout(() => {
        setIsResendDisabled(false)
      }, 30e3)
    }
    return () => clearTimeout(resendTimer)
  }, [isResendDisabled])

  const resend = async () => {
    if (isResendDisabled) {
      return
    }
    setIsResendDisabled(true)

    analytics.logPixelEvent<Pixel.BuyerEvent.ResendCode>({
      type: BuyerEvent.RESEND_CODE,
      channel,
      ...analytics.getEventMetadata(),
    })

    try {
      await verification.resend()
    } catch (err) {
      setError(handleStartVerificationError(err))
    }
  }

  return (
    <Fragment>
      <Title>{title || defaultTitles[channel]}</Title>
      <form onSubmit={form.handleSubmit}>
        <fieldset>
          {channel === 'sms' && (
            <Label label="Phone">
              <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                component={Input}
                defaultCountry="AU"
                type="text"
                value={sendTo}
                readOnly
                disabled
              />
            </Label>
          )}
          {channel === 'email' && (
            <Label label="Email">
              <Input type="email" value={sendTo} readOnly disabled />
            </Label>
          )}

          <Label label={`${channelDisplayName[channel]} code`}>
            <Input
              autoFocus={!isMobile()}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*\s*"
              autoComplete={isMobile() ? 'one-time-code' : 'off'}
              placeholder="..."
              {...form.getFieldProps(`verificationCode`)}
              errors={getErrors(form, `verificationCode`)}
            />
          </Label>
        </fieldset>
        <p className="grey">
          We’ve sent a verification code to make sure we don’t send your
          information to the wrong person. If you haven’t received anything,{' '}
          {channel === 'email'
            ? 'please check your spam or junk mail folder, otherwise '
            : ''}{' '}
          we can{' '}
          {isResendDisabled ? (
            <Tooltip
              content={
                <Fragment>
                  Please wait{' '}
                  <span css={styles.countdown}>{timeRemaining}</span>s
                </Fragment>
              }
            >
              <button
                type="button"
                aria-disabled="true"
                css={[buttonReset, link]}
              >
                resend the code
              </button>
            </Tooltip>
          ) : (
            <button type="button" onClick={resend} css={[buttonReset, link]}>
              resend the code
            </button>
          )}
          .
        </p>
        {error && <CommonError>{error}</CommonError>}
        <PrimaryButton
          label="Continue"
          pending={form.isSubmitting}
          onClick={form.submitForm}
        />
      </form>
    </Fragment>
  )
}

const validationSchema = Yup.object({
  verificationCode: Yup.string()
    .trim()
    .required(`Please enter verification code.`),
})

export function getCompleteVerificationErrorMessage(err: any) {
  switch (err.code) {
    case VerificationErrorCode.VERIFICATION_CODE_INVALID:
      return 'The verification code is invalid. Please press resend to get a new verification code or contact help@propps.com for support.'
    case VerificationErrorCode.VERIFICATION_EXPIRED:
      return 'The verification code has expired. Please press resend to get a new verification code or contact help@propps.com for support.'
    case VerificationErrorCode.VERIFICATION_CODE_INCORRECT:
      return 'The verification code appears to be incorrect. Try retyping the code or press resend to get a new one. You can contact help@propps.com for support.'
    case VerificationErrorCode.TOO_MANY_ATTEMPTS:
      return 'Too many attempts. Please wait and try again in a couple of minutes. You can contact help@propps.com for support.'
    default:
      ErrorReporting.report(err)
      return 'We were unable to complete verification. Try again later or contact help@propps.com for support.'
  }
}
