import {
  FormattedPhoneNumberInput,
  getErrors,
  useAddressFinderWidget,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  ActionCard,
  Article,
  ArticleTitle,
  Button,
  CommonError,
  IconLargePlus,
  IconSearch,
  Input,
  Label,
  Modal,
  Select,
  Textarea,
  Title,
  useModalState,
  UserCard,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { uniqBy } from 'ramda'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { IsCIContext } from '../is-ci'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { Property } from './values'
import { PropertyAgentDetails_ListingFragment } from './__generated__/property-agent-details.generated'

type PropertyAgentDetailsInput = {
  line1: Property['address']['line1'] | ''
  city: Property['address']['city'] | ''
  state: Property['address']['state'] | ''
  postcode: Property['address']['postcode'] | ''
  agent: {
    id: string | ''
    name: string | ''
    email: string | ''
    phone: string | ''
  }
}

function getFullName(
  firstName: string | undefined | null,
  lastName: string | undefined | null
) {
  if (!firstName && !lastName) {
    return ''
  }
  return `${firstName || ''} ${lastName ?? ''}`
}

function dedupeAgents(
  agents: PropertyAgentDetails_ListingFragment['agents'],
  referredAgents: PropertyAgentDetails_ListingFragment['referredAgentDetails']
) {
  const allAgents = [...agents, ...referredAgents!]
  const pickPhone = (agent: typeof allAgents[0]) => agent.phone
  return uniqBy(pickPhone, allAgents)
}

/** Parses and normalizes phone numbers to their international format without spaces */
export function isPhoneNumberValid(value?: string) {
  if (!value) {
    return false
  }

  const phoneNumber = parsePhoneNumberFromString(value, {
    defaultCountry: 'AU',
    extract: false,
  })

  if (!phoneNumber || !phoneNumber.isValid()) {
    return false
  }

  return true
}

export function PropertyAgentDetails({
  listing,
  label = 'Done',
  onContinue,
}: {
  listing: PropertyAgentDetails_ListingFragment
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update } = useOfferForm()
  const isCI = useContext(IsCIContext)
  const addressInput = useRef<HTMLTextAreaElement>()
  const [useAddressFinder, setUseAddressFinder] = useState(
    !(listing.property.address.line1 || state.property?.address.line1)
  )
  const [hasSelectedAgent, setHasSelectedAgent] = useState(
    state.agents.length > 0 ? true : false
  )
  const [canEditAgentDetails, setCanEditAgentDetails] = useState(
    state.agents[0]?.id ? false : true
  )
  const showAddAgentModal = useModalState()

  const dedupedAgents = dedupeAgents(
    listing.agents,
    listing.referredAgentDetails
  )

  useEffect(() => {
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferPropertyAgentDetails>({
      type: BuyerEvent.VIEW_OFFER_PROPERTY_AGENT_DETAILS,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik<PropertyAgentDetailsInput & { fullAddress: string }>({
    initialValues: {
      fullAddress: '',
      line1:
        listing.property.address.line1 || state.property?.address.line1 || '',
      city: listing.property.address.city || state.property?.address.city || '',
      state:
        listing.property.address.state || state.property?.address.state || '',
      postcode:
        listing.property.address.postcode ||
        state.property?.address.postcode ||
        '',
      agent: {
        id: state.agents[0]?.id || '',
        name: getFullName(
          state.agents[0]?.firstName,
          state.agents[0]?.lastName
        ),
        phone: state.agents[0]?.phone || '',
        email: state.agents[0]?.email || '',
      },
    },
    validationSchema,
    onSubmit: (values, helpers) => {
      update((s) => ({
        ...s,
        property: {
          address: {
            line1: values.line1,
            city: values.city,
            state: values.state,
            postcode: values.postcode,
          },
        },
        agents: [
          {
            id: values.agent.id,
            firstName: values.agent.name.trim().split(' ')[0],
            lastName: values.agent.name.trim().split(' ').slice(1).join(' '),
            email: values.agent.email.trim(),
            phone: values.agent.phone.trim(),
          },
        ],
      }))

      onContinue()
    },
  })

  useAddressFinderWidget(
    addressInput,
    {
      onAddressSelect: (fullAddress, metaData) => {
        form.setValues({
          ...form.values,
          fullAddress: fullAddress,
          line1: [metaData.address_line_1, metaData.address_line_2]
            .filter(Boolean)
            .join(', '),
          city: metaData.locality_name,
          state: metaData.state_territory,
          postcode: metaData.postcode,
        })
        form.setTouched({
          fullAddress: true,
          line1: true,
          city: true,
          state: true,
          postcode: true,
        })
        setUseAddressFinder(false)
      },
    },
    !isCI && useAddressFinder
  )

  useEffect(() => {
    setCanEditAgentDetails(!form.values.agent.id)
  }, [form.values.agent.id])

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>Property details</Title>
      <Article>
        <ArticleTitle>Property address</ArticleTitle>
        <fieldset>
          {useAddressFinder ? (
            <Label label="Address">
              <Textarea
                placeholder="..."
                autoComplete="off"
                required
                icon={{ svg: IconSearch }}
                {...form.getFieldProps('fullAddress')}
                errors={
                  (form.touched.line1 && form.errors.line1) ||
                  (form.touched.city && form.errors.city) ||
                  (form.touched.state && form.errors.state) ||
                  (form.touched.postcode && form.errors.postcode)
                    ? [
                        'Please type and select an address or enter one manually',
                      ]
                    : undefined
                }
                ref={addressInput as React.RefObject<HTMLTextAreaElement>}
              />
            </Label>
          ) : (
            <>
              <Label label="Address">
                <Textarea
                  placeholder="..."
                  required
                  {...form.getFieldProps('line1')}
                  errors={getErrors(form, 'line1')}
                />
              </Label>
              <Label label="City">
                <Input
                  type="text"
                  placeholder="..."
                  required
                  {...form.getFieldProps('city')}
                  errors={getErrors(form, 'city')}
                />
              </Label>
              <Label label="State">
                <Select
                  required
                  {...form.getFieldProps(`state`)}
                  errors={getErrors(form, `state`)}
                >
                  <optgroup label="State">
                    <option disabled value="">
                      ...
                    </option>
                    <option value="NSW">New South Wales</option>
                    <option value="VIC">Victoria</option>
                    <option value="QLD">Queensland</option>
                    <option value="WA">Western Australia</option>
                    <option value="SA">South Australia</option>
                    <option value="TAS">Tasmania</option>
                    <option value="ACT">Australian Capital Territory</option>
                    <option value="NT">Northern Territory</option>
                  </optgroup>
                </Select>
              </Label>
              <Label label="Postcode">
                <Input
                  type="text"
                  placeholder="..."
                  required
                  {...form.getFieldProps('postcode')}
                  errors={getErrors(form, 'postcode')}
                />
              </Label>
            </>
          )}
        </fieldset>
        {useAddressFinder ? (
          <p className="grey">
            Can't find the property? You can{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={(e) => [
                e.preventDefault(),
                form.setFieldValue('fullAddress', ''),
                setUseAddressFinder(false),
              ]}
            >
              enter the address manually
            </a>
            .
          </p>
        ) : (
          <p className="grey">
            You can also{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={(e) => [
                e.preventDefault(),
                form.setFieldValue('line1', ''),
                form.setFieldValue('city', ''),
                form.setFieldValue('state', ''),
                form.setFieldValue('postcode', ''),
                setUseAddressFinder(true),
              ]}
            >
              search for the address
            </a>
            .
          </p>
        )}
      </Article>
      <Article>
        <ArticleTitle
          marginBottom={hasSelectedAgent ? false : true}
          end={
            hasSelectedAgent ? (
              <Button
                size="xs"
                pill
                onClick={(e: any) => {
                  showAddAgentModal.show()
                }}
                data-testid="change-agent-button"
              >
                Choose agent
              </Button>
            ) : null
          }
        >
          Agent details
        </ArticleTitle>

        {hasSelectedAgent ? (
          <fieldset>
            <Label label="Name">
              <Input
                type="text"
                placeholder="..."
                required
                {...form.getFieldProps('agent.name')}
                errors={getErrors(form, 'agent.name')}
                readOnly={!canEditAgentDetails}
                disabled={!canEditAgentDetails}
              />
            </Label>
            <Label label="Email">
              <Input
                type="email"
                placeholder="..."
                required
                {...form.getFieldProps('agent.email')}
                errors={getErrors(form, 'agent.email')}
                readOnly={!canEditAgentDetails}
                disabled={!canEditAgentDetails}
              />
            </Label>
            <Label label="Phone">
              <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                component={Input}
                defaultCountry="AU"
                type="text"
                placeholder="..."
                {...form.getFieldProps('agent.phone')}
                onChange={(value) => form.setFieldValue(`agent.phone`, value)}
                errors={getErrors(form, 'agent.phone')}
                autoComplete="tel"
                inputMode="tel"
                readOnly={!canEditAgentDetails}
                disabled={!canEditAgentDetails}
              />
            </Label>
          </fieldset>
        ) : (
          <Fragment>
            <Button
              onClick={(e: any) => {
                showAddAgentModal.show()
              }}
              data-testid="choose-agent-button"
            >
              Choose agent
            </Button>
            {(form.touched.agent?.name && form.errors.agent?.name) ||
            (form.touched.agent?.phone && form.errors.agent?.phone) ||
            (form.touched.agent?.email && form.errors.agent?.email) ? (
              <CommonError>Please choose an agent</CommonError>
            ) : null}
          </Fragment>
        )}

        <p className="grey">
          This is the agent you’d like to receive your offer and/or who you’ve
          been dealing with most.
        </p>
      </Article>
      <PrimaryButton
        onClick={form.submitForm}
        label={label}
        pending={form.status}
      />
      <Modal state={showAddAgentModal} title="Choose agent">
        {dedupedAgents.map((agent, index) => {
          return (
            <UserCard
              key={index}
              variant={agent.__typename === 'Agent' ? undefined : 'dashed'}
              userAvatar={{
                image:
                  'profileImage' in agent
                    ? agent?.profileImage?.url
                    : undefined,
              }}
              firstName={agent.firstName ?? ''}
              lastName={agent.lastName ?? ''}
              email={agent.email ?? 'Email not provided'}
              phone={agent.phone ?? 'Phone not provided'}
              onClick={() => {
                form.setValues({
                  ...form.values,
                  agent: {
                    id: agent.__typename === 'Agent' ? agent.id : '',
                    name: getFullName(agent.firstName, agent.lastName),
                    phone: agent.phone ?? '',
                    email: agent.email ?? '',
                  },
                })
                setHasSelectedAgent(true)
                setCanEditAgentDetails(false)
                showAddAgentModal.hide()
              }}
            />
          )
        })}
        <ActionCard
          icon={{ svg: IconLargePlus }}
          title="Add agent"
          onClick={() => {
            form.setValues({
              ...form.values,
              agent: {
                id: '',
                name: '',
                phone: '',
                email: '',
              },
            })
            setHasSelectedAgent(true)
            setCanEditAgentDetails(true)
            showAddAgentModal.hide()
          }}
        >
          Don't see your agent? You can enter their details manually.
        </ActionCard>
      </Modal>
    </form>
  )
}

export const GRAPHQL = gql`
  fragment PropertyAgentDetails_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    property {
      address {
        line1
        city
        postcode
        state
      }
    }
    agents {
      id
      firstName
      lastName
      email
      phone
      profileImage {
        url
      }
    }
    referredAgentDetails {
      firstName
      lastName
      phone
      email
    }
  }
`

const validationSchema = Yup.object({
  line1: Yup.string().required("You'll need to provide an address."),
  city: Yup.string().required("You'll need to provide a city."),
  state: Yup.string().required("You'll need to provide a state."),
  postcode: Yup.string().required("You'll need to provide a postcode."),
  agent: Yup.object({
    name: Yup.string().required('Please let us know the name of the agent.'),
    email: Yup.string().required("This is where we'll send your offer."),
    phone: Yup.string()
      .required("This is where we'll send your offer.")
      .test(
        'valid-phone',
        'Please enter a valid Australian mobile number.',
        isPhoneNumberValid
      ),
  }),
})
