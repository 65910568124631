import type * as Types from '../../../../__generated__/types';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetPrivacyPolicyQueryVariables = Types.Exact<{
  privacyPolicyLineName: Types.Scalars['String'];
}>;


export type GetPrivacyPolicyQuery = { privacyPolicy?: { __typename: 'TermsLine', name: string, currentRevision: { __typename: 'TermsRevision', id: string } } | null };


export const GetPrivacyPolicyQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPrivacyPolicyQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"privacyPolicyLineName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"privacyPolicy"},"name":{"kind":"Name","value":"termsLine"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"privacyPolicyLineName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"currentRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>;