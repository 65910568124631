import { gql, useQuery } from '@apollo/client'
import { useListing } from '../../pages/listing/listing-context'
import { OfferForm_CurrentOfferBuyerIdvStatusDocument } from './__generated__/use-idv-status.generated'

export const useIdvStatus = ({ buyerId }: { buyerId: string }) => {
  const { id: listingId } = useListing()
  const { data, loading } = useQuery(
    OfferForm_CurrentOfferBuyerIdvStatusDocument,
    {
      variables: {
        listingId,
        buyerId,
      },
      fetchPolicy: 'cache-first',
    }
  )
  const idv = data?.offer?.identityVerification.find(
    ({ buyer }) => buyer.id === buyerId
  )
  return {
    status: idv?.status,
    statusMessage: idv?.statusMessage,
    loading,
  }
}

gql`
  query OfferForm_CurrentOfferBuyerIdvStatus($buyerId: ID!, $listingId: ID!) {
    offer: getCurrentOfferByListingAndBuyer(
      listingId: $listingId
      buyerId: $buyerId
    ) {
      id
      identityVerification {
        status
        statusMessage
        buyer {
          id
        }
      }
    }
  }
`
