import { FormikContextType } from 'formik'

export const getError = (
  formik: FormikContextType<any>,
  path: string
): string | undefined => {
  const meta = formik.getFieldMeta(path)
  return meta.touched && meta.error ? meta.error : undefined
}

/**
 * Like getError but returns the error wrapped in an array
 */
export const getErrors = (
  formik: FormikContextType<any>,
  path: string
): string[] | undefined => {
  const error = getError(formik, path)
  return error ? [error] : undefined
}
