import { gql, useMutation } from '@apollo/client'
import { ErrorReporting } from '@propps-au/client'
import { ButtonCTA, CommonError, Modal, ModalStateReturn } from '@propps-au/ui'
import React from 'react'
import { OfferStatusWithdrawOfferMutationDocument } from './__generated__/withdraw-offer-modal.generated'

export function WithdrawOfferModal({
  state,
  offerId,
}: {
  state: ModalStateReturn
  offerId: string
}) {
  const [mutate, { loading, error }] = useMutation(
    OfferStatusWithdrawOfferMutationDocument,
    {
      variables: {
        input: {
          id: offerId,
        },
      },
    }
  )

  const withdrawOffer = async () => {
    try {
      await mutate()
      state.hide()
    } catch (err) {
      ErrorReporting.report(err)
    }
  }

  return (
    <Modal
      size="sm"
      state={state}
      title="Withdraw offer"
      aria-label="withdraw-offer"
    >
      <p>Are you sure you want to withdraw this offer and notify the agent?</p>
      {error && (
        <CommonError>
          Could not withdraw offer. Please contact help@propps.com.
        </CommonError>
      )}
      <ButtonCTA onClick={() => withdrawOffer()} pending={loading}>
        Withdraw
      </ButtonCTA>
    </Modal>
  )
}

export const GRAPHQL = gql`
  mutation OfferStatusWithdrawOfferMutation($input: WithdrawOfferInput!) {
    result: withdrawOffer(input: $input) {
      offer {
        id
        ...OfferStatus_Offer
      }
    }
  }
`
