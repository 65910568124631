import { Action, Location } from 'history'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const PREFIX = '__scroll__:'

export type ScrollPosition = { left: number; top: number }

export function useScrollHistory({
  onRestore,
}: {
  onRestore: (
    position: ScrollPosition | null,
    location: Location,
    action: Action
  ) => void
}) {
  const history = useHistory()

  useEffect(() => {
    if (!isSessionStorageEnabled()) return
    if (!('scrollRestoration' in window.history)) return
    window.history.scrollRestoration = 'manual'

    let location: Location = history.location
    const unsub = history.listen((next, action) => {
      if (!next.key) onRestore(null, location, action)

      // Capture the current scroll position
      try {
        if (location.key && next.key !== location?.key) {
          const position = {
            left: window.pageXOffset,
            top: window.pageYOffset,
          }
          sessionStorage.setItem(
            PREFIX + location.key,
            JSON.stringify(position)
          )
        }
      } catch {}

      // Retrieve the stored scroll position for this route
      try {
        const entry = sessionStorage.getItem(PREFIX + next.key)
        if (entry) {
          const position = JSON.parse(entry) as { left: number; top: number }
          onRestore(position, location, action)
        } else {
          onRestore(null, location, action)
        }
      } catch {
        onRestore(null, location, action)
      }

      location = next
    })

    return () => {
      window.history.scrollRestoration = 'auto'
      unsub()
    }
  }, [history, onRestore])
}

function isSessionStorageEnabled() {
  try {
    const value = '__sess'
    sessionStorage.setItem(value, value)
    sessionStorage.removeItem(value)

    return true
  } catch (n) {
    return false
  }
}
