import { Auth, ErrorReporting, useAuthVerification } from '@propps-au/client'
import { CommonError } from '@propps-au/ui'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  AuthVerifyCode,
  handleStartVerificationError,
} from '../../buyer-auth-flow/steps'
import { BuyerCreateData, createBuyer } from './create-buyer'

export function AddBuyerVerifyEmail({
  auth,
  buyer,
  verification,
}: {
  auth: Auth
  buyer: BuyerCreateData
  verification: ReturnType<typeof useAuthVerification>
}) {
  const match = useRouteMatch()
  const history = useHistory()
  const [error, setError] = useState('')

  useEffect(() => {
    verification.start('email', buyer.email, true).catch((err: any) => {
      setError(handleStartVerificationError(err))
      ErrorReporting.report(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onComplete = async () => {
    let buyerId: string
    try {
      buyerId = await createBuyer(auth, buyer)
    } catch (err) {
      ErrorReporting.report(err)

      history.replace(match.url, {
        status: 'new-signatory-form',
        buyer,
        error:
          'There was a problem adding this buyer to your offer. Please contact support@propps.com for further help.',
      })
      return
    }

    history.replace(match.url, {
      status: 'signatory-details',
      signatory: {
        ...buyer,
        isPrimaryBuyer: false,
        buyerId,
      },
    })
  }
  return (
    <>
      <AuthVerifyCode
        channel="email"
        sendTo={buyer.email}
        verification={verification}
        onComplete={onComplete}
      />
      {error && <CommonError>{error}</CommonError>}
    </>
  )
}
