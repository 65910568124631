/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  Article,
  ArticleTitle,
  color,
  Flex,
  FlexItem,
  fontSize,
  Icon,
  IconClock,
  IconUserPassport,
  pxToRem,
  Section,
} from '@propps-au/ui'
import { Fragment } from 'react'

const styles = {
  needs: css`
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid ${color.grey10};
  `,
  need: css`
    padding: ${pxToRem(24)};
    text-align: center;

    &:not(:first-of-type) {
      border-left: 1px solid ${color.grey10};
    }
  `,
  title: css`
    display: block;
    margin-top: ${pxToRem(8)};
    font-size: ${fontSize.sm};
    line-height: ${fontSize.lg};
  `,
}

export function OverviewPreroll() {
  return (
    <Fragment>
      <Article>
        Interested in this property? Let’s capture and validate your details and
        send your offer to the selling agent for consideration.
      </Article>
      <Section>
        <ArticleTitle>You'll need</ArticleTitle>
        <Flex css={styles.needs}>
          <FlexItem xs={{ size: 3 }} css={styles.need}>
            <Icon svg={IconClock} size={pxToRem(48)} />
            <span css={styles.title}>A few minutes</span>
          </FlexItem>
          <FlexItem xs={{ size: 3 }} css={styles.need}>
            <Icon svg={IconUserPassport} size={pxToRem(48)} />
            <span css={styles.title}>Your license or passport</span>
          </FlexItem>
        </Flex>
      </Section>
    </Fragment>
  )
}
