import { z } from 'zod'
import { PixelEventBaseSchema } from '../pixel-event'

export enum _EventType {
  LISTING_REDIRECT = 'listing-redirect',
}
export namespace TrackingEvent {
  export import EventType = _EventType
  interface ITrackingEvent {
    type: string
    url: string
  }

  const BaseSchema = PixelEventBaseSchema.extend({
    url: z.string(),
  })

  export interface ListingRedirect extends ITrackingEvent {
    type: _EventType.LISTING_REDIRECT
    listingId: string
    source: string
    // optional identifiers
    isListingUrl?: boolean
    announcementId?: string
    attributionId?: string
  }

  export const ListingRedirectEventSchema = BaseSchema.extend({
    type: z.literal(_EventType.LISTING_REDIRECT),
    listingId: z.string(),
    source: z.string().nullable(),
    isListingUrl: z.boolean().optional(),
    announcementId: z.string().optional(),
    attributionId: z.string().optional(),
  })

  export type Event = ListingRedirect

  export const Validators: { [key in _EventType]: z.ZodObject<any> } = {
    [_EventType.LISTING_REDIRECT]: ListingRedirectEventSchema,
  }
}
