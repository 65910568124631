/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  breakpoint,
  Button,
  ButtonCTA,
  onePxToREM,
  pxToRem,
} from '@propps-au/ui'
import { AnimatePresence, motion } from 'framer-motion'
import getIsMobile from 'is-mobile'
import { complement } from 'ramda'
import { Children, cloneElement, useContext } from 'react'
import * as ReactIs from 'react-is'
import useMedia from 'use-media'
import { IsCIContext } from './is-ci'

const isMobile = getIsMobile()

export function FrameContentLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const button = Children.toArray(children).find(isPrimaryAction) ?? null
  children = Children.toArray(children).filter(complement(isPrimaryAction))

  const key = getKey(children)

  const isButtonInline =
    useMedia(`(max-width: ${breakpoint.sm - onePxToREM}rem)`) && isMobile

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      `}
    >
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          window.scrollTo(0, 0)
        }}
      >
        {children && (
          <Transition key={key}>
            <div
              css={css`
                flex-grow: 1;
              `}
            >
              {children}
            </div>
            {isButtonInline && (
              <NormalButtonWrapper
                css={css`
                  margin-bottom: ${pxToRem(-56)};
                `}
              >
                {button && cloneElement(button, { internal_isChild: true })}
              </NormalButtonWrapper>
            )}
          </Transition>
        )}
      </AnimatePresence>
      {!isButtonInline &&
        button &&
        cloneElement(button, { internal_isChild: true })}
    </div>
  )
}

function PrimaryAction({
  children,
  internal_isChild,
  ...props
}: Omit<React.ComponentProps<typeof Button>, 'cta'> & {
  children?: React.ReactNode
  internal_isChild?: boolean
}) {
  if (process.env.NODE_ENV !== 'production') {
    if (!internal_isChild) {
      throw new Error(
        '<FrameContentLayout.Button> must only be rendered as a direct child of <FrameContentLayout>'
      )
    }
  }

  const isButtonInline =
    useMedia(`(max-width: ${breakpoint.sm - onePxToREM}rem)`) && isMobile

  return (
    <ButtonCTA {...props} fixed={!isButtonInline}>
      {children}
    </ButtonCTA>
  )
}

const isPrimaryAction = (
  child: React.ReactNode
): child is React.ReactElement<React.ComponentProps<typeof PrimaryAction>> =>
  ReactIs.isElement(child) && child.type === PrimaryAction

FrameContentLayout.PrimaryAction = PrimaryAction

function Transition({ children }: { children?: React.ReactNode }) {
  const isCI = useContext(IsCIContext)

  const shouldReduceMotion: boolean =
    useMedia('(prefers-reduced-motion)') || isCI

  if (shouldReduceMotion) {
    return (
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        {children}
      </div>
    )
  }

  return (
    <motion.div
      initial={{ y: 64, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -64, opacity: 0 }}
      transition={{ type: 'spring', damping: 40, stiffness: 400 }}
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      {children}
    </motion.div>
  )
}

function NormalButtonWrapper({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
      {...rest}
    >
      {children}
    </div>
  )
}

function getKey(node: React.ReactNode): string | number | undefined {
  if (Children.count(node) !== 1) {
    return undefined
  }

  node = Children.only(Array.isArray(node) ? node[0] : node)

  if (ReactIs.isElement(node) || ReactIs.isFragment(node)) {
    return node.key || undefined
  }

  return undefined
}
