import React, { useState } from 'react'

export function BlurFormatInput<
  P extends {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    type?: string
    value?: string | number | readonly string[] | undefined
  }
>({
  component: Component,
  format,
  onFocus,
  onBlur,
  value,
  type,
  ...rest
}: {
  component: React.ComponentType<P>
  format: (value: string) => string
} & P) {
  const [isFocused, setFocused] = useState(false)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    onFocus && onFocus(e)
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    onBlur && onBlur(e)
  }

  // Show the formatted version of the value when focused
  const displayValue = isFocused ? value : format(value?.toString() || '')

  return React.createElement(Component, {
    ...rest,
    type: isFocused ? type : 'text',
    onBlur: handleBlur,
    onFocus: handleFocus,
    value: displayValue,
  } as any as P)
}
