import { ActionCard, Badge, IconLargeAgentUpdate } from '@propps-au/ui'
import React from 'react'
import { useUnseenActivityNotification } from '../../../components/activity-feed/use-unseen-activity-notification'

export function OfferAnnouncementsCard({
  onClick,
}: {
  onClick: React.ComponentProps<typeof ActionCard>['onClick']
}) {
  const { loading, unseen } = useUnseenActivityNotification()

  return (
    <ActionCard
      icon={{ svg: IconLargeAgentUpdate }}
      title="Property announcements"
      end={
        loading ? (
          <Badge loading={loading} />
        ) : (
          !!unseen && <Badge count={unseen} loading={loading} />
        )
      }
      onClick={onClick}
    >
      Stay in the loop with the latest property activity.
    </ActionCard>
  )
}
