import { useAuthState } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { useAnalytics } from './analytics'
import { useFrameTransport } from './FrameTransport'

export const useSignOutAndClose = () => {
  const auth = useAuthState()
  const analytics = useAnalytics()
  const transport = useFrameTransport()

  return () => {
    auth.signOut()
    analytics.logPixelEvent<Pixel.BuyerEvent.Logout>({
      type: BuyerEvent.LOGOUT,
      ...analytics.getEventMetadata(),
    })
    transport.send({ type: 'close' })
  }
}
