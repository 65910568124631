/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StartVerificationRequest = {
    isSignedIn: boolean;
    sendTo: string;
    channel: StartVerificationRequest.channel;
}

export namespace StartVerificationRequest {

    export enum channel {
        SMS = 'sms',
        EMAIL = 'email',
    }


}
