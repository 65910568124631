import { gql, useMutation, useQuery } from '@apollo/client'
import { Role, useAuthState } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { StackFooter, useStackTheme } from '@propps-au/ui'
import { LocationDescriptor } from 'history'
import React, { Fragment, Suspense, useEffect, useState } from 'react'
import { match as Match, Redirect, Route, useHistory } from 'react-router-dom'
import { useAnalytics } from '../../components/analytics'
import { BestAndFinalProvider } from '../../components/best-final/best-final'
import { BuyerAuthFlow } from '../../components/buyer-auth-flow'
import { useChatWidget } from '../../components/chat'
import { CountdownTimerProvider } from '../../components/countdown-timer-provider'
import { FlatSwitch } from '../../components/flat-switch'
import { useFrameVisibility } from '../../components/frame-visibility'
import { Loading } from '../../components/loading'
import { useSearchParams } from '../../components/use-search-params'
import { Activity } from './activity/activity'
import { GetReady } from './get-ready'
import { HoldingDepositPage } from './holding-deposit'
import { ListingProvider } from './listing-context'
import { Listing404 } from './Listing404'
import { MakeOffer } from './make-offer'
import { OfferHub, OfferHubHistoryState } from './offer-hub'
import { Paused } from './Paused'
import { UnderOffer } from './UnderOffer'
import { UpdateOffer } from './update-offer'
import { ViewDocuments } from './view-documents'
import {
  ListingRouteDispatchBuyerRegistrationMutationDocument,
  ListingRouteFromIdQueryDocument,
  ListingRouteFromSourceQueryDocument,
  ListingRoute_ListingFragment,
  ListingRoute_UserFragment,
} from './__generated__/index.generated'

export function ListingRouteLoaderFromSource({
  appId,
  foreignListingId: foreignId,
  match,
}: {
  appId: string
  foreignListingId: string
  match: Match
}) {
  const analytics = useAnalytics()
  const { data, loading, error } = useQuery(
    ListingRouteFromSourceQueryDocument,
    {
      variables: {
        appId,
        foreignId,
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    analytics.logAmplitudeEvent('load listing from source', {
      appId,
      foreignId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading && !data) {
    return <Loading />
  }

  if (
    !data?.developerApp?.listing ||
    error?.message.match(/failed to fetch/i)
  ) {
    return <Listing404 />
  }

  if (error) {
    throw error
  }

  return (
    <HideUntilOpened>
      <ListingRoute
        match={match}
        listing={data.developerApp.listing}
        user={data.me ?? null}
      />
    </HideUntilOpened>
  )
}

export function ListingRouteLoaderFromId({
  listingId,
  match,
}: {
  listingId: string
  match: Match
}) {
  const analytics = useAnalytics()
  const { data, loading, error } = useQuery(ListingRouteFromIdQueryDocument, {
    variables: {
      listingId,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  })

  useEffect(() => {
    analytics.logAmplitudeEvent('load listing from id', {
      listingId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading && !data) {
    return <Loading />
  }

  if (!data?.listing || error?.message.match(/failed to fetch/i)) {
    return <Listing404 />
  }

  if (error) {
    throw error
  }

  return (
    <HideUntilOpened>
      <ListingRoute
        match={match}
        listing={data.listing}
        user={data.me ?? null}
      />
    </HideUntilOpened>
  )
}

function HideUntilOpened({ children }: { children?: React.ReactNode }) {
  const isVisible = useFrameVisibility()
  const [hasBeenOpened, setHasBeenOpened] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setHasBeenOpened(true)
    }
  }, [isVisible])

  if (!hasBeenOpened) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

export function ListingRoute({
  match: base,
  listing,
  user,
}: {
  match: Match
  listing: ListingRoute_ListingFragment
  user: ListingRoute_UserFragment | null
}) {
  useChatWidget({ listingId: listing.id, agencyId: listing.agency?.id })
  const analytics = useAnalytics()
  const auth = useAuthState()
  const history = useHistory<OfferHubHistoryState>()
  const params = useSearchParams()
  const { setTheme } = useStackTheme()

  const [dispatchBuyerRegistration] = useMutation(
    ListingRouteDispatchBuyerRegistrationMutationDocument
  )

  const isBuyer = auth.role?.name === Role.BUYER
  const buyer = user?.buyer
  const branding = listing.branding

  useEffect(
    () => {
      analytics.setEventMetadata({
        listingId: listing.id,
        foreignId: listing.source.foreignId,
        appId: listing.source.appId,
      })
      analytics.logPixelEvent<Pixel.BuyerEvent.OpenOfferFrame>({
        type: BuyerEvent.OPEN_OFFER_FRAME,
        ...analytics.getEventMetadata(),
      })
    },
    // We only want to fire this once per listing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listing.id]
  )

  useEffect(() => {
    if (branding) {
      setTheme(branding)
    }
  }, [setTheme, branding])

  useEffect(() => {
    if (auth.role && auth.role.name === 'buyer') {
      dispatchBuyerRegistration({
        variables: { input: { userUid: auth.uid, listingId: listing.id } },
      })
    }
  }, [auth.role, auth.uid, dispatchBuyerRegistration, listing.id])

  useEffect(() => {
    if (auth.role && auth.role.name === 'buyer') {
      analytics.pixel?.dispatch<Pixel.BuyerEvent.BuyerStartSession>({
        ...analytics.getEventMetadata(),
        type: BuyerEvent.BUYER_START_SESSION,
        buyerId: auth.role.id,
      })
    }
  }, [isBuyer, auth.role, analytics])

  const goToOfferHub = () => history.push(base.url + '/offer-hub')
  const goToOfferHubFromSubmit = () =>
    history.push(base.url + '/offer-hub', { fromOfferSubmit: true })
  const goToMakeNewOffer = () => history.push(base.url + '/offer/new')
  const goToMakeOffer = () => history.push(base.url + '/offer/form')
  const goToMakeOfferFromRedirect = () =>
    history.push(base.url + '/offer/form', { fromRedirect: true })
  const goToMakeOfferFromPrepare = () =>
    history.push(base.url + '/offer/form', { fromPrepare: true })
  const goToUpdateOffer = () => history.push(base.url + '/offer/update')
  const goToPrepareToBuy = () => history.push(base.url + '/prepare')
  const goToDocuments = () => history.push(base.url + '/documents')
  const goToHoldingDepositPage = (offerId: string) =>
    history.push(base.url + '/offer/holding-deposit?offerId=' + offerId)
  const goToActivity = () => history.push(base.url + '/activity')

  const buildSignInRoute = (_params?: {
    from?: string
    to?: string
  }): LocationDescriptor<any> => {
    return {
      pathname: base.url + '/auth',
      search: _params
        ? new URLSearchParams(_params as Record<string, string>).toString()
        : undefined,
    }
  }
  const goToSignIn = (to?: string) => history.push(buildSignInRoute({ to }))

  if (listing.status === 'UNDER_OFFER' && !listing.isAcceptingOffers) {
    return <UnderOffer />
  }

  if (!listing.isAcceptingOffers) {
    return <Paused />
  }

  return (
    <ListingProvider value={listing}>
      <BestAndFinalProvider>
        <CountdownTimerProvider>
          <Suspense fallback={<Loading />}>
            <FlatSwitch>
              <Route path={base.path} exact>
                {() => <Redirect to={base.url + '/offer-hub'} />}
              </Route>
              <Route path={base.path + '/offer-hub'}>
                {() => (
                  <OfferHub
                    listing={listing}
                    buyer={buyer || undefined}
                    goTo={{
                      makeNewOffer: goToMakeNewOffer,
                      makeOfferFromRedirect: goToMakeOfferFromRedirect,
                      continueOffer: goToMakeOffer,
                      prepareToBuy: goToPrepareToBuy,
                      documents: goToDocuments,
                      signIn: () => goToSignIn(history.location.pathname),
                      activity: goToActivity,
                    }}
                    goToOffer={{
                      update: goToUpdateOffer,
                      holdingDeposit: goToHoldingDepositPage,
                    }}
                  />
                )}
              </Route>
              <Route path={base.path + '/offer/new'}>
                {({ match }) => (
                  <MakeOffer
                    startAgain
                    match={match!}
                    listingId={listing.id}
                    goBack={goToOfferHub}
                    buildSignInRoute={buildSignInRoute}
                    onContinue={(offerId) => {
                      if (
                        offerId &&
                        listing.legallyBindingOffersAllowed &&
                        listing.holdingDeposit.enabled
                      ) {
                        goToHoldingDepositPage(offerId)
                      } else {
                        goToOfferHubFromSubmit()
                      }
                    }}
                    offerHubRoute={base.url + '/offer-hub'}
                  />
                )}
              </Route>
              <Route path={base.path + '/offer/form'}>
                {({ match }) => (
                  <MakeOffer
                    match={match!}
                    listingId={listing.id}
                    goBack={goToOfferHub}
                    buildSignInRoute={buildSignInRoute}
                    onContinue={(offerId) => {
                      if (
                        offerId &&
                        listing.legallyBindingOffersAllowed &&
                        listing.holdingDeposit.enabled
                      ) {
                        goToHoldingDepositPage(offerId)
                      } else {
                        goToOfferHubFromSubmit()
                      }
                    }}
                    offerHubRoute={base.url + '/offer-hub'}
                  />
                )}
              </Route>
              <Route path={base.url + '/prepare'}>
                {({ match }) => (
                  <GetReady
                    match={match!}
                    listingId={listing.id}
                    goBack={goToOfferHub}
                    buildSignInRoute={buildSignInRoute}
                    onContinue={goToMakeOfferFromPrepare}
                    offerHubRoute={base.url + '/offer-hub'}
                  />
                )}
              </Route>
              <Route path={base.url + '/documents'}>
                {() =>
                  isBuyer ? (
                    <ViewDocuments listing={listing} goBack={goToOfferHub} />
                  ) : (
                    <Redirect
                      to={`${base.url}/auth?to=${history.location.pathname}`}
                    />
                  )
                }
              </Route>
              <Route path={base.path + '/activity'}>
                {isBuyer ? (
                  <Activity listing={listing} />
                ) : (
                  <Redirect
                    to={`${base.url}/auth?to=${history.location.pathname}`}
                  />
                )}
              </Route>
              <Route path={base.path + '/auth'}>
                {() =>
                  isBuyer ? (
                    <Redirect to={params.from ?? base.url + '/offer-hub'} />
                  ) : (
                    <BuyerAuthFlow
                      onComplete={(isNewBuyer) => {
                        history.push(
                          params.to
                            ? addParams(params.to, { fromAuth: 'true' })
                            : base.url +
                                (isNewBuyer ? '/offer/new' : '/offer-hub')
                        )
                      }}
                    />
                  )
                }
              </Route>
              {/* Buyer only routes */}
              {isBuyer ? (
                <Fragment>
                  <Route path={base.path + '/offer/update'}>
                    {({ match }) => (
                      <UpdateOffer
                        match={match!}
                        listingId={listing.id}
                        goBack={goToOfferHub}
                        buildSignInRoute={buildSignInRoute}
                        onContinue={goToOfferHubFromSubmit}
                      />
                    )}
                  </Route>
                  <Route path={base.path + '/offer/holding-deposit'}>
                    {() => (
                      <HoldingDepositPage
                        goToOfferHubPage={goToOfferHubFromSubmit}
                      />
                    )}
                  </Route>
                </Fragment>
              ) : null}
              {/* 404 */}
              <Route path={base.path + '/*'}>
                {() => <Redirect to={base.url} />}
              </Route>
            </FlatSwitch>
          </Suspense>
          {!!branding?.formFooter.content.html ||
          !!branding?.logos?.logos?.color ? (
            <StackFooter
              logoUrl={branding?.logos?.logos?.color?.thumb.url}
              variant="frames"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: branding?.formFooter?.content.html ?? '',
                }}
              />
            </StackFooter>
          ) : null}
        </CountdownTimerProvider>
      </BestAndFinalProvider>
    </ListingProvider>
  )
}

function addParams(url: string, entries: Record<string, string>) {
  const [base, search] = url.split('?')

  let params = new URLSearchParams(search as string | undefined)
  for (const [key, value] of Object.entries(entries)) {
    params.append(key, value)
  }

  return base + '?' + params.toString()
}

export const GRAPHQL = gql`
  query ListingRouteFromSourceQuery($appId: ID!, $foreignId: ID!) {
    developerApp(id: $appId) {
      listing(foreignId: $foreignId) {
        id
        ...ListingRoute_Listing
      }
    }
    me {
      ...ListingRoute_User
    }
  }

  query ListingRouteFromIdQuery($listingId: ID!) {
    listing(id: $listingId) {
      id
      ...ListingRoute_Listing
    }
    me {
      ...ListingRoute_User
    }
  }

  mutation ListingRouteDispatchBuyerRegistrationMutation(
    $input: DispatchListingBuyerRegistrationInput!
  ) {
    dispatchListingBuyerRegistration(input: $input)
  }

  fragment ListingRoute_Listing on Listing {
    id
    status
    type
    isAcceptingOffers
    source {
      appId
      foreignId
    }
    holdingDeposit {
      enabled
    }
    branding {
      colors {
        primary
      }
      formFooter {
        content {
          markdown
          html
        }
      }
      logos {
        altText
        logos {
          color {
            thumb {
              url
            }
          }
        }
      }
    }
    ...BuyerAuthFlow_Listing
    ...OfferHubInitial_Listing
    ...ViewDocuments_Listing
    ...Activity_Listing
  }

  fragment ListingRoute_User on User {
    uid
    buyer {
      id
      firstName
      lastName
    }
  }
`
