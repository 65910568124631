import React from 'react'

export const PreRollBanner = ({
  onClick,
  ...props
}: {
  onClick?: (e?: React.MouseEvent) => void
} & React.HTMLProps<HTMLDivElement>) => (
  <div className="pre-loading:pre-roll-banner" {...props}>
    <img
      src="/images/pre-roll-banner.svg"
      alt=""
      onClick={onClick}
      className="pre-loading:pre-roll-banner__img"
    />
    <div className="pre-loading:pre-roll-banner__gradient" />
  </div>
)
