/* eslint-disable jsx-a11y/anchor-is-valid */
import { BlurFormatInput, getErrors } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { Input, Label, Select, Title } from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import isMobile from 'is-mobile'
import React, { Fragment, useEffect } from 'react'
import * as Yup from 'yup'
import { useAnalytics } from '../analytics'
import { PrimaryButton } from '../primary-button'
import { useOfferForm } from './context'
import { DateSchema, formatInputDate } from './date-schema'
import { OfferFormMode } from './mode'
import { FinanceType } from './values'
import { Finance_ListingFragment } from './__generated__/finance.generated'

type FinanceFormValues = {
  type: FinanceType | ''
  institution: string
  institutionOther?: string
  date: string
  note: string
}

const INSTITUTIONS = [
  { name: 'ANZ', value: 'anz' },
  { name: 'NAB', value: 'nab' },
  { name: 'Commonwealth Bank', value: 'cba' },
  { name: 'Westpac Bank', value: 'westpac' },
  { name: 'Macquarie Bank', value: 'macquarie' },
  { name: 'Bendigo Bank', value: 'bendigo' },
  { name: 'AMP Bank', value: 'amp' },
  { name: 'Suncorp Bank', value: 'suncorp' },
  { name: 'Bankwest', value: 'bankwest' },
  { name: 'Bank of Melbourne', value: 'melbourne' },
  { name: 'ME (Members Equity) Bank', value: 'me' },
]

const isMobileDevice = isMobile()

const constructFinanceObject = (values: FinanceFormValues) => {
  switch (values.type) {
    case FinanceType.APPROVED:
      return {
        type: values.type as FinanceType.APPROVED,
        institution:
          values.institution === 'other' && values.institutionOther
            ? values.institutionOther
            : values.institution,
        date: formatInputDate(values.date.trim()),
        note: null,
      }
    case FinanceType.NONE:
      return {
        type: values.type as FinanceType.NONE,
        institution: null,
        date: null,
        note: null,
      }
    case FinanceType.NOT_APPLICABLE:
      return {
        type: values.type as FinanceType.NOT_APPLICABLE,
        institution: null,
        date: null,
        note: values.note || null,
      }
    case FinanceType.OTHER:
      return {
        type: values.type as FinanceType.OTHER,
        institution: null,
        date: null,
        note: values.note || null,
      }
    default:
      return null
  }
}

export function Finance({
  listing,
  label = 'Done',
  onContinue,
}: {
  listing: Finance_ListingFragment
  label?: string
  onContinue: () => void
}) {
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbFinance>({
        type: BuyerEvent.VIEW_PTB_FINANCE,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferFinance>({
        type: BuyerEvent.VIEW_OFFER_FINANCE,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik<FinanceFormValues>({
    initialValues: {
      type: state.finance?.type || '',
      institution: state.finance?.institution
        ? INSTITUTIONS.find(
            (institution) => institution.value === state.finance?.institution
          )
          ? state.finance?.institution
          : 'other'
        : '',
      institutionOther: state.finance?.institution
        ? !INSTITUTIONS.find(
            (institution) => institution.value === state.finance?.institution
          )
          ? state.finance?.institution
          : ''
        : '',
      date: state.finance?.date || '',
      note: state.finance?.note || '',
    },
    validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      const finance = constructFinanceObject(values)
      update((s) => ({
        ...s,
        finance,
      }))

      if (finance) {
        analytics.logPixelEvent<Pixel.BuyerEvent.CompleteOfferFinance>({
          type: BuyerEvent.COMPLETE_OFFER_FINANCE,
          financeType: finance.type,
          financeInstitution: finance.institution,
          financeDate: finance.date,
          financeNote: finance.note,
          ...analytics.getEventMetadata(),
        })
      }
      onContinue()
    },
  })

  const skip = (e: any) => {
    e.preventDefault()
    update((s) => ({
      ...s,
      finance: {
        type: FinanceType.NONE,
        institution: null,
        date: null,
        note: null,
      },
    }))
    onContinue()
  }

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>Do you have finance organised to buy a property?</Title>
      <fieldset>
        <Label label="Finance status">
          <Select
            {...form.getFieldProps('type')}
            errors={getErrors(form, 'type')}
            autoFocus={!isMobileDevice}
            data-testid="type-select"
          >
            <optgroup label="Finance status">
              <option disabled value="">
                ...
              </option>
              <option value={FinanceType.APPROVED}>
                Pre-approved or Approved in Principle
              </option>
              <option value={FinanceType.NONE}>
                I don't have finance arranged yet
              </option>
              <option value={FinanceType.NOT_APPLICABLE}>
                I don't need finance
              </option>
              <option value={FinanceType.OTHER}>Other</option>
            </optgroup>
          </Select>
        </Label>
        {[FinanceType.NOT_APPLICABLE, FinanceType.OTHER].includes(
          form.values.type as FinanceType
        ) && (
          <Label label="Notes">
            <Input
              type="text"
              placeholder="..."
              {...form.getFieldProps('note')}
              errors={getErrors(form, 'note')}
              data-testid="note-input"
            />
          </Label>
        )}
        {FinanceType.APPROVED === form.values.type && (
          <Fragment>
            <Label label="Institution">
              <Select
                {...form.getFieldProps('institution')}
                errors={getErrors(form, 'institution')}
                data-testid="institution-select"
              >
                <optgroup label="Institution">
                  <option disabled value="">
                    ...
                  </option>
                  {INSTITUTIONS.map(({ name, value }) => (
                    <option value={value} key={value}>
                      {name}
                    </option>
                  ))}
                  <option value="other">Other</option>
                </optgroup>
              </Select>
            </Label>
            {form.values.institution === 'other' && (
              <Input
                type="text"
                placeholder="Bank or institution name"
                {...form.getFieldProps('institutionOther')}
                errors={getErrors(form, 'institutionOther')}
                data-testid="institutionOther-input"
              />
            )}
            <Label label="Date obtained">
              <BlurFormatInput<React.ComponentProps<typeof Input>>
                component={Input}
                type="text"
                placeholder="DD / MM / YYYY"
                {...form.getFieldProps('date')}
                errors={getErrors(form, 'date')}
                format={(value) => formatInputDate(value)}
                onBlur={(event) => {
                  form.setFieldValue('date', formatInputDate(form.values.date))
                  form.handleBlur(event)
                }}
                data-testid="date-input"
              />
            </Label>
          </Fragment>
        )}
      </fieldset>
      <p className="grey">
        Confirming your finance is organised will make your offer appear
        stronger to the vendor. You can also{' '}
        <a href="" onClick={skip}>
          skip this step
        </a>
        .
      </p>
      <PrimaryButton onClick={form.submitForm} label={label} />
    </form>
  )
}

const validationSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf([
      FinanceType.NONE,
      FinanceType.APPROVED,
      FinanceType.NOT_APPLICABLE,
      FinanceType.OTHER,
    ])
    .required('Please select a finance status option'),
  institution: Yup.string().when(
    'type',
    (type: string, schema: Yup.StringSchema) =>
      'approved' === type
        ? schema.required('Please enter the financial institution.')
        : schema.notRequired()
  ),
  date: DateSchema.when('type', (type: string, schema: Yup.StringSchema) =>
    FinanceType.APPROVED === type
      ? DateSchema.required('Please enter the date obtained.')
      : schema.notRequired()
  ),
  note: Yup.string(),
})

export const GRAPHQL = gql`
  fragment Finance_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
  }
`
