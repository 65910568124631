import { useQuery } from '@apollo/client'
import { Role, useAuthState } from '@propps-au/client'
import { LocationDescriptor } from 'history'
import React, { useEffect } from 'react'
import { match as Match, useHistory } from 'react-router-dom'
import { PrimaryButton } from '../primary-button'
import { useLiteOffer } from '../use-lite-offer'
import { OfferFormController } from './controller'
import { OfferFormLayout } from './layout'
import { OfferFormMode } from './mode'
import {
  OfferForm_CurrentOfferQueryDocument,
  OfferForm_DraftOfferQueryDocument,
  OfferForm_QueryDocument,
} from './query'
import { OfferFormRoutes } from './routes'
import { VendorLeadProvider } from './selling/vendor-lead-context'

export function OfferForm({
  match,
  listingId,
  buildSignInRoute,
  goBack: goBackRoot,
  mode,
  onContinue,
  fromDraft = false,
}: {
  match: Match
  listingId: string
  buildSignInRoute: (params: {
    from?: string
    to?: string
  }) => LocationDescriptor
  goBack: () => void
  mode: OfferFormMode
  onContinue: (offerId?: string) => void
  fromDraft?: boolean
}) {
  const history = useHistory()
  const auth = useAuthState()
  const isBuyer = auth.role?.name === Role.BUYER && auth.role.id

  const { data: listingBuyerData, error: listingBuyerError } = useQuery(
    OfferForm_QueryDocument,
    {
      variables: { listingId },
    }
  )

  const shouldLoadDraftOffer =
    fromDraft && isBuyer && mode === OfferFormMode.CREATE_OFFER

  const {
    data: draftOfferData,
    loading: draftOfferLoading,
    error: draftOfferError,
  } = useQuery(
    OfferForm_DraftOfferQueryDocument,
    auth.role?.id
      ? {
          fetchPolicy: 'network-only',
          variables: {
            buyerId: auth.role.id,
            listingId,
          },
          skip: !shouldLoadDraftOffer,
        }
      : {
          skip: true,
        }
  )

  const shouldLoadCurrentOffer = isBuyer && mode === OfferFormMode.UPDATE_OFFER
  const {
    data: currentOfferData,
    loading: currentOfferLoading,
    error: currentOfferError,
  } = useQuery(
    OfferForm_CurrentOfferQueryDocument,
    auth.role?.id
      ? {
          fetchPolicy: 'network-only',
          variables: {
            buyerId: auth.role.id,
            listingId,
          },
          skip: !shouldLoadCurrentOffer,
        }
      : { skip: true }
  )

  const shouldLoadLiteOffer = isBuyer && mode === OfferFormMode.CREATE_OFFER
  const {
    liteOffer,
    loading: liteOfferLoading,
    claimLiteOffer,
  } = useLiteOffer({
    skip: !shouldLoadLiteOffer,
  })

  useEffect(() => {
    if (liteOffer && shouldLoadLiteOffer) {
      claimLiteOffer()
    }
  }, [liteOffer, claimLiteOffer, shouldLoadLiteOffer])

  const goBack = () => {
    if (history.location.pathname === match.url) {
      goBackRoot()
    } else if (history.location.pathname.endsWith('/buyers')) {
      goToIndex()
    } else {
      history.goBack()
    }
  }
  const goToSignIn = () =>
    history.replace(buildSignInRoute({ to: history.location.pathname }))
  const goToIndex = () => history.push(match.url)

  if (listingBuyerError || draftOfferError || currentOfferError) {
    throw listingBuyerError || draftOfferError || currentOfferError
  }

  const listing = listingBuyerData?.listing
  const buyer = listingBuyerData?.me?.buyer
  const loading =
    !listing ||
    (isBuyer && !buyer) ||
    liteOfferLoading ||
    draftOfferLoading ||
    currentOfferLoading

  if (loading) {
    return (
      <OfferFormLayout
        goBack={goBack}
        goToSignIn={goToSignIn}
        goToOfferHub={goBackRoot}
      >
        <PrimaryButton pending />
      </OfferFormLayout>
    )
  }

  return (
    <VendorLeadProvider>
      <OfferFormController
        mode={mode}
        listing={listing}
        buyer={buyer ?? null}
        liteOffer={liteOffer ?? null}
        draftOffer={draftOfferData?.draftOffer ?? null}
        currentOffer={currentOfferData?.offer ?? null}
        onSubmit={onContinue}
      >
        <OfferFormLayout
          goBack={goBack}
          goToSignIn={goToSignIn}
          goToOverview={goToIndex}
          goToOfferHub={goBackRoot}
          isOnOverview={history.location.pathname === match.url}
        >
          <OfferFormRoutes
            buildSignInRoute={buildSignInRoute}
            goBack={goBack}
            match={match}
            listing={listing}
            buyer={buyer ?? null}
          />
        </OfferFormLayout>
      </OfferFormController>
    </VendorLeadProvider>
  )
}

export { OfferFormMode }
