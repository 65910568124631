import { IAuth } from '../../auth'
import { ApiError, DEFAULT_CONFIG, OpenAPIClient } from './__generated__'
import { ApiRequestOptions } from './__generated__/core/ApiRequestOptions'
import { ApiResult } from './__generated__/core/ApiResult'

export class RESTClient extends OpenAPIClient {
  private auth?: IAuth

  constructor({ basepath, auth }: RESTClientOptions) {
    super({
      ...DEFAULT_CONFIG,
      BASE: basepath,
      TOKEN: () =>
        this.auth?.getResolvedState().then((result) => result.token ?? '') ??
        Promise.resolve(''),
    })

    this.auth = auth
  }

  async request(options: ApiRequestOptions): Promise<ApiResult> {
    return this._request(options)
  }

  setAuth(auth: IAuth) {
    this.auth = auth
  }

  private async _request(
    options: ApiRequestOptions,
    context?: { isRetry: boolean }
  ): Promise<ApiResult> {
    try {
      return await super.request(options)
    } catch (err) {
      if (
        this.auth &&
        err instanceof ApiError &&
        err.status === 401 &&
        !context?.isRetry
      ) {
        await this.auth.refreshToken()
        return this._request(options, { isRetry: true })
      }
      throw err
    }
  }
}

export type RESTClientOptions = {
  basepath: string
  auth?: IAuth
}
