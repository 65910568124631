import { formatDollarAmount } from './format-dollar-amount'

export function renderConditionWithParams({
  contentHTML,
  params,
  paramDefinitions,
}: {
  contentHTML: string
  params: readonly { name: string; value: string }[]
  paramDefinitions?: readonly { name: string; type?: string | null }[]
}): string {
  let result = contentHTML
  const definitions = new Map(paramDefinitions?.map((p) => [p.name, p]))

  for (const param of params) {
    const regexp = new RegExp('%\\[' + param.name + '\\]%', 'g')
    const definition = definitions.get(param.name) ?? null
    const value = formatValue(definition?.type, param.value)
    result = result.replace(regexp, value)
  }

  return result.replace(/%\[.+\]%/g, '')
}

function formatValue(type: string | undefined | null, value: string) {
  if (type === 'amount') {
    const num = parseInt(value)
    if (isNaN(num)) {
      return value
    }
    return formatDollarAmount(num)
  }

  return value
}
