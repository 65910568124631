import React, { useEffect, useState } from 'react'
import { useEvent } from '../use-event'

export function useFormattedNumberInput<T extends Element = HTMLInputElement>({
  format,
  value,
  onChange,
  onBlur,
}: {
  format: (value: number) => string
  value: string
  onChange?: (value: string) => void
  onBlur?: (e: React.FocusEvent<T>) => void
}) {
  const [intermediateValue, setIntermediateValue] = useState(value)
  const strip = (str: string) => str.trim().replace(/[^0-9.]/g, '')

  const runFormatter = (val: string | number = intermediateValue) => {
    const num: number = typeof val === 'string' ? parseInt(strip(val)) : val

    if (!isNaN(num)) {
      setIntermediateValue(format(num))
    }
  }

  // format the initial value
  useEffect(() => {
    runFormatter()
    // this should only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // dispatch onChange when intermediateValue changes
  const dispatch = useEvent((value: string) => {
    onChange?.(value)
  })
  useEffect(() => {
    const num = parseInt(strip(intermediateValue))

    const val = isNaN(num) ? '' : num.toString()
    if (val !== value && onChange) {
      dispatch(val)
    }
  }, [intermediateValue, value])

  // when the value changes and it doesn't seem that the change
  // was initiated from this hook, reset the intermediate value
  useEffect(() => {
    const valueAsNumber = parseInt(strip(value))
    const intermediateValueAsNumber = parseInt(strip(intermediateValue))

    if (valueAsNumber !== intermediateValueAsNumber || isNaN(valueAsNumber)) {
      setIntermediateValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = useEvent((e: React.ChangeEvent<HTMLInputElement>) => {
    const num = parseInt(strip(e.target.value))

    let shouldFormat = false

    // do format when we have appended a new digit
    // this can cause cursor jump if the last n characters are the same and another is appended
    if (
      e.target.value.startsWith(intermediateValue) &&
      e.target.value.length === intermediateValue.length + 1
    )
      shouldFormat = true

    // do format when we have deleted a digit from the end
    // this can cause cursor jump if the last n characters are the same and one of them is deleted
    if (
      intermediateValue.startsWith(e.target.value) &&
      e.target.value.length === intermediateValue.length - 1
    )
      shouldFormat = true

    // but don't reformat if we can't make a valid number
    if (isNaN(num)) shouldFormat = false

    if (shouldFormat) {
      runFormatter(num)
    } else {
      setIntermediateValue(e.target.value)
    }
  })

  const handleBlur = useEvent((e: React.FocusEvent<T>) => {
    runFormatter()
    onBlur?.(e)
  })

  return {
    onChange: handleChange,
    onBlur: handleBlur,
    value: intermediateValue,
  }
}

export function FormattedNumberInput<
  P extends {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    value?: string | number | readonly string[] | undefined
  }
>({
  component: Component,
  format,
  onChange,
  onBlur,
  value,
  ...rest
}: {
  component: React.ComponentType<P>
  format: (value: number) => string
  onChange?: (value: string) => void
} & Omit<P, 'onChange'>) {
  const {
    onChange: handleChange,
    onBlur: handleBlur,
    value: displayValue,
  } = useFormattedNumberInput({
    format,
    onChange,
    onBlur,
    value: value?.toString() || '',
  })

  return React.createElement(Component, {
    ...rest,
    onChange: handleChange,
    onBlur: handleBlur,
    value: displayValue,
  } as any as P)
}
