/** @jsx jsx */
import { gql } from '@apollo/client'
import { jsx } from '@emotion/react'
import { Activity } from '@propps-au/ui'
import { ListingStatusUpdated_ListingStatusUpdatedEventFragment } from './__generated__/listing-status-updated.generated'

export const ListingStatusUpdatedEvent = ({
  event,
}: {
  event: ListingStatusUpdated_ListingStatusUpdatedEventFragment
}) => {
  const title = (
    status: ListingStatusUpdated_ListingStatusUpdatedEventFragment['status']
  ) => {
    if (status === 'ENABLED') {
      return ' is open to offers'
    } else if (status === 'UNDER_OFFER') {
      return ' is under offer'
    } else if (status === 'PAUSED') {
      return ' is not accepting offers'
    } else if (status === 'SOLD') {
      return ' has been sold'
    } else {
      return null
    }
  }

  if (!title(event.status)) {
    return null
  }

  return (
    <Activity.Event
      label="The property"
      createdAt={event.createdAt}
      title={title(event.status)}
    />
  )
}

gql`
  fragment ListingStatusUpdated_ListingStatusUpdatedEvent on ListingStatusUpdatedEvent {
    createdAt
    eventId
    status
  }
`
