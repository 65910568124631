/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FormattedPhoneNumberInput, getErrors } from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import {
  ActionCard,
  Article,
  ArticleTitle,
  Checkbox,
  CommonError,
  IconLargeUsers,
  Input,
  Label,
  List,
  ListItem,
  Radio,
  Title,
} from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import React, { Fragment, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as Yup from 'yup'
import { OfferType } from '../../../__generated__/types'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { useOfferForm } from '../context'
import { OfferFormMode } from '../mode'
import { BuyerCreateData } from './create-buyer'
import { AddBuyer_ListingFragment } from './__generated__/add-buyer.generated'

type BuyerData = {
  email: string
  phone: string
  firstName: string
}

export function AddBuyer({
  listing,
  onContinue,
  buyer,
  setBuyer,
  error,
}: {
  listing: AddBuyer_ListingFragment
  onContinue: () => void
  error?: string
  buyer?: BuyerData
  setBuyer: (buyer: BuyerCreateData) => void
}) {
  const match = useRouteMatch()
  const history = useHistory()
  const analytics = useAnalytics()
  const { state, update, mode } = useOfferForm()

  useEffect(() => {
    if (mode === OfferFormMode.PREPARE_OFFER) {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewPtbAddBuyer>({
        type: BuyerEvent.VIEW_PTB_ADD_BUYER,
        ...analytics.getEventMetadata(),
      })
    } else {
      analytics.logPixelEvent<Pixel.BuyerEvent.ViewOfferAddBuyer>({
        type: BuyerEvent.VIEW_OFFER_ADD_BUYER,
        ...analytics.getEventMetadata(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik({
    initialValues: {
      canSignNow: true,
      addTitleHoldersLater: state.addTitleHoldersLater,
      buyer: buyer || {
        email: '',
        phone: '',
        firstName: '',
      },
    },
    initialStatus: error ? { error } : undefined,
    validationSchema,
    onSubmit: (values, helpers) => {
      if (!values.canSignNow) {
        update((v) => ({
          ...v,
          addTitleHoldersLater: values.addTitleHoldersLater,
        }))
        onContinue()
      }

      try {
        const newBuyer = {
          ...values.buyer,
          email: values.buyer.email.trim().toLowerCase(),
          phone: values.buyer.phone.replace(/\s/g, ''),
        }
        if (
          state.signatories.some(
            (item) => item.email.toLowerCase() === newBuyer.email.toLowerCase()
          )
        ) {
          throw new Error(
            'Another buyer on this offer is already using the provided email.'
          )
        }
        if (state.signatories.some((item) => item.phone === newBuyer.phone)) {
          throw new Error(
            'Another buyer on this offer is already using the provided phone number.'
          )
        }
        setBuyer(newBuyer)
        history.push(match.url, {
          status: 'validate-buyer-phone',
          buyer: newBuyer,
        })
      } catch (err) {
        helpers.setStatus({ error: err.message })
      }
    },
  })

  return (
    <form onSubmit={form.handleSubmit}>
      <Title>Add new buyer</Title>
      <Article>
        <ArticleTitle>
          {listing.acceptedOfferType === OfferType.binding
            ? 'Can this person sign now?'
            : 'Is this person with you now?'}
        </ArticleTitle>
        <List>
          <ListItem as="label">
            <Radio
              name="canSignNow"
              value="true"
              checked={form.values.canSignNow}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue('canSignNow', true)
                }
              }}
              label={
                listing.acceptedOfferType === OfferType.binding
                  ? 'Yes, this person can sign with me now'
                  : 'Yes, this person is with me now'
              }
            />
          </ListItem>
          <ListItem as="label">
            <Radio
              name="canSignNow"
              value="false"
              checked={!form.values.canSignNow}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue('canSignNow', false)
                }
              }}
              label={
                listing.acceptedOfferType === OfferType.binding
                  ? "No, this person can't sign now"
                  : "No, this person isn't with me now"
              }
            />
          </ListItem>
        </List>
      </Article>
      <Article>
        {form.values.canSignNow ? (
          <Fragment>
            <ArticleTitle>New buyer</ArticleTitle>
            <fieldset>
              <Label label="First name">
                <Input
                  type="text"
                  placeholder="..."
                  {...form.getFieldProps(`buyer.firstName`)}
                  errors={getErrors(form, `buyer.firstName`)}
                  autoComplete="given-name"
                />
              </Label>
              <Label label="Mobile number">
                <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                  component={Input}
                  type="text"
                  placeholder="..."
                  defaultCountry="AU"
                  {...form.getFieldProps(`buyer.phone`)}
                  onChange={(value) => form.setFieldValue(`buyer.phone`, value)}
                  errors={getErrors(form, `buyer.phone`)}
                  autoComplete="tel"
                  inputMode="tel"
                />
              </Label>
              <Label label="Email address">
                <Input
                  type="email"
                  placeholder="..."
                  {...form.getFieldProps(`buyer.email`)}
                  errors={getErrors(form, `buyer.email`)}
                  autoComplete="email"
                />
              </Label>
            </fieldset>
          </Fragment>
        ) : (
          <Fragment>
            <ArticleTitle marginBottom>Add buyers later</ArticleTitle>
            <ActionCard
              icon={{ svg: IconLargeUsers }}
              title="And/or nominee"
              end={
                <Checkbox
                  {...form.getFieldProps({
                    type: 'checkbox',
                    name: 'addTitleHoldersLater',
                  })}
                />
              }
              onClick={() => {
                form.setFieldValue(
                  'addTitleHoldersLater',
                  !form.values.addTitleHoldersLater
                )
              }}
              chevron={false}
            >
              This allows you to nominate buyers later.
            </ActionCard>
            {form.touched.addTitleHoldersLater &&
              form.errors.addTitleHoldersLater && (
                <CommonError>{form.errors.addTitleHoldersLater}</CommonError>
              )}
          </Fragment>
        )}
        {form.status?.error ? (
          <CommonError>{form.status.error}</CommonError>
        ) : null}
        {form.values.canSignNow && (
          <p className="grey">
            {listing.acceptedOfferType === 'binding' ? (
              <span>
                We’ll send an SMS to verify this buyer’s phone. This person will
                need to complete an identity verification and sign the offer.
                You can also choose to add them later.
              </span>
            ) : (
              <span>
                We’ll send an SMS to verify this buyer’s phone. This person will
                need to complete an identity verification. You can also choose
                to add them later.
              </span>
            )}
          </p>
        )}
      </Article>
      <PrimaryButton
        label={form.values.canSignNow ? 'Add buyer' : 'Continue'}
        onClick={form.submitForm}
        pending={form.isSubmitting}
      />
    </form>
  )
}

const validationSchema = Yup.object({
  canSignNow: Yup.boolean(),
  buyer: Yup.mixed().when(
    'canSignNow',
    (value: boolean, schema: Yup.AnySchema) =>
      value
        ? Yup.object({
            firstName: Yup.string().required().label('First name'),
            email: Yup.string().trim().email().required().label('Email'),
            phone: Yup.string().required().label('Phone number'),
          })
        : schema
  ),
  addTitleHoldersLater: Yup.boolean().when(
    'canSignNow',
    (value: boolean, schema: Yup.BooleanSchema) =>
      !value
        ? schema.oneOf(
            [true],
            'Add the and/or nominee clause to allow you to nominate additional buyers later.'
          )
        : schema
  ),
})

gql`
  fragment AddBuyer_Listing on Listing {
    id
    source {
      appId
      foreignId
    }
    acceptedOfferType
  }
`
