/** @jsx jsx */
import { jsx } from '@emotion/react'
import { getErrors, useAddressFinderWidget } from '@propps-au/client'
import {
  buttonReset,
  IconSearch,
  Input,
  Label,
  link,
  Select,
  Textarea,
} from '@propps-au/ui'
import { FormikContextType, FormikValues, useFormik } from 'formik'
import { equals, omit } from 'ramda'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { IsCIContext } from '../is-ci'

export type AddressInputValues = {
  line1: string
  city: string
  state: string
  postcode: string
}

export type AddressInputForm = FormikContextType<
  AddressInputValues & FormikValues
>

export function AddressInput({
  address,
  setAddress,
  disableAddressFinder = false,
}: {
  address: AddressInputValues
  setAddress: (address: AddressInputValues) => void
  disableAddressFinder?: boolean
}) {
  const [useAddressFinder, setUseAddressFinder] = useState(
    !disableAddressFinder && !address?.line1
  )
  const isCI = useContext(IsCIContext)
  const addressInput = useRef<HTMLInputElement>()

  const form = useFormik({
    initialValues: {
      fullAddress: '',
      line1: address.line1 || '',
      city: address.city || '',
      state: address.state || '',
      postcode: address.postcode || '',
    },
    validationSchema: AddressInputValidationSchema,
    enableReinitialize: false,
    onSubmit: (values) => {},
  })

  useAddressFinderWidget(
    addressInput,
    {
      onAddressSelect: (fullAddress, metaData) => {
        form.setValues({
          ...form.values,
          fullAddress: fullAddress,
          line1: [metaData.address_line_1, metaData.address_line_2]
            .filter(Boolean)
            .join(', '),
          city: metaData.locality_name,
          state: metaData.state_territory,
          postcode: metaData.postcode,
        })
        form.setTouched({
          fullAddress: true,
          line1: true,
          city: true,
          state: true,
          postcode: true,
        })
        setUseAddressFinder(false)
      },
    },
    !isCI && useAddressFinder
  )

  useEffect(() => {
    if (!equals(address, omit(['fullAddress'], form.values))) {
      setAddress({
        line1: form.values.line1,
        city: form.values.city,
        state: form.values.state,
        postcode: form.values.postcode,
      })
    }
  }, [address, form.values, setAddress])

  return (
    <Fragment>
      <fieldset>
        {useAddressFinder ? (
          <Label label="Address">
            <Input
              type="text"
              placeholder="..."
              autoComplete="off"
              required
              icon={{ svg: IconSearch }}
              {...form.getFieldProps('fullAddress')}
              errors={
                (form.touched.line1 && form.errors.line1) ||
                (form.touched.city && form.errors.city) ||
                (form.touched.state && form.errors.state) ||
                (form.touched.postcode && form.errors.postcode)
                  ? ['Please type and select an address or enter one manually']
                  : undefined
              }
              ref={addressInput as React.RefObject<HTMLInputElement>}
            />
          </Label>
        ) : (
          <Fragment>
            <Label label="Address">
              <Textarea
                placeholder="..."
                required
                {...form.getFieldProps('line1')}
                errors={getErrors(form, 'line1')}
              />
            </Label>
            <Label label="City">
              <Input
                type="text"
                placeholder="..."
                required
                {...form.getFieldProps('city')}
                errors={getErrors(form, 'city')}
              />
            </Label>
            <Label label="State">
              <Select
                required
                {...form.getFieldProps(`state`)}
                errors={getErrors(form, `state`)}
              >
                <optgroup label="State">
                  <option disabled value="">
                    ...
                  </option>
                  <option value="NSW">New South Wales</option>
                  <option value="VIC">Victoria</option>
                  <option value="QLD">Queensland</option>
                  <option value="WA">Western Australia</option>
                  <option value="SA">South Australia</option>
                  <option value="TAS">Tasmania</option>
                  <option value="ACT">Australian Capital Territory</option>
                  <option value="NT">Northern Territory</option>
                </optgroup>
              </Select>
            </Label>
            <Label label="Postcode">
              <Input
                type="text"
                placeholder="..."
                required
                {...form.getFieldProps('postcode')}
                errors={getErrors(form, 'postcode')}
              />
            </Label>
          </Fragment>
        )}
      </fieldset>
      {useAddressFinder ? (
        <p className="grey">
          Can't find the property? You can{' '}
          <button
            type="button"
            onClick={(e) => [
              e.preventDefault(),
              form.setFieldValue('fullAddress', ''),
              setUseAddressFinder(false),
            ]}
            css={[buttonReset, link]}
          >
            enter the address manually
          </button>
          .
        </p>
      ) : (
        <p className="grey">
          You can also{' '}
          <button
            type="button"
            onClick={(e) => [
              e.preventDefault(),
              form.setFieldValue('line1', ''),
              form.setFieldValue('city', ''),
              form.setFieldValue('state', ''),
              form.setFieldValue('postcode', ''),
              setUseAddressFinder(true),
            ]}
            css={[buttonReset, link]}
          >
            search for the address
          </button>
          .
        </p>
      )}
    </Fragment>
  )
}

export const AddressInputValidationSchema = Yup.object({
  line1: Yup.string().required('Please provide an address.'),
  city: Yup.string().required('Please provide a city.'),
  state: Yup.string().required('Please provide a state.'),
  postcode: Yup.string().required('Please provide a postcode.'),
})
