/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useStableCallback } from '@propps-au/client'
import { Button, pxToRem } from '@propps-au/ui'
import { fabric } from 'fabric'
import React, { Fragment, useEffect, useRef, useState } from 'react'

type SignatureCanvasProps = {
  onChange?: (data: string) => void
  value?: string // data uri
} & Omit<React.PropsWithoutRef<JSX.IntrinsicElements['canvas']>, 'onChange'>

export function SignatureCanvas({
  onChange,
  style,
  value,
  ...rest
}: SignatureCanvasProps) {
  const canvasElement = useRef<HTMLCanvasElement>(null!)
  const containerElement = useRef<HTMLDivElement>(null!)
  const canvas = useRef<fabric.Canvas>()
  const [activated, setActivated] = useState(false)
  const size = useRef<{ width: number; height: number }>({
    width: -1,
    height: -1,
  })

  const handleChange = useStableCallback(
    (value: string) => {
      onChange && onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    if (activated) {
      canvasElement.current.width = size.current.width
      canvasElement.current.height = size.current.height
      canvas.current = new fabric.Canvas(canvasElement.current!, {
        isDrawingMode: true,
      })
      canvas.current.freeDrawingBrush.width = 2

      let raf: number
      const handler = function (e: fabric.IEvent) {
        // For some reason calling toDataUrl from within the event handler
        // will erase the new path from the canvas, so we defer it
        cancelAnimationFrame(raf)
        raf = requestAnimationFrame(() => {
          handleChange(canvas.current!.toDataURL({ format: 'png' }))
        })
      }
      canvas.current!.on('path:created', handler)
      return () => {
        cancelAnimationFrame(raf)
        canvas.current!.off('path:created', handler)
        canvas.current!.dispose()
      }
    }
  }, [activated, handleChange])

  const start = () => {
    size.current = {
      width: containerElement.current.clientWidth,
      height: containerElement.current.clientHeight,
    }
    setActivated(true)
  }

  const clear = () => {
    canvas.current?.clear()
    handleChange('')
  }

  return (
    <div
      css={css`
        width: 100%;
        height: ${style?.height ?? '100%'};
        position: relative;
      `}
      ref={containerElement}
    >
      <hr
        css={css`
          display: block;
          position: absolute;
          bottom: ${pxToRem(64)};
          width: 100%;
          border: none;
          border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          pointer-events: none;
          width: 100%;
          height: calc(100% - ${pxToRem(64)});
          z-index: 0;
        `}
      />
      {!activated && (
        <div
          css={css`
            width: 100%;
            height: 100%;
            padding-bottom: ${pxToRem(64)};
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ccc;
            background-image: ${value ? css`url(${value})` : undefined};
          `}
          onClick={start}
        >
          {!value ? (
            <Button
              size="xs"
              pill
              css={css`
                position: absolute;
                margin-top: ${pxToRem(-24)};
                z-index: 1;
              `}
              onClick={clear}
            >
              Tap to sign
            </Button>
          ) : (
            <Button
              size="xs"
              pill
              css={css`
                position: absolute;
                margin-top: ${pxToRem(12)};
                right: 0;
                top: 0;
                z-index: 1;
              `}
              onClick={clear}
            >
              Clear
            </Button>
          )}
        </div>
      )}
      {activated && (
        <Fragment>
          <Button
            size="xs"
            pill
            css={css`
              position: absolute;
              margin-top: ${pxToRem(12)};
              right: 0;
              z-index: 1;
              display: ${value ? 'block' : 'none'};
            `}
            onClick={clear}
          >
            Clear
          </Button>
          <canvas {...rest} style={style} ref={canvasElement} />
        </Fragment>
      )}
    </div>
  )
}

export default SignatureCanvas
