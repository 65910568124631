import { useQuery } from '@apollo/client'
import {
  AuthStateResolved,
  ErrorReporting,
  formatPhoneNumber,
  FormattedPhoneNumberInput,
  getErrors,
  PRIVACY_POLICY_LINE,
  useAuth,
} from '@propps-au/client'
import type { Pixel } from '@propps-au/pixel-analytics-types'
import { BuyerEvent } from '@propps-au/pixel-analytics-types/event-types'
import { CommonError, Input, Label, Title } from '@propps-au/ui'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import isMobile from 'is-mobile'
import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { NewBuyerDetails } from '..'
import { useAnalytics } from '../../analytics'
import { PrimaryButton } from '../../primary-button'
import { useLiteOffer } from '../../use-lite-offer'
import { GetPrivacyPolicyQueryDocument } from './__generated__/buyer-registration.generated'

const isMobileDevice = isMobile()

export function BuyerRegistration({
  phone,
  email,
  onComplete,
}: {
  phone?: string
  email?: string
  onComplete: (buyer: NewBuyerDetails) => Promise<void>
}) {
  const analytics = useAnalytics()
  const auth = useAuth()
  const [error, setError] = useState('')
  const { data } = useQuery(GetPrivacyPolicyQueryDocument, {
    variables: { privacyPolicyLineName: PRIVACY_POLICY_LINE },
  })
  const { contact } = useLiteOffer({ skip: false })

  const privacyPolicyUrl = data?.privacyPolicy
    ? '/terms/' +
      data.privacyPolicy.name +
      '/' +
      data.privacyPolicy.currentRevision.id
    : '#'

  const constructValidationSchema = () => {
    const baseSchema = {
      firstName: Yup.string().required('Please enter your first name'),
      lastName: Yup.string().required('Please enter you last name'),
    }
    let extendedSchema = {}
    if (phone) {
      extendedSchema = {
        email: Yup.string()
          .trim()
          .email()
          .required('Please enter your email address'),
      }
    }
    if (email) {
      extendedSchema = {
        phone: Yup.string().trim().required('Please enter your phone number'),
      }
    }
    return Yup.object({
      ...baseSchema,
      ...extendedSchema,
    })
  }

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: phone || '',
      email: email || '',
    },
    validationSchema: constructValidationSchema(),
    onSubmit: async (values, helpers) => {
      const { uid } = auth.state as AuthStateResolved

      if (!uid) {
        throw new Error(
          'Invariant violation. User must be logged in to submit this step.'
        )
      }
      try {
        await onComplete({
          ...values,
          phone: formatPhoneNumber(values.phone, {
            defaultCountry: 'AU',
          }),
          email: values.email.trim(),
        })
      } catch (err) {
        ErrorReporting.report(err)

        setError(
          'We were unable to create your account. Please contact help@propps.com for support.'
        )
      }
    },
  })

  useEffect(() => {
    if (contact) {
      contact.firstName && form.setFieldValue('firstName', contact.firstName)
      contact.lastName && form.setFieldValue('lastName', contact.lastName)
      !phone && contact.phone && form.setFieldValue('phone', contact.phone)
      !email && contact.email && form.setFieldValue('email', contact.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  useEffect(() => {
    analytics.logPixelEvent<Pixel.BuyerEvent.ViewLoginRegister>({
      type: BuyerEvent.VIEW_LOGIN_REGISTER,
      ...analytics.getEventMetadata(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Title sub="It's nice to meet you">Welcome</Title>
      <form onSubmit={form.handleSubmit}>
        <fieldset>
          <Label label="First name">
            <Input
              autoFocus={!isMobileDevice}
              type="text"
              placeholder="..."
              {...form.getFieldProps(`firstName`)}
              errors={getErrors(form, `firstName`)}
              autoComplete="given-name"
            />
          </Label>
          <Label label="Last name">
            <Input
              type="text"
              placeholder="..."
              {...form.getFieldProps(`lastName`)}
              errors={getErrors(form, 'lastName')}
              autoComplete="family-name"
            />
          </Label>
          <Label label="Email address">
            <Input
              type="email"
              placeholder="..."
              disabled={!!email}
              {...form.getFieldProps(`email`)}
              errors={getErrors(form, `email`)}
              autoComplete="email"
            />
          </Label>
          <Label label="Mobile number">
            <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
              component={Input}
              defaultCountry="AU"
              type="text"
              placeholder="..."
              {...form.getFieldProps(`phone`)}
              onChange={(value) => form.setFieldValue(`phone`, value)}
              errors={getErrors(form, `phone`)}
              autoComplete="tel"
              inputMode="tel"
              readOnly={!!phone}
              disabled={!!phone}
            />
          </Label>
        </fieldset>
        <p className="grey">
          We'll make an account for you that lets you save and retrieve your
          offer and access other neat features. Your information is treated with
          respect and subject to our{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={privacyPolicyUrl} target="_blank">
            Privacy Policy
          </a>
          .
        </p>
        {error && <CommonError>{error}</CommonError>}
        <PrimaryButton
          label="Continue"
          pending={form.isSubmitting}
          onClick={form.submitForm}
        />
      </form>
    </Fragment>
  )
}

export const GRAPHQL = gql`
  query GetPrivacyPolicyQuery($privacyPolicyLineName: String!) {
    privacyPolicy: termsLine(name: $privacyPolicyLineName) {
      name
      currentRevision {
        id
      }
    }
  }
`
