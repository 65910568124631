/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  Button,
  color,
  Icon,
  IconCircleMinus,
  IconCircleTick,
  List,
  ListItem,
  pxToRem,
} from '@propps-au/ui'

export function ConditionStatus({
  included,
  onRemove,
  onAdd,
}: {
  included: boolean
  onRemove: () => void
  onAdd: () => void
}) {
  return (
    <List>
      <ListItem
        start={
          included ? (
            <Icon svg={IconCircleTick} size={pxToRem(32)} aria-label="tick" />
          ) : (
            <Icon
              svg={IconCircleMinus}
              fill={color.black10}
              size={pxToRem(32)}
              aria-label="minus"
            />
          )
        }
        end={
          included ? (
            <Button size="sm" variant="danger" pill onClick={onRemove}>
              Remove
            </Button>
          ) : (
            <Button size="sm" variant="success" pill onClick={onAdd}>
              Add
            </Button>
          )
        }
        css={css`
          & > div {
            align-items: center;
          }
        `}
      >
        {included ? 'Condition included' : 'Condition not included'}
      </ListItem>
    </List>
  )
}
