import { z } from 'zod'

export type IPixelEvent<T> = {
  /** Event type */
  type: string
  /** Created at in client time */
  cat: Date
  /** User */
  user: User | null
  /** Session id */
  sid: string | null
} & T

export type User = {
  uid: string | null
  role: Role | null
}

export type Role = {
  type: string
  id: string | null
}

export const PixelEventBaseSchema = z.object({
  type: z.string(),
  cat: z.string(),
  user: z
    .object({
      uid: z.string().nullable(),
      role: z
        .object({
          type: z.string(),
          id: z.string().nullable(),
        })
        .nullable(),
    })
    .nullable(),
  sid: z.string().nullable(),
})
