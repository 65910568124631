import { GlobalStyle, StackApp, StackThemeProvider } from '@propps-au/ui'
import React, { Suspense } from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'
import { AnalyticsUserReporter } from './components/analytics-user-reporter'
import { Loading } from './components/loading'
import {
  PrimaryButtonHost,
  PrimaryButtonProvider,
  usePrimaryButton,
} from './components/primary-button'
import { BuyerDevtools } from './pages/devtools/buyer-devtools'
import { ListingNotAvailable, PageNotFound } from './pages/error'
import {
  ListingRouteLoaderFromId,
  ListingRouteLoaderFromSource,
} from './pages/listing'
import ReferrerRoute from './pages/referrer/landing'
import { TermsRoute } from './pages/terms'

export interface ListingUrlRouteParams {
  appId: string
  foreignListingId: string
}

function App() {
  const button = usePrimaryButton()

  return (
    <StackThemeProvider>
      <StackApp variant="frames">
        <GlobalStyle />
        <PrimaryButtonProvider value={button.ctx}>
          <Suspense fallback={<Loading />}>
            <AnalyticsUserReporter>
              <Switch>
                <Route path="/" exact>
                  <Loading />
                </Route>
                <Route
                  path={['/terms/:lineName', '/terms/:lineName/:revisionId']}
                  exact
                >
                  {({ match }) => <TermsRoute match={match!} />}
                </Route>
                <Route path={['/listing/unavailable']} exact>
                  {() => <ListingNotAvailable />}
                </Route>
                <Route path={['/pagenotfound']} exact>
                  {() => <PageNotFound />}
                </Route>
                <Route path="/referrer" exact>
                  {() => <ReferrerRoute />}
                </Route>
                <Route path="/buyer-devtools" exact>
                  {() => <BuyerDevtools />}
                </Route>
                <Route path="/listing/:id">
                  {({ match }: { match: Match<{ id: string }> | null }) =>
                    match && (
                      <ListingRouteLoaderFromId
                        match={match}
                        listingId={match.params.id}
                      />
                    )
                  }
                </Route>
                <Route path="/:appId">
                  {({ match }: { match: Match<{ appId: string }> | null }) =>
                    match && (
                      <Route path={`${match.path}/listings/:foreignListingId`}>
                        {({
                          match: match2,
                        }: {
                          match: Match<{
                            foreignListingId: string
                          }> | null
                        }) =>
                          match2 && (
                            <ListingRouteLoaderFromSource
                              match={match2}
                              appId={match.params.appId}
                              foreignListingId={match2.params.foreignListingId}
                            />
                          )
                        }
                      </Route>
                    )
                  }
                </Route>
              </Switch>
            </AnalyticsUserReporter>
          </Suspense>
        </PrimaryButtonProvider>
      </StackApp>
      <PrimaryButtonHost context={button} />
    </StackThemeProvider>
  )
}

export default App
