import { gql } from '@apollo/client'
import {
  OfferForm_BuyerFragment,
  OfferForm_CurrentOfferFragment,
  OfferForm_CurrentOfferQueryDocument,
  OfferForm_DraftOfferFragment,
  OfferForm_DraftOfferQueryDocument,
  OfferForm_ListingFragment,
  OfferForm_Query,
  OfferForm_QueryDocument,
} from './__generated__/query.generated'

export const GRAPHQL = gql`
  query OfferForm_Query($listingId: ID!) {
    listing(id: $listingId) {
      id
      ...OfferForm_Listing
    }
    me {
      uid
      buyer {
        id
        ...OfferForm_Buyer
      }
    }
  }

  fragment OfferForm_Listing on Listing {
    source {
      appId
      foreignId
    }
    type
    ...OfferFormIndex_Listing
    ...PropertyAgentDetails_Listing
    ...Amount_Listing
    ...Settlement_Listing
    ...ConditionsRoot_Listing
    ...Conveyancer_Listing
    ...Documents_Listing
    ...AddBuyer_Listing
    ...Signature_Listing
    ...VendorLead_Listing
  }

  fragment OfferForm_Buyer on Buyer {
    id
    ...OfferForm_Buyers_Buyer
  }

  query OfferForm_DraftOfferQuery($listingId: ID!, $buyerId: ID!) {
    draftOffer(listingId: $listingId, buyerId: $buyerId) {
      id
      ...OfferForm_DraftOffer
    }
  }

  fragment OfferForm_DraftOffer on DraftOffer {
    property {
      address {
        line1
        city
        state
        postcode
      }
    }
    agents {
      firstName
      lastName
      phone
      email
    }
    amount
    settlement {
      ... on OfferSettlementPeriodDefault {
        _
      }
      ... on OfferSettlementPeriodDays {
        days
      }
      ... on OfferSettlementPeriodDate {
        date {
          formatted
        }
      }
    }
    conditions {
      ... on OfferCondition {
        revision {
          title
          line: lineName
          region
          id
        }
        params {
          name
          value
        }
      }
      ... on CustomOfferCondition {
        content
      }
    }
    signatories {
      buyer {
        id
      }
      firstName
      middleName
      lastName
      phone
      email
      address
      dateOfBirth {
        formatted
      }
      onBehalfOf {
        ... on RepresentedEntityCompany {
          name
        }
        ... on RepresentedEntityPerson {
          name
        }
        ... on RepresentedEntitySelf {
          __typename
        }
      }
      identityDocument {
        type
        country
        region
        documentNo
      }
    }
    agreements {
      ... on OfferAgreementNominal {
        type
      }
      ... on OfferAgreementPlatformTerms {
        termsRevision {
          id
          line {
            name
          }
        }
      }
    }
    expiresAt
    addTitleHoldersLater
    conveyancer {
      ... on OfferConveyancerInformationLegalProcessHelp {
        __typename
      }
      ... on OfferConveyancerInformationFindConveyancerHelp {
        __typename
      }
      ... on OfferConveyancerInformationProvided {
        phone
        name
      }
      ... on OfferConveyancerInformationNone {
        __typename
      }
    }
    finance {
      ... on OfferFinanceDeclarationNone {
        note
      }
      ... on OfferFinanceDeclarationApproved {
        institution
        date {
          formatted
        }
      }
      ... on OfferFinanceDeclarationNotApplicable {
        note
      }
      ... on OfferFinanceDeclarationOther {
        note
      }
    }
    attributedAgent {
      id
    }
    disposition
  }

  query OfferForm_CurrentOfferQuery($buyerId: ID!, $listingId: ID!) {
    offer: getCurrentOfferByListingAndBuyer(
      listingId: $listingId
      buyerId: $buyerId
    ) {
      id
      ...OfferForm_CurrentOffer
    }
  }

  fragment OfferForm_CurrentOffer on Offer {
    isBinding
    attributedAgent {
      id
    }
    property {
      address {
        line1
        city
        state
        postcode
      }
    }
    agents {
      firstName
      lastName
      phone
      email
    }
    amount
    depositPercentage
    depositAmount
    settlement {
      ... on OfferSettlementPeriodDefault {
        _
      }
      ... on OfferSettlementPeriodDays {
        days
      }
      ... on OfferSettlementPeriodDate {
        date {
          formatted
        }
      }
    }
    conditions {
      ... on OfferCondition {
        revision {
          title
          line: lineName
          region
          id
        }
        params {
          name
          value
        }
      }
      ... on CustomOfferCondition {
        content
      }
    }
    signatories {
      buyer {
        id
      }
      firstName
      middleName
      lastName
      phone
      email
      address
      dateOfBirth {
        formatted
      }
      onBehalfOf {
        ... on RepresentedEntityCompany {
          name
        }
        ... on RepresentedEntityPerson {
          name
        }
        ... on RepresentedEntitySelf {
          __typename
        }
      }
      identityDocument {
        type
        country
        region
        documentNo
      }
    }
    agreements {
      ... on OfferAgreementNominal {
        type
      }
      ... on OfferAgreementPlatformTerms {
        termsRevision {
          id
          line {
            name
          }
        }
      }
    }
    expiresAt
    addTitleHoldersLater
    conveyancer {
      ... on OfferConveyancerInformationNone {
        __typename
      }
      ... on OfferConveyancerInformationProvided {
        phone
        name
      }
    }
    finance {
      ... on OfferFinanceDeclarationNone {
        note
      }
      ... on OfferFinanceDeclarationApproved {
        institution
        date {
          formatted
        }
      }
      ... on OfferFinanceDeclarationNotApplicable {
        note
      }
      ... on OfferFinanceDeclarationOther {
        note
      }
    }
  }
`

export {
  OfferForm_QueryDocument,
  OfferForm_DraftOfferQueryDocument,
  OfferForm_CurrentOfferQueryDocument,
}
export type {
  OfferForm_Query,
  OfferForm_ListingFragment,
  OfferForm_BuyerFragment,
  OfferForm_DraftOfferFragment,
  OfferForm_CurrentOfferFragment,
}
