/** @jsx jsx */
import { jsx } from '@emotion/react'
import { AnimatePresence } from 'framer-motion'
import gql from 'graphql-tag'
import { Fragment } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { PageTransition } from '../../page-transition'
import { Condition } from './condition'
import { Conditions } from './conditions'
import { CustomCondition } from './custom'
import { Expiry } from './expiry'
import { ConditionsRoot_ListingFragment } from './__generated__/index.generated'

export function ConditionsRoot({
  listing,
  onContinue,
  label = 'Done',
  animate = false,
}: {
  listing: ConditionsRoot_ListingFragment
  onContinue: () => void
  label?: string
  animate?: boolean
}) {
  const { conditions } = listing
  const history = useHistory()
  const match = useRouteMatch()

  const goToIndex = () => history.push(match.url)
  const goToCondition = (line: string) => {
    history.push(match.url + '/' + line)
  }
  const goToCustomCondition = () => {
    history.push(match.url + '/custom')
  }
  const goToExpiry = () => history.push(match.url + '/expiry')

  return (
    <AnimatePresence exitBeforeEnter>
      <PageTransition key={history.location.pathname} disabled={!animate}>
        <Switch location={animate ? history.location : undefined}>
          <Route path={match.url} exact>
            <Conditions
              listing={listing}
              onContinue={onContinue}
              goToCondition={goToCondition}
              goToExpiry={goToExpiry}
              goToCustomCondition={goToCustomCondition}
              label={label}
            />
          </Route>

          <Route path={`${match.url}/custom`}>
            <CustomCondition onContinue={goToIndex} />
          </Route>

          <Route path={`${match.url}/expiry`}>
            <Expiry onContinue={goToIndex} />
          </Route>

          <Route path={`${match.url}/:line`}>
            {({ match }) => (
              <Fragment>
                <Condition
                  line={
                    conditions.find((line) => line.name === match!.params.line)!
                  }
                  onContinue={goToIndex}
                />
              </Fragment>
            )}
          </Route>
        </Switch>
      </PageTransition>
    </AnimatePresence>
  )
}

export const GRAPHQL = gql`
  fragment ConditionsRoot_Listing on Listing {
    ...Conditions_Listing
    conditions {
      ...Condition_OfferConditionsLine
    }
  }
`
