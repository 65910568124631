import React, { createContext, useContext } from 'react'
import { Analytics } from '../../analytics'

const AnalyticsContext = createContext<Analytics | null>(null)

export function AnalyticsProvider({
  analytics,
  children,
}: {
  analytics: Analytics
  children?: React.ReactNode
}) {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export function useAnalytics() {
  const ctx = useContext(AnalyticsContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!ctx) {
      throw new Error(
        'useAnalytics must only be used from within an <AnalyticsProvider>'
      )
    }
  }

  return ctx!
}
