import { useCallback, useReducer } from 'react'
import {
  ApiError,
  AuthService,
  RESTClient,
  StartVerificationRequest,
} from '../api/rest'
import { ErrorReporting } from '../error-reporting'

type OnCompleteCallback = (token: string) => Promise<void>

type VerificationChannel = 'sms' | 'email'

type Status =
  | 'awaiting-sendto-submission'
  | 'awaiting-verification-code-submission'

type State = {
  status: Status
  channel: VerificationChannel | null
  sendTo: string | null
  verificationId: string | null
  verificationCode: string | null
}

type Action =
  | {
      type: 'send-to-submitted'
      channel: VerificationChannel
      sendTo: string
      verificationId: string
    }
  | {
      type: 'verification-code-submitted'
      verificationCode: string
    }
  | {
      type: 'reset'
    }

const INITIAL_STATE: State = {
  status: 'awaiting-sendto-submission',
  channel: null,
  sendTo: null,
  verificationId: null,
  verificationCode: null,
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'send-to-submitted':
      return {
        ...state,
        status: 'awaiting-verification-code-submission',
        channel: action.channel,
        sendTo: action.sendTo,
        verificationId: action.verificationId,
      }
    case 'verification-code-submitted':
      return {
        ...state,
        status: 'awaiting-sendto-submission',
        verificationCode: action.verificationCode,
      }
    case 'reset':
      return INITIAL_STATE
    default:
      return state
  }
}

export function useAuthVerification({
  onComplete,
  client,
}: {
  onComplete?: OnCompleteCallback
  client: RESTClient
}) {
  const [state, action] = useReducer(reducer, INITIAL_STATE)

  const start = useCallback(
    async (
      channel: 'sms' | 'email',
      sendTo: string,
      isSignedIn: boolean = false
    ) => {
      try {
        const result = await client.call(AuthService.startVerification, {
          body: {
            channel: channel as StartVerificationRequest.channel,
            sendTo: sendTo.trim(),
            isSignedIn,
          },
        })
        action({
          type: 'send-to-submitted',
          channel,
          sendTo,
          verificationId: result.verificationId,
        })
        return result
      } catch (err) {
        if (err instanceof ApiError) {
          if (err.body.error?.code) {
            throw new VerificationError(
              err.body.error.code,
              err.body.error.message || ''
            )
          }
        }
        ErrorReporting.report(err)
        throw err
      }
    },
    [client]
  )

  const complete = useCallback(
    async (verificationCode: string) => {
      try {
        const result = await client.call(AuthService.checkVerification, {
          verificationId: state.verificationId!,
          body: { code: verificationCode.trim() },
        })
        action({
          type: 'verification-code-submitted',
          verificationCode: verificationCode,
        })
        await onComplete?.(result.token)
        return result
      } catch (err) {
        if (err instanceof ApiError) {
          if (err.body.error?.code) {
            throw new VerificationError(
              err.body.error.code,
              err.body.error.message || ''
            )
          }
        }
        ErrorReporting.report(err)
        throw err
      }
    },
    [client, onComplete, state.verificationId]
  )

  const resend = useCallback(async () => {
    if (!state.channel || !state.sendTo) {
      throw new Error('No verification to resend.')
    }
    return await start(state.channel, state.sendTo)
  }, [start, state.channel, state.sendTo])

  const reset = useCallback(() => {
    action({ type: 'reset' })
  }, [])

  return { start, complete, resend, reset, state }
}

export enum VerificationErrorCode {
  PHONE_NUMBER_INCORRECT = 'auth/phone-number-incorrect',
  PHONE_NUMBER_TAKEN = 'auth/phone-number-taken',
  EMAIL_ADDRESS_TAKEN = 'auth/email-address-taken',
  VERIFICATION_EXPIRED = 'auth/verification-expired',
  VERIFICATION_CODE_INVALID = 'auth/invalid-code',
  VERIFICATION_CODE_INCORRECT = 'auth/incorrect-code',
  TOO_MANY_ATTEMPTS = 'auth/too-many-attempts',
}
export class VerificationError extends Error {
  constructor(public readonly code: VerificationErrorCode, message: string) {
    super(message)
  }
}
