import { useMemo, useState } from 'react'
import { useEvent } from '../use-event'
import { createDeferred } from './deferred'

export type Resource<T> = {
  read: () => T
}

type ResourceStatus = 'pending' | 'resolved' | 'rejected'

/** Create a suspense resource from a Promise */
export function createResource<T>(source: Promise<T>) {
  let status: ResourceStatus = 'pending'
  let result: T | undefined = undefined
  let error: any = undefined

  const suspender = source.then(
    (value) => {
      status = 'resolved'
      result = value
    },
    (err) => {
      status = 'rejected'
      error = err
    }
  )

  const read = () => {
    switch (status) {
      case 'resolved':
        return result!
      case 'rejected':
        throw error
      case 'pending':
        throw suspender
    }
  }

  return { read }
}

export function createResolvedResource<T>(source: T) {
  return { read: () => source }
}

export function createRejectedResource(error: any) {
  return {
    read: () => {
      throw error
    },
  }
}

export type ResourceController<T> = {
  write: (value: T) => void
  reject: (err: any) => void
  invalidate: () => void
  peek: () => ResourceSnapshot<T>
}

type ResourceSnapshot<T> =
  | { status: 'pending' }
  | { status: 'resolved'; value: T }
  | { status: 'rejected'; error: any }

export function useResource<T>(
  initialValue?: T
): [Resource<T>, ResourceController<T>] {
  const [settled, setSettled] = useState(initialValue !== undefined)

  const [deferred, setDeferred] = useState(() =>
    !settled ? createDeferred<T>() : null
  )
  const [resource, setResource] = useState<Resource<T>>(() =>
    settled
      ? createResolvedResource(initialValue!)
      : createResource(deferred!.promise)
  )

  const write = useEvent((value: T) => {
    if (settled) {
      setResource(createResolvedResource(value))
    } else {
      deferred!.resolve(value)
      setSettled(true)
    }
  })

  const reject = useEvent((error: any) => {
    if (settled) {
      setResource(createRejectedResource(error))
    } else {
      deferred!.reject(error)
      setSettled(true)
    }
  })

  const invalidate = useEvent(() => {
    if (settled) {
      const deferred = createDeferred<T>()
      setDeferred(deferred)
      setResource(createResource(deferred.promise))
      setSettled(false)
    }
  })

  const peek = useEvent((): ResourceSnapshot<T> => {
    try {
      return { status: 'resolved', value: resource.read() }
    } catch (err) {
      if (err instanceof Promise) {
        return { status: 'pending' }
      }
      return { status: 'rejected', error: err }
    }
  })

  const controller = useMemo(() => {
    return {
      write,
      reject,
      invalidate,
      peek,
    }
  }, [])

  return [resource, controller]
}
