/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useStableCallback } from '@propps-au/client'
import { ButtonCTA } from '@propps-au/ui'
import isMobile from 'is-mobile'
import { nanoid } from 'nanoid'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

type PrimaryButtonConfig = {
  onClick: () => void
  pending: boolean
  label?: string
}

type PrimaryButtonContextType = {
  ref: React.MutableRefObject<PrimaryButtonConfig | null>
  update: () => void
  id: string
}

const PrimaryButtonContext = createContext<PrimaryButtonContextType | null>(
  null
)

export function usePrimaryButton() {
  const [id] = useState(() => nanoid())
  const ref = useRef<PrimaryButtonConfig | null>(null)
  const [config, setConfig] = useState<PrimaryButtonConfig | null>(null)

  const update = useStableCallback(() => {
    setConfig(ref.current)
  }, [])

  const ctx = useMemo(
    () => ({
      update,
      ref,
      id,
    }),
    [id, update]
  )

  return { ctx, state: config }
}

export function PrimaryButton({
  onClick,
  pending = false,
  label,
}: {
  onClick?: (e?: React.MouseEvent) => void
  pending?: boolean
  label?: string
}) {
  const ctx = useContext(PrimaryButtonContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!ctx) {
      throw new Error(
        '<PrimaryButton> must only be rendered as descendant of <PrimaryButtonProvider>'
      )
    }
  }

  const onClickStable = useStableCallback(onClick || (() => {}), [onClick])

  useEffect(() => {
    ctx!.ref.current = {
      onClick: onClickStable,
      pending,
      label,
    }
    ctx!.update()

    return () => {
      ctx!.ref.current = null
      ctx!.update()
    }
  }, [onClickStable, pending, label, ctx])

  return (
    <button
      type="submit"
      css={css`
        display: none;
      `}
    />
  )
}

const styles = {
  /** dock to the bottom of the page instead of the bottom of the viewport */
  docked: {
    position: 'absolute' as const,
    // bottom: 24,
    // left: '50%',
    // transform: 'translateX(-50%)',
  },
}

const isMobileDevice = isMobile()

export function PrimaryButtonHost({
  context,
}: {
  context: ReturnType<typeof usePrimaryButton>
}) {
  const ctx = useContext(PrimaryButtonContext)

  if (process.env.NODE_ENV !== 'production') {
    if (ctx && ctx.id === context.ctx.id) {
      throw new Error(
        '<PrimaryButtonHost> must be rendered outside <PrimaryButtonProvider>'
      )
    }
  }

  const [isFocused, setFocused] = useState(false)

  // when an input or textarea is focused on mobile,
  // scroll so that it is not covered by the primary button
  useEffect(() => {
    if (!isMobileDevice) return

    const onFocusIn = (e: FocusEvent) => {
      const target = e.target

      if (
        target &&
        (target instanceof HTMLInputElement ||
          target instanceof HTMLTextAreaElement)
      ) {
        setFocused(true)
      }
    }

    const onFocusOut = (e: FocusEvent) => {
      setFocused(false)
    }

    window.addEventListener('focusin', onFocusIn)
    window.addEventListener('focusout', onFocusOut)

    return () => {
      window.removeEventListener('focusin', onFocusIn)
      window.removeEventListener('focusout', onFocusOut)
    }
  }, [])

  const fixed = !(isMobileDevice && isFocused)

  return context.state ? (
    <ButtonCTA
      pending={context.state.pending}
      onClick={context.state.onClick}
      fixed
      css={fixed ? undefined : styles.docked}
    >
      {context.state.label}
    </ButtonCTA>
  ) : null
}

export const PrimaryButtonProvider = PrimaryButtonContext.Provider
