import { z } from 'zod'

enum PriceIndicationType {
  range = 'range',
  custom = 'custom',
  min = 'min',
}

export const ReferrerPayloadSchema = z.object({
  source: z.object({ appId: z.string(), foreignId: z.string() }),
  address: z.object({
    line1: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    postcode: z
      .string()
      .length(4)
      .regex(/^[0-9]*$/)
      .nullable()
      .optional(),
    state: z.string().min(2).nullable().optional(),
  }),
  agents: z
    .array(
      z.object({
        firstName: z.string().nullable().optional(),
        lastName: z.string().nullable().optional(),
        phone: z.string().nullable().optional(),
        email: z.string().nullable().optional(),
      })
    )
    .optional(),
  priceIndication: z
    .object({
      type: z.nativeEnum(PriceIndicationType),
      min: z.string().nullable().optional(),
      max: z.string().nullable().optional(),
      message: z.string().nullable(),
    })
    .optional(),
  identifiers: z
    .array(z.object({ type: z.string(), id: z.string() }))
    .nullable()
    .optional(),
  url: z.string().nullable().optional(),
})

export const ReferrerReferenceSchema = z.object({
  refId: z.string(),
  source: z.object({ appId: z.string(), foreignId: z.string() }),
})
