/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import fetch from 'isomorphic-unfetch';

import { ApiError } from './ApiError';
import type { ApiRequestOptions } from './ApiRequestOptions';
import type { ApiResult } from './ApiResult';
import { Config } from './OpenAPI';

function isDefined<T>(value: T | null | undefined): value is Exclude<T, null | undefined> {
    return value !== undefined && value !== null;
}

function isString(value: any): value is string {
    return typeof value === 'string';
}

function isStringWithValue(value: any): value is string {
    return isString(value) && value !== '';
}

function isBinary(value: any): value is Buffer | ArrayBuffer | ArrayBufferView {
    return isBuffer(value) || value instanceof ArrayBuffer || isArrayBufferView(value);
}

const ARRAY_BUFFER_VIEWS = [
    Int8Array,
    Uint8Array,
    Uint8ClampedArray,
    Int16Array,
    Uint16Array,
    Int32Array,
    Uint32Array,
    Float32Array,
    Float64Array,
    DataView,
]

function isArrayBufferView(value: any): value is ArrayBufferView {
    return ARRAY_BUFFER_VIEWS.some(t => value instanceof t)
}

function isBuffer(obj: any) {
  return obj != null && obj.constructor != null &&
    typeof obj.constructor.isBuffer === 'function' && obj.constructor.isBuffer(obj)
}

function getQueryString(params: Record<string, any>): string {
    const qs: string[] = [];
    Object.keys(params).forEach(key => {
        const value = params[key];
        if (isDefined(value)) {
            if (Array.isArray(value)) {
                value.forEach(value => {
                    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
                });
            } else {
                qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
            }
        }
    });
    if (qs.length > 0) {
        return `?${qs.join('&')}`;
    }
    return '';
}

function getUrl(options: ApiRequestOptions, config: Config): string {
    const path = options.path.replace(/[:]/g, '_');
    const url = `${config.BASE}${path}`;

    if (options.query) {
        return `${url}${getQueryString(options.query)}`;
    }
    return url;
}

function getFormData(params: Record<string, any>): URLSearchParams {
    const formData = new URLSearchParams();
    Object.keys(params).forEach(key => {
        const value = params[key];
        if (isDefined(value)) {
            formData.append(key, value);
        }
    });
    return formData;
}

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

async function resolve<T>(options: ApiRequestOptions, resolver?: T | Resolver<T>): Promise<T | undefined> {
    if (typeof resolver === 'function') {
        return (resolver as Resolver<T>)(options);
    }
    return resolver;
}

async function getHeaders(options: ApiRequestOptions, config: Config): Promise<Record<string, string>> {
    const token = await resolve(options, config.TOKEN);
    const username = await resolve(options, config.USERNAME);
    const password = await resolve(options, config.PASSWORD);
    const defaultHeaders = await resolve(options, config.HEADERS);

    const headers: Record<string, string> = {
        Accept: 'application/json',
        ...defaultHeaders,
        ...options.headers,
    };

    if (isStringWithValue(token)) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    if (isStringWithValue(username) && isStringWithValue(password)) {
        const credentials = Buffer.from(`${username}:${password}`).toString('base64');
        headers['Authorization'] = `Basic ${credentials}`;
    }

    if (options.body) {
        if (options.mediaType) {
            headers['Content-Type'] = options.mediaType;
        } else if (isBinary(options.body)) {
            headers['Content-Type'] = 'application/octet-stream';
        } else if (isString(options.body)) {
            headers['Content-Type'] = 'text/plain';
        } else {
            headers['Content-Type'] = 'application/json';
        }
    }
    return headers;
}

function getRequestBody(options: ApiRequestOptions): BodyInit | undefined {
    if (options.formData) {
        return getFormData(options.formData);
    }
    if (options.body) {
        if (options.mediaType?.includes('/json')) {
            return JSON.stringify(options.body)
        } else if (isString(options.body) || isBinary(options.body)) {
            return options.body;
        } else {
            return JSON.stringify(options.body);
        }
    }
    return undefined;
}

async function sendRequest(options: ApiRequestOptions, url: string, config: Config): Promise<Response> {
    const request: RequestInit = {
        method: options.method,
        headers: await getHeaders(options, config),
        body: getRequestBody(options),
    };
    return await fetch(url, request);
}

function getResponseHeader(response: Response, responseHeader?: string): string | null {
    if (responseHeader) {
        const content = response.headers.get(responseHeader);
        if (isString(content)) {
            return content;
        }
    }
    return null;
}

async function getResponseBody(response: Response): Promise<any> {
    try {
        const contentType = response.headers.get('Content-Type');
        if (contentType) {
            const isJSON = contentType.toLowerCase().startsWith('application/json');
            if (isJSON) {
                return await response.json();
            } else {
                return await response.text();
            }
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}

function catchErrors(options: ApiRequestOptions, result: ApiResult): void {
    const errors: Record<number, string> = {
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error',
        502: 'Bad Gateway',
        503: 'Service Unavailable',
        ...options.errors,
    }

    const error = errors[result.status];
    if (error) {
        throw new ApiError(result, error);
    }

    if (!result.ok) {
        throw new ApiError(result, 'Generic Error');
    }
}
export interface IOpenAPIClient {
    request(options: ApiRequestOptions): Promise<ApiResult>
}

export class OpenAPIClient implements IOpenAPIClient {
    constructor(private config: Config) {}

    public async request(options: ApiRequestOptions): Promise<ApiResult> {
        const url = getUrl(options, this.config);
        const response = await sendRequest(options, url, this.config);
        const responseBody = await getResponseBody(response);
        const responseHeader = getResponseHeader(response, options.responseHeader);

        const result: ApiResult = {
            url,
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            body: responseHeader || responseBody,
        };

        catchErrors(options, result);
        return result;
    }

    public call<T extends Endpoint<any, any>>(
        fn: T,
        params: EndpointParams<T>
    ): Promise<EndpointResult<T>> {
        return this.endpoint(fn)(params)
    }

    public endpoint<T extends Endpoint<any, any>>(
        fn: T
    ): (params: EndpointParams<T>) => Promise<EndpointResult<T>> {
        return (params) => fn(this, params)
    }
}

type Endpoint<Params, Result> = (client: OpenAPIClient, params: Params) => Promise<Result>
type EndpointParams<T> = T extends Endpoint<infer Params, any> ? Params : never
type EndpointResult<T> = T extends Endpoint<any, infer Result> ? Result : never