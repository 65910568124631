import { FrameTransport } from '@propps-au/frame-transport'
import React, { createContext, useContext } from 'react'

const FrameTransportContext = createContext<FrameTransport | null>(null)

export function FrameTransportProvider({
  children,
  value,
}: {
  children?: React.ReactNode
  value: FrameTransport
}) {
  return (
    <FrameTransportContext.Provider value={value}>
      {children}
    </FrameTransportContext.Provider>
  )
}

export function useFrameTransport() {
  const transport = useContext(FrameTransportContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!transport) {
      throw new Error(
        'useFrameTransport can only be used within a <FrameTransportProvider>'
      )
    }
  }

  return transport!
}
