import {
  ButtonCTA,
  Icon,
  IconHeroCross,
  pxToRem,
  StackMain,
  Title,
} from '@propps-au/ui'
import React from 'react'
import { FrameContentLayout } from '../../components/frame-content-layout'
import { useFrameTransport } from '../../components/FrameTransport'

export function Listing404() {
  const { send } = useFrameTransport()

  return (
    <StackMain variant="topCenter">
      <FrameContentLayout>
        <Icon svg={IconHeroCross} size={pxToRem(128)} />
        <Title sub="for this property">Offers are unavailable</Title>
        <p className="grey">
          If you would like to purchase this property, get in touch with the
          agent directly.
        </p>

        <ButtonCTA
          type="submit"
          onClick={() => send({ type: 'close' })}
          fixed={false}
        >
          Close
        </ButtonCTA>
      </FrameContentLayout>
    </StackMain>
  )
}
