import { z } from 'zod'

export enum _EventType {
  OPEN_EMBEDDED = 'open-embedded',
}

export namespace EmbeddedEvent {
  export import EventType = _EventType

  interface IEmbeddedEvent {
    type: string
    userId?: string
    agentId?: string
    products?: string[]
  }

  const BaseSchema = z.object({
    userId: z.string().optional(),
    agentId: z.string().optional(),
    products: z.array(z.string()).optional(),
  })

  export interface OpenEmbedded extends IEmbeddedEvent {
    type: _EventType.OPEN_EMBEDDED
  }

  export const OpenEmbeddedSchema = BaseSchema.extend({
    type: z.literal(_EventType.OPEN_EMBEDDED),
  })

  export type Event = OpenEmbedded

  export const Validators: { [key in _EventType]: z.ZodObject<any> } = {
    [_EventType.OPEN_EMBEDDED]: OpenEmbeddedSchema,
  }
}
